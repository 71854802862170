import PipelineNodeIcon, { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeName, { PipelineNodeFieldName } from "@components/pipelineNodes/PipelineNodeName.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { ReportBuilderMeasureORM, useMeasures } from "@models/reportBuilder";
import { getPromptAnswer } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import toast from "@services/toast.service";
import { usePipelineNodes } from "@stores/data.store";
import { node } from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const MetricsListWidget = () => {
    const metrics = useMeasures();

    const [search, setSearch] = useState('');

    const metricsToShow = useMemo(() => {
        if (!metrics.data) {
            return [];
        }
        return metrics.data.filter(a => a.name.toLowerCase().indexOf(search.toLowerCase()) >= 0).sort((a, b) => a.name.localeCompare(b.name));
    }, [metrics.dataUpdatedAt, search]);

    const navigate = useNavigate();
    const createNewMetric = useCallback(async () => {
        const newName = await getPromptAnswer('What do you want to name this metric?', 'New Metric');
        if (!newName) {
            return;
        }

        try {
            const newMetric = await ReportBuilderMeasureORM.save({
                id: null,
                name: newName,
                column_name: newName,
                description: '',
            });
            navigate('/metric/' + newMetric.id);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, []);
    return <>
        <div className="d-flex center-vertically mb-2">
            <input type="text" className="form-control input-rounded" style={{width: '50%'}} placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />

            <div className="flex-1">
            </div>
            <div className="btn-group">
                <Link to="/semantic-layer" className="btn btn-outline-primary">
                    <i className="mdi mdi-star"></i> Visual Editor
                </Link>
                <DraftOnly>
                    <button className="btn btn-outline-primary" onClick={createNewMetric}>
                        <i className="mdi mdi-plus-thick"></i> New Metric
                    </button>
                </DraftOnly>
                
            </div>
        </div>
        <div className="list-group">
            {metricsToShow.map((metric, i) => {
                return <div key={metric.id} className="list-group-item">
                    <div className="d-flex center-vertically">
                        <DashboardIcon icon="mdi mdi-pound-box" bgColor="success"/>
                        
                        <div className="me-3">
                            <h3 className="mb-0">{metric.name}</h3>
                            <div className="font-poppins text-muted font-13">
                                {metric.description}
                            </div>
                        </div>
                        <div className="flex-1">
                            
                        </div>
                        <div>
                            <Link to={`/metric/${metric.id}`} className="btn btn-light btn-sm me-1"><i className="mdi mdi-cog"></i> Configure</Link>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}

export default MetricsListWidget;