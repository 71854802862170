import LoadingCard from "@components/card/LoadingCard.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import { DraftOnly, ProdOnly } from "@components/project/DraftModeRequired.component";
import { Template } from "@pages/Template/MyTemplates.page";
import ApiService from "@services/api/api.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { invalidateEverything } from "@stores/data.store";
import { useCallback, useState } from "react";
import { Modal } from "react-bootstrap";
import { useQuery } from "react-query";

interface TemplateInstallation {
    id: string;
    template_id: string;
    latest_version: number;
    template: Template;
}

interface InstallationResponse {
    records: TemplateInstallation[];
}

export const useTemplateInstallations = () => {
    return useQuery('template_installations', async () => {
        const response = await ApiService.getInstance().request('GET', '/templates/installations') as InstallationResponse;
        return response.records;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000
    });
}

const TemplateInstallations = () => {
    const installations = useTemplateInstallations();

    const [upgrading, setUpgrading] = useState(false);
    const upgrade = useCallback(async (installationId: string, skipMappings: boolean = false) => {
        setUpgrading(true);
        try {
            const result = await ApiService.getInstance().request('POST', `/templates/upgrade/${installationId}`, {skipMappings});
            toast('success', 'Success!', 'Template upgraded');
            invalidateEverything();
        } catch (err) {
            toast('danger', 'Upgrade Failed', getErrorMessage(err));
        } finally {
            setUpgrading(false);
        }
    }, []);

    return <div>
        {upgrading && <Modal show>
            <Modal.Body>
                <LoadingCard/>
            </Modal.Body>    
        </Modal>}
        <h2>Your Installed Templates</h2>
        {installations.isLoading && <PliableLoader/>}
        {!installations.isLoading && installations.data && <ul className="list-group">
            {installations.data.map(r => {
                return <li className="list-group-item">
                    <h4>{r.template.name}</h4>
                    <div className="small-text text-muted">
                        Current Version: {r.latest_version} &bull; Latest Version: {r.template.version}
                    </div>
                    {r.latest_version < r.template.version && <div>
                        <DraftOnly>
                            <button onClick={() => {
                                upgrade(r.id)
                            }} className="btn btn-sm btn-outline-secondary">Upgrade</button>

                        </DraftOnly>
                        <DraftOnly>
                            <button onClick={() => {
                                upgrade(r.id, true)
                            }} className="btn btn-sm btn-outline-secondary">Upgrade w/o Mapping</button>

                        </DraftOnly>
                        <ProdOnly>
                            <small>Enter develop mode to upgrade.</small>
                        </ProdOnly>
                    </div>}
                    {r.latest_version == r.template.version && <div>
                        <DraftOnly>
                            <button onClick={() => {
                                upgrade(r.id)
                            }} className="btn btn-sm btn-outline-secondary">
                                Reinstall
                            </button>
                        </DraftOnly>    
                        <DraftOnly>
                            <button onClick={() => {
                                upgrade(r.id, true)
                            }} className="btn btn-sm btn-outline-secondary">Reinstall w/o Mapping</button>

                        </DraftOnly>
                    </div>}
                </li>
            })}
        </ul>}
    </div>

}

export default TemplateInstallations;