import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Outlet } from "react-router-dom";

import { ToastContainer } from 'react-toastify';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';


import { AlertsContainer } from '@services/alert/alert.service';

import {
    QueryClientProvider
} from 'react-query';
import { queryClient } from '@stores/data.store';

import "allotment/dist/style.css";

import useGlobalState from '@stores/global.state';

import AuthService from '@services/auth/auth.service';
import ConfigService from '@services/config/config.service';
import styled from 'styled-components';
import { ReactFlowProvider } from 'reactflow';
import { FronteggProvider, FronteggThemeOptions } from '@frontegg/react';
import { FullPageLoader } from '@components/loaders/FullPageLoader.component';


declare global {
    interface Window { 
        analytics: any; 
        LogRocket: any;
        thenaWidget: any;
    }
}

function usePageViews(){
    const location = useLocation();
    useEffect(() => {
        ConfigService.getInstance().checkRouting();
        window.analytics.page(location.pathname);
    }, [location]);
}

const ThenaWidgetStyles = styled.button`
    position: fixed;
    bottom: 1rem;;
    right: 1rem;
    height: 50px;
    border-radius: 25px;
    line-height: 50px;
    text-align: center;
    display: flex;
    align-items: center;
    color: white;
    font-size: 24px;
    background-color: var(--pliable-yellow);
    border: none;
    padding: 0px 1rem;

    transition: scale 0.15s ease-in-out;
      

    i {

    }

    span {
        font-family: "Poppins";
        font-size: 18px;
        margin-left: 0.5rem;
    }

    &:hover {
        transform: scale(1.1);
        cursor: pointer;
    }
`

const contextOptions = {
    baseUrl: AuthService.getInstance().authUrl,
    clientId: AuthService.getInstance().feClientId,
    appId: AuthService.getInstance().feAppId,
  };
  
  const authOptions = {
    keepSessionAlive: true,
    routes: {
      loginUrl: '/login',
      logoutUrl: '/do/logout',
      authenticatedUrl: '/afterlogin',
      // signUpUrl: '' // disable frontegg signup page
    }
  };
  
  const themeOptions: FronteggThemeOptions = {
    loginBox: {
      // login: {
      //   signupMessage:
      //     '<div style="text-align:center;">Dont have an account? <a href="/signup">Sign up</a></div>',
      // },
    },
  };



function App() {
    const [fronteggLoading, setFronteggLoading] = useState(true);
    usePageViews();
    const globalState = useGlobalState();

    const location = useLocation();

    useEffect(() => {
        if (location && !location.pathname.includes('/account/') &&  !['/', '/afterlogin', '/login', '/logout', '/do/logout', '/unable-to-connect'].includes(location.pathname)) {
            // this allows us to caputre the path of the page they entered from
            // if they are forced to login, we redirect them to returnPath after successfull login
            localStorage.setItem('returnPath', location.pathname + location.search);
        }
      }, [location]);

    return (
        <>
            <FronteggProvider customLoader={setFronteggLoading} authOptions={authOptions} contextOptions={contextOptions} themeOptions={themeOptions} hostedLoginBox={false} entitlementsOptions={{ enabled: true }} >
                <QueryClientProvider client={queryClient}>
                    <ToastContainer />
                    <AlertsContainer/>
                    <ReactFlowProvider>
                        <Outlet/>
                    </ReactFlowProvider>
                </QueryClientProvider>
            </FronteggProvider>

            { fronteggLoading && <FullPageLoader />  }
        </>
    );
}

export default App;
