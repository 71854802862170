import useGlobalState from '@stores/global.state';
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import { useAuth, useAuthActions, useAuthUser } from '@frontegg/react';
import { useEffect, useState } from 'react';
import AuthService from '@services/auth/auth.service';
import ConfigService from '@services/config/config.service';
import { useDraftVersionId } from '@stores/data.store';
import { FullPageLoader } from '@components/loaders/FullPageLoader.component';
import { showKeyboardShortcuts } from '@services/alert/alert.service';
import { useHotkeys } from 'react-hotkeys-hook';

const PATHS_WITH_NO_CONFIG = [
    "/signup",
    "/welcome",
    "/create-account",
    "/unable-to-connect"
  ];

const HelpButton = styled.button`
    

position: fixed;
z-index: 5000;
background-color: var(--pliable-yellow);
border: none;
font-family: "Poppins";
font-weight: bold;
font-size: 20px;
color: white;
padding: 1rem 2rem;
border-radius: 15px;
bottom: 2rem;
right: 2rem;
box-shadow: 1px 1px 5px 0px #555;

&:hover {
    background-color: var(--ct-primary);
}

    
`

const ToggleLibraryButton = styled.button`

`

const AuthenticatedRootPage = () => {
    // This will redirect to login in case we are not logged in
    const user = useAuthUser();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const { switchTenant } = useAuthActions();

    const [loadingConfig, setLoadingConfig] = useState(true);
    const authState = useAuth();

    // we want this to load high up in the app to force cached branch to load
    const {data: draftVersionId} = useDraftVersionId();

    useHotkeys('meta+/', () => {
        console.log('showing keyboard shortcuts');
        showKeyboardShortcuts();
        
    }, []);

    useEffect(() => {
        AuthService.getInstance().setAuthState(authState);
        if(!PATHS_WITH_NO_CONFIG.includes(window.location.pathname)){
            const inbound_tenant_id = searchParams.get('inbound_tenant_id');
            if(!!inbound_tenant_id && authState?.user?.tenantId != inbound_tenant_id){
                switchTenant({ tenantId: inbound_tenant_id });
                navigate(window.location.pathname);
            }else{
                ConfigService.getInstance().loadConfig().then(() => setLoadingConfig(false));
            }            
        }else{
            setLoadingConfig(false);
        }
    }, []);


    const sidebarExpanded = useGlobalState((state:any) => state.sidebarExpanded);
    const dataLibraryVisible = useGlobalState((state: any) => !state.libraryCollapsed)
    // const dataLibraryVisible = false;
    // const dataLibraryEnabled = false;

    const dataLibraryEnabled = useGlobalState((state: any) => state.libraryEnabled);
    const toggleDataLibrary = useGlobalState((state: any) => state.toggleLibrary);
    const setLibraryEnabled = useGlobalState((state: any) => state.setLibraryEnabled);
    const location = useLocation();

    let className = 'd-flex flex-nowrap';

    if (dataLibraryEnabled && dataLibraryVisible) {
        className = className + ' data-library';
    }


    

    useEffect(() => {
        // Default to true and let individual pages hide it
        setLibraryEnabled(true);
    }, [location]);

    if (loadingConfig) {
        return <FullPageLoader />;
      }

    return (
        <>
            <Outlet/>
        </>
    );
}

export default AuthenticatedRootPage;