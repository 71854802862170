import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import SaveButton from "@components/button/SaveButton.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import EditableText from "@components/general/EditableText.component";
import { comparatorOptions, comparatorsWithValues } from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeRelationshipParentLookupEditor from "@components/pipelineNodes/PipelineNodeRelationshipParentLookupEditor.component";
import { DataWhitelist, PipelineNode, PipelineNodeField, PipelineNodeRelationship, PipelineNodeRelationshipParentLookup } from "@models/pipelineNode";
import { ReportBuilderMeasure, useMeasures } from "@models/reportBuilder";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { alert, requireConfirmation } from "@services/alert/alert.service";
import ApiService from "@services/api/api.service";
import { autoLayout } from "@services/diagram.service";
import { getErrorMessage } from "@services/errors.service";
import { shortid } from "@services/id.service";
import { useRouteBlocker } from "@services/routing.service";
import toast from "@services/toast.service";
import { enterDraftMode, invalidateEverything, useIsInDraftMode, usePipelineNodeRelationships, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";
import { Link } from "react-router-dom";
import ReactFlow, { Node, Edge, NodeProps, Handle, Position, ConnectionMode, NodeChange, NodePositionChange, Background, MiniMap, Controls, MarkerType, useViewport, ReactFlowInstance } from "reactflow";
import styled from "styled-components";
import { useImmer } from "use-immer";
import React, { type FC } from 'react';
import { BlockInProd, DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import ConfirmationModal from "@components/alert/ConfirmationModal.component";
import Warning from "@components/statusIndicators/Warning.component";

const getErrorsFromMetric = (m: ReportBuilderMeasure): string[] => {
    let errors: string[] = [];
    if (!m.is_calculation) {
        if (!m.pipeline_node_id) {
            errors.push('This metric is not connected to an entity.');
        }
        if (!m.is_calculation && !m.aggregator) {
            errors.push('This metric does not have a rollup type.');
        }
        if (!m.is_calculation && !m.field_id) {
            errors.push('This metric does not have a source column.');
        }
    }
   
    if (m.is_calculation && !m.formula) {
        errors.push('This metric is a calculated metric but does not have a formula.');
    }
    return errors;
}



interface Template {
    id: string;
    label: string;
    description: string;
    nodes: PipelineNode[];
    relationships: PipelineNodeRelationship[];
}

const Templates: Template[] = [
    {
        id: 'B2B_SALES_MARKETING',
        label: 'B2B Sales & Marketing',
        description: 'asdasdf',
        relationships: [{
            id: 'RELATIONS1',
            name: 'Company to Contact',
            parent_node_id: 'CUSTOMER__',
            child_node_id: 'CONTACT___',
            child_foreign_key_name: 'company_id',
            parent_lookups: [{
                parent_field_id: 'COMP_ID_PARENT',
                child_field_id: 'COMP_ID_CHILD',
            }],
            parent_lookup_logic_gate: 'AND',
            description: 'Connection between companies and the contacts that work for them',
            denormalized_fields: [],
        }],
        nodes: [
            {
                id: 'CONTACT___',
                label: 'Contacts',
                description: 'Individual people who work for your target companies',
                upstream_node_ids: [],
                node_type: 'DIMENSION',
                table_name: '',
                name: 'contacts',
                diagram_x: 100,
                diagram_y: 500,
                fields: [
                    {
                        id: shortid(),
                        name: 'First Name',
                        label: 'First Name',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The first name of the contact',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Last Name',
                        label: 'Last Name',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The last name of the contact',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Email',
                        label: 'Email',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The email address of the contact',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Phone',
                        label: 'Phone',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The phone number of the contact',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Title',
                        label: 'Title',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The title of the contact',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: 'COMP_ID_CHILD',
                        name: 'Company ID',
                        label: 'Company ID',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The ID of the company the contact works for (from your CRM)',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    }
                ]
            },
            {
                id: 'CUSTOMER__',
                label: 'Customers',
                description: 'Companies your business sells to',
                name: 'customers',
                upstream_node_ids: [],
                node_type: 'DIMENSION',
                table_name: '',
                diagram_x: 300,
                diagram_y: 100,
                fields: [
                    {
                        id: 'COMP_ID_PARENT',
                        name: 'ID',
                        label: 'ID',
                        part_of_composite_key: true,
                        type: 'STRING',
                        description: 'The ID of the company (from your CRM)',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Company Name',
                        label: 'Company Name',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The name of the company',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Website',
                        label: 'Website',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The website of the company',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Size',
                        label: 'Size',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The size of the company',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                    {
                        id: shortid(),
                        name: 'Industry',
                        label: 'Industry',
                        part_of_composite_key: false,
                        type: 'STRING',
                        description: 'The industry the company is in',
                        map_options: [],
                        taxonomic_id: '',
                        cell_actions: [],
                    },
                ],
            }
        ]
    }
];

const TemplateOptions = Templates.map(t => {
    return {
        label: t.label,
        description: t.description,
        value: t.id,
    }
});

interface RelationshipEditorProps {
    rel: PipelineNodeRelationship;
    parentNode: PipelineNode;
    childNode: PipelineNode;
    onChange: (rel: PipelineNodeRelationship) => void;
    onCancel: () => void;
    onDelete: () => void;

    onCreatePeerRelationship: (rel: PipelineNodeRelationship) => void;
    onSwitchRelationship: (relationshipId: string) => void;
    allWorkingRelationships: PipelineNodeRelationship[];
}

const RelationshipEditor = (props: RelationshipEditorProps) => {
    const [name, setName] = useState('');
    const [childForeignKeyName, setChildForeignKeyName] = useState('');
    const [parentLookups, setParentLookups] = useImmer<PipelineNodeRelationshipParentLookup[]>([]);
    const [parentLookupLogicGate, setParentLookupLogicGate] = useState('AND');
    const [description, setDescription] = useState('');

    const onDelete = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this relationship?');
        if (confirmed) {
            props.onDelete();
        }
    }, [props.onDelete]);

    const saveAble = useMemo(() => {
        if (!props.rel) {
            return false;
        }
        if (name != props.rel.name) {
            return true;
        }

        if (parentLookups.length != props.rel.parent_lookups.length) {
            return true;
        }

        for (let i = 0; i < parentLookups.length; i++) {
            if (parentLookups[i] != props.rel.parent_lookups[i]) {
                return true;
            }
        }

        if (parentLookupLogicGate != props.rel.parent_lookup_logic_gate) {
            return true;
        }

        if (description != props.rel.description) {
            return true;
        }


        return false;
    }, [name, childForeignKeyName, parentLookups, parentLookupLogicGate, description, props.rel]);

    const onSave = useCallback(() => {
        props.onChange({
            ...props.rel,
            name: name,
            child_foreign_key_name: childForeignKeyName,
            parent_lookups: parentLookups,
            parent_lookup_logic_gate: parentLookupLogicGate,
            description: description,
        });
    }, [name, childForeignKeyName, parentLookups, parentLookupLogicGate, description, props.onChange]);

    useEffect(() => {
        if (!props.rel) {
            return;
        }
        setName(props.rel.name);
        setParentLookups(props.rel.parent_lookups || []);
        setParentLookupLogicGate(props.rel.parent_lookup_logic_gate);
        setDescription(props.rel.description);
    }, []);

    const swap = useCallback(() => {
        props.onChange({
            ...props.rel,
            parent_node_id: props.childNode.id as string,
            child_node_id: props.parentNode.id as string,
            parent_lookups: parentLookups.map(l => {
                return {
                    parent_field_id: l.child_field_id,
                    child_field_id: l.parent_field_id,
                }
            }),
        });

    }, [props.onChange, props.childNode, props.parentNode, parentLookups]);

    

    return <>
        <Offcanvas.Header className="d-flex justify-content-between align-items-center stick-header">
            <Offcanvas.Title>Relationship Details</Offcanvas.Title>
                <div>
                {(props.rel.id || '').length > 10 && <>
                    <Link className="btn btn-light me-1 btn-sm" to={`/relationship/${props.rel.id}`}>
                        <i className="mdi mdi-open-in-new"></i> Details
                    </Link>
                </>}
                <DraftOnly>
                    <button 
                        disabled={!saveAble}
                        className="btn btn-primary btn-sm me-1" 
                        onClick={onSave}
                    ><i className="mdi mdi-check-bold"></i> Apply Edits</button>
                    <button  className="btn btn-outline-secondary btn-sm  me-1" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        {saveAble && <><i className="mdi mdi-undo"></i> Cancel</>}
                        {!saveAble && <><i className="mdi mdi-close-thick"></i> Close</>}
                        
                    </button>
                    <button
                        className="btn btn-outline-danger btn-sm me-1"
                        onClick={onDelete}
                    >
                        <i className="mdi mdi-delete"></i> Delete
                    </button>
                </DraftOnly>
                <ProdOnly>
                    <button  className="btn btn-outline-secondary btn-sm" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        <i className="mdi mdi-close-thick"></i> Close
                    </button>
                </ProdOnly>
                    
                </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Pane>
                <PaneContent>
                    <ReadOnly>
                        <div className="p-2">
                            <div className="shadow-box p-2">
                                <div className="row">
                                    <div className="col-4 text-end">
                                        <Form.Group className="">
                                            <Form.Label>Parent</Form.Label>
                                            <div>{props.parentNode.label}</div>
                                        </Form.Group>
                                    </div>
                                    <div className="col-4">
                                        <button className="btn btn-outline-primary w-100" onClick={swap}>
                                            <i className="mdi mdi-swap-horizontal font-18"></i> Swap
                                        </button>
                                    </div>
                                    <div className="col-4">
                                        <Form.Group className="">
                                            <Form.Label>Child</Form.Label>
                                            <div>{props.childNode.label}</div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            <hr />
                            <h3><i className="mdi mdi-information"></i> Basic Configuration</h3>
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                <Form.Text>Give your relationship a name to distinguish it from other relationships between the same entities.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </Form.Group>
                            <hr />
                            <h3>Relationship Resolution</h3>
                            <p>Control how Pliable determines whether two records are related. If values in both columns are equal, the records will be related.</p>
                            {parentLookups.length > 1 && <>
                                <Form.Check
                                    type="switch"
                                    label={parentLookupLogicGate == 'AND' ? 'Match ALL' : 'Match ANY'}
                                    checked={parentLookupLogicGate === 'AND'}
                                    onChange={(e) => {
                                        parentLookupLogicGate == 'AND' ? setParentLookupLogicGate('OR') : setParentLookupLogicGate('AND');
                                    }}
                                />
                            </>}
                            <PipelineNodeRelationshipParentLookupEditor
                                parentNode={props.parentNode}
                                childNode={props.childNode}
                                parentLookups={parentLookups}
                                onChange={(lookups) => {
                                    setParentLookups(lookups);
                                }}
                            />
                        </div>
                    </ReadOnly>
                </PaneContent>
            </Pane>
        </Offcanvas.Body>
    </>;
}

interface MetricEditorProps {
    metric: ReportBuilderMeasure;
    workingMeasures: ReportBuilderMeasure[];
    workingNodes: PipelineNode[];
    workingRelationships: PipelineNodeRelationship[];
    onChange: (metric: ReportBuilderMeasure) => void;
    onCancel: () => void;
    onDelete: () => void;
    onAddColumnToNode: (nodeId: string, field: PipelineNodeField) => void;
}

const MetricEditor = (props: MetricEditorProps) => {
    const [name, setName] = useState('');
    const [columnHeader, setColumnHeader] = useState('');
    const [description, setDescription] = useState('');
    const [sourceNodeId, setSourceNodeId] = useState('');
    const [sourceFieldId, setSourceFieldId] = useState('');
    const [parentMetricIds, setParentMetricIds] = useState<string[]>([]);
    const [rollupType, setRollupType] = useState('');
    const [isCalculated, setIsCalculated] = useState(false);
    const [formula, setFormula] = useState('');


    const [newFieldName, setNewFieldName] = useState('');
    const [newFieldType, setNewFieldType] = useState('STRING');

    const [whitelist, setWhitelist] = useImmer<DataWhitelist>({
        entries: [],
        logic_gate: 'OR',
    });


    const onDelete = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this metric?');
        if (confirmed) {
            props.onDelete();
        }
    }, [props.onDelete]);

    const saveAble = useMemo(() => {
        if (!props.metric) {
            return false;
        }
        if (name != props.metric.name) {
            return true;
        }

        if (columnHeader != props.metric.column_name) {
            return true;
        }

        if (description != props.metric.description) {
            return true;
        }

        if (sourceNodeId != props.metric.pipeline_node_id) {
            return true;
        }

        if (rollupType != props.metric.aggregator) {
            return true;
        }

        if (isCalculated != props.metric.is_calculation) {
            return true;
        }

        if (parentMetricIds.length != props.metric.parent_measure_ids?.length) {
            return true;
        }

        if (formula != props.metric.formula) {
            return true;
        }

        for (let i = 0; i < parentMetricIds.length; i++) {
            if (parentMetricIds[i] != props.metric.parent_measure_ids[i]) {
                return true;
            }
        }

        // Check if the white list has changed at all
        if (whitelist.entries.length != props.metric.data_whitelist?.entries?.length) {
            return true;
        }
        
        for (let i = 0; i < whitelist.entries.length; i++) {
            const entry = whitelist.entries[i];
            const oldEntry = props.metric.data_whitelist?.entries[i];
            if (entry.pipeline_node_id != oldEntry?.pipeline_node_id) {
                return true;
            }

            if (entry.field_id != oldEntry?.field_id) {
                return true;
            }

            if (entry.comparator != oldEntry?.comparator) {
                return true;
            }

            if (entry.value != oldEntry?.value) {
                return true;
            }
        }

        return false;
    }, [name, description, sourceNodeId, rollupType, isCalculated, parentMetricIds, props.metric, whitelist, columnHeader, formula]);

    const errors = useMemo(() => {
        return getErrorsFromMetric({
            id: props.metric.id,
            column_name: columnHeader,
            name: name,
            description: description,
            field_id: sourceFieldId,
            pipeline_node_id: sourceNodeId,
            parent_measure_ids: parentMetricIds,
            aggregator: rollupType,
            is_calculation: isCalculated,
            data_whitelist: whitelist,
            formula: formula,
        });
    }, [isCalculated, rollupType, sourceNodeId, sourceFieldId, parentMetricIds, formula, columnHeader]);

    const nodeOptionsForFiltering = useMemo(() => {
        if (!sourceNodeId) {
            return [];
        }

        function getConnectedNodes(node: PipelineNode, skipNodeIds: string[] = []): PipelineNode[] {
            const connectedNodes: PipelineNode[] = [];
            props.workingRelationships.forEach(rel => {
                if ((rel.parent_node_id == node.id && !skipNodeIds.includes(rel.child_node_id)) || (rel.child_node_id == node.id && !skipNodeIds.includes(rel.parent_node_id))) {
                    const connectedNode = props.workingNodes.find(n =>n.id != node.id && (n.id == rel.child_node_id || n.id == rel.parent_node_id));
                    if (connectedNode) {
                        skipNodeIds.push(node.id);
                        connectedNodes.push(connectedNode);
                        connectedNodes.push(...getConnectedNodes(connectedNode, skipNodeIds));
                    }
                }
                
            });
            return connectedNodes;
        }

        const sourceNode = props.workingNodes.find(n => n.id == sourceNodeId);
        if (!sourceNode) {
            return [];
        }

        const allConnectedNodes = [sourceNode].concat(getConnectedNodes(sourceNode));
        return allConnectedNodes;
    }, [props.workingNodes, props.workingRelationships, sourceNodeId]);

    const allFilterableFieldOptions = useMemo(() => {
        const options: Option[] = [];
        nodeOptionsForFiltering.forEach(n => {
            n.fields.forEach(f => {
                if (['FOREIGN_KEY'].includes(f.type)) {
                    return;
                }
                options.push({
                    label: `${n.label} - ${f.name}`,
                    value: `${n.id}:${f.id}`,
                });
            })
        });
        return options;
    }, [nodeOptionsForFiltering]);

    

    useEffect(() => {
        if (!props.metric) {
            return;
        }
        setName(props.metric.name);
        setFormula(props.metric.formula || '');
        setDescription(props.metric.description);
        setSourceFieldId(props.metric.field_id || '');
        setSourceNodeId(props.metric.pipeline_node_id || '');
        setParentMetricIds(props.metric.parent_measure_ids || []);
        setRollupType(props.metric.aggregator || '');
        setIsCalculated(props.metric.is_calculation || false);
        setWhitelist(props.metric.data_whitelist || {entries: [], logic_gate: 'OR'});
        setColumnHeader(props.metric.column_name || props.metric.name);
    }, [props.metric]);

    const columnOptions = useMemo(() => {
        if (!props.workingNodes) {
            return [];
        }

        const options: Option[]  = props.workingNodes.find(n => n.id == sourceNodeId)?.fields.filter(f => f.type != 'FOREIGN_KEY').map(f => {
            return {
                label: f.name,
                value: f.id as string,
            }
        }) || [];
        options.unshift({
            label: 'Pliable Record ID',
            value: '_PLB_UUID'
        });
        options.push({
            label: '[Add New Column]',
            value: 'NEW',
        });
        return options;
    }, [props.workingNodes, sourceNodeId]);

    const aggregationOptions = useMemo(() => {
        let fieldType: string;
        if (sourceFieldId == 'NEW') {
            fieldType = newFieldType;
        } else {
            const selectedField = props.workingNodes.find(n => n.id == sourceNodeId)?.fields.find(f => f.id == sourceFieldId);
            fieldType = selectedField?.type || 'STRING';
        }
        
        const options: Option[] = [{
            value: 'COUNT_DISTINCT',
            label: 'Count Distinct',
        }, {
            value: 'PICK_ONE',
            label: 'Random',
        }];

        switch (fieldType) {
            case 'DATE':
            case 'DATETIME':
            case 'DATETIME_TZ':
                options.push({
                    value: `MIN`,
                    label: 'Min'
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                break;
            case 'INT':
            case 'DECIMAL':
                options.push({
                    value: `MIN`,
                    label: 'Min',
                });
                options.push({
                    value: `MAX`,
                    label: 'Max',
                });
                options.push({
                    value: `AVG`,
                    label: 'Avg'
                });
                options.push({
                    value: `SUM`,
                    label: 'Sum'
                });
                break;
            case 'STRING':
                options.push({
                    value: 'CONCAT',
                    label: 'Comma-separated list of unique values',
                });
                break;
        }
        return options;

    }, [sourceFieldId, sourceNodeId, props.workingNodes, newFieldType]);

    const onSave = useCallback(() => {
        // Figure out if we have to add a new field
        let sourceFieldToUse: string = sourceFieldId;
        if (sourceFieldId == 'NEW') {
            if (!newFieldName) {
                toast('danger', 'Error', 'Please enter a name for the new column');
                return;
            }
            if (!newFieldType) {
                toast('danger', 'Error', 'Please select a type for the new column');
                return;
            }
            sourceFieldToUse = shortid();
            props.onAddColumnToNode(sourceNodeId, {
                id: sourceFieldToUse,
                name: newFieldName,
                label: newFieldName,
                type: newFieldType,
                description: '',
                part_of_composite_key: false,
                map_options: [],
                taxonomic_id: '',
                cell_actions: [],
            });
        }
        const metricData = {
            ...props.metric,
            name: name,
            column_name: columnHeader, 
            description: description,
            field_id: sourceFieldToUse,
            pipeline_node_id: sourceNodeId,
            parent_measure_ids: parentMetricIds,
            aggregator: isCalculated ? undefined : rollupType, // Skip aggregation for calculated metrics
            is_calculation: isCalculated,
            data_whitelist: whitelist,
            formula: formula,
        };
        props.onChange(metricData);
    }, [name, description, sourceFieldId, sourceNodeId, parentMetricIds, rollupType, isCalculated, props.onChange, whitelist, newFieldName, newFieldType, formula, columnHeader]);

    const selectedNodeLabel = useMemo(() => {
        const node = props.workingNodes.find(n => n.id == sourceNodeId);
        return node?.label || '';
    }, [sourceNodeId, props.workingNodes]);

    const formulaVariables = useMemo(() => {
        return props.workingMeasures.filter(m => parentMetricIds.includes(m.id as string)).map(m => '"' + m.column_name + '"') || [];
    }, [parentMetricIds, props.workingMeasures]);


    return <>
        <Offcanvas.Header className="d-flex justify-content-between align-items-center stick-header">
            <Offcanvas.Title>Edit Metric</Offcanvas.Title>
            <div>
                {(props.metric.id || '').length > 10 && <>
                    <Link className="btn btn-light me-1 btn-sm" to={`/metric/${props.metric.id}`}>
                        <i className="mdi mdi-open-in-new"></i> Details
                    </Link>
                </>}
                
                <DraftOnly>
                    <button 
                        disabled={!saveAble}
                        className="btn btn-primary btn-sm me-1" 
                        onClick={onSave}
                    ><i className="mdi mdi-check-bold"></i> Apply Edits
                    </button>
                    <button  className="btn btn-outline-secondary btn-sm  me-1" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        {saveAble && <><i className="mdi mdi-undo"></i> Cancel</>}
                        {!saveAble && <><i className="mdi mdi-close-thick"></i> Close</>}
                        
                    </button>
                    <button
                        className="btn btn-outline-danger btn-sm me-1"
                        onClick={onDelete}
                    >
                        <i className="mdi mdi-delete"></i> Delete
                    </button>
                </DraftOnly>
                <ProdOnly>
                    <button  className="btn btn-outline-secondary btn-sm" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        <i className="mdi mdi-close-thick"></i> Close
                    </button>
                </ProdOnly>
                
            </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Pane>
                <PaneContent>
                    <ReadOnly>
                        
                        <div className="p-2">
                            
                            {errors.length > 0 && <div>
                                <div>
                                    {errors.map(e => <Warning>{e}</Warning>)}
                                </div>
                            </div>}
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                                <Form.Text>Used to reference this metric in the Pliable application.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Column Header</Form.Label>
                                <Form.Control type="text" value={columnHeader} onChange={(e) => setColumnHeader(e.target.value)}/>
                                <Form.Text>Defines the column header for any tables using this metric.</Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="switch"
                                    id="isCalculated"
                                    label="Calculated"
                                    checked={isCalculated}
                                    onChange={(e) => setIsCalculated(e.target.checked)}
                                />
                            </Form.Group>
                            {!isCalculated && <div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Source Node</Form.Label>
                                    <Dropdown
                                        options={props.workingNodes.map(n => {
                                            return {
                                                label: n.label,
                                                value: n.id as string,
                                            }
                                        })}
                                        selected={sourceNodeId}
                                        onChange={(v) => setSourceNodeId(v)}
                                    />
                                </Form.Group>
                                {sourceNodeId && <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Select Column</Form.Label>
                                        <Dropdown
                                            options={columnOptions}
                                            selected={sourceFieldId}
                                            onChange={(v) => setSourceFieldId(v)}
                                        />
                                        {sourceFieldId == 'NEW' && 
                                        <div className="shadow-box bg-light p-2 mt-2">
                                            <div className="d-flex">
                                                <input type="text" className="form-control flex-1 me-1" placeholder="New Column Name" value={newFieldName} onChange={(e) => {
                                                    setNewFieldName(e.target.value);
                                                }}/>
                                                <BusinessObjectFieldTypeSelector normalSize selected={newFieldType} onSelect={(v) => setNewFieldType(v)} />
                                            </div>
                                            
                                            
                                            
                                            
                                            
                                        </div>
                                        }

                                    </Form.Group>
                                    
                                    <Form.Group className="mb-3">
                                        <Form.Label>Rollup Type</Form.Label>
                                        <Dropdown
                                            options={aggregationOptions}
                                            selected={rollupType}
                                            onChange={(v) => setRollupType(v)}
                                        />
                                        <Form.Text>How should Pliable calculate this metric?</Form.Text>
                                    </Form.Group>
                                    <hr />
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            <i className="mdi mdi-filter"></i> Filters (Optional)
                                        </Form.Label>

                                        <p className="text-muted font-13">Only include {selectedNodeLabel} records that match these rules. You can select columns on {selectedNodeLabel} as well as any connected entities.</p>
                                        {whitelist.entries.length == 0 && <div className="shadow-box p-3">
                                            <p>No filters applied (all <strong>{selectedNodeLabel}</strong> records will be included in this metric.)</p>
                                            <button onClick={() => {
                                                setWhitelist(draft => {
                                                    draft.entries.push({
                                                        pipeline_node_id: '',
                                                        field_id: '',
                                                        comparator: '',
                                                        value: '',
                                                    });
                                                });
                                            }} className="btn btn-light">
                                                <i className="mdi mdi-plus-thick"></i> Add Filter
                                            </button>
                                        </div>}
                                        {whitelist.entries.length > 0 && <>
                                        <table className="table table-fixed table-condensed table-bordered table-compact table-centered">
                                                <thead className="bg-light font-poppins">
                                                    <tr>
                                                        <th style={{width: '30%'}}>Column</th>
                                                        <th style={{width: '30%'}}>Comparator</th>
                                                        <th style={{width: '30%'}}>Value</th>
                                                        <th className="text-end">
                                                            <button className="icon-button font-18" onClick={() => {
                                                                setWhitelist(draft => {
                                                                    draft.entries.push({
                                                                        pipeline_node_id: '',
                                                                        field_id: '',
                                                                        comparator: '',
                                                                        value: '',
                                                                    });
                                                                });
                                                            }}>
                                                                <i className="mdi mdi-plus-thick"></i>
                                                            </button>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {whitelist.entries.map((e, idx) => {
                                                        const node = props.workingNodes.find(n => n.id == e.pipeline_node_id);
                                                        const field = node?.fields.find(f => f.id == e.field_id);
                                                        return <tr>
                                                             <td style={{width: '30%'}}>
                                                                <Dropdown
                                                                    size="sm"
                                                                    options={allFilterableFieldOptions}
                                                                    selected={e.pipeline_node_id + ':' + e.field_id}
                                                                    onChange={(newval) => {
                                                                        setWhitelist(draft => {
                                                                            const spl = newval.split(':');
                                                                            draft.entries[idx].pipeline_node_id = spl[0];
                                                                            draft.entries[idx].field_id = spl[1];
                                                                        });

                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{width: '30%'}}>
                                                                <Dropdown
                                                                    size="sm"
                                                                    options={comparatorOptions}
                                                                    selected={e.comparator}
                                                                    onChange={(newval) => {
                                                                        setWhitelist(draft => {
                                                                            draft.entries[idx].comparator = newval;
                                                                        });

                                                                    }}
                                                                />
                                                            </td>
                                                            <td style={{width: '50%'}}>
                                                                {comparatorsWithValues.includes(e.comparator) && <input type="text" className="form-control" value={e.value} onChange={(e) => {
                                                                    setWhitelist(draft => {
                                                                        draft.entries[idx].value = e.target.value;
                                                                    });
                                                                }}/>}
                                                            </td>
                                                            <td className="text-end">
                                                                <button className="icon-button font-18" onClick={() => {
                                                                    setWhitelist(draft => {
                                                                        draft.entries.splice(idx, 1);
                                                                    });
                                                                }}>
                                                                    <i className="mdi mdi-delete"></i>
                                                                </button>
                                                            </td>
                                                        </tr>;
                                                    })}


                                                </tbody>
                                            </table></>}
                                    </Form.Group>
                                </>}
                                                
                            </div>}

                            {isCalculated && <div>
                                <Form.Group className="mb-3">
                                    <Form.Label>Parent Metrics</Form.Label>
                                    <MultiDropdown
                                        options={props.workingMeasures.filter(m => m.id != props.metric.id).map(m => {
                                            return {
                                                label: m.name,
                                                value: m.id as string,
                                            }
                                        })}
                                        selected={parentMetricIds}
                                        onChange={(v) => setParentMetricIds(v)}
                                    />
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Formula</Form.Label>
                                    <Form.Control className="w-100 font-code" value={formula} as="textarea" onChange={(e) => {
                                        setFormula(e.target.value);
                                    }}/>
                                    <Form.Text>You can use any Snowflake SQL here that can operate in a <code>SELECT</code> statement.</Form.Text>
                                </Form.Group>
                                <Form.Group className="mb-3">
                                    <Form.Label>Available Variables</Form.Label>
                                    <div className="shadow-box p-2 font-code">
                                        {formulaVariables.map(v => {
                                            return <div>{v}</div>
                                        })}
                                    </div>
                                </Form.Group>
                                    
                            </div>}

                                    
                                    
                                    
                        </div>
                    </ReadOnly>
                </PaneContent>
            </Pane>
        </Offcanvas.Body>
    </>
}

interface NodeEditorProps {
    node: PipelineNode;
    onChange: (node: PipelineNode) => void;
    onCancel: () => void;
    onDelete: () => void;
}

const NodeEditor = (props: NodeEditorProps) => {
    const [label, setLabel] = useState('');
    const [description, setDescription] = useState('');
    const [fields, setFields] = useImmer<PipelineNodeField[]>([]);

    const saveAble = useMemo(() => {
        if (!props.node) {
            return false;
        }
        if (label != props.node.label) {
            return true;
        }

        if (description != props.node.description) {
            return true;
        }

        if (fields.length != props.node.fields.length) {
            return true;
        }

        for (let i = 0; i < fields.length; i++) {
            if (fields[i].name != props.node.fields[i].name || fields[i].description != props.node.fields[i].description || fields[i].type != props.node.fields[i].type || fields[i].is_dimension != props.node.fields[i].is_dimension) {
                return true;
            }
        }
        return false;
    }, [props.node, label, description, fields]);

    const onDelete = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this node?');
        if (confirmed) {
            props.onDelete();
        }
    }, [props.onDelete]);

    const onSave = useCallback(() => {
        props.onChange({
            ...props.node,
            label: label,
            description: description,
            fields: fields,
        });
    }, [label, description, fields, props.onChange]);

    useEffect(() => {
        if (!props.node) {
            return;
        }
        setLabel(props.node.label);
        setDescription(props.node.description);
        setFields(props.node.fields || []);
    }, [props.node]);

    const toggleDimension = useCallback((fieldId: string) => {
        setFields(draft => {
            const foundField = draft.find(f => f.id == fieldId);
            if (foundField) {
                foundField.is_dimension = !foundField.is_dimension;
            }
        });
    }, []);

    if (!props.node) {
        return <></>
    }

    return <>
        <Offcanvas.Header className="d-flex justify-content-between align-items-center stick-header">
            <Offcanvas.Title>Edit Entity</Offcanvas.Title>
            <div>
                {(props.node.id || '').length > 10 && <>
                    <Link className="btn btn-light me-1 btn-sm" to={`/node/${props.node.id}/config`}>
                        <i className="mdi mdi-open-in-new"></i> Config
                    </Link>
                </>}
                <DraftOnly>

                    <button 
                        disabled={!saveAble}
                        className="btn btn-primary btn-sm me-1" 
                        onClick={onSave}
                    >
                        <i className="mdi mdi-check-bold"></i> Apply Edits
                    </button>
                    <button  className="btn btn-outline-secondary btn-sm  me-1" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        {saveAble && <><i className="mdi mdi-undo"></i> Cancel</>}
                        {!saveAble && <><i className="mdi mdi-close-thick"></i> Close</>}
                        
                    </button>
                    {props.node.node_type !== 'DATE_DIMENSION' && (
                        <button
                            className="btn btn-outline-danger btn-sm"
                            onClick={onDelete}
                        >
                            <i className="mdi mdi-delete"></i> Delete
                        </button>
                    )}
                </DraftOnly>
                <ProdOnly>
                <button  className="btn btn-outline-secondary btn-sm" onClick={() => {
                        props.onCancel && props.onCancel();
                    }}>
                        <i className="mdi mdi-close-thick"></i> Close
                    </button>
                </ProdOnly>
                
            </div>
        </Offcanvas.Header>
        <Offcanvas.Body>
            <Pane>
                <PaneContent>
                    <ReadOnly>
                        <div className="p-2">
                            <Form.Group className="mb-3">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" value={label} onChange={(e) => setLabel(e.target.value)}/>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Description</Form.Label>
                                <Form.Control as="textarea" value={description} onChange={(e) => setDescription(e.target.value)}/>
                            </Form.Group>
                            <hr />
                            <span className={props.node.node_type === 'DATE_DIMENSION' ? 'disabled' : ''}>
                                <Form.Group>
                                    <div className="d-flex center-vertically">
                                        <Form.Label className="flex-1">Columns</Form.Label>
                                        <button className="btn btn-outline-primary btn-sm" onClick={() => {
                                            setFields(draft => {
                                                draft.push({
                                                    id: shortid(),
                                                    name: 'New Column',
                                                    label: '',
                                                    part_of_composite_key: false,
                                                    type: 'STRING',
                                                    description: '',
                                                    map_options: [],
                                                    taxonomic_id: '',
                                                    cell_actions: [],
                                                });
                                            });
                                        }}>
                                            <i className="mdi mdi-plus-thick"></i> Add Column
                                        </button>
                                    </div>
                                    <p><i className="mdi mdi-label"></i> Toggle columns that you want to use as dimensions.</p>
                                    {/* <Link to={`/node/${props.node.id}/config`}>Node configuration screen</Link> */}
                                    
                                    <div className="list-group">
                                        {fields.filter(f => !['FOREIGN_KEY', 'DENORMALIZED'].includes(f.type)).map((f, idx) => {

                                            return <div className="list-group-item" style={{position: 'relative', overflow: 'visible'}}>
                                                <div className="d-flex center-vertically">
                                                    <div className="me-2">
                                                        <i onClick={() => {
                                                            toggleDimension(f.id)
                                                        }} className={`clickable font-18 mdi mdi-label ${f.is_dimension ? 'text-pliable' : 'text-muted'}`}></i>
                                                    </div>
                                                    <div className="flex-1">
                                                        <strong>
                                                        <EditableText value={f.name} onChange={(v) => {
                                                            setFields(draft => {
                                                                const foundField = draft.find(field => field.id == f.id);
                                                                if (foundField) {
                                                                    foundField.name = v;
                                                                }
                                                            });
                                                        }}/></strong>
                                                        <div className="text-muted font-13">
                                                            <EditableText value={f.description} textarea onChange={(v) => {
                                                                setFields(draft => {
                                                                    const foundField = draft.find(field => field.id == f.id);
                                                                    if (foundField) {
                                                                        foundField.description = v;
                                                                    }
                                                                });
                                                            }} emptyPlaceholder="No description"/>
                                                        </div>
                                                    </div>
                                                    <div className="me-3">
                                                        <BusinessObjectFieldTypeSelector
                                                            selected={f.type}
                                                            onSelect={(v) => {
                                                                setFields(draft => {
                                                                    const foundField = draft.find(field => field.id == f.id);
                                                                    if (foundField) {
                                                                        foundField.type = v;
                                                                    }
                                                                });
                                                            }}
                                                        />
                                                    </div>
                                                    <button className="icon-button font-18" onClick={() => {
                                                        setFields(draft => {
                                                            const foundField = draft.findIndex(field => field.id == f.id);
                                                            if (foundField >= 0) {
                                                                draft.splice(foundField, 1);
                                                            }

                                                        });
                                                    }}>
                                                        <i className="mdi mdi-delete"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </Form.Group>
                            </span>
                        </div>
                        </ReadOnly>
                </PaneContent>
            </Pane>
        </Offcanvas.Body>
        
    </>
}


const RelationshipHandle = styled(Handle)`
width: 16px;
height: 16px;
background-color: var(--pliable-blue);

&:hover {
    cursor:move;
    background-color: var(--pliable-yellow)
}


`

interface PipelineNodeInfo {
    label: string;
    description: string;
    id: string;
    upstreamNodeIds: string[];
}

interface MetricInfo {
    label: string;
    description: string;
    id: string;
    sourceNodeId?: string;
    rollupType?: string;
    sourceMetricIds: string[];
    isCalculated?: boolean;
    error?: string;
}


interface RelationshipInfo {
    name: string;
}

const NodeStyles = styled.div<{topColor?: string}>`
width: 300px;

margin: 1rem;

.shadow-box {
    padding: .5rem;

}

&:hover .shadow-box {
    background-color: var(--ct-light);
}

${props => props.topColor && `
    
    .shadow-box {
        border-top: solid 5px ${props.topColor};
    }
`}
`

function getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

const PipelineNodeNode = (props: NodeProps<PipelineNodeInfo>) => {
    return <NodeStyles topColor="var(--pliable-purple)">
        <div className="shadow-box d-flex center-vertically">
            <div className="me-1">
                <i className="mdi mdi-table font-24 text-muted"></i>
            </div>
            <div>
                <h3 className="mb-0">{props.data.label}</h3>
                <div className="text-muted font-13 lh-compact">{props.data.description}</div>
            </div>
            <DraftOnly>
                <button className="icon-button font-18 ms-auto text-purple" title="Edit">
                    <i className="mdi mdi-cog"></i>
                </button>
            </DraftOnly>
        
        </div>
        
        <RelationshipHandle type="source" position={Position.Right}/>
        <RelationshipHandle type="target" position={Position.Left}/>
    </NodeStyles>
};

const MetricNode = (props: NodeProps<MetricInfo>) => {
    return <NodeStyles topColor="var(--ct-success)">
        <div className="shadow-box d-flex center-vertically">
            <div className="me-1">
                <i className="mdi mdi-pound-box font-24 text-muted"></i>
            </div>
            <div className="flex-1">
                <h3 className="mb-0">{props.data.label}</h3>
                <div className="text-muted font-13 lh-compact">{props.data.description}</div>
            </div>
            {props.data.error && <div className="text-danger me-2 font-18 pointer" title={props.data.error}>
                <i className="mdi mdi-alert-circle"></i>
            </div>}
            
            <DraftOnly>
                <button className="icon-button font-18 " title="Edit">
                    <i className="mdi mdi-cog"></i>
                </button>
            </DraftOnly>
        
        </div>
        
        <RelationshipHandle type="source" position={Position.Right}/>
        <RelationshipHandle type="target" position={Position.Left}/>
    </NodeStyles>
}

const Toolbar = styled.div`
    height: 50px;
    padding: 0px 1rem;
    width: calc(100vw - 330px);
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1000;
    background: white;
    border-bottom: solid 1px var(--ct-border-color);
`

interface EdgeMenuProps {
    parentNodeId: string;
    parentNodeLabel: string;
    childNodeId: string;
    childNodeLabel: string;
    relationships: PipelineNodeRelationship[];
    onCreateNewPeerRelationship: () => void;
    onSelectRelationship: (id: string) => void;
    positionX: number;
    positionY: number;
    onHideMenu: () => void;
}

const EdgeMenuStyles = styled.div<{x: number, y:number}>`
background: var(--ct-dark);
color: white;
border-radius: 4px;
position: absolute;
top: ${props => props.y}px;
left: ${props => props.x}px;
z-index: 1000;
box-shadow: 0 0 5px rgba(0,0,0,.5);
width: 300px;

div.header {
    padding: .25rem .5rem;
    font-weight: bold;
    border-bottom: solid 1px var(--ct-light-gray);

    .icon-button {
        color: white;
    }
}

div.options {
    .option {
        padding: .25rem .5rem;
        font-size: 13px;

        &:hover {
            background: black;
            cursor: pointer;
        }
    }
}
`

const EdgeMenu = (props: EdgeMenuProps) => {
    return <EdgeMenuStyles x={props.positionX} y={props.positionY}>
        <div className="header d-flex center-vertically">
            <div className="flex-1">
                <div className="text-muted font-13"><i className="mdi mdi-transit-connection-variant"></i> Relationships</div>
                <div>{props.childNodeLabel} &rarr; {props.parentNodeLabel}</div>
            </div>
            <div>
                <button className="icon-button" onClick={props.onHideMenu}>
                    <i className="mdi mdi-close-thick"></i>
                </button>
            </div>
        </div>
        <div className="options">
            {props.relationships.map(rel => {
                return <div className="option" onClick={() => {
                    props.onSelectRelationship(rel.id as string);
                }}>
                    {rel.name || '(No Name)'}
                </div>
            })}
            <DraftOnly>
                <div className="option" onClick={props.onCreateNewPeerRelationship}>
                    <i className="mdi mdi-plus-thick"></i> Create New
                </div>
            </DraftOnly>
        </div>
    </EdgeMenuStyles>
}

const nodeTypes = {
    pipeline_node: PipelineNodeNode,
    metric: MetricNode,
}

const DataModelWizard = () => {
    const pipelineNodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships();
    const inDraftMode = useIsInDraftMode();

    const measures = useMeasures();

    const [workingNodes, setWorkingNodes] = useImmer<PipelineNode[]>([]);
    const [workingMeasures, setWorkingMeasures] = useImmer<ReportBuilderMeasure[]>([]);
    const [workingRelationships, setWorkingRelationships] = useImmer<PipelineNodeRelationship[]>([]);

    const [showModal, setShowModal] = useState(false);
    const [showVisualEditor, setShowVisualEditor] = useState(true);
    const [closedModal, setClosedModal] = useState(false);

    const viewport = useViewport();

    useEffect(() => {
        if (pipelineNodes.data) {

            setWorkingNodes(pipelineNodes.data?.filter(node => ['DIMENSION', 'DATE_DIMENSION'].includes(node.node_type)) || []);
        }
    }, [pipelineNodes.dataUpdatedAt]);

    useEffect(() => {
        if (measures.data) {
            console.log('Measures got updated');
            setWorkingMeasures(measures.data || []);
        }
    }, [measures.dataUpdatedAt])

    useEffect(() => {
        if (relationships.data && pipelineNodes.data) {
            const validNodeIds = pipelineNodes.data.map(n => n.id);
            setWorkingRelationships(relationships.data.filter(r => {
                return r.parent_node_id && r.child_node_id && validNodeIds.includes(r.parent_node_id) && validNodeIds.includes(r.child_node_id);
            }));
        }
    }, [relationships.dataUpdatedAt, pipelineNodes.dataUpdatedAt]);

    const onConnect = useCallback(async (params: any) => {
        if (!inDraftMode) {
            return;
        }
        setPageDirty(true);
        // Is the target a metric or a node?
        const sourceType = params.source.split(':')[0];
        const sourceId = params.source.split(':')[1];
        const targetType = params.target.split(':')[0];
        const targetId = params.target.split(':')[1];

        if (sourceType == 'PipelineNode' && targetType == 'PipelineNode') {
            setWorkingRelationships(draft => {
                draft.push({
                    id: shortid(),
                    parent_node_id: targetId,
                    child_node_id: sourceId,
                    name: '',
                    child_foreign_key_name: '',
                    parent_lookups: [],
                    parent_lookup_logic_gate: 'AND',
                    description: '',
                    denormalized_fields: [],
                });
            });
        } else if (sourceType == 'PipelineNode' && targetType == 'Metric') {
            const theMeasure = workingMeasures.find(m => m.id == targetId);
            if (!theMeasure) {
                return;
            }

            // If the measure already has a pipeline node ID, make them confirm first
            if (theMeasure.pipeline_node_id) {
                if (theMeasure.pipeline_node_id == sourceId) {
                    alert('warning', 'This metric is already connected to this node');
                    return;
                }
                const connectedNode = workingNodes.find(n => n.id == theMeasure.pipeline_node_id);
                const confirmed = await requireConfirmation(`This metric is already connected to a different entity (${connectedNode?.label}). Are you sure you want to connect it to this entity instead?`);
                if (confirmed) {
                    setWorkingMeasures(draft => {
                        const measure = draft.find(m => m.id == targetId);
                        if (measure) {
                            measure.pipeline_node_id = sourceId;
                        }
                    });
                }
            } else if (theMeasure.parent_measure_ids && theMeasure.parent_measure_ids.length) {
                const confirmed = await requireConfirmation('This metric is a composite metric. If you connect it directly to an entity, it will become a basic metric. Are you sure?');
                if (confirmed) {
                    setWorkingMeasures(draft => {
                        const measure = draft.find(m => m.id == targetId);
                        if (measure) {
                            measure.pipeline_node_id = sourceId;
                            measure.parent_measure_ids = [];
                        }
                    });
                }
            } else {
                setWorkingMeasures(draft => {
                    const measure = draft.find(m => m.id == targetId);
                    if (measure) {
                        console.log('found measure', sourceId);
                        measure.pipeline_node_id = sourceId;
                    } else {
                        console.log('did not find measure');
                    }
                });
            }
        } else if (sourceType == 'Metric' && targetType == 'Metric') {
            // Do our best to figure out which one is the parent and which is the child
            const sourceMeasure = workingMeasures.find(m => m.id == sourceId);
            const targetMeasure = workingMeasures.find(m => m.id == targetId);
            if (!sourceMeasure || !targetMeasure) {
                return;
            }

            if (sourceMeasure.is_calculation && targetMeasure.is_calculation) {
                // What we do here? Ask? probably pop open a modal

                alert('warning', 'These are both calculated metrics, so we do not know what to do.');
            } else if (sourceMeasure.is_calculation) {
                // The target measure is upstream of the source measure
                setWorkingMeasures(draft => {
                    const sourceMeasure = draft.find(m => m.id == sourceId);
                    if (sourceMeasure) {
                        sourceMeasure.parent_measure_ids = sourceMeasure.parent_measure_ids || [];
                        sourceMeasure.parent_measure_ids.push(targetId);
                    }
                });
            } else if (targetMeasure.is_calculation) {
                // The source measure is upstream of the target measure
                setWorkingMeasures(draft => {
                    const targetMeasure = draft.find(m => m.id == targetId);
                    if (targetMeasure) {
                        targetMeasure.parent_measure_ids = targetMeasure.parent_measure_ids || [];
                        targetMeasure.parent_measure_ids.push(sourceId);
                    }
                });
            } else {
                // Neither of them are calculated - check if one of them is empty and if so make that one calculated
                if ((!targetMeasure.parent_measure_ids || !targetMeasure.parent_measure_ids.length) && !targetMeasure.pipeline_node_id) {
                    setWorkingMeasures(draft => {
                        const targetMeasure = draft.find(m => m.id == targetId);
                        if (targetMeasure) {
                            targetMeasure.is_calculation = true;
                            targetMeasure.parent_measure_ids = [sourceId];
                        }
                    });
                } else if ((!sourceMeasure.parent_measure_ids || !sourceMeasure.parent_measure_ids.length) && !sourceMeasure.pipeline_node_id) {
                    setWorkingMeasures(draft => {
                        const sourceMeasure = draft.find(m => m.id == sourceId);
                        if (sourceMeasure) {
                            sourceMeasure.is_calculation = true;
                            sourceMeasure.parent_measure_ids = [targetId];
                        }
                    });
                } else {
                    alert('warning', 'You can only connect a metric to a calculated metric.');
                }
            }

            
        }
        
        
    }, [workingMeasures, workingNodes]);



    const [diagramNodes, setDiagramNodes] = useImmer<Node<PipelineNodeInfo|MetricInfo>[]>([]);
    const [diagramEdges, setDiagramEdges] = useImmer<Edge<RelationshipInfo>[]>([]);
    useEffect(() => {
        if (!pipelineNodes.data || !relationships.data || !measures.data) {
            return;
        }


        const nodes: Node<PipelineNodeInfo|MetricInfo>[] = workingNodes.map(n => {
            return {
                id: 'PipelineNode:' + n.id as string,
                type: 'pipeline_node',
                data: {
                    label: n.label,
                    description: n.description,
                    id: n.id as string,
                    upstreamNodeIds: n.upstream_node_ids
                },
                position: {
                    x: n.diagram_x || 0,
                    y: n.diagram_y || 0,
                }
            }
        });

        workingMeasures.forEach(m => {
            // Is there a problem here?
            const errors = getErrorsFromMetric(m);
            let error = '';
            if (errors.length > 0) {
                error = errors[0];
            }
            
            nodes.push({
                id: 'Metric:' + m.id as string,
                type: 'metric',
                data: {
                    label: m.name,
                    description: m.description,
                    id: m.id as string,
                    upstreamNodeIds: [],
                    error: error,
                },
                position: {
                    x: m.diagram_x || 0,
                    y: m.diagram_y || 0,
                }
            });
        });

        const relevantNodeIds = nodes.map(n => n.id.split(':')[1]);

        const edges: Edge[] = workingRelationships.filter(r => relevantNodeIds.includes(r.parent_node_id) && relevantNodeIds.includes(r.child_node_id)).map(r => {
            return {
                id: 'Relationship:' + r.id as string,
                source: 'PipelineNode:' + r.child_node_id,
                target: 'PipelineNode:' + r.parent_node_id,
                type: 'relationship',
                data: {
                    label: r.name
                },
                markerEnd: {
                    type: MarkerType.ArrowClosed,
                    width: 20,
                    height: 20,
                    color: '#FF9F00',
                },
                style: {
                    strokeWidth: 2,
                    stroke: '#FF9F00',
                },
            }
        });

        workingMeasures.forEach(m => {
            if (m.parent_measure_ids && m.parent_measure_ids.length > 0) {
                m.parent_measure_ids.forEach(sourceMetricId => {
                    edges.push({
                        id: 'CompositeMetric:' + shortid(),
                        source: 'Metric:' + sourceMetricId,
                        target: 'Metric:' + m.id as string,
                        type: 'composite_metric',
                        data: {
                            label: 'Source Metric',
                        },
                        markerEnd: {
                            type: MarkerType.ArrowClosed,
                            width: 20,
                            height: 20,
                            color: '#FF9F00',
                        },
                        style: {
                            strokeWidth: 2,
                            stroke: '#FF9F00',
                        },
                    });
                });
            } else if (m.pipeline_node_id) {
                edges.push({
                    id: 'BasicMetric:' + shortid(),
                    source: 'PipelineNode:' + m.pipeline_node_id,
                    target: 'Metric:' + m.id as string,
                    type: 'basic_metric',
                    data: {
                        label: 'Source Node',
                    },
                    markerEnd: {
                        type: MarkerType.ArrowClosed,
                        width: 20,
                        height: 20,
                        color: '#FF9F00',
                    },
                    style: {
                        strokeWidth: 2,
                        stroke: '#FF9F00',
                    },
                });
            } else {
                console.log('Skipping measure', m);
            }
            
        });

        setDiagramNodes(nodes);
        setDiagramEdges(edges);
    }, [workingNodes, workingRelationships, workingMeasures]);

    const onNodesChange = useCallback((changes: NodeChange[]) => {
        if (changes.length == 1) {
            const theChange = changes[0];

            if (theChange.type == 'position') {
                const posChange = theChange as NodePositionChange;
                if (!posChange.position) {
                    return;

                }

                // Is this a node or a metric? Try for both I guess
                setWorkingNodes(draft => {
                    const node = draft.find(n => n.id == theChange.id.split(':')[1]);
                    if (node) {
                        node.diagram_x = posChange.position!.x;
                        node.diagram_y = posChange.position!.y;
                    }
                });

                setWorkingMeasures(draft => {
                    const metric = draft.find(n => n.id == theChange.id.split(':')[1]);
                    if (metric) {
                        metric.diagram_x = posChange.position!.x;
                        metric.diagram_y = posChange.position!.y;
                    }
                });
                setPageDirty(true);
            }
        }
    }, []);

    const [activeNodeId, setActiveNodeId] = useState<string|undefined>(undefined);
    const [activeMetricId, setActiveMetricId] = useState<string|undefined>(undefined);
    const [activeEdgeId, setActiveEdgeId] = useState<string|undefined>(undefined);
    const [activeRelationshipId, setActiveRelationshipId] = useState<string|undefined>(undefined);

    const activeNode = useMemo(() => {
        return workingNodes.find(n => n.id == activeNodeId);
    }, [workingNodes, activeNodeId]);

    const activeMetric = useMemo(() => {
        return workingMeasures.find(n => n.id == activeMetricId);
    }, [workingMeasures, activeMetricId]);

    const activeRelationship = useMemo(() => {
        const rv= workingRelationships.find(r => r.id == activeRelationshipId);
        console.log(rv);
        return rv;
    }, [workingRelationships, activeRelationshipId]);

    const [edgeClickPosition, setEdgeClickPosition] = useState({x: 0, y: 0});

    const onEdgeClick = useCallback((e: any, edge: Edge) => {
        switch (edge.type) {
            case 'relationship':
                setActiveEdgeId(edge.id);
                setEdgeClickPosition({
                    x: e.pageX - 300,
                    y: e.pageY - 150,
                })
                break;
            case 'composite_metric':
                setActiveMetricId(edge.target.split(':')[1]);
                break;
            case 'basic_metric':
                setActiveMetricId(edge.target.split(':')[1]);
                break;
        }
    }, []);

    const onNodeClick= useCallback((e: any, node: Node) => {
        if (node.type == 'metric') {
            setActiveMetricId(node.id.replace('Metric:', ''));
        } else {
            setActiveNodeId(node.id.replace('PipelineNode:', ''));
        }
    }, []);

    
    const [newNodeLabel, setNewNodeLabel] = useState('');

    const nodeLabelsById = useMemo(() => {
        const labels: {[key: string]: string} = {};
        workingNodes.forEach(n => {
            labels[n.id as string] = n.label;
        });    
        return labels;
    }, [workingNodes]);
    const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance|null>(null);

    const containerRef = useRef<HTMLDivElement>(null);

    const onReactFlowInit = useCallback((instance: ReactFlowInstance) => {
        setReactFlowInstance(instance);
    }, []);

    const getNewNodePosition = useCallback(() => {
        if (!viewport || !containerRef.current || !reactFlowInstance) {
            return { x: Math.random() * 1000, y: Math.random() * 1000 };
        }
    
        let x = viewport.x * -1 * viewport.zoom;
        let y = viewport.y * -1 * viewport.zoom;
    
        if (containerRef.current && reactFlowInstance) {
            // Leave enough room to fit the node
            const bottomRight = reactFlowInstance.project({
                x: containerRef.current.getBoundingClientRect().width - 300,
                y: containerRef.current.getBoundingClientRect().height - 100
            });
            x = getRandomNumber(x, bottomRight.x);
            y = getRandomNumber(y, bottomRight.y);
        }
        return { x, y };
    }, [containerRef.current, reactFlowInstance, viewport]);

    const [newMetricLabel, setNewMetricLabel] = useState('');
    const addNewMetric = useCallback(() => {
        const newPosition = getNewNodePosition();
        setWorkingMeasures(draft => {
            draft.push({
                id: shortid(),
                name: newMetricLabel,
                column_name: newMetricLabel,
                description: '',
                diagram_x: newPosition.x,
                diagram_y: newPosition.y,
            })
        });
        if(!showVisualEditor) setShowModal(true);
        setPageDirty(true);
        setNewMetricLabel('');
    }, [newMetricLabel, getNewNodePosition]);


    const addNewNode = useCallback(() => {
        const newPosition = getNewNodePosition();
        
        setWorkingNodes(draft => {
            // We're just generating an ID here knowing it'll change when we save - need to go through all relationships and swap
            // them accordingly on save. Otherwise we won't be able to reference a particular node by ID before it has been saved.
            draft.push({
                id: shortid(),
                label: newNodeLabel,
                description: '',
                node_type: 'DIMENSION',
                fields: [],
                upstream_node_ids: [],
                name: '',
                table_name: '',
                // diagram_x: viewport.x * -1 * viewport.zoom,
                // diagram_y: viewport.y * -1 * viewport.zoom
                diagram_x: newPosition.x,
                diagram_y: newPosition.y,
            });
        });
        if(!showVisualEditor) setShowModal(true);
        setNewNodeLabel('');
        setPageDirty(true);
    }, [newNodeLabel, getNewNodePosition]);

    const [saving, setSaving] = useState(false);
   
    const saveModel = useCallback(async () => {
        setSaving(true);
    
        try {
            const requestData = {
                workingNodes,  
                workingRelationships, 
                workingMeasures      
            };
            const response = await ApiService.getInstance().request('POST', '/pipelinenodes/save-model', requestData);
            if (response && (response as any).status === 'success') {
                toast('success', 'Success', 'Data model saved successfully');
                invalidateEverything(); 
                setPageDirty(false);
            } else {
                throw new Error((response as any)?.message || 'Unknown error');
            }
    
        } catch (err) {
            console.error('Error during save model:', err); 
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
    }, [workingNodes, workingRelationships, workingMeasures]);
    

    const activeRelParentNode = useMemo(() => {
        if (!activeRelationship) {
            return undefined;
        }
        const parentNode = workingNodes.find(n => n.id == activeRelationship.parent_node_id);
        return parentNode;
    }, [workingNodes, activeRelationship]);

    const activeRelChildNode = useMemo(() => {
        if (!activeRelationship) {
            return undefined;
        }
        const childNode = workingNodes.find(n => n.id == activeRelationship.child_node_id);
        return childNode;
    }, [workingNodes, activeRelationship]);

    const deleteActiveRel = useCallback(() => {
        setWorkingRelationships(draft => {
            const idx = draft.findIndex(r => r.id == activeRelationshipId);
            draft.splice(idx, 1);
        });
        setPageDirty(true);
        setActiveRelationshipId(undefined);
    }, [activeRelationshipId]);

    const deleteActiveNode = useCallback(() => {
        setWorkingNodes(draft => {
            const idx = draft.findIndex(n => n.id == activeNodeId);
            draft.splice(idx, 1);
        });

        setWorkingRelationships(draft => {
            draft = draft.filter(r => r.parent_node_id != activeNodeId && r.child_node_id != activeNodeId);
        });
        setPageDirty(true);
        setActiveNodeId(undefined);
    }, [activeNodeId]);

    
    const deleteActiveMetric = useCallback(() => {
        setWorkingMeasures(draft => {
            const idx = draft.findIndex(m => m.id == activeMetricId);
            draft.splice(idx, 1);
        });
        setPageDirty(true);
        setActiveMetricId(undefined);
    }, [activeMetricId]);


    const { pageDirty, setPageDirty } = useRouteBlocker(saveModel);

    const [selectedTemplate, setSelectedTemplate] = useState('');

    const applyTemplate = useCallback(() => {
        const templateData = Templates.find(t => t.id == selectedTemplate);
        if (!templateData) {
            return;
        }
        setWorkingNodes(draft => {
            draft.push(...templateData.nodes);
        });

        setWorkingRelationships(draft => {
            draft.push(...templateData.relationships);
        });
    }, [selectedTemplate]);

    const checkForBusinessObjectEnterKey = useCallback((e: React.KeyboardEvent) => {
    
        if (e.key == 'Enter') {
            addNewNode();
        }
    }, [addNewNode]);

    const checkForMetricEnterKey = useCallback((e: React.KeyboardEvent) => {
    
        if (e.key == 'Enter') {
            addNewMetric();
        }
    }, [addNewMetric]);

    const edgeMenuProps: EdgeMenuProps | undefined = useMemo(() => {
        if (!activeEdgeId) {
            return undefined;
        }
        const activeEdge = diagramEdges.find(e => e.id == activeEdgeId);
        if (!activeEdge) {
            return undefined;
        }

        const parentNode = workingNodes.find(n => n.id == activeEdge.target.split(':')[1]);
        const childNode = workingNodes.find(n => n.id == activeEdge.source.split(':')[1]);

        if (!parentNode || !childNode) {
            return undefined;
        }
        return {
            parentNodeId: parentNode.id as string,
            parentNodeLabel: parentNode.label,
            childNodeId: childNode.id as string,
            childNodeLabel: childNode.label,
            relationships: workingRelationships.filter(r => r.parent_node_id == parentNode.id && r.child_node_id == childNode.id),
            onCreateNewPeerRelationship: () => {
                const newId = shortid();
                setWorkingRelationships(draft => {
                    draft.push({
                        id: newId,
                        parent_node_id: parentNode.id as string,
                        child_node_id: childNode.id as string,
                        name: '',
                        child_foreign_key_name: '',
                        parent_lookups: [],
                        parent_lookup_logic_gate: 'AND',
                        description: '',
                        denormalized_fields: [],
                    });
                });
                setPageDirty(true);
                setActiveEdgeId(undefined);
                setActiveRelationshipId(newId);
            },
            onSelectRelationship: (id: string) => {
                setActiveRelationshipId(id);
                setActiveEdgeId(undefined);
            },
            positionX: edgeClickPosition.x + 150,
            positionY: edgeClickPosition.y - 50,
            onHideMenu: () => {
                setActiveEdgeId(undefined);
            }
        };
    }, [workingRelationships, diagramEdges, activeEdgeId, edgeClickPosition]);

    const assistantContent = useMemo(() => {
        return <>
            
            <div className="shadow-box p-2 mb-2 bg-primary text-white">
                <h4 className="text-white">Welcome to the Semantic Layer!</h4>
                <p className="mb-0">Pliable's semantic layer allows you to define and connect key concepts in your business, making reporting simple and reliable.</p>

            </div>
            <div className="shadow-box p-2 mb-2">
                <h4><i className={`mdi ${workingMeasures.length > 0 ? 'mdi-check-circle text-success': 'mdi-check-circle-outline'}`}></i> Create a Metric</h4> 
                <p className="mb-0">A metric is a key number that you want to keep track of in your business, like Total Revenue or Active Users.</p>

            </div>
            <div className="shadow-box p-2 mb-2">
                <h4><i className={`mdi ${workingNodes.length > 0 ? 'mdi-check-circle text-success': 'mdi-check-circle-outline'}`}></i> Create an Entity</h4> 
                <p className="mb-0">An Entity is a key object in your business, like a Customer or a Product. It will have fields that you can use to build metrics.
                </p>

            </div>
            <div className="shadow-box p-2 mb-2">
                <h4><i className={`mdi ${workingRelationships.length > 0 ? 'mdi-check-circle text-success': 'mdi-check-circle-outline'}`}></i> Create a Relationship</h4> 
                <p className="mb-0">Relationships connect Entities so you can report on them together. For example, an "Order" is related to the "Customer" who placed it, and the "Product" that was ordered. You can draw lines between Entities to create relationships.</p>

            </div>
            
        </>
        
    }, [workingNodes, workingMeasures, workingRelationships]);

    const [searchQuery, setSearchQuery] = useState('');

    const zoomToFitNode = useCallback((nodeId: string) => {
        if (!reactFlowInstance) {
            return;
        }
        reactFlowInstance.fitView({
            padding: 50,
            minZoom: 1,
            maxZoom: 5,
            nodes: [{id: nodeId}],
            duration: 500,
        });
    }, [reactFlowInstance]);

    const zoomToFitNodes = useCallback((nodeIds: string[]) => {
        if (!reactFlowInstance) {
            return;
        }
        reactFlowInstance.fitView({
            padding: 50,
            minZoom: 1,
            maxZoom: 5,
            nodes: nodeIds.map(id => ({id})),
            duration: 500,
        });
    }, [reactFlowInstance]);

    const sortedNodes = useMemo(() => {
        return workingNodes.filter(n => {
            // Filter out nodes that don't match the search query
            if (!searchQuery) {
                return true;
            }
            return n.label.toLowerCase().includes(searchQuery.toLowerCase());
        }).sort((a, b) => a.label.localeCompare(b.label));
    }, [workingNodes, searchQuery]);

    const sortedMetrics = useMemo(() => {
        return workingMeasures.filter(m => {
            if (!searchQuery) {
                return true;
            }
            return m.name.toLowerCase().includes(searchQuery.toLowerCase());
        }).sort((a, b) => a.name.localeCompare(b.name));
    }, [workingMeasures, searchQuery]);

    const sortedRelationships = useMemo(() => {
        return workingRelationships.filter(r => {
            const parentNode = workingNodes.find(n => n.id == r.parent_node_id);
            const childNode = workingNodes.find(n => n.id == r.child_node_id);
            if (!parentNode || !childNode) {
                return false;
            }

            if (!searchQuery) {
                return true;
            }
            
            return nodeLabelsById[r.parent_node_id].toLowerCase().includes(searchQuery.toLowerCase()) || nodeLabelsById[r.child_node_id].toLowerCase().includes(searchQuery.toLowerCase()) || r.name.toLowerCase().includes(searchQuery.toLowerCase());
        }).sort((a, b) => (nodeLabelsById[a.parent_node_id] || '').localeCompare(nodeLabelsById[b.parent_node_id]));
    }, [workingRelationships, searchQuery, nodeLabelsById])

    const [visibleElements, setVisibleElements] = useState<'nodes'|'metrics'|'relationships'>('nodes');

    const [timesOnPage, setTimesOnPage] = useState(0);

    useEffect(() => {
        const currentTimes = localStorage.getItem('timesOnSemanticLayerPage');
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnPage(parsed);
        } else {
            setTimesOnPage(0);
        }

        localStorage.setItem('timesOnSemanticLayerPage', ((parsed || 0) + 1).toString());
    }, []);

    const handleConfirm = () => {
        localStorage.setItem('completedListView', 'true');
        setShowVisualEditor(true);
        setShowModal(false);
    };

    const handleClose = () => {
        setShowModal(false);
        setClosedModal(true);
        setShowVisualEditor(false);
    };

    const doAutoLayout = useCallback(async () => {
        const confirmed = await requireConfirmation('This will automatically arrange your nodes and relationships. Are you sure you want to continue?', 'Confirmation', 'Yes', 'Cancel');
        if (!confirmed) {
            return;
        }
        const newPositions = autoLayout(diagramNodes, diagramEdges, 'LINEAR');
        setWorkingNodes(draft => {
            draft.forEach(n => {

                const newPosition = newPositions['PipelineNode:' + n.id];
                if (newPosition) {
                    n.diagram_x = newPosition.x;
                    n.diagram_y = newPosition.y;
                }
            });
        });

        setWorkingMeasures(draft => {
            draft.forEach(m => {
                const newPosition = newPositions['Metric:' + m.id];
                if (newPosition) {
                    m.diagram_x = newPosition.x;
                    m.diagram_y = newPosition.y;
                }
            });
        });
    }, [diagramNodes, diagramEdges]);
    
    useEffect(() => {
        const hasNodes = workingNodes.length > 0;
        const hasMeasures = workingMeasures.length > 0;
        const completedListView = localStorage.getItem('completedListView');
        // If the user has already completed the list view, don't show visual editor
        if (completedListView === 'true') {
            return;
        }
        // If the user has no nodes or measures and hasn't completed the list view, don't show the visual editor
        if (completedListView === null && !hasNodes && !hasMeasures) {
            setShowVisualEditor(false);
        // If the user has existing nodes or measures, simply skip the list view and go to the visual editor
        // The use of !showModal is to prevent the immediate setting of showVisualEditor when opening the modal
        } else if (!showModal && (hasNodes || hasMeasures)) {
            localStorage.setItem('completedListView', 'true');
            setShowVisualEditor(true);
            setShowModal(true);
        }
    }, [workingNodes.length, workingMeasures.length]);
    

    // if (workingNodes.length == 0 && workingMeasures.length == 0) {
    //     return <PageStructure>
    //         <PageContent>
    //             <Pane>
    //                 <PaneContent>
    //                     <div className="p-onboarding">
    //                         <h1 className="pt-5">Welcome to your Semantic Layer!</h1>
    //                         <p>Pliable's semantic layer allows you to define and connect key concepts in your business.</p>
    //                         <hr />
    //                         <div className="row">
    //                             <div className="col-6">
    //                                 <h2>Start by creating a Metric</h2>
    //                                 <InfoAlert>
    //                                     A metric is a key number that you want to keep track of in your business, like Total Revenue or Active Users.
    //                                 </InfoAlert>
    //                                 <Form.Group className="mb-3">
    //                                     <Form.Label>Metric Name</Form.Label>
    //                                     <Form.Control value={newMetricLabel} onChange={(e) => setNewMetricLabel(e.target.value)}/>
    //                                 </Form.Group>
    //                                 <button className="btn btn-lg btn-success" disabled={!newMetricLabel} onClick={() => {
    //                                     addNewMetric();
    //                                     setNewMetricLabel('');
    //                                 }}>Create Metric</button>
    //                             </div>
    //                             <div className="col-6">
    //                                 <h2>Or, start by creating a Business Object</h2>
    //                                 <InfoAlert>
    //                                     A Business Object is a key entity in your business, like a Customer or a Product. It will have fields that you can use to build metrics.
    //                                 </InfoAlert>
    //                                 <Form.Group className="mb-3">
    //                                     <Form.Label>Object Name</Form.Label>
    //                                     <Form.Control value={newNodeLabel} onChange={(e) => setNewNodeLabel(e.target.value)}/>
    //                                 </Form.Group>
    //                                 <button className="btn btn-lg btn-success" disabled={!newNodeLabel} onClick={() => {
    //                                     addNewNode();
    //                                     setNewNodeLabel('');
    //                                 }}>Create Object</button>
    //                             </div>
    //                         </div>
    //                     </div>
    //                 </PaneContent>
    //             </Pane>
    //         </PageContent>
    //     </PageStructure>
    // }

    // <div className="input-group me-3" style={{width: '300px'}}>
    //                     <Form.Control className="flex-1" value={newMetricLabel} placeholder="Add a metric" onChange={(e) => setNewMetricLabel(e.target.value)} onKeyUp={checkForMetricEnterKey}/>
    //                     <button className="btn btn-outline-secondary" disabled={!newMetricLabel} onClick={() => {
    //                         addNewMetric();
    //                         setNewMetricLabel('');
    //                     }}>
    //                         <i className="mdi mdi-plus-thick"></i>
    //                     </button>

    //                 </div>
    //                 <div className="input-group me-3" style={{width: '300px'}}>
    //                     <Form.Control className="flex-1" value={newNodeLabel} placeholder="Add a business object" onChange={(e) => setNewNodeLabel(e.target.value)} onKeyUp={checkForBusinessObjectEnterKey}/>
    //                     <button className="btn btn-outline-secondary" disabled={!newNodeLabel} onClick={() => {
    //                         addNewNode();
    //                         setNewNodeLabel('');
    //                     }}>
    //                         <i className="mdi mdi-plus-thick"></i>
    //                     </button>

    //                 </div>
    

  

    if (!showVisualEditor) {
        return  <PageStructure>
            <PageContent>
                {closedModal && (
                <div className="d-flex justify-content-end mt-3 me-3">
                    <button className="btn btn-primary" onClick={handleConfirm}>Go To Visual Editor</button>
                </div>)}
                <div className="p-onboarding pt-5">
                    <div className="empty-state text-center">
                        <h1>Welcome to the Semantic Layer.</h1>
                        <h3 className="fw-normal">Pliable's semantic layer allows you to define and connect key concepts in your business, making reporting simple and reliable.</h3>
                        <hr />
                        <div className="row">
                            <BlockInProd>
                            <div className="col-6 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Entities</h2>
                                        <p style={{height:'100px'}}>An Entity is a key object in your business, like a Customer or a Product. It will have fields that you can use to build metrics. You can create relationships between Entities (like a "Customer" making an "Order") so they can be reported on together.</p>
                                        <Form.Group>
                                            <Form.Label>Add an Entity</Form.Label>
                                            <div className="input-group">
                                                <Form.Control className="flex-1" value={newNodeLabel} placeholder="Entity name" onChange={(e) => setNewNodeLabel(e.target.value)} onKeyUp={checkForBusinessObjectEnterKey}/>
                                                <button className="btn btn-pliable" disabled={!newNodeLabel} onClick={() => {
                                                    addNewNode();
                                                    setNewNodeLabel('');
                                                }}>
                                                    <i className="mdi mdi-plus-thick"></i>
                                                </button>
                                            </div>
                                            <div className="list-group mb-2">
                                                {sortedNodes.map(node => {
                                                    return <div className="my-2 list-group-item" key={node.id}>
                                                        <div className="d-flex center-vertically text-start">
                                                            <DashboardIcon compact bgColor="purple" icon="mdi mdi-table"/>
                                                            <div className="flex-1 overflow-ellipsis">
                                                                <h5 className="mb-0">{node.label}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                                </div>
                            <div className="col-6 d-flex">
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Metrics</h2>
                                        <p style={{height:'100px'}}>A metric is a key number that you want to keep track of in your business, like Total Revenue or Active Users.</p>
                                        <Form.Group className="mb-3">
                                            <Form.Label>Add a Metric</Form.Label>
                                            <div className="input-group">
                                                <Form.Control className="flex-1" value={newMetricLabel} placeholder="Metric name" onChange={(e) => setNewMetricLabel(e.target.value)} onKeyUp={checkForMetricEnterKey}/>
                                                <button className="btn btn-pliable" disabled={!newMetricLabel} onClick={() => {
                                                    addNewMetric();
                                                    setNewMetricLabel('');
                                                }}>
                                                    <i className="mdi mdi-plus-thick"></i>
                                                </button>
                                            </div>
                                            <div className="list-group mb-2">
                                                {sortedMetrics.map(metric => {
                                                    return <div className="my-2 list-group-item" key={metric.id}>
                                                        <div className="d-flex center-vertically text-start">
                                                            <DashboardIcon compact bgColor="success" icon="mdi mdi-pound"/>
                                                            <div className="flex-1 overflow-ellipsis">
                                                                <h5 className="mb-0">{metric.name}</h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                })}
                                            </div>
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                            </BlockInProd>
                        </div>
                    </div>
                </div>
            </PageContent>
            <ConfirmationModal
                header="Nice."
                message="Now let's switch to a visual editor so you can connect everything together."
                show={showModal}
                onConfirm={handleConfirm}
                onClose={() => handleClose()}
                confirmationButtonText="OK"
            />
            </PageStructure>
    }

    return <PageStructure>
        <Offcanvas backdrop="static" placement="end" show={!!activeNodeId} onHide={() => setActiveNodeId(undefined)}>
            {activeNode &&
            <NodeEditor 
                node={activeNode!} 
                onChange={(node) => {
                    setWorkingNodes(draft => {
                        const idx = draft.findIndex(n => n.id == node.id);
                        draft[idx] = node;
                    });
                    setActiveNodeId(undefined);
                    setPageDirty(true);
                }}
                onCancel={() => {
                    setActiveNodeId(undefined);
                }}
                onDelete={deleteActiveNode}
            />}
        </Offcanvas>
        <Offcanvas backdrop="static" placement="end" show={!!activeMetricId} onHide={() => setActiveMetricId(undefined)}>
            {activeMetric &&
            <MetricEditor
                workingNodes={workingNodes}
                workingMeasures={workingMeasures}
                workingRelationships={workingRelationships}
                metric={activeMetric!}
                onChange={(metric) => {
                    setWorkingMeasures(draft => {
                        const idx = draft.findIndex(m => m.id == metric.id);
                        draft[idx] = metric;
                    });
                    setActiveMetricId(undefined);
                    setPageDirty(true);
                }}
                onCancel={() => {
                    setActiveMetricId(undefined);
                }}
                onDelete={deleteActiveMetric}
                onAddColumnToNode={(nodeId, field) => {
                    setWorkingNodes(draft => {
                        const node = draft.find(n => n.id == nodeId);
                        if (node) {
                            node.fields.push(field);
                        }
                    });
                    setPageDirty(true);
                }}
            />}
        </Offcanvas>
        <Offcanvas 
            placement="end"
            show={!!activeRelationshipId} 
            onHide={() => setActiveRelationshipId(undefined)}
        >
            {activeRelationship && 
            <RelationshipEditor
                rel={activeRelationship!}
                parentNode={activeRelParentNode!}
                childNode={activeRelChildNode!}
                allWorkingRelationships={workingRelationships}
                onCreatePeerRelationship={(fromRel: PipelineNodeRelationship) => {
                    const newId = shortid();
                    setWorkingRelationships(draft => {
                        draft.push({
                            id: newId,
                            parent_node_id: fromRel.parent_node_id,
                            child_node_id: fromRel.child_node_id,
                            name: '',
                            child_foreign_key_name: '',
                            parent_lookups: [],
                            parent_lookup_logic_gate: 'AND',
                            description: '',
                            denormalized_fields: [],
                        });
                    });
                    setPageDirty(true);
                }}
                onSwitchRelationship={(newRelId) => {
                    setActiveRelationshipId(newRelId);
                }}
                onChange={(rel) => {
                    setWorkingRelationships(draft => {
                        const idx = draft.findIndex(r => r.id == rel.id);
                        draft[idx] = rel;
                    });
                    setActiveRelationshipId(undefined);
                    setPageDirty(true);
                }}
                onDelete={deleteActiveRel}
                onCancel={() => {
                    setActiveRelationshipId(undefined);
                }}
            />}
        </Offcanvas>
            <PageContent hasSidebar>
                <PageContentHeader
                >
                    <div className="d-flex center-vertically" style={{height: '100%'}}>
                        <DashboardIcon bgColor="success" icon="mdi mdi-star"/> 
                        <div className="mb-0 flex-1 me-3">
                            <h1 className="mb-0">Semantic Layer</h1>
                            <p className="text-muted font-13 mb-0">The Semantic Layer allows you to define and connect key concepts in your business, making reporting simple and reliable.</p>
                        </div>
                        <DraftOnly>
                            <button className="btn btn-light btn-lg me-1" onClick={doAutoLayout}>Auto Layout</button>
                            <SaveButton
                                className="btn-lg"
                                disabled={!pageDirty}
                                onClick={saveModel}
                            />
                        </DraftOnly>
                        <ProdOnly>
                            <button className="btn btn-outline-secondary hover-only" onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                enterDraftMode('edit-button');
                            }}>
                                <i className="mdi mdi-pencil"></i> Edit
                            </button>
                        </ProdOnly>
                    </div> 
                </PageContentHeader>
                <PageContentInner noScroll hasHeader>
                    <div style={{height: '100%', position: 'relative'}}>
                        {edgeMenuProps && <EdgeMenu {...edgeMenuProps!}/>}
                        
                        <Pane>
                            <PaneContent>
                                <div style={{height: '100%', width: '100%'}} ref={containerRef}>
                                        <ReactFlow
                                            nodes={diagramNodes}
                                            edges={diagramEdges}
                                            nodeTypes={nodeTypes}
                                            nodesDraggable={inDraftMode}
                                            nodesConnectable={inDraftMode}
                                            connectionMode={ConnectionMode.Loose}
                                            onNodesChange={onNodesChange}
                                            proOptions={{hideAttribution: true}}
                                            onConnect={onConnect}
                                            onEdgeClick={onEdgeClick}
                                            onNodeClick={onNodeClick}
                                            onInit={onReactFlowInit}
                                            fitView={true}
                                        >
                                        <Background/>
                                        <MiniMap/>
                                        <Controls
                                            showZoom
                                            showFitView
                                            showInteractive
                                        />
                                    </ReactFlow>
                                </div>
                            </PaneContent>
                        </Pane>
                    </div>
                </PageContentInner>
            </PageContent>
        <PageSidebar right>
            <Pane>
                <PaneContent>
                    <div className="p-2 pt-4">
                        <ReadOnly>
                            <Form.Group className="mb-3">
                                <Form.Label>Add an Entity</Form.Label>
                                <div className="input-group">
                                    <Form.Control className="flex-1" value={newNodeLabel} placeholder="Entity name" onChange={(e) => setNewNodeLabel(e.target.value)} onKeyUp={checkForBusinessObjectEnterKey}/>
                                    <button className="btn btn-pliable" disabled={!newNodeLabel} onClick={() => {
                                        addNewNode();
                                        setNewNodeLabel('');
                                    }}>
                                        <i className="mdi mdi-plus-thick"></i>
                                    </button>
                                </div>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Add a Metric</Form.Label>
                                <div className="input-group">
                                    <Form.Control className="flex-1" value={newMetricLabel} placeholder="Metric name" onChange={(e) => setNewMetricLabel(e.target.value)} onKeyUp={checkForMetricEnterKey}/>
                                    <button className="btn btn-pliable" disabled={!newMetricLabel} onClick={() => {
                                        addNewMetric();
                                        setNewMetricLabel('');
                                    }}>
                                        <i className="mdi mdi-plus-thick"></i>
                                    </button>
                                </div>
                            </Form.Group>
                        </ReadOnly>
                        <hr />
                        <input value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} className="form-control input-rounded mb-2" placeholder="Search..." />
                        
                                <h5>Entities</h5>
                                <div className="list-group mb-2">
                                {sortedNodes.map(node => {
                                    return <div className="list-group-item" key={node.id}>
                                        <div className="d-flex center-vertically">
                                            <DashboardIcon compact bgColor="purple" icon="mdi mdi-table"/>
                                            <div className="flex-1">
                                                <h5 className="mb-0">{node.label}</h5>
                                            </div>
                                            <div>
                                                <DraftOnly>
                                                    <button className="icon-button me-1 font-18" title="Edit" onClick={() => setActiveNodeId(node.id as string)}>
                                                        <i className="mdi mdi-cog"></i>
                                                    </button>
                                                </DraftOnly>
                                                <button className="icon-button font-18" title="Show in Diagram" onClick={() => {
                                                    zoomToFitNode('PipelineNode:' + node.id);
                                                }}>
                                                    <i className="mdi mdi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {sortedNodes.length == 0 && <div className="list-group-item text-center">None found</div>}
                                </div>
                                <h5>Metrics</h5>
                                <div className="list-group mb-2">

                                
                                {sortedMetrics.map(metric => {
                                    return <div className="list-group-item" key={metric.id}>
                                        <div className="d-flex center-vertically">
                                            <DashboardIcon compact bgColor="success" icon="mdi mdi-pound"/>
                                            <div className="flex-1">
                                                <h5 className="mb-0">{metric.name}</h5>
                                            </div>
                                            <div>
                                                <DraftOnly>
                                                    <button className="icon-button me-1 font-18" title="Edit" onClick={() => setActiveMetricId(metric.id as string)}>
                                                        <i className="mdi mdi-cog"></i>
                                                    </button>
                                                </DraftOnly>
                                                <button className="icon-button font-18" title="Show in Diagram" onClick={() => {
                                                    zoomToFitNode('Metric:' + metric.id);
                                                }}>
                                                    <i className="mdi mdi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {sortedMetrics.length == 0 && <div className="list-group-item text-center">None found</div>}
                                </div>
                            
                                <h5>Relationships</h5>
                                <div className="list-group mb-2">

                            
                                {sortedRelationships.map(rel => {
                                    return <div className="list-group-item" key={rel.id}>
                                        <div className="d-flex center-vertically">
                                            <DashboardIcon compact bgColor="blue" icon="mdi mdi-relation-many-to-many"/>
                                            <div className="flex-1">
                                                <h5 className="mb-0">
                                                    {nodeLabelsById[rel.parent_node_id]} <i className="mdi mdi-arrow-right-bold"></i> {nodeLabelsById[rel.child_node_id]}
                                                    {rel.name ? ' (' + rel.name + ')' : ''}
                                                </h5>
                                            </div>
                                            <div>
                                                <DraftOnly>
                                                    <button className="icon-button me-1 font-18" title="Edit" onClick={() => setActiveRelationshipId(rel.id as string)}>
                                                        <i className="mdi mdi-cog"></i>
                                                    </button>
                                                </DraftOnly>
                                                <button className="icon-button font-18" title="Show in Diagram" onClick={() => {
                                                    zoomToFitNodes(['PipelineNode:' + rel.parent_node_id, 'PipelineNode:' + rel.child_node_id]);
                                                }}>
                                                    <i className="mdi mdi-eye"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                })}
                                {workingRelationships.length == 0 && <div className="list-group-item text-center">None found</div>}
                                </div>
                        

                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
    </PageStructure>
}

export default DataModelWizard;