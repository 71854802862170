import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import './swagger-custom.css';

type SwaggerUIProps = {
  url: string;
  // Add any additional props you want to support
  defaultModelExpandDepth?: number;
  displayOperationId?: boolean;
  filter?: boolean;
};

const SwaggerUIComponent = ({
  url,
  defaultModelExpandDepth = -1,
  displayOperationId = false,
  filter = true,
}: SwaggerUIProps): JSX.Element => {
  return (
    <div className="swagger-wrapper">
      <SwaggerUI
        url={url}
        docExpansion="list"
        deepLinking={true}
        defaultModelsExpandDepth={-1}
        defaultModelExpandDepth={defaultModelExpandDepth}
        displayOperationId={displayOperationId}
        filter={filter}
      />
    </div>
  );
};

export default SwaggerUIComponent;