import React, { ReactNode } from 'react';

/**
 * NotFound component props interface
 */
interface NotFoundProps {
  /** Name of the resource that wasn't found (defaults to 'Resource') */
  resourceName?: string;
  icon?: string;
  /** Custom message to display (defaults to '[ResourceName] not found: 404') */
  message?: string;
}

/**
 * NotFound component for displaying 404 error pages
 * @param props - Component props
 * @returns The NotFound component
 */
function NotFound({
  resourceName = 'Resource',
  icon,
  message,
}: NotFoundProps): JSX.Element {
  // Default icon is AlertCircle if no custom icon is provided
  const IconComponent = <i className='mdi mdi-alert' />;
  
  // Default message uses the resourceName prop
  const displayMessage = message || `${resourceName} not found: 404`;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="card h-100">
        <div className="card-body flex flex-col items-center py-10 px-6">
          <div className="text-red-500 mb-6 text-center" style={{ fontSize: '4rem' }}>
            {IconComponent}
          </div>
          
          <h2 className="text-2xl font-bold text-gray-800 mb-2  text-center">
            {displayMessage}
          </h2>
          
          <p className="text-gray-600 text-center">
            The requested {resourceName.toLowerCase()} could not be found.
          </p>
        </div>
        </div>
    </div>
  );
}

export default NotFound;