import React from 'react';
import Select from 'react-select';
import { Form } from "react-bootstrap";

type CronOption = {
  label: string;
  value: string;
  id: CronScheduleId | 'custom';
};

type CronScheduleId = '15min' | '6hour' | '12hour' | 'daily' | 'weekly' | 'monthly';

interface CronScheduleSelectProps {
  onChange: (cronExpression: string) => void;
  onToggle: () => void;
  value: string;
  scheduleEnabled: boolean;
  label?: string;
  isDisabled?: boolean;
  enabledOptions?: CronScheduleId[];
}

const CronScheduleSelect: React.FC<CronScheduleSelectProps> = ({ 
  onChange, 
  onToggle,
  value,
  scheduleEnabled,
  label = 'Schedule',
  isDisabled = false,
  enabledOptions = ['daily', 'weekly', 'monthly']
}) => {
  const allOptions: CronOption[] = [
    // { 
    //   label: 'Every 15 minutes', 
    //   value: '*/15 * * * *',
    //   id: '15min'
    // },
    // { 
    //   label: 'Every 6 hours', 
    //   value: '0 */6 * * *',
    //   id: '6hour'
    // },
    // { 
    //   label: 'Every 12 hours', 
    //   value: '0 */12 * * *',
    //   id: '12hour'
    // },
    { 
      label: 'Once a day', 
      value: '0 0 * * *',
      id: 'daily'
    },
    { 
      label: 'Once a week', 
      value: '0 0 * * 0',
      id: 'weekly'
    },
    { 
      label: 'Once a month', 
      value: '0 0 1 * *',
      id: 'monthly'
    }
  ];

  // Filter options based on enabledOptions prop
  const availableOptions = allOptions.filter(option => 
    enabledOptions.includes(option.id as CronScheduleId)
  );

  // Find if current value matches any predefined option
  const currentOption = availableOptions.find(option => option.value === value);

  // If no match found, create a custom option
  const effectiveOptions = availableOptions.concat(
    currentOption ? [] : [{
      label: `Custom (${value})`,
      value: value,
      id: 'custom'
    }]
  );

  return (
    <div className="flex flex-col gap-2">
      {label && (
        <div className="d-flex flex-row justify-content-between text-sm font-medium text-gray-700 mb-1">
          <label>{label}</label> 

          <Form.Check // prettier-ignore
                                          type="switch"
                                          id="toggle-sync"
                                          label={(!!scheduleEnabled ? 'Enabled' : 'Disabled')}
                                          title={(!!scheduleEnabled ? 'Syncing enabled' : 'Syncing disabled')}
                                          checked={!!scheduleEnabled}
                                          disabled={isDisabled}
                                          onChange={() => onToggle()}
                                      />
        </div>
      )}
      <Select<CronOption>
        className="w-64"
        value={currentOption || {
          label: `Custom (${value})`,
          value: value,
          id: 'custom'
        }}
        onChange={(selected) => selected && onChange(selected.value)}
        options={effectiveOptions}
        isDisabled={isDisabled}
        placeholder="Select schedule..."
      />
    </div>
  );
};

export default CronScheduleSelect;
