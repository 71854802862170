import React, { useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import Dropdown from './Dropdown.component';

const DATE_RANGE_OPTIONS = [
    { label: 'All Time', value: 'ALL_TIME' },
    { label: 'Today', value: 'TODAY' },
    { label: 'Yesterday', value: 'YESTERDAY' },
    { label: 'This Week', value: 'THIS_WEEK' },
    { label: 'Last Week', value: 'LAST_WEEK' },
    { label: 'This Month', value: 'THIS_MONTH' },
    { label: 'Last Month', value: 'LAST_MONTH' },
    { label: 'This Quarter', value: 'THIS_QUARTER' },
    { label: 'Last Quarter', value: 'LAST_QUARTER' },
    { label: 'This Year', value: 'THIS_YEAR' },
    { label: 'Last Year', value: 'LAST_YEAR' },
    { label: 'Custom', value: 'CUSTOM' }
];

interface DateRangeSelectorProps {
    dateRange?: string;
    customDateRangeStart?: string;
    customDateRangeEnd?: string;
    onDateRangeChange: (dateRange: string) => void;
    onCustomStartChange?: (date: string) => void;
    onCustomEndChange?: (date: string) => void;
    className?: string;
    compact?: boolean;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({
    dateRange = 'ALL_TIME',
    customDateRangeStart,
    customDateRangeEnd,
    onDateRangeChange,
    onCustomStartChange,
    onCustomEndChange,
    className,
    compact
}) => {
    const [showCustomModal, setShowCustomModal] = useState(false);

    const CustomDateRangeFields = () => (
        <>
            <Form.Group className="mb-2">
                <Form.Label className="small">
                    Start (Inclusive)
                </Form.Label>
                <Form.Control 
                    type="date" 
                    value={customDateRangeStart || ''} 
                    onChange={(e) => onCustomStartChange?.(e.target.value)}
                />
            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label className="small">
                    End (Inclusive)
                </Form.Label>
                <Form.Control 
                    type="date" 
                    value={customDateRangeEnd || ''} 
                    onChange={(e) => onCustomEndChange?.(e.target.value)}
                />
            </Form.Group>
        </>
    );

    if (compact) {
        return (
            <>
                <Form.Group className={className}>
                    <div className="input-group">
                        <span className="input-group-text">
                            <i className="mdi mdi-calendar"></i>
                        </span>
                        <Dropdown
                            variant="button"
                            btnVariant="light"
                            options={DATE_RANGE_OPTIONS}
                            selected={dateRange}
                            onChange={(value) => {
                                onDateRangeChange(value);
                                if (value === 'CUSTOM') {
                                    setShowCustomModal(true);
                                }
                            }}
                        />
                    </div>
                </Form.Group>

                <Modal show={showCustomModal} onHide={() => setShowCustomModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Custom Date Range</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <CustomDateRangeFields />
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-primary" onClick={() => setShowCustomModal(false)}>
                            Done
                        </button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }

    return (
        <Form.Group className={className}>
            <Form.Label>Date Range</Form.Label>
            <div className="mb-2">
                <Dropdown
                    options={DATE_RANGE_OPTIONS}
                    selected={dateRange}
                    onChange={onDateRangeChange}
                />
            </div>
            
            {dateRange === 'CUSTOM' && <CustomDateRangeFields />}
        </Form.Group>
    );
};

export default DateRangeSelector;
