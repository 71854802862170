import { useEffect, useCallback } from "react";
import { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { Column, SimpleDataTable, FetchDataParams, CellFormatOptions } from "./DataTable.component";
import { apiurl } from '@services/url.service';
import ApiService, { ListRecordsResponse } from "../../services/api/api.service";
import { getErrorMessage } from "../../services/errors.service";
import { ColumnStatsModal } from "./ColumnStatsModal.component";
import styled from 'styled-components';
import { ColumnFormat, TableLoadResponse, TableShape } from "@models/shared";
import { useDraftVersionId, useTableData } from "@stores/data.store";
import { useQuery } from "react-query";
import { DataWhitelist, FilterConfig, PipelineNodeCellAction } from "@models/pipelineNode";

const ExpandButton = styled.button`
position: absolute;
bottom: 15px;
right: 15px;

background-color: black;
border-radius: 5px;
width: 30px;
height: 30px;
border: solid 1px var(--ct-border-color);
color: white;
z-index: 2000;
font-size: 20px;
line-height: 30px;
padding: 0px;
text-align: center;
transition: scale .15s ease-in-out, opacity .15s ease-in-out;
opacity: 0.7;

i {
    display: block;
    height: 28px;
    line-height: 28px;
}
&:hover {
    scale: 1.5;
    opacity: 1.0;
}

`

export interface TableColumnFormat {
    column: string;
    format: ColumnFormat;
}

interface Props {
    tablePath: string;
    tableShape?: TableShape;
    columnStatsUrl?: string;
    maxHeight?: string;

    // @deprecated
    columnOrder?: string[];
    justTable?: boolean;

    columns?: Column[];
    columnFormatters?: { [columnId: string]: (value: any) => CellFormatOptions; };
    columnActions?: { [columnId: string]: PipelineNodeCellAction[] };
    onClickExpand?: () => void;
    onColumnClick?: {
        [key: string]: (value: any) => void;
    }
    onCellDoubleClick?: (col: Column, row: Record<string, string>, value: any) => void;
    onDataLoaded?: (response: TableLoadResponse) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
    searchQuery?: string;
    filters?: FilterConfig;
    whitelist?: DataWhitelist;
    cacheBuster?: string;
    initialSort?: any[];
    onShowColumnStats?: (columnName: string) => any;
    onShowColumnLineage?: (columnName: string) => any;
    onCleanColumn?: (columnName: string) => any;
    enableColumnContextMenu?: boolean;
    enableRowContextMenu?: boolean;
    onRowContextMenuSelect?: (row: any, contextMenuOption: string) => any;
}





export const TableExplorer = (props: Props) => {   
    const [loading, setLoading] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [tablePageData, setTablePageData] = useState<ListRecordsResponse<any>>({records:[], total_pages: 0, total_records: 0});
    const [error, setError] = useState('');
    const {data: draftVersionId} = useDraftVersionId();

    const [fetchParams, setFetchParams] = useState<FetchDataParams>({
        pageIndex: 0,
        pageSize: 100,
        sortBy: props.initialSort || [],
    });

    const [previousTablePath, setPreviousTablePath] = useState('');

    // If we change the table path, clear out fetch params so we don't sort on a non-existent column
    useEffect(() => {
        setPreviousTablePath(props.tablePath);
        setFetchParams({
            pageIndex: 0,
            pageSize: 100,
            sortBy: props.initialSort || [],
        });
    }, [props.tablePath, previousTablePath, props.initialSort]);

    const tableData = useTableData(
        props.tablePath,
        fetchParams.pageIndex + 1,
        fetchParams.pageSize,
        props.filters ? JSON.stringify(props.filters) : '',
        `${draftVersionId as string}${props.cacheBuster}`,
        props.searchQuery,
        // This handles the case where, before the useEffect above has been run, we'd query the new table using the old sort. Once
        // one tick has passed and previousTablePath updated, fetchParams are initialized etc, then we're fine
        previousTablePath == props.tablePath ? fetchParams.sortBy.map((sort: any) => {
            return `${sort['desc'] ? "-" : ""}${sort['id']}`;
        }).join(",") : '',

        props.whitelist ? JSON.stringify(props.whitelist) : '',
    );

    useEffect(() => {
        setLoading(tableData.isLoading || tableData.isRefetching);
        

        if (tableData.isLoading || tableData.isRefetching) {
            setError('');
        } else if (tableData.error) {
            setError(getErrorMessage(tableData.error));
        } else if (tableData.data) {
            setError('');
            setTablePageData(tableData.data);
            console.log('Running on data loaded', tableData.data);
            props.onDataLoaded && props.onDataLoaded(tableData.data);
        } else {
            setError('');
        }
       
    }, [tableData.isLoading, tableData.error, tableData.data, tableData.dataUpdatedAt]);

 

    const onFilterSortChange = useCallback((params: FetchDataParams) => {
        setFetchParams(params);
    }, []);


    let containerStyle: React.CSSProperties = {
        height: '100%'
    };
    if (props.maxHeight) {
        containerStyle.maxHeight = props.maxHeight;
    }


    const doubleClickedCell = (col: Column, row: Record<string, string>, value: any) => {
        if (props.onCellDoubleClick) {
            props.onCellDoubleClick(col, row, value);
        }
    }
    
    const tableComponent = <SimpleDataTable 
        loading={loading}
        onColumnClick={props.onColumnClick}
        columns={props.columns}
        columnFormatters={props.columnFormatters}
        columnActions={props.columnActions}
        data={tablePageData!.records}
        totalPageCount={tablePageData!.total_pages}
        totalRecords={tablePageData!.total_records}
        onFilterSortChange={onFilterSortChange}
        onShowColumnStats={props.onShowColumnStats}
        onShowColumnLineage={props.onShowColumnLineage}
        onCleanColumn={props.onCleanColumn}
        onCellDoubleClick={props.onCellDoubleClick ? doubleClickedCell : undefined}
        initialSort={props.initialSort}
        enableColumnContextMenu={props.enableColumnContextMenu}
        enableRowContextMenu={props.enableRowContextMenu}
        onRowContextMenuSelect={props.onRowContextMenuSelect}
    />;
    

    return (
        <>

            
            {!loading && error && (
                <div className="card">
                    <div className="card-body">
                        <div className="alert alert-danger">{error}</div>
                    </div>
                </div>
            )}
            {!error && (
                <>
                    <Modal show={expanded} className="fullscreen" onHide={() => setExpanded(false)} animation={false}>
                        <Modal.Header closeButton>
                            <Modal.Title>Viewing Data</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {tableComponent}
                        </Modal.Body>
                    </Modal>
                    {!expanded && props.onClickExpand && tablePageData && tablePageData.records.length > 0 && <div style={{position: 'relative', height: '100%'}}>
                        <ExpandButton onClick={() => props.onClickExpand!()}>
                            <i className="mdi mdi-arrow-expand-all"></i>
                        </ExpandButton>
                        {tableComponent}
                    </div>}
                    {!expanded && !props.onClickExpand && (
                        <>{tableComponent}</>
                    )}
                </>
                
            )}
        </>
    )
}
