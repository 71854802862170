import { useCallback, useMemo } from 'react';
import { Badge, Dropdown as BSDropdown, DropdownButton } from 'react-bootstrap';
import Select, { InputProps, SingleValueProps, components as ReactSelectComponents, OptionProps, SingleValue, MultiValue, IndicatorsContainerProps } from 'react-select';
import styled, { css } from 'styled-components';
import CreatableSelect from 'react-select/creatable';

const SelectorOptionStyles = styled.div`
padding: 0px;

h6 {
    font-weight: 400;
    font-size: 14px;
    font-family: "Nunito" !important;
}
`

const SelectorOptionWrapperStyles = styled.div`
&:hover {
    cursor: pointer;
    background-color: var(--pliable-yellow-light);
}

&.selected {
    background-color: var(--pliable-blue-bg);
}

&.disabled {
    cursor: disabled;
    opacity: 0.3 !important;
}

padding: 8px;
`

export interface Option {
    label: string;
    value: string;
    description?: string;
    badgeText?: string;
    badgeVariant?: string;
    icon?: string;
}

interface ColumnSelectorOptionProps {
    option: Option;
}

const SelectorOption = (props: ColumnSelectorOptionProps) => {
    return <SelectorOptionStyles>
            <div  style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{flex: 1, display: 'block'}}>
                    <h6 className="mb-0">{props.option.label}</h6>
                    {props.option.description && (
                        <div style={{lineHeight: '12px', fontSize: '12px'}}>
                            <span>{props.option.description}</span>
                        </div>
                    )}
                </div>
                <div>
                    {props.option.badgeText && <Badge bg={props.option.badgeVariant}>{props.option.badgeText}</Badge>}
                </div>
            </div>

            
            
            
        </SelectorOptionStyles>
}

const SelectorValue = (props: SingleValueProps<Option>) => {
    return <div style={{gridArea: '1/1/2/3'}}>
        <SelectorOption option={props.data}/>
    </div>   
}

const SelectorInputWithRoomForDescription = (props: InputProps<Option>) => {
    return <div style={{height: '53.6px', gridArea: '1/1/2/3', lineHeight: '49.6px'}}>

    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorInputOneLiner = (props: InputProps<Option>) => {
    return <div style={{height: '36px', gridArea: '1/1/2/3', lineHeight: '28px'}}>

    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorInputOneLinerSmall = (props: InputProps<Option>) => {
    return <div style={{height: '16px', gridArea: '1/1/2/3', lineHeight: '16px'}}>
    
        <ReactSelectComponents.Input {...props}>{props.children}</ReactSelectComponents.Input>
    </div>
}

const SelectorOptionWrapper = (props: OptionProps<Option>) => {
    const className = useMemo(() => {
        if (props.isDisabled) {
            return 'disabled';
        }
        if (props.isSelected) {
            return 'selected';
        }
        return '';
    }, [props.isSelected, props.isDisabled]);
    return <div ref={props.innerRef} {...props.innerProps}>
        <SelectorOptionWrapperStyles className={className}>
            <SelectorOption option={props.data}/>
        </SelectorOptionWrapperStyles>
    </div>
}

const SmallIndicatorsContainerStyles = styled.div`
div {
    padding: 0px !important;
}
`

const SelectorIndicatorsContainerSmall = (props: IndicatorsContainerProps<Option>) => {
    return (
        <SmallIndicatorsContainerStyles><ReactSelectComponents.IndicatorsContainer {...props}/></SmallIndicatorsContainerStyles>
    
    );
}

const DropdownStyles = styled.div<{compact?: boolean; buttonHeight?: boolean}>`
    ${props => props.compact ? css`
        h6 {
            font-size: 12px;
            font-weight: 400;
        }
    ` : ''}

    ${props => props.buttonHeight && css`
        .react-select__control {
            min-height: 38px;
            height: 38px;
        }
        
        .react-select__value-container {
            padding: 2px 8px;
        }
        
        .react-select__indicators {
            height: 36px;
        }
    `}
`

interface Props {
    selected?: string;
    placeholder?: string;
    options: Option[];
    onChange: (newValue: string) => void;
    onInputChange?: (inputValue: string) => void;
    allowEmpty?: boolean;
    className?: string;
    size?: string;
    allowCreate?: boolean;
    onCreateOption?: (inputValue: string) => any;
    disabled?: boolean;
    isClearable?: boolean;
    variant?: string;
    btnTitleIcon?: string;
    btnVariant?: string;
    btnSize?: 'sm' | 'lg' | undefined;
    autoFocus?: boolean;
    onKeyPress?: (event: any) => void;
    disabledOptionIds?: string[];
    buttonHeight?: boolean; // New prop
}

const Dropdown = (props: Props) => {
    let InputComponent: any;
    let IndicatorsComponent: any;
    

    if (props.options.length > 0 && !!props.options[0].description) {
        InputComponent = SelectorInputWithRoomForDescription;
    } else if (props.size == 'sm') {
        InputComponent = SelectorInputOneLinerSmall;
    } else {
        InputComponent = SelectorInputOneLiner;
    }

    if (props.size == 'sm') {
        IndicatorsComponent = SelectorIndicatorsContainerSmall;
    } else {
        IndicatorsComponent = ReactSelectComponents.IndicatorsContainer;
    }
    const handleChange = useCallback((e: SingleValue<Option>|MultiValue<Option>) => {
        if (e) {
            // @ts-ignore
            props.onChange(e.value);
        } else {
            props.onChange('');
        }
    }, [props.onChange]);


    const value = useMemo(() => {
        const normalize = (str: string) => str.replace(/["\\]/g, '');
        const found = props.options.find(c => normalize(c.value) === normalize(props.selected || ''));
        if (found) {
            return found;
        } else if (props.allowEmpty) {
            return {
                label: props.placeholder ? props.placeholder : 'Select',
                value: '',
            };
        }
        return null;
        
    }, [props.selected, props.options, props.placeholder]);

    const options = useMemo(() => {
        if (props.allowEmpty) {
            return [{
                label: props.placeholder ? props.placeholder : 'Select',
                value: '',
            }, ...props.options];
        }
        return props.options;
    }, [props.allowEmpty, props.options, props.placeholder])

    if (props.allowCreate) {
        return <DropdownStyles compact={props.size=="sm"} buttonHeight={props.buttonHeight}>
            <CreatableSelect
                isDisabled={props.disabled}
                isClearable={props.isClearable !== false}
                options={options}
                value={value}
                onChange={handleChange}
                onCreateOption={props.onCreateOption}
                onInputChange={props.onInputChange}
                isOptionDisabled={(option) => {
                    if (props.disabledOptionIds && props.disabledOptionIds.includes(option.value)) {
                        return true;
                    }
                    return false;
                }}
                styles={{
                    // Fixes the overlapping problem of the component
                    menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                    input: (styles: any) => {
                        return {
                            ...styles,
                            padding: '0px',
                            margin: '0px',
                        }
                    },
                    option: (styles: any) => {
                        return {
                            ...styles,
                            margin: '0px',
                            padding: '0px 8px',
                        }
                    }
                }}
                components={{ 
                    Option: SelectorOptionWrapper, 
                    SingleValue: SelectorValue,
                    Input: InputComponent,
                    IndicatorsContainer: IndicatorsComponent,
                }}
            />
        </DropdownStyles>
    } else if (props.variant == 'button') {
        return <DropdownButton
            className={props.className}
            title={
                <>
                    {(value?.icon || props.btnTitleIcon) && 
                        <><i className={value?.icon || props.btnTitleIcon}/>&nbsp;</>
                    }
                    {value ? value.label : props.placeholder}
                </>
            }
            size={props.btnSize}
            variant={props.btnVariant || 'secondary'}
        >
            {options.map(o => {
                return <BSDropdown.Item
                    disabled={props.disabledOptionIds && props.disabledOptionIds.includes(o.value)}
                    onClick={() => {
                        props.onChange(o.value)
                    }}
                >
                    {o.icon && <><i className={o.icon}/>&nbsp;</>}
                    {o.label}
                </BSDropdown.Item>
            })}
        </DropdownButton>
        
    }
    return <DropdownStyles compact={props.size == "sm"} buttonHeight={props.buttonHeight} className={props.className}>
        <Select
            autoFocus={props.autoFocus}
            isDisabled={props.disabled}
            placeholder={props.placeholder}
            isClearable={props.isClearable !== false}
            isOptionDisabled={(option) => {
                if (props.disabledOptionIds && props.disabledOptionIds.includes(option.value)) {
                    return true;
                }
                return false;
            }}
            styles={{
                // Fixes the overlapping problem of the component
                menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
                input: (styles: any) => {
                    return {
                        ...styles,
                        padding: '0px',
                        margin: '0px',
                    }
                },
                option: (styles: any) => {
                    return {
                        ...styles,
                        margin: '0px',
                        padding: '0px 8px',
                    }
                }
            }}
            components={{ 
                Option: SelectorOptionWrapper, 
                SingleValue: SelectorValue,
                Input: InputComponent,
                IndicatorsContainer: IndicatorsComponent,
            }}
            options={options}
            value={value}
            onChange={handleChange}
            onKeyDown={props.onKeyPress}
            onInputChange={props.onInputChange}
        />
    </DropdownStyles>
}

export default Dropdown;

interface MultiDropdownProps {
    selected: string[];
    options: Option[];
    placeholder?: string;
    onChange: (newValue: string[]) => any;
    onInputChange?: (inputValue: string) => void;
    className?: string;
    disabled?: boolean;
    disabledOptionIds?: string[];
    allowCreate?: boolean;
}

export const MultiDropdown = (props: MultiDropdownProps) => {
    const handleChange = useCallback((e: SingleValue<Option>|MultiValue<Option>) => {
        if (e) {
            // @ts-ignore
            props.onChange(e ? e.map(v => v.value) : []);
        } else {
            props.onChange([]);
        }
    }, [props.onChange]);

    const value = useMemo(() => {
        const found: Option[] = [];
        props.selected.forEach(s => {
            const foundOption = props.options.find(c => c.value === s);
            if (foundOption) {
                found.push(foundOption);
            } else {
                // Add an option just so we can show what's currently selected
                found.push({
                    label: s,
                    value: s,
                });
            }
        })

        if (found) {
            return found;
        }

        return [{
            label: props.placeholder ? props.placeholder : 'Select',
            value: '',
        }];
    }, [props.selected, props.options, props.placeholder]);

    const options = useMemo(() => {
        return props.options;
    }, [props.options, props.placeholder]);

    const sharedProps = {
        isDisabled: !!props.disabled,
        isMulti: true,
        placeholder: props.placeholder,
        isOptionDisabled: (option: Option) => {
            if (props.disabledOptionIds && props.disabledOptionIds.includes(option.value)) {
                return true;
            }
            return false;
        },
        styles: {
            menu: (provided: any) => ({ ...provided, zIndex: 9999 }),
            input: (styles: any) => ({
                ...styles,
                padding: '0px',
                margin: '0px',
            }),
            option: (styles: any) => ({
                ...styles,
                margin: '0px',
                padding: '0px 8px',
            })
        },
        components: { 
            Option: SelectorOptionWrapper, 
            SingleValue: SelectorValue,
        },
        options,
        value,
        onChange: handleChange,
        onInputChange: props.onInputChange
    };

    const onCreateOption = useCallback((newName: string) => {
        props.onChange([...props.selected, newName]);
    }, [props.onChange, props.selected]);

    return (
        <DropdownStyles className={props.className}>
            {props.allowCreate ? (
                <CreatableSelect {...sharedProps} onCreateOption={onCreateOption} />
            ) : (
                <Select {...sharedProps} />
            )}
        </DropdownStyles>
    );
}
