import { Source } from "@models/source";
import { useSource, useSourceTypes, useSources } from '@stores/data.store';
import { useEffect, useMemo, useState } from "react";
import styled, { css } from 'styled-components';

interface SourceIconProps {
    source?: Source;
    sourceId?: string;
    className?: string;
    image_url?: string;
    compact?: boolean;
}

const ImageContainer = styled.div<{ compact?: boolean }>`
    width: 50px;
    border-radius: 100%;
    overflow: hidden;

    img {
        height: 50px;
    }

    ${props => props.compact && css`
        width: 30px;

        img {
            height: 30px;
        }
    `}
`;

const SourceIcon = (props: SourceIconProps) => {
    
    const { isLoading: loadingSourceTypes, data: soureTypeOptions } = useSourceTypes();
    const sourceData = useSource(props.sourceId || '');

    const [theSource, setTheSource] = useState<Source|undefined>(undefined);

    useEffect(() => {
        if (props.source) {
            setTheSource(props.source);
        } else if (sourceData.dataUpdatedAt) {
            setTheSource(sourceData.data);
        }
    }, [props.sourceId, props.source, sourceData.dataUpdatedAt]);

    if(!!props.image_url) {
        return <ImageContainer compact={props.compact}>
            <img src={props.image_url}/>
        </ImageContainer>;
    }

    if (loadingSourceTypes || !theSource){
        return <></>;
    }
    

    const sourceType = soureTypeOptions?.find((st) => st.id == theSource?.type);

    if (!sourceType) {
        return <></>
    }

    return <ImageContainer compact={props.compact}>
        <img src={sourceType.icon_path}/>
    </ImageContainer>;

}

export default SourceIcon;