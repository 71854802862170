import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import { PipelineNodeField, PipelineNodeFieldTranslation } from "@models/pipelineNode";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Badge, Form, Modal } from "react-bootstrap";
import { useImmer } from "use-immer";
import PipelineNodeFieldTransformer from "../mapping/PipelineNodeFieldTransformer.component";
import PipelineNodeColumnValues from "../PipelineNodeColumnValues.component";
import Dropdown, { Option } from "@components/form/Dropdown.component";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";

interface SummaryOptionPickerProps {
    aggregator: string;
    fieldType: string;
    onChangeAggregator: (newAggregator: string) => void;

    // In some cases the group-by indicator is managed outside this component
    disableGroupBySelection?: boolean;
}

export const SummaryOptionPicker = (props: SummaryOptionPickerProps) => {
    const options = useMemo(() => {
        const runningList: Option[] = [ {
            label: 'Pick one',
            value: 'PICK_ONE',
            description: 'Pick a random value'
        }, {
            label: 'Count distinct',
            value: 'COUNT_DISTINCT',
            description: 'Count the number of distinct values'
        }];

        if (!props.disableGroupBySelection) {
            runningList.unshift({
                label: 'Group',
                description: 'Group by this column',
                value: 'GROUP_BY',
            });
        }

        switch (props.fieldType) {
            case 'DATE':
            case 'DATETIME':
            case 'DATETIME_TZ':
                runningList.push({
                    label: 'Earliest',
                    value: 'MIN',
                    description: 'Pick the earliest date',
                })
                runningList.push({
                    label: 'Latest',
                    value: 'MAX',
                    description: 'Pick the latest date',
                });
                break;
            case 'STRING':
                runningList.push({
                    label: 'Concat',
                    value: 'CONCAT',
                    description: 'Combine all values into a single comma-separated string',
                });
                break;
            case 'BOOLEAN':
                runningList.push({
                    label: 'Count "trues"',
                    value: 'COUNT_TRUE',
                    description: 'Counts the number of "true" values',
                });
                runningList.push({
                    label: 'Count "falses"',
                    value: 'COUNT_FALSE',
                    description: 'Counts the number of "false" values',
                });
                break;
            case 'INT':
            case 'DECIMAL':
                runningList.push({
                    label: 'Sum',
                    value: 'SUM',
                    description: 'Sum all values',
                });
                runningList.push({
                    label: 'Average',
                    value: 'AVG',
                    description: 'Average all values',
                });
                runningList.push({
                    label: 'Minimum',
                    value: 'MIN',
                    description: 'Use the minimum value',
                });
                runningList.push({
                    label: 'Maximum',
                    value: 'MAX',
                    description: 'Use the maximum value',
                });
                break;
        }
        return runningList;


    }, [props.fieldType]);

    return <Dropdown
        options={options}
        selected={props.aggregator}
        onChange={props.onChangeAggregator}
    />
}

interface DataTypeIconProps {
    dataType: string;
}

const DataTypeIcon = (props: DataTypeIconProps) => {
    switch (props.dataType) {
        case 'STRING':
            return <i className="mdi mdi-alphabetical-variant"></i>;
        case 'INT':
            return <i className="mdi mdi-numeric"></i>;
        case 'DECIMAL':
            return <i className="mdi mdi-decimal"></i>;
        case 'DATE':
            return <i className="mdi mdi-calendar"></i>;
        case 'DATETIME':
            return <i className="mdi mdi-calendar-clock"></i>;
        case 'DATETIME_TZ':
            return <i className="mdi mdi-calendar-clock"></i>;
        case 'BOOLEAN':
            return <i className="mdi mdi-check"></i>;
    }
    return <></>
}

interface PipelineNodeFieldCleaningBadgesProps {
    datatype?: string;
    transform?: string;


    onEdit: () => void;
}

export const PipelineNodeFieldCleaningBadges = (props: PipelineNodeFieldCleaningBadgesProps) => {
    return <ReadOnly>
        {props.datatype && <Badge bg="light" className="me-1 border clickable" onClick={props.onEdit} title={props.datatype}>
            {props.datatype}
        </Badge>}
        {props.transform && <Badge bg="purple" className="me-1 clickable border" onClick={props.onEdit} title="Edit Transform">
            <i className="mdi mdi-transcribe"></i> {props.transform}
        </Badge>}
        {!props.transform && <Badge bg="secondary" className="me-1 clickable border" title="No Formatting" onClick={props.onEdit}>
            <i className="mdi mdi-format-clear"></i>
        </Badge>}
            {/* <button className="btn btn-light btn-sm" title="Edit" onClick={props.onEdit}>
                <i className="mdi mdi-pencil"></i> Edit
            </button> */}
    </ReadOnly>
}

interface TranslationsProps {
    translations: PipelineNodeFieldTranslation[];
    onChange: (newTranslations: PipelineNodeFieldTranslation[]) => void;
    tableRef: React.RefObject<HTMLTableElement>;
}

const PipelineNodeFieldTranslationsTable = (props: TranslationsProps) => {
    const addTranslation = useCallback(() => {
        props.onChange([...props.translations, {
            source: '',
            translation: '',
        }]);
    }, [props.translations, props.onChange]);

    const removeTranslation = useCallback((idx: number) => {
        props.onChange(props.translations.filter((_, i) => i != idx));
    }, [props.onChange, props.translations]);

    return <Form.Group>
        <Form.Label>Translations</Form.Label>
        <p>Hint: use <code>%</code> for wildcard matches.</p>
        
        
        <table className="table table-bordered table-centered table-condensed" ref={props.tableRef}>
            <thead className="bg-light">
                <tr>
                    <th>Value</th>
                    <th>Translation</th>
                    <th className="text-end">
                        <button className="icon-button" onClick={addTranslation}><i className="mdi mdi-plus-thick font-18"></i></button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.translations.map((t, idx) => <tr key={idx}>
                    <td>
                        <input type="text" defaultValue={t.source} name={`trans_${idx}_source`} className="form-control"/>
                    </td>
                    <td>
                        <input type="text" defaultValue={t.translation} name={`trans_${idx}_translation`} className="form-control"/>
                    </td>
                    <td className="text-end">
                        <button onClick={() => {
                            removeTranslation(idx);
                        }} className="icon-button"><i className="mdi mdi-delete font-18"></i></button>
                    </td>
                </tr>)}
            </tbody>
        </table>
    </Form.Group>
}

interface Props {
    field?: PipelineNodeField;
    onChange: (newField: PipelineNodeField) => void;
    show: boolean;
    onClose: () => void;

    // This controls what values are shown in the column values pane
    sourcePipelineNodeId: string;
    sourceFieldId: string;
    hasSourceData?: boolean;

    // If the field is calculated, do not allow transforms
    isCalculated?: boolean;
}

const PipelineNodeColumnCleanerUpper = (props: Props) => {
    const [dataType, setDataType] = useState('');
    const [transform, setTransform] = useState('');
    const [customTransform, setCustomTransform] = useState('');
    const customRef = useRef<HTMLTextAreaElement>(null);
    const [translations, setTranslations] = useImmer<PipelineNodeFieldTranslation[]>([]);


    useEffect(() => {

        if (!props.field) {
            setDataType('');
            setTransform('');
            if (customRef.current) {
                customRef.current.value = '';
            }
            return;
        }
        setDataType(props.field.type || '');
        setTransform(props.field.transformer || '');
        setCustomTransform(props.field.custom_transform_sql || '');
        setTranslations(props.field.translations || []);
    }, [props.field]); 
    const translationTableRef = useRef<HTMLTableElement>(null);

    const hasSourceData = useMemo(() => {
        if(props.hasSourceData !== undefined ) {
            return props.hasSourceData;
        }else{
            return Boolean(props.field?.map_options?.length);
        }

    }, [props.field, props.hasSourceData]);

    const getTranslations = useCallback(() => {
        if (!translationTableRef.current) {
            return [];
        }

        const inputs = translationTableRef.current.querySelectorAll('input');
        const translations: PipelineNodeFieldTranslation[] = [];

        inputs.forEach((input, idx) => {
            const nameParts = input.name.split('_');
            const transIdx = parseInt(nameParts[1]);
            const key = nameParts[2] as keyof PipelineNodeFieldTranslation;

            if (!translations[transIdx]) {
                translations[transIdx] = {
                    source: '',
                    translation: '',
                }
            }

            translations[transIdx][key] = input.value;
        });

        return translations;
    }, [translationTableRef]);

    const save = useCallback(() => {
        if (!props.field) {
            return;
        }

        let fieldTranslations: PipelineNodeFieldTranslation[] = [];

        if (transform == 'TRANSLATE') {
            fieldTranslations = getTranslations();
        }

        props.onChange({
            ...props.field,
            type: dataType,
            transformer: transform,
            custom_transform_sql: customTransform,
            translations: fieldTranslations,
        });
        props.onClose();
    }, [props.onChange, props.onClose, props.field, dataType, transform, customTransform, getTranslations]);

    return <Modal size="xl" show={props.show} onHide={props.onClose}>
    <Modal.Header closeButton>
        <Modal.Title>Format Column: {props.field?.label}</Modal.Title>
    </Modal.Header>
    <Modal.Body style={{height: 'calc(70vh)'}} className="p-0">
        <div className="row" style={{height: '100%'}}>
            {props.field && <>
                <div className="col-7">
                    <Pane>
                        <PaneContent className="p-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Data Type</Form.Label>
                                <BusinessObjectFieldTypeSelector
                                    className="input-sm"
                                    selected={dataType}
                                    onSelect={setDataType}
                                />
                                <Form.Text>Ensure this column is a particular data type, like a number or a date.</Form.Text>
                            </Form.Group>
                            {props.isCalculated && <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Transform</Form.Label>
                                    <p>This is a <strong>calculated field</strong> so it cannot be transformed.</p>
                                </Form.Group>
                            </>}
                            {!props.isCalculated && <>
                                <Form.Group className="mb-3">
                                    <Form.Label>Transform</Form.Label>
                                    <PipelineNodeFieldTransformer
                                        transformer={transform}
                                        onChangeTransformer={setTransform}
                                    />
                                    <Form.Text>Choose from our list of off-the-shelf cleanup functions, or write your own.</Form.Text>
                                </Form.Group>
                                {transform == 'CUSTOM' && <>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Custom Transform</Form.Label>
                                        <Form.Control className="font-code text-13" onChange={(e) => {
                                            setCustomTransform(e.target.value || '');
                                        }} as="textarea" value={customTransform}  />
                                        <Form.Text>Write a custom transform function in Snowflake SQL. Use <code>[VALUE]</code> to reference the source value.</Form.Text>
                                    </Form.Group>
                                </>}
                                {transform == 'TRANSLATE' && <>
                                    <PipelineNodeFieldTranslationsTable 
                                        translations={translations} 
                                        onChange={setTranslations}
                                        tableRef={translationTableRef}
                                    />
                                    
                                </>}
                            </>}
                           
                        </PaneContent>
                    </Pane>
                
            </div>
            <div className="col-5">
                <Pane className="border-left">
                    <PaneContent className="p-3">
                        <PipelineNodeColumnValues
                            pipelineNodeId={props.sourcePipelineNodeId}
                            fieldId={props.sourceFieldId}
                            hasSourceData={hasSourceData}
                        />
                    </PaneContent>
                </Pane>
                
            </div>
            </>}
            
        </div>
        
    </Modal.Body>
    <Modal.Footer>
        <button className="btn btn-light me-1" onClick={props.onClose}>Cancel</button>
        <button className="btn btn-success" onClick={save}>Save</button>
    </Modal.Footer>
</Modal>
}

export default PipelineNodeColumnCleanerUpper;