import { useQuery } from "react-query";
import ORM, { BaseModel } from "./orm";

export interface DashboardFilterOptionColumnReference {
    pipeline_node_id: string;
    field_id: string;
}

export interface DashboardDateRange {
    date_range?: string;
    custom_date_range_start?: string;
    custom_date_range_end?: string;
    user_timezone?: string;
}

export interface DashboardFilterOption {
    column_references?: DashboardFilterOptionColumnReference[];
    filter_type: 'QUERY_LIST' | 'STATIC_LIST' | 'FREE_TEXT' | 'DIMENSION' | 'DATE_RANGE';
    comparator: string;
    query_list_node_id?: string;
    query_list_field_id?: string;
    static_list?: string[];
    allow_multiple?: boolean;
    default_value?: string;
    dimension_id?: string;
}


export type DashboardObjectType = undefined | 'NODE' | 'VISUALIZATION' | 'MARKDOWN' | 'FILTER' | 'ANALYSIS';

export interface DashboardComponent {
    id: string;
    title: string;
    x: number;
    y: number;
    width: number;
    height: number;
    object_id: string;
    object_type?: DashboardObjectType;
    markdown_content?: string;
    filter_config?: DashboardFilterOption;
    column_whitelist?: string[];
    
}

export interface Dashboard extends BaseModel {
    name: string;
    description: string;
    components: DashboardComponent[];
    date_range_config?: DashboardDateRange;
}

export class _DashboardORM extends ORM<Dashboard>{

};

export const DashboardORM = new _DashboardORM('dashboards');

export const useDashboards = () => {
    return useQuery(['dashboards'], async () => {
        const result = await DashboardORM.find({});
        return result.records;
    });
}

export const useDashboard = (dashboardId: string) => {
    return useQuery(['dashboards', dashboardId], async () => {
        if (!dashboardId) {
            return undefined;
        }
        const result = await DashboardORM.findById(dashboardId);
        return result;
    })
}
