import { useCallback, useState, useEffect, useMemo } from "react";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import ApiService from "@services/api/api.service";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAuth, useAuthState } from "@frontegg/react";
import TrackingService, { Events } from "@services/tracking.service";
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import pliableLogo from '@assets/images/pliable/logo_with_text_black.png'
import { Main, NavHeader, SimpleHeader } from "@pages/PageStructure.component";

const Styles  = styled.div`
    h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }

    a {
        color: rgb(60, 74, 90);
    }

    .card {
        color: rgb(60, 74, 90);
        transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        overflow: auto;
        box-sizing: border-box;
        padding: 32px;
        border: none;
        border-radius: 24px;
        background-color: rgb(255, 255, 255);
        box-shadow: rgba(157, 168, 189, 0.17) 0px 4px 24px;
        padding: 30px;
    }

    .existing-account {
        text-align: center;
        margin: 5px;
        margin-bottom: 15px;
    }

    .signup-info{
        font-size: 1.1em;
        padding-left: 15px;
        padding-right: 25px;
    }
`

const EmbeddedStyles  = styled.div`
    background-color: transparent;
`

const AuthorizationComplete = () => {
    const host = window.location.protocol + "//" + window.location.host;
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate = useNavigate();
    const authState = useAuthState();

    const [searchParams, setSearchParams] = useSearchParams();

    const sourceId = searchParams.get('sourceId');
    const sourceType = searchParams.get('sourceType');

    const hasAuth = !!authState.user;
    

    return (<Main>
            <div className="content-wrapper">
                <div className="page-content">
                    {!hasAuth && <SimpleHeader/> }
                    {!!hasAuth && <NavHeader /> }
                    <div className={`page-content-inner page-content-inner-draft`}>
                    <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
                      <Row className="w-100">
                        <Col xs={12} md={8} lg={6} className="mx-auto">
                          <Card className="border-0 shadow-sm">
                            <Card.Body className="text-center p-5">
                              <i className="bi bi-check-circle text-success fs-1 mb-4"></i>
                              
                              <h1 className="mb-3"><i className="mdi mdi-check-circle" style={{color: 'var(--pliable-success)'}}></i> Authorization Complete</h1>
                              
                              <Card.Text className="text-muted mb-4">
                                Your {sourceType} account has been successfully connected. You're all set and ready to go!
                              </Card.Text>
                              
                              <div className="d-grid gap-2">
                              {!!hasAuth && <Button variant="pliable" size="lg" href={`/source/${sourceId}`}>
                                  View Source
                                </Button>}
                                <Button variant="light" href="https://docs.pliable.co" className="mt-2">
                                  Need help?
                                </Button>
                              </div>
                            </Card.Body>
                            
                            <Card.Footer className="bg-white text-center border-0 pb-4">
                              <small className="text-muted">
                                If you have any questions, please contact <a href="mailto:support@pliable.co">support@pliable.co</a>
                              </small>
                            </Card.Footer>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                    </div>
                </div>
                
                
            </div>
        </Main>

    );
    
}

export default AuthorizationComplete;