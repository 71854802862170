import * as LDClient from 'launchdarkly-js-client-sdk';
import AuthService from '@services/auth/auth.service';
import { useState, useEffect } from 'react';

export enum FeatureFlag {
  TOGGLE_SNAPSHOTS = 'toggle-snapshots',
  SYSTEM_SCHEDULER = 'system-scheduler',
  TENANT_ASSISTANT = 'tenant-assistant',
  PIVOT_TABLES = 'pivot-tables',
  CELL_ACTIONS = 'cell-actions',
  TEMPLATES_EDITOR = 'templates-editor',
  TEMPLATE_MANAGEMENT = 'template-management',
  TEMPLATE_UPGRADE = 'template-upgrade',
  HOSTED_SUPERSET = 'hosted-superset',
  PLIABLE_VISUALIZATIONS = 'pliable-visualizations'
}

type FlagChangeListener = (newValue: any) => void;

export default class FeatureFlagService {
  private static instance: FeatureFlagService;
  private client: LDClient.LDClient | null = null;
  private cachedFlags: Map<string, any> = new Map();
  private listeners: Map<string, Set<FlagChangeListener>> = new Map();

  private constructor() {
    // Private constructor for singleton pattern
  }
  
  // Initialize service with LaunchDarkly client from AuthService
  initialize(authService: AuthService) {
    this.client = authService.ldClient;
    
    if (this.client) {
      // Set up global change listener
      this.client.on('change', (changes) => {
        for (const flagKey in changes) {
          this.cachedFlags.set(flagKey, changes[flagKey].current);
          this.notifyListeners(flagKey, changes[flagKey].current);
        }
      });
      this.preloadFlags();
    }
  }

  private preloadFlags() {
    if (!this.client) return;
    
    Object.values(FeatureFlag).forEach(flagName => {
      try {
        const value = this.client!.variation(flagName, null);
        this.cachedFlags.set(flagName, value);
      } catch (error) {
        console.error(`Error preloading flag ${flagName}:`, error);
      }
    });
  }

  getFlag<T>(name: FeatureFlag | string, defaultValue: T): T {
    if (this.cachedFlags.has(name)) {
      return this.cachedFlags.get(name) as T;
    }
    if (!this.client) return defaultValue;
    
    try {
      const value = this.client.variation(name, defaultValue);
      this.cachedFlags.set(name, value);
      return value;
    } catch (error) {
      console.error(`Error getting flag ${name}:`, error);
      return defaultValue;
    }
  }

  onFlagChange(name: FeatureFlag | string, callback: FlagChangeListener): () => void {
    if (!this.listeners.has(name)) {
      this.listeners.set(name, new Set());
    }
    
    this.listeners.get(name)!.add(callback);

    return () => {
      const flagListeners = this.listeners.get(name);
      if (flagListeners) {
        flagListeners.delete(callback);
      }
    };
  }

  private notifyListeners(name: string, newValue: any) {
    const flagListeners = this.listeners.get(name);
    if (flagListeners) {
      flagListeners.forEach(listener => {
        try {
          listener(newValue);
        } catch (error) {
          console.error(`Error in flag listener for ${name}:`, error);
        }
      });
    }
  }

  isEnabled(name: FeatureFlag | string): boolean {
    return this.getFlag(name, false);
  }

  public static getInstance(): FeatureFlagService {
    if (!FeatureFlagService.instance) {
      FeatureFlagService.instance = new FeatureFlagService();
    }
    return FeatureFlagService.instance;
  }
}

export function useFeatureFlag<T>(flagName: FeatureFlag | string, defaultValue: T): T {
  const featureFlagService = FeatureFlagService.getInstance();
  const [value, setValue] = useState<T>(featureFlagService.getFlag(flagName, defaultValue));
  
  useEffect(() => {
    setValue(featureFlagService.getFlag(flagName, defaultValue));
    const unsubscribe = featureFlagService.onFlagChange(flagName, (newValue) => {
      setValue(newValue as T);
    });
    return unsubscribe;
  }, [flagName, defaultValue]);

  return value;
}