import { useNotifications } from '@models/notification';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { timeAgo } from '@services/time.service';
import styled from 'styled-components';
import PliableLoader from './loaders/PliableLoader.component';
import PipelineNodeName from './pipelineNodes/PipelineNodeName.component';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SuccessAlert from './statusIndicators/SuccessAlert.component';
import { useNodesThatNeedBuilding } from '@models/pipelineNode';
import { usePipelineNodeRelationships, usePipelineNodes } from '@stores/data.store';
import { off } from 'process';
import { useMeasures } from '@models/reportBuilder';
import MeasureName from './reportBuilder/MeasureName.component';
import { getPromptAnswer, runBuild } from '@services/alert/alert.service';
import { Dropdown as BSDropdown } from 'react-bootstrap';
import { DraftOnly, ProdOnly } from './project/DraftModeRequired.component';
import { useHotkeys } from 'react-hotkeys-hook';
import { subscribe, unsubscribe, emit } from '@nextcloud/event-bus'


interface MessageProps {
    title: string;
    type: string;
    time: Date;
    description: string;
    objectType?: string;
    objectId?: string;
}

const MessageStyles = styled.div`
font-family: "Poppins";
color: black;
padding: 1rem;
overflow: hidden;
border-bottom: solid 1px var(--ct-border-color);

&:last-child {
    border-bottom: none;
}

&:hover {
    cursor: pointer;
    background-color: #FF9F001A;

}
.header {
    display: flex;
    align-items: center;

    .icon {
        width: 40px;
        font-size: 28px;
    }

    .title {
        line-height: 1rem;
        font-weight: bold;
    }

    .time {
        font-size: 13px;
        color: var(--ct-muted-text);
    }

    margin-bottom: 10px;
}

.description {
    color: var(--ct-body-color);
    font-size: 13px;
}

&:first-child {
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
}

&:last-child {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

&.warning {
    div.icon {
        color: rgb(238, 205, 76);
    }
}

&.BUILD_ERROR {
    div.icon {
        color: var(--ct-danger);
    }
}

&.BUILD_NEEDED {
    div.icon {
        color: var(--pliable-yellow);
    }
}

&.MISSING_RELATIONSHIPS {
    div.icon {
        color: var(--pliable-purple);
    }
}

&.MISSING_MAPPINGS {
    div.icon {
        color: var(--ct-danger);
    }
}

&.NO_BUSINESS_OBJECTS {
    div.icon {
        color: var(--pliable-yellow);
    }
}

&.info {
    div.icon {
        color: var(--ct-info);
    }
}

`

/**
 * Message types:
 * 
 * - Generic "X nodes need building"
 * - Build error - this particular node failed for this reason
 * - Generic "this node has no sources"
 * - Generic "this relationship has no lookup parameters"
 * 
 */

const Message = (props: MessageProps) => {
    
    return <MessageStyles className={'message ' + props.type}>
        <div className="header">
            <div className="icon">
                {props.type == "BUILD_ERROR" && <i className="mdi mdi-close-circle"></i>}
                {props.type == "BUILD_NEEDED" && <i className="mdi mdi-hammer-screwdriver"></i>}
                {props.type == 'MISSING_RELATIONSHIPS' && <i className="mdi mdi-link-off"></i>}
                {props.type == 'MISSING_MAPPINGS' && <i className="mdi mdi-database-alert"></i>}
                {props.type == 'MISSING_JOIN_TREE' && <i className="mdi mdi-link-off"></i>}
                {props.type == 'MISSING_METRIC_SOURCE' && <i className="mdi mdi-database-alert"></i>}
                {props.type == 'MISSING_METRIC_FORMULA' && <i className="mdi mdi-function-variant"></i>}
                {props.type == 'NO_BUSINESS_OBJECTS' && <i className="mdi mdi-star-off"></i>}
            </div>
            <div>
                <div className="time">
                    {timeAgo(props.time)}
                </div>
                <div className="title">
                {props.title}
                </div>
                
            </div>
            

        </div>
        <div className="description">
            {props.type == 'BUILD_ERROR' && <>
                <strong><PipelineNodeName pipelineNodeId={props.objectId}/></strong> failed to build.
            </>}
            {props.type == 'BUILD_NEEDED' && <>
                Click to run a build.
            </>}
            {props.type == 'MISSING_RELATIONSHIPS' && <>
                <strong><PipelineNodeName pipelineNodeId={props.objectId}/></strong> has no relationships to other entities. Click here to connect it to your data model.
            </>}
            {props.type == 'MISSING_MAPPINGS' && <>
                <strong><PipelineNodeName pipelineNodeId={props.objectId}/></strong> has no source data. Click here to connect a data source.
            </>}
            {props.type == 'MISSING_JOIN_TREE' && <>
                Metric <strong><MeasureName measureId={props.objectId as string}/></strong> is not connected to all of your dimensions. Click here to resolve.
            </>}
            {props.type == 'MISSING_METRIC_FORMULA' && <>
                Metric <strong><MeasureName measureId={props.objectId as string}/></strong> does not have a valid formula. Click here to resolve.
            </>}
            {props.type == 'NO_BUSINESS_OBJECTS' && <>
                You haven't created any entities yet. Click here to get started.
            </>}
            {props.type == 'MISSING_METRIC_SOURCE' && <>
                Metric <strong><MeasureName measureId={props.objectId as string}/></strong> is not connected to your data model. Click here to resolve.

            </>}


        </div>
    </MessageStyles>
}

const Indicator = styled.div`
position: absolute;
top: 32px;
left: 32px;
background-color: var(--ct-danger);
width: 10px;
height: 10px;
border-radius: 5px;
`

interface Props {
    onRunBuild?: () => void;
}

export const NotificationsList = (props: Props) => {
    const notifications = useNotifications();
    const nodesThatNeedBuilding = useNodesThatNeedBuilding();
    const navigate = useNavigate();

    const measures = useMeasures();

    const pipelineNodes = usePipelineNodes();
    const relationships = usePipelineNodeRelationships();

    const onClickBuild = useCallback(() => {
        props.onRunBuild && props.onRunBuild();
    }, [props.onRunBuild])

    const onClickMessage = useCallback((msg: MessageProps) => {
        switch (msg.type) {
            case 'BUILD_ERROR':
                navigate(`/node/${msg.objectId}/config`);
                break;
            case 'MISSING_RELATIONSHIPS':
                navigate(`/semantic-layer?focusOn=PipelineNode:${msg.objectId}`);
                break;
            case 'MISSING_MAPPINGS':
                navigate(`/wizard/data-source?next=cleaning&bo_context=${msg.objectId}`);
                break;
            case 'MISSING_JOIN_TREE':
            case 'MISSING_METRIC_FORMULA':
            case 'MISSING_METRIC_SOURCE':
                navigate(`/metric/${msg.objectId}`);
                break;
            case 'NO_BUSINESS_OBJECTS':
                navigate(`/semantic-layer`);
                break;

        }
        
    }, [props.onRunBuild])

    const staticAlerts = useMemo(() => {
        if (!pipelineNodes.data || !relationships.data || !measures.data) {
            return [];
        }

        const boNodes = pipelineNodes.data.filter(n => ['DATE_DIMENSION', 'DIMENSION'].includes(n.node_type));
        const nodeIdsWithRelationships = relationships.data.map(r => r.parent_node_id).concat(relationships.data.map(r => r.child_node_id));
        
        const alerts: MessageProps[] = [];

        if (boNodes.length == 0) {
            alerts.push({
                title: 'No entities',
                description: 'You should create some entities to get started.',
                time: new Date(),
                type: 'NO_BUSINESS_OBJECTS'
            });
        }
        boNodes.forEach(n => {
            
            if (boNodes.length > 1 && !nodeIdsWithRelationships.includes(n.id as string)) {
                alerts.push({
                    title: `Missing relationships`,
                    description: 'You should connect it to the rest of your data model.',
                    time: n.last_updated || new Date(),
                    type: 'MISSING_RELATIONSHIPS',
                    objectId: n.id as string,
                    objectType: 'pipeline_node'
                });
            }

            if (n.upstream_node_ids.length == 0 && n.node_type == 'DIMENSION') {
                alerts.push({
                    title: 'Missing source data',
                    description: '',
                    time: n.last_updated || new Date(),
                    type: 'MISSING_MAPPINGS',
                    objectId: n.id as string,
                    objectType: 'pipeline_node',
                })
            }
        });

        measures.data.forEach(m => {
            if (!m.pipeline_node_id && !m.is_calculation) {
                alerts.push({
                    title: 'Missing metric source',
                    description: '',
                    time: m.last_updated || new Date(),
                    type: 'MISSING_METRIC_SOURCE',
                    objectId: m.id as string,
                    objectType: 'measure'
                });
            }

            if (m.is_calculation && !m.formula) {
                alerts.push({
                    title: 'Missing formula',
                    description: '',
                    time: m.last_updated || new Date(),
                    type: 'MISSING_METRIC_FORMULA',
                    objectId: m.id as string,
                    objectType: 'measure'
                });
            }

            // Check for missing join trees
            if (m.dimension_join_trees) {
                for (let i = 0; i < m.dimension_join_trees.length; i++) {
                    const jt = m.dimension_join_trees[i];
                    if (jt.join_tree == null && !jt.ignore) {
                        alerts.push({
                            title: 'Missing dimension connections',
                            description: '',
                            time: m.last_updated || new Date(),
                            type: 'MISSING_JOIN_TREE',
                            objectId: m.id as string,
                            objectType: 'measure'
                        });
                        break;
                    }
                
                }
            }
        })
        


        

        return alerts;
    }, [pipelineNodes.dataUpdatedAt, relationships.dataUpdatedAt, measures.dataUpdatedAt]);


    const allMessages = useMemo(() => {
        const messages: MessageProps[] = (notifications.data || []).map(n => {
            return {
                title: n.title,
                description: '',
                time: n.timestamp,
                type: n.type,
                objectId: n.object_id,
                objectType: n.object_type,
            };
        });
        return [...staticAlerts].concat(messages);
    }, [notifications.dataUpdatedAt, staticAlerts]);



    useEffect(() => {
        emit('pliable:notifications', {
            count: allMessages.length
        })
    }, [allMessages.length]);
    return <>
        {notifications.isLoading && <PliableLoader />}
        {!notifications.isLoading && !nodesThatNeedBuilding.isLoading && !!notifications.data && !!nodesThatNeedBuilding.data && notifications.data.length == 0 && nodesThatNeedBuilding.data.length == 0 && staticAlerts.length == 0 && <>
            <div className="m-2">
                <SuccessAlert>
                    Nothing to report. Your data is fly AF.
                </SuccessAlert>
            </div>

        </>}

        {nodesThatNeedBuilding.data && nodesThatNeedBuilding.data.length > 0 && <div onClick={onClickBuild}>
            <Message
                title={`${nodesThatNeedBuilding.data.length} nodes need to be built`}
                description=""
                time={new Date()}
                type="BUILD_NEEDED" />
        </div>}
        {allMessages.map((m, idx) => <div onClick={() => {
            onClickMessage(m);
        }} key={idx}>
            <Message {...m}/>

        </div>)}
    </>
}

export const confirmRunBuild = async () => {
    const build_type = await getPromptAnswer('How do you want to run this build?', 'Build All', false, 'basic', [
        {
            label: 'Just build it',
            value: 'basic',
            description: 'Just build any nodes that have changed configuration since the last build.'
        },
        {
            label: 'Check for new data, then build',
            value: 'check_for_records',
            description: 'Before building, Pliable will check your data sources for new data before building. '
        },
        {
            label: 'Force a rebuild of everything',
            description: 'This will rebuild all nodes, even if they are up to date. Might be expensive if you have a lot of data in Snowflake.',
            value: 'force'
        }
    ]);
    if (build_type) {
        switch (build_type) {
            case 'check_for_records':
                runBuild('', false, true);
                break;
            case 'force':
                runBuild('', true, true);
                break;
            case 'basic':
            default:
                runBuild('', false);
                break;
        }
    }
};

const Inbox = (props: Props) => {
    const [hasNotifications, setHasNotifications] = useState(false);
    const processNotificationCount = (data: any) => {
        setHasNotifications(data.count > 0);
    }
    useEffect(() => {
        subscribe('pliable:notifications', processNotificationCount);

        return () => {
            unsubscribe('pliable:notifications', processNotificationCount);
        }

    }, []);

    useHotkeys('shift+ctrl+a', () => {
        confirmRunBuild();
    }, []);

    

    return <>

        <DraftOnly>
            <BSDropdown>
                <Indicator />

                <BSDropdown.Toggle title="Notifications" className={`nocaret me-4`} variant="inverted" id="dropdown-basic">
                    <i className="mdi mdi-bell font-24"></i>

                </BSDropdown.Toggle>
                <BSDropdown.Menu style={{
                    width: '500px',
                    overflowY: 'scroll',
                    maxHeight: '90vh',
                }}>
                    <NotificationsList onRunBuild={confirmRunBuild}/>
                </BSDropdown.Menu>
            </BSDropdown>
        </DraftOnly>
        <ProdOnly>
            <button className="btn btn-xs btn-primary me-4" onClick={confirmRunBuild} title="Build All (Ctrl+Shift+A)">
                <i className="mdi mdi-hammer-screwdriver" style={{ fontSize: '20px' }}></i>
            </button>
        </ProdOnly>
    </>
}

export default Inbox;