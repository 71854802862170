import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown, { Option } from '@components/form/Dropdown.component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Offcanvas } from 'react-bootstrap';
import { useDimensions } from '@models/reportBuilder';
import PipelineNodeName, { PipelineNodeFieldName } from '@components/pipelineNodes/PipelineNodeName.component';
import { reorderList } from '@services/list.service';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { MetricsList, MetricItem, InfoSection } from './shared.styles';
import PipelineNodeColumnLineage from '@components/pipelineNodes/PipelineNodeColumnLineage.component';
import FullScreenTakeover from '@components/general/FullScreenTakeover.component';

interface DimensionSelectorProps {
    options: Option[];
    selected: string[];
    onChange: (newValue: string[]) => void;
    disabledOptionIds?: string[];
    disableMulti?: boolean;
}

const DimensionSelector: React.FC<DimensionSelectorProps> = ({
    options,
    selected,
    onChange,
    disabledOptionIds,
    disableMulti = false
}) => {
    const [dropdownValue, setDropdownValue] = useState('');
    const [infoDimensionId, setInfoDimensionId] = useState<string>();
    const [showLineage, setShowLineage] = useState(false);
    const dimensions = useDimensions();

    const handleAdd = (newValue: string) => {
        if (disableMulti) {
            // In single selection mode, replace the existing selection
            onChange([newValue]);
        } else {
            // In multi selection mode, add to the list if not already present
            if (!selected.includes(newValue)) {
                onChange([...selected, newValue]);
            }
        }
        setDropdownValue('');
    };

    const handleRemove = (valueToRemove: string) => {
        onChange(selected.filter(value => value !== valueToRemove));
    };

    const getOptionLabel = (value: string) => {
        return options.find(opt => opt.value === value)?.label || value;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination || disableMulti) {
            return;
        }

        const newOrder = reorderList(
            selected,
            result.source.index,
            result.destination.index
        );
        onChange(newOrder);
    };

    const activeDimension = dimensions.data?.find(d => d.id === infoDimensionId);

    return (
        <div>
            <Dropdown
                options={options}
                selected={dropdownValue}
                onChange={handleAdd}
                disabledOptionIds={[...disabledOptionIds || [], ...selected]}
                placeholder={disableMulti ? "Select a new dimension" : "Add dimension..."}
            />
            
            {selected.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="dimensions-list">
                        {(provided) => (
                            <MetricsList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {selected.map((value, index) => (
                                    <Draggable 
                                        key={value} 
                                        draggableId={value} 
                                        index={index}
                                        isDragDisabled={disableMulti}
                                    >
                                        {(provided, snapshot) => (
                                            <MetricItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={snapshot.isDragging ? 'dragging' : ''}
                                            >
                                                {!disableMulti && <i className="mdi mdi-drag drag-handle"></i>}
                                                <span className="metric-name">{getOptionLabel(value)}</span>
                                                <button
                                                    className="icon-button"
                                                    onClick={() => setInfoDimensionId(value)}
                                                >
                                                    <i className="mdi mdi-information"></i>
                                                </button>
                                                <button 
                                                    className="icon-button delete-button"
                                                    onClick={() => handleRemove(value)}
                                                >
                                                    <i className="mdi mdi-delete"></i>
                                                </button>
                                            </MetricItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </MetricsList>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <FullScreenTakeover
                show={showLineage}
                onHide={() => setShowLineage(false)}
            >
                {activeDimension && (
                    <PipelineNodeColumnLineage
                        pipelineNodeId={activeDimension.pipeline_node_id}
                        fieldId={activeDimension.field_id}
                    />
                )}
            </FullScreenTakeover>

            <Offcanvas 
                show={!!infoDimensionId} 
                onHide={() => setInfoDimensionId(undefined)} 
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="flex-1">Dimension Details</Offcanvas.Title>
                    {activeDimension && (
                        <>
                            {/* <button 
                                className="btn btn-light btn-sm me-2"
                                onClick={() => setShowLineage(true)}
                            >
                                <i className="mdi mdi-source-branch"></i> Lineage
                            </button> */}
                            {/* <Link 
                                to={`/dimension/${activeDimension.id}`}
                                className="btn btn-light btn-sm me-2"
                            >
                                <i className="mdi mdi-pencil"></i> Edit
                            </Link> */}
                        </>
                    )}
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                            <div className="p-2">
                                {activeDimension && (
                                    <>
                                        <InfoSection>
                                            <h6>Name</h6>
                                            <p>{activeDimension.name}</p>
                                        </InfoSection>

                                        <InfoSection>
                                            <h6>Description</h6>
                                            <p>{activeDimension.description || <em>No description provided</em>}</p>
                                        </InfoSection>

                                        <InfoSection>
                                            <h6>Source Table</h6>
                                            <p>
                                                <PipelineNodeName 
                                                    pipelineNodeId={activeDimension.pipeline_node_id} 
                                                />
                                            </p>
                                        </InfoSection>

                                        <InfoSection>
                                            <h6>Column</h6>
                                            <p>
                                                <PipelineNodeFieldName 
                                                    pipelineNodeId={activeDimension.pipeline_node_id} 
                                                    fieldId={activeDimension.field_id} 
                                                />
                                            </p>
                                        </InfoSection>
                                    </>
                                )}
                            </div>
                        </PaneContent>
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default DimensionSelector;
