/**
 * TODO:
 * 
 * Phase 1:
 * - Allow users to access analysis screen from the nav
 * - Remove the analysis name - by definition they are always ephemeral in phase 1
 * - Allow downloading a CSV of analysis results
 * - Allow searching the table like we do with other screens
 * - Bug: when drilling down, the same filter may be added multiple times
 * - Bug: bar charts are having issues when dates are empty
 * 
 * Phase 2:
 * - Allow users to save analyses
 *      - In production, saving an analysis needs a different button to copy it into DEV
 *      - In DEV, if you are editing an existing analysis, you can either save it or copy it
 *      - If you are drilling down from a "saved" analysis, it will create a new ephemeral analysis to handle the drill-down so you don't affect the structure of the current analysis
 */


import { PipelineNode, PipelineNodeExtraReportColumn, PipelineNodeField, PipelineNodeMeasureJoinTree, PipelineNodeORM, useNodesThatNeedBuilding, useReportingPaths } from "@models/pipelineNode";
import { ReportBuilderDimension, ReportBuilderDimensionJoinTree, ReportBuilderDimensionORM, ReportBuilderMeasure, ReportBuilderMeasureORM, useDimensions, useMeasures, useUniqueValuesInDimension } from "@models/reportBuilder";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Button, Form, Modal, Offcanvas } from "react-bootstrap";
import { Updater, useImmer } from "use-immer";
import { invalidatePipelineNodes, useIsInDraftMode, usePipelineNode, usePipelineNodes, useReportingTree } from "@stores/data.store";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import { graphlib } from "dagre";

import  { getConnectionsForAllGraphNodes } from "@components/datamodel/MeasureForm.component";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";

import { reorderList } from "@services/list.service";
import { Analysis, AnalysisDimensionFilter, AnalysisORM, AnalysisSortOrder, AnalysisVisualizationConfig } from "@models/analysis";
import AsyncButton from "@components/button/AsyncButton.component";
import BuildOrchestrationORM, { BuildOrchestration } from "@models/buildOrchestration";
import { getErrorMessage } from "@services/errors.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import Danger from "@components/statusIndicators/Danger.component";
import { Column } from "@components/datatable/DataTable.component";
import Chart from 'react-apexcharts';
import { comparatorOptions, comparatorsWithValues } from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import produce from "immer";
import ConfettiExplosion from "react-confetti-explosion";
import DataTablePagination from "@components/datatable/Pagination.component";
import LoadingCard from "@components/card/LoadingCard.component";
import { formatValue } from "@services/formatting.service";
import toast from "@services/toast.service";
import SaveButton from "@components/button/SaveButton.component";
import EditableText from "@components/general/EditableText.component";
import { fastInnerHTML } from "handsontable/helpers/dom";
import { getPromptAnswer, requireConfirmation, runBuild } from "@services/alert/alert.service";
import { useRouteBlocker } from "@services/routing.service";
import { timeAgo } from "@services/time.service";
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import { MiddlePanel } from "redoc";
import MetricSelector from './MetricSelector.component';
import DimensionSelector from "./DimensionSelector.component";
import HumanReadableDimensionFilters from './HumanReadableDimensionFilters.component';
import { useDebounce } from "use-debounce";
import SingleDimensionFilter from "./SingleDimensionFilter.component";
import DateRangeSelector from '@components/form/DateRangeSelector.component';
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import BigMetricCard from "@components/metric/BigMetricCard.component";
import { LTVPivotTableForm, LTVPivotTableVisualization } from './premium/LTVPivotTable';
import { DrillDownParams, BaseVisualizationProps } from "@models/analysisVisualization";

interface VisualizationProps<T> extends BaseVisualizationProps<T> {}

export interface TableVisualizationProps {
    options: {
        columns: {
            field: string;
            entityType: string;
            entityIdx: number;
            headerName: string;
            sortable: boolean;
            filter: boolean;
            formatter: string;
        }[];
        data: Record<string, any>[];
        total_records: number;
    }
}

export interface SingleValueVisualizationProps {
    options: {
        value: number | null;
        format: string | null;  // Formatter type (e.g., 'NUMERIC', 'CURRENCY', etc.)
        name: string;  // Display name/label for the value
    }
}

export const SingleValueVisualization = (props: VisualizationProps<SingleValueVisualizationProps>) => {
    return <div>
        <BigMetricCard
            title={props.params.options.name}
            metric={formatValue({
                value: props.params.options.value,
                format: props.params.options.format || ''
            })}
            loading={false}
            icon=""
        />

    </div>
}

export const TableVisualization = (props: VisualizationProps<TableVisualizationProps>) => {
    const dimColumns = useMemo(() => {
        return props.params.options.columns.filter(c => c.entityType == 'DIMENSION').map(c => c.field);
    }, [props.params.options.columns]);

    const onClickMetric = useCallback((metricIdx: number, rowData: Record<string, any>) => {
        const dimensionValues = dimColumns.map(d => rowData[d]);
        const dimensionFilters = dimensionValues.map((val, idx) => ({
            dimension_id: props.dimensionIds[idx],
            comparator: (!val || val === '(EMPTY)') ? 'EMPTY' : 'IN',
            value: (!val || val === '(EMPTY)') ? '' : [val],
        }));

        props.onDrillDown({
            metricIds: [props.measureIds[metricIdx]],
            dimensionIds: props.dimensionIds,
            dimensionFilters
        });
    }, [props.onDrillDown, dimColumns, props.dimensionIds, props.measureIds]);

    const columns = useMemo(() => {
        return props.params.options.columns.map(c => {
            const rv: Column = {
                header: c.headerName,
                key: c.field,
                onClick: undefined,
            }

            if (c.entityType == 'METRIC' && c.entityIdx >= 0) {
                rv.onClick = (value, rowValues) => {
                    onClickMetric(c.entityIdx, rowValues);
                }
            }
            return rv;
        });
    }, [props.params.options.columns]);

    const sortDirByEntity = useMemo(() => {
        const sortDirs: {[key: string]: string} = {};
        props.sortOrder.forEach(s => {
            sortDirs[s.entity_type + ':' + s.entity_id] = s.ascending ? 'asc' : 'desc';
        });
        return sortDirs;
    }, [props.sortOrder]);

    const currentPage = useMemo(() => {
        return props.currentPage || 1;
    }, [props.currentPage]);

    const totalPages = useMemo(() => {
        return Math.ceil(props.params.options.total_records / (props.pageSize || 50));
    }, [props.params.options.total_records, props.pageSize]);

    return <div><table className="table table-bordered table-compact">
        <thead className="bg-light">
            <tr>
                {props.params.options.columns.map(c => {
                    const entityType = c.entityType;
                    let entityId = '';
                    if (entityType == 'METRIC') {
                        entityId = props.measureIds[c.entityIdx];
                    } else if (entityType == 'DIMENSION') {
                        entityId = props.dimensionIds[c.entityIdx];
                    }
                    const sortDir = sortDirByEntity[entityType + ':' + entityId];

                    return <th key={c.field}>
                        <div className="d-flex center-vertically" >
                            <div>{c.headerName}</div>
                            <div className="flex-1"></div>
                            <div>
                                <a role="button" onClick={() => {
                                    props.onToggleSort(entityType, entityId);
                                }}>
                                    {sortDir == 'asc' && <i className="mdi mdi-sort-ascending"></i>}
                                    {sortDir == 'desc' && <i className="mdi mdi-sort-descending"></i>}
                                    {!sortDir && <i className="mdi mdi-sort"></i>}
                                </a>
                            </div>
                        </div>
                    </th>;
                })}
            </tr>
        </thead>
        <tbody>
            {props.params.options.data.map((row, idx) => {

                return <tr key={idx}>
                    {props.params.options.columns.map(c => {
                        if (c.entityType == 'DIMENSION') {
                            return <th key={c.field}>{row[c.field]}</th>
                        }
                        return <td key={c.field}>
                            <a role="button" className="force-link" onClick={() => {
                                onClickMetric(c.entityIdx, row);
                            }}>{formatValue({
                                value: row[c.field], 
                                format: c.formatter
                            })}</a>
                            
                        </td>
                    })}
                </tr>
            })}
        </tbody>
    </table>
    {props.onChangePage && <div>
        <DataTablePagination
            pageIndex={currentPage - 1}
            canPreviousPage={currentPage > 1}
            canNextPage={currentPage < totalPages}
            pageSize={props.pageSize || 50}
            setPageSize={(size: number) => {
                props.onChangePage!(currentPage, size);
            }}
            pageCount={totalPages}
            nextPage={() => {
                props.onChangePage!(currentPage + 1, props.pageSize || 50);
            }}
            previousPage={() => {
                props.onChangePage!(currentPage - 1, props.pageSize || 50);
            }}
            gotoPage={(newPage) => {
                props.onChangePage!(newPage + 1, props.pageSize || 50);
            }}
        />
    </div>}
    </div>
}

const getAllUpstreamNodes = (nodeId: string, graph: graphlib.Graph<string>): string[] => {
    const rv: string[] = [];
    const stack: string[] = [nodeId];
    while (stack.length > 0) {
        const nextNode = stack.pop() as string;
        rv.push(nextNode);
        const preds = graph.predecessors(nextNode);
        if (preds) {
            stack.push(...preds);
        }
    }
    return rv;
}

export const PivotTableVisualization = (props: VisualizationProps<TableVisualizationProps>) => {
    const columns = useMemo(() => {
        return props.params.options.columns.map(c => {
            return {
                header: c.headerName,
                key: c.field,
            }
        });
    }, [props.params.options.columns]);

    const dim1Column = useMemo(() => {
        return props.params.options.columns[0].field || '';
    }, [props.params.options.columns]);

    const onClickMetric = useCallback((colIdx: number, rowData: Record<string, any>) => {
        const dimensionFilters = [
            {
                dimension_id: props.dimensionIds[0],
                comparator: 'IN',
                value: [rowData[props.params.options.columns[0].field]],
            },
            {
                dimension_id: props.dimensionIds[1],
                comparator: 'IN',
                value: [props.params.options.columns[colIdx].field],
            }
        ];

        props.onDrillDown({
            metricIds: [props.measureIds[0]],
            dimensionIds: props.dimensionIds,
            dimensionFilters
        });
    }, [props.onDrillDown, props.dimensionIds, props.measureIds]);

    return <table className="table table-bordered table-compact">
        <thead className="bg-light">
            <tr>{props.params.options.columns.map(c => <th key={c.field}>{c.headerName}</th>)}</tr>
        </thead>
        <tbody>
            {props.params.options.data.map((row, idx) => {

                return <tr key={idx}>
                    {props.params.options.columns.map((c, idx) => {
                        if (idx == 0) {
                            return <th key={c.field}>{row[c.field]}</th>
                        }
                        return <td key={c.field}>
                            <a role="button" className="force-link" onClick={() => {
                                onClickMetric(idx, row);
                            }}>{row[c.field]}</a>
                            
                        </td>
                    })}
                </tr>
            })}
        </tbody>
    </table>
}

interface ApexVisualizationProps {
    options: ApexCharts.ApexOptions;
    render_type: string;
}

export const ApexVisualization = (props: VisualizationProps<ApexVisualizationProps>) => {
    const chartType = props.params.render_type == 'apex_bar' ? 'bar' : 'line';

    const handleDataPointSelection = useCallback((event: any, chartContext: any, config: any) => {
        if (!props.params.options.series) return;

        // @ts-ignore
        const series = props.params.options.series[config.seriesIndex].name;
        const xAxis = props.params.options.xaxis?.categories[config.dataPointIndex];

        const dimensionFilters = props.params.options.series.length > 1 ? [
            {
                dimension_id: props.dimensionIds[0],
                comparator: 'IN',
                value: [xAxis],
            },
            {
                dimension_id: props.dimensionIds[1],
                comparator: 'IN',
                value: [series],
            }
        ] : [
            {
                dimension_id: props.dimensionIds[0],
                comparator: 'IN',
                value: [xAxis],
            }
        ];

        props.onDrillDown({
            metricIds: [props.measureIds[0]],
            dimensionIds: props.dimensionIds,
            dimensionFilters
        });
    }, [props.onDrillDown, props.dimensionIds, props.measureIds]);

    const chartParams = {
        ...props.params.options,
        chart: {
            ...props.params.options.chart,
            events: {
                dataPointSelection: handleDataPointSelection
            }
        }
    }
    return <Chart
        options={chartParams}
        series={props.params.options.series}
        type={chartType}
        height="100%"
    />
}


interface DimensionFilterFormProps {
    dimensionFilters: AnalysisDimensionFilter[];
    onChange: (filters: AnalysisDimensionFilter[]) => void;
    dimensionOptions: Option[];
}

const DimensionFilterForm = (props: DimensionFilterFormProps) => {
    const addNewFilter = useCallback(() => {
        props.onChange([...props.dimensionFilters, {
            dimension_id: '',
            comparator: 'IN',
            value: '',
        }]);
    }, [props.onChange, props.dimensionFilters]);

    const handleDelete = useCallback((idx: number) => {
        props.onChange(produce(props.dimensionFilters, draft => {
            draft.splice(idx, 1);
        }));
    }, [props.onChange, props.dimensionFilters]);

    return <div>
        <table className="table table-bordered table-fixed table-centered">
            <thead className="bg-light">
                <tr>
                    <th style={{width:'40%'}}>Dimension</th>
                    <th style={{width: '50%'}}>Value</th>
                    <th className="text-end">
                        <button className="icon-button" onClick={addNewFilter}>
                            <i className="mdi mdi-plus-thick"></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                {props.dimensionFilters.map((f, idx) => (
                    <SingleDimensionFilter
                        displayType='table-row'
                        key={idx}
                        dimensionFilter={f}
                        onChange={(newFilter) => {
                            props.onChange(produce(props.dimensionFilters, draft => {
                                draft[idx] = newFilter;
                            }));
                        }}
                        dimensionOptions={props.dimensionOptions}
                        onDelete={() => handleDelete(idx)}
                    />
                ))}
            </tbody>
        </table>
    </div>
}

export class ReportBuilderState {

    constructor(
        graph: graphlib.Graph,
        allDimensions: ReportBuilderDimension[],
        allMeasures: ReportBuilderMeasure[],
        initialSelectedDimensions: string[],
        initialSelectedMeasures: string[],
    ) {
        this.allDimensions = allDimensions;
        this.allMeasures = allMeasures;
        this._availableDimensionIds = [];
        this._availableMeasureIds = [];
        this.selectedDimensionIds = initialSelectedDimensions;
        this.selectedMeasureIds = initialSelectedMeasures;
        this.graph = graph;

        const measuresById: {[key: string]: ReportBuilderMeasure} = {};
        this.allMeasures.forEach(m => {
            measuresById[m.id as string] = m;
        });
        this.measuresById = measuresById;

        const dimensionsById: {[key: string]: ReportBuilderDimension} = {};
        this.allDimensions.forEach(d => {
            dimensionsById[d.id as string] = d;
        });
        this.dimensionsById = dimensionsById;

        // Now create a graph of measure dependencies for calculations so we can handle multiple layers
        this.measureGraph = new graphlib.Graph<string>({
            directed: true,
            multigraph: true,
        });

        this.allMeasures.forEach(m => {
            this.measureGraph.setNode(m.id as string, m.name);
            if (m.is_calculation && m.parent_measure_ids) {
                m.parent_measure_ids.forEach(parentId => {
                    this.measureGraph.setEdge(parentId, m.id as string);
                });
            }
        });

        
        this.updateAvailableDimensionIds();
        this.updateAvailableMeasureIds();
    }

    protected measuresById: {[key: string]: ReportBuilderMeasure};
    protected dimensionsById: {[key: string]: ReportBuilderDimension};

    public allDimensions: ReportBuilderDimension[] = [];
    public allMeasures: ReportBuilderMeasure[] = [];
    public selectedDimensionIds: string[] = [];
    public selectedMeasureIds: string[] = [];
    protected graph: graphlib.Graph;
    protected measureGraph: graphlib.Graph<string>;
    

    protected _availableDimensionIds: string[] = [];
    protected _availableMeasureIds: string[] = [];
    public get availableDimensionIds() {
        return this._availableDimensionIds;
    }
    public get availableMeasureIds() {
        return this._availableMeasureIds;
    }

    public hasMissingDeps(measure: ReportBuilderMeasure): boolean {
        if (!measure.is_calculation || !measure.parent_measure_ids) {
            return false;
        }
        
        return this.selectedMeasureIds.includes(measure.id as string) && 
               measure.parent_measure_ids.some(parentId => 
                   !this.selectedMeasureIds.includes(parentId));
    }

    public getMissingDeps(measure: ReportBuilderMeasure): string[] {
        if (!measure.is_calculation || !measure.parent_measure_ids) {
            return [];
        }
        return measure.parent_measure_ids.filter(id => 
            !this.selectedMeasureIds.includes(id));
    }

    public getMeasureNameById(id: string): string {
        return this.measuresById[id]?.name || id;
    }

    public hasAnyMissingDeps(): boolean {
        return this.allMeasures.some(measure => this.hasMissingDeps(measure));
    }

    public getMissingDepsMessage(measure: ReportBuilderMeasure): string {
        const missingDeps = this.getMissingDeps(measure);
        if (!measure.parent_measure_ids || missingDeps.length === 0) {
            return '';
        }

        return `This measure requires ${measure.parent_measure_ids.map(id => 
            this.getMeasureNameById(id)).join(' and ')} in order to be calculated, but is missing ${
            missingDeps.map(id => this.getMeasureNameById(id)).join(', ')}.`;
    }

    public updateAvailableDimensionIds() {
        if (this.selectedMeasureIds.length == 0) {
            return [];
        }

        const currentlySelectedNodeIds = this.allDimensions.filter(d => this.selectedDimensionIds.includes(d.id as string)).map(d => d.pipeline_node_id) || [];
        
        
        let requiredDimensionIds: string[] = [];

        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        const measureIdsToConsiderForDimensionsList = [...this.selectedMeasureIds];
        this.selectedMeasureIds.forEach((mid, idx) => {
            const measure = this.measuresById[mid];
            if (measure?.is_calculation) {
                const allUpstream = getAllUpstreamNodes(measure.id as string, this.measureGraph);
                // Now concatenate the upstream nodes to the list of measures to consider
                measureIdsToConsiderForDimensionsList.push(...allUpstream);
            }
        })

        // Ensure the list is unique so we don't double-process
        const uniqueMeasureIdsToConsider = new Set(measureIdsToConsiderForDimensionsList);
        if (uniqueMeasureIdsToConsider.size > 0) {
            let startedTree = false;
            uniqueMeasureIdsToConsider.forEach((mid, idx) => {
                const measure = this.measuresById[mid];
                if (!measure) {
                    return;
                }

                if (measure?.is_calculation) {
                    return;
                }
                
                // Possible edge case where the measure doesn't yet have a pipeline node ID set
                const startWith: string[] = [];
                if (measure.pipeline_node_id) {
                    startWith.push(measure.pipeline_node_id as string);
                }

                /**
                 * Here we're basically building a list of pipeline node IDs that are connected to
                 * every single selected measure. If we haven't selected a measure yet, (startedTree == false)
                 * then we just start with the selected measure's pipeline node ID and all of that measure's join
                 * trees.
                 * 
                 * If we have already started the tree, then we filter the list of required dimension IDs to only
                 * those that are also connected to every subsequent measure.
                 */
                if (!startedTree) {
                    requiredDimensionIds = startWith.concat(measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || []);
                    startedTree = true;
                } else {
                    requiredDimensionIds = startWith.concat(requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d)));
                }
            });
        }

        /**
         * Now we can select the available dimension IDs. 
         */
        const uniqueNodeIds = new Set(currentlySelectedNodeIds);
        const MAX_NODES = 5;
        
        this._availableDimensionIds = this.allDimensions.filter(d => {
            // if (this.selectedDimensionIds.includes(d.id as string)) {
            //     return true;
            // }

            if (!d.pipeline_node_id) {
                return false;
            }

            if (!requiredDimensionIds.includes(d.pipeline_node_id)) {
                return false;
            }

            // If they've already hit the max, then only allow dimensions in the already selected nodes
            if (uniqueNodeIds.size >= MAX_NODES) {
                return uniqueNodeIds.has(d.pipeline_node_id);
            }
            
            if (currentlySelectedNodeIds.length == 0) {
                return true;
            }

            return true;
        }).map(d => d.id as string);
        
    }

    protected updateAvailableMeasureIds() {
        let requiredDimensionIds: string[] = [];
        /**
         * Get a list of the pipeline node IDs that are connected to EVERY selected measure
         */
        this.selectedMeasureIds.forEach((mid, idx) => {
            const measure = this.measuresById[mid];
            if (idx == 0) {
                requiredDimensionIds = measure?.dimension_join_trees?.map(d => d.pipeline_node_id) || [];
            } else {
                requiredDimensionIds = requiredDimensionIds.filter(d => measure?.dimension_join_trees?.map(d => d.pipeline_node_id).includes(d));
            }
        });
        const requiredPipelineNodeIdsFromCurrentDimensions = this.selectedDimensionIds.filter(did => this.dimensionsById.hasOwnProperty(did)).map(did => this.dimensionsById[did].pipeline_node_id) || [];

        requiredDimensionIds = requiredDimensionIds?.filter(did => requiredPipelineNodeIdsFromCurrentDimensions.includes(did));

        this._availableMeasureIds = this.allMeasures.filter(m => {
            if (this.selectedMeasureIds.includes(m.id as string)) {
                return true;
            }
            
            if (m.is_calculation) {
                if (!m.parent_measure_ids) {
                    return true;
                }
                // return m.parent_measure_ids.every(pmid => 
                //     this.selectedMeasureIds.includes(pmid));
                // Now we allow them to select a calculated measure because we automatically include its dependencies in the backend.
                return true;
            }

            if (!m.dimension_join_trees) {
                return false;
            }

            if (requiredDimensionIds.length == 0) {
                return true;
            }
            
            const allConnectedPipelineNodeIdsForThisMeasure = m.dimension_join_trees
                .map(d => d.pipeline_node_id);
            return requiredDimensionIds.every(r => 
                allConnectedPipelineNodeIdsForThisMeasure.includes(r));
        }).map(m => m.id as string);
    }
}

const DataOption = styled.div<{disabled?: boolean, used?: boolean, noHover?: boolean}>`
font-family: "Poppins";
&:last-child {
    margin-bottom: 0;
}

font-weight: 600;

margin-bottom: .25rem;

.inner {
border: solid 1px var(--ct-border-color);
border-radius: 4px;

padding: .25rem;
}

${props => !props.noHover && css`
    &:hover .inner {
        background-color: var(--ct-light);
        cursor: move;
    }
`}


${props => props.disabled && css`
    opacity: 0.5;
    pointer-events: none;
`}
`

const DropZone = styled.div`
border-radius: 6px;
border: dashed 2px var(--ct-border-color);
min-height: 40px;
padding: .25rem;
div.placeholder {
    height: 50px;
    line-height: 50px;
    color: var(--ct-border-color);
    text-align: center;
    font-family: "Poppins";


}
`




const VizOptionButton = styled.button`
border-radius: 4px;
border: 1px solid var(--ct-border-color);
`

export const OptionGrid = styled.div`
display: grid;
gap: 10px;
grid-template-columns: 1fr 1fr;

button.active {
    background-color: var(--pliable-blue-bg);
}
`

/**
 * Returns a random whimsical loading message for Pliable's loading screen.
 * These messages highlight the complex data processing happening behind the scenes.
 * @returns A random loading message string
 */
function getRandomLoadingMessage(): string {
    const loadingMessages: string[] = [
        "Looking under rocks for your data...",
        "Performing data wizardry that would impress your entire engineering team...",
        "Compressing hours of data engineering into milliseconds...",
        "Teaching machines to do what used to require three caffeinated humans...",
        "Bending the laws of data physics so you don't have to...",
        "Negotiating peace treaties between conflicting datasets...",
        "Folding space-time continuum (and your data) into meaningful insights...",
        "Turning what used to be a three-person job into your coffee break...",
        "Persuading stubborn data points to reveal their secrets...",
        "Doing in seconds what your last company needed a whole team for...",
        "Making big data feel small and manageable...",
        "Converting data chaos into beautiful order...",
        "Saving you thousands of dollars in data engineering salaries...",
    ];
  
    // Generate a random index within the array length
    const randomIndex = Math.floor(Math.random() * loadingMessages.length);
    
    // Return the message at the random index
    return loadingMessages[randomIndex];
};

const DATE_RANGE_OPTIONS = [
    {
        label: 'All Time',
        value: 'ALL_TIME',
    },
    {
        label: 'Today',
        value: 'TODAY',
    }, {
        label: 'Yesterday',
        value: 'YESTERDAY',
    }, {
        label: 'This Week',
        value: 'THIS_WEEK',
    }, {
        label: 'Last Week',
        value: 'LAST_WEEK',
    }, {
        value: 'THIS_MONTH',
        label: 'This Month',
    }, {
        value: 'LAST_MONTH',
        label: 'Last Month',

    }, {
        value: 'THIS_QUARTER',
        label: 'This Quarter',
    }, {
        value: 'LAST_QUARTER',
        label: 'Last Quarter',
    }, {
        value: 'THIS_YEAR',
        label: 'This Year',
    }, {
        value: 'LAST_YEAR',
        label: 'Last Year',
    }, {
        value: 'CUSTOM',
        label: 'Custom',
    }
]


const AnalysisScreen = () => {
    const [analysis, setAnalysis] = useImmer<Analysis>({
        id: null,
        name: '',
        description: '',
        visualization_type: 'TABLE',
        measure_ids: [],
        dimension_ids: [],
        dimension_filters: [],
        viz_options: {},
        date_range: 'ALL_TIME',
        ephemeral: true,
        data_source_type: 'DYNAMIC',
        static_source_pipeline_node_id: '',
        default_sort_order: [],
    });

    // Not using route blocker yet bc of all the weirdness around saving a copy vs saving the original
    const [pageDirty, setPageDirty] = useState(false);

    const staticPipelineNode = usePipelineNode(analysis.static_source_pipeline_node_id);
    const { analysisId } = useParams<{analysisId: string}>();
    const [loadingAnalysis, setLoadingAnalysis] = useState(false);
    const loadAnalysis = useCallback(async () => {
        setLoadingAnalysis(true);
        try {
            const result = await AnalysisORM.findById(analysisId as string);
            setAnalysis(result);
            setTimeout(() => {
                loadVisualizationConfig(analysisId as string, false, true);
            }, 500)

        } catch (err) {
            toast('danger', 'Error loading analysis', getErrorMessage(err));
        } finally {
            setLoadingAnalysis(false);
        }


    }, [analysisId]);
    useEffect(() => {
        if (!analysisId) {
            return;
        }
        loadAnalysis();
    }, [analysisId]);


    const [showConfetti, setShowConfetti] = useState(false);

    const inDraft = useIsInDraftMode();

    const [editingDimension, setEditingDimension] = useState<ReportBuilderDimension | null>(null);
    const [editingMeasure, setEditingMeasure] = useState<ReportBuilderMeasure | null>(null);

    const dimensions = useDimensions();
    const measures = useMeasures();

    const saveDim = useCallback(async (updatedDim: ReportBuilderDimension) => {
        await ReportBuilderDimensionORM.save(updatedDim);
        dimensions.refetch();
        setEditingDimension(null);
    }, []);

    const saveMeasure = useCallback(async (updatedMeasure: ReportBuilderMeasure) => {
        await ReportBuilderMeasureORM.save(updatedMeasure);
        measures.refetch();
        setEditingMeasure(null);
    }, []);

    const tree = useReportingTree();

    const [reportBuilderState, setReportBuilderState] = useState<ReportBuilderState>(new ReportBuilderState(new graphlib.Graph(), [], [], [], []));

    const graph = useMemo(() => {
        const g = new graphlib.Graph({
            directed: true,
            multigraph: true,
        });

        if (!tree.data) {
            return g;
        }

        tree.data.nodes.forEach(n => {
            g.setNode(n.id, n.title);
        });

        tree.data.edges.forEach(e => {
            g.setEdge(e.from_id, e.to_id, e.relationship_id);
        });

        return g
    }, [tree.dataUpdatedAt]);

    useEffect(() => {
        const newState = new ReportBuilderState(graph, dimensions.data || [], measures.data || [], analysis.dimension_ids || [], analysis.measure_ids || []);
        setReportBuilderState(newState);
    }, [graph, analysis.measure_ids, analysis.dimension_ids, dimensions.dataUpdatedAt, measures.dataUpdatedAt, dimensions.data, measures.data])

    const allNodeConnections = useMemo(() => {
        return getConnectionsForAllGraphNodes(graph);
    }, [graph]);

    const dimensionsById = useMemo(() => {
        if (!dimensions.data) {
            return {};
        }
        return dimensions.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderDimension});
    }, [dimensions.dataUpdatedAt]);

    const measuresById = useMemo(() => {
        if (!measures.data) {
            return {};
        }
        return measures.data.reduce((acc, d) => {
            acc[d.id as string] = d;
            return acc;
        }, {} as {[key: string]: ReportBuilderMeasure});
    }, [measures.dataUpdatedAt]);

    const dimensionPipelineNodeIds = useMemo(() => {
        return dimensions.data?.map(d => d.pipeline_node_id) || [];
    }, [analysis.dimension_ids, dimensionsById]);

    const [showColumn, setShowColumn] = useState<boolean>(false);

    

    const [measureFilter, setMeasureFilter] = useState('');
    const filteredMeasures = useMemo(() => {
        if (!measures.data) {
            return [];
        }
        return measures.data.filter(m => m.name.toLowerCase().includes(measureFilter.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }, [measureFilter, measures.dataUpdatedAt]);

    const [dimensionFilter, setDimensionFilter] = useState('');
    const filteredDimensions = useMemo(() => {
        if (!dimensions.data) {
            return [];
        }
        return dimensions.data.filter(m => m.name.toLowerCase().includes(dimensionFilter.toLowerCase())).sort((a, b) => a.name.localeCompare(b.name));
    }, [dimensionFilter, dimensions.dataUpdatedAt]);

    const clearAll = useCallback(() => {
        setAnalysis(draft => {
            draft.measure_ids = [];
            draft.dimension_ids = [];
        });
    }, []);

    const onReorderMeasures = useCallback((result: any) => {
        if (!result.destination || !result.source) {
            return;
        }
        setAnalysis(draft => {
            if (!draft.measure_ids) {
                return;
            }
            const newOrder = reorderList(draft.measure_ids, result.source.index, result.destination.index);
            draft.measure_ids = newOrder;
        });
    }, []);

    const onReorderDimensions = useCallback((result: any) => {
        if (!result.destination || !result.source) {
            return;
        }
        setAnalysis(draft => {
            if (!draft.dimension_ids) {
                return;
            }
            const newOrder = reorderList(draft.dimension_ids, result.source.index, result.destination.index);
            draft.dimension_ids = newOrder;
        });
    }, []);

    const isPremiumVisualization = useMemo(() => {
        return analysis.visualization_type?.startsWith('PREMIUM_');
    }, [analysis.visualization_type]);

    const measureOptions = useMemo(() => {
        if (analysis.data_source_type === 'STATIC') {
            if (!staticPipelineNode.data?.fields) {
                return [];
            }
            return staticPipelineNode.data.fields
                .filter(f => f.type === 'INT' || f.type === 'DECIMAL')  // Only numeric fields can be measures
                .map(f => ({
                    value: f.id as string,
                    description: f.description,
                    label: f.name,
                }));
        }

        if (!measures.data) {
            return [];
        }

        return measures.data.map(m => ({
            value: m.id as string,
            description: m.description,
            label: m.name,
        }));
    }, [analysis.data_source_type, staticPipelineNode.data, measures.dataUpdatedAt]);

    const dimensionOptions = useMemo(() => {
        if (analysis.data_source_type === 'STATIC') {
            if (!staticPipelineNode.data?.fields) {
                return [];
            }
            return staticPipelineNode.data.fields
                .filter(f => f.type !== 'INT' && f.type !== 'DECIMAL')  // Non-numeric fields can be dimensions
                .map(f => ({
                    value: f.id as string,
                    description: f.description,
                    label: f.name,
                }));
        }

        if (!dimensions.data) {
            return [];
        }

        return dimensions.data.map(m => ({
            value: m.id as string,
            label: m.name,
        }));
    }, [analysis.data_source_type, staticPipelineNode.data, dimensions.dataUpdatedAt]);

    const disabledMeasureIds = useMemo(() => {
        return reportBuilderState.allMeasures.map(m => m.id as string).filter(id => !reportBuilderState.availableMeasureIds.includes(id));
    }, [reportBuilderState.allMeasures, reportBuilderState.availableMeasureIds]);

    const disabledDimensionIds = useMemo(() => {
        return reportBuilderState.allDimensions.map(m => m.id as string).filter(id => !reportBuilderState.availableDimensionIds.includes(id));
    }, [reportBuilderState.allDimensions, reportBuilderState.availableDimensionIds]);


    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);
    const [loadingMessage, setLoadingMessage] = useState('Doing the work of 3 data engineers and a cave troll...');
    const [running, setRunning] = useState(false);

    const [visualizationConfig, setVisualizationConfig] = useState<AnalysisVisualizationConfig|undefined>(undefined);

    const [loadingViz, setLoadingViz] = useState(false);
    const [vizError, setVizError] = useState('');
    
    const [pageNum, setPageNum] = useState(1);
    const [pageSize, setPageSize] = useState(50);

    const goToPage = useCallback((newPage: number) => {
        setPageNum(newPage);
    }, []);

    const toggleSort = useCallback((entityType: string, entityId: string) => {
        // It goes ASC -> DESC -> OFF
        setAnalysis((draft) => {
            if (!draft.default_sort_order) {
                draft.default_sort_order = [];
            }
            const idx = draft.default_sort_order.findIndex(s => s.entity_id == entityId && s.entity_type == entityType);
            if (idx >= 0) {
                if (!draft.default_sort_order[idx].ascending) {
                    draft.default_sort_order.splice(idx, 1);
                } else {
                    draft.default_sort_order[idx].ascending = !draft.default_sort_order[idx].ascending;
                }
            } else {
                draft.default_sort_order.push({
                    entity_id: entityId,
                    entity_type: entityType,
                    ascending: true,
                });
            }
        });
        setPageDirty(true);
    }, []);

    const [downloadNodeId, setDownloadNodeId] = useState('');

    const [isPrepped, setIsPrepped] = useState(false);

    const backingNode = usePipelineNode(downloadNodeId);

    const loadVisualizationConfig = useCallback(async (analysisId: string, shouldShowConfetti: boolean = true, suppressErrors: boolean = false) => {
        setLoadingViz(true);
        setVizError('');
        setVisualizationConfig(undefined);
        setDownloadNodeId('');
        try {
            const config = await AnalysisORM.getVisualizationConfig({
                analysisId: analysisId,
                sortOrder: analysis.default_sort_order || [],
                page: pageNum,
                perPage: pageSize,
            });
            setVisualizationConfig(config.record);
            setDownloadNodeId(config.node_id);
            if (shouldShowConfetti) {
                setShowConfetti(true);
                setTimeout(() => {
                    setShowConfetti(false);
                }, 2000);
            }

            // Tell other parts of the app that we've run at least one analysis.
            const currentTimes = localStorage.getItem('timesRanAnalysis');
            const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
            
    
            localStorage.setItem('timesRanAnalysis', ((parsed || 0) + 1).toString());

            // Set isPrepped to true here just in case we're jumping around navigating. If we loaded it successfully we are prepped
            setIsPrepped(true);
            
        } catch (err) {
            if (!suppressErrors) {
                setVizError(getErrorMessage(err));
            }
        } finally {
            setLoadingViz(false);
        }
    }, [pageNum, analysis.default_sort_order, pageSize]);

    useEffect(() => {
        if (isPrepped) {
            loadVisualizationConfig(analysis.id as string, false);
        }
    }, [pageNum, analysis.default_sort_order, pageSize, analysis.id]);

    const navigate = useNavigate();

    const saveAnalysis = useCallback(async (newData: Analysis, ephemeral: boolean = true) => {
        
        const savedAnalysis = await AnalysisORM.save({
            ...newData,
            ephemeral: ephemeral,
            user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        });

        if (!analysisId) {
            // This will trigger a loading of the analysis. No need to do it twice.
            navigate(`/analysis/${savedAnalysis.id}`);
        } else {
            setAnalysis(savedAnalysis);
        }

        setPageDirty(false);

        return savedAnalysis;

    }, [analysisId]);

    const onChangeVisualization = useCallback((type: string) => {
        setAnalysis(draft => {
            draft.visualization_type = type;
            // Automatically set to DYNAMIC for premium visualizations
            if (type.startsWith('PREMIUM_')) {
                draft.data_source_type = 'DYNAMIC';
            }
        });
        setPageDirty(true);

        setVisualizationConfig(undefined); 
    }, [analysis.visualization_type, visualizationConfig]);

    const checkOrchestration = useCallback(async () => {
        if (!activeOrchestration) {
          
            return;
        }

        if (!['ERROR', 'COMPLETE', 'IN_REVIEW', 'CANCELLED'].includes(activeOrchestration.status)) {
            setTimeout(async () => {
                // Keep polling, which should then re-run this effect, and so on.
                const updatedOrch = await BuildOrchestrationORM.findById(activeOrchestration.id as string);
                setActiveOrchestration(updatedOrch);
            }, 1000);
            
        } else if (activeOrchestration.status == 'COMPLETE') {
            // setShowConfetti(true);
            // setTimeout(() => {
            //     setShowConfetti(false);
            // }, 1000);
            // invalidateEverything();
            setRunning(false);
            invalidatePipelineNodes();

            // If we were building a NEW analysis, navigate to that new one which will trigger a load of the visualization config
            if (analysis.id != analysisId) {
                navigate(`/analysis/${analysis.id}`);
            } else {
                loadVisualizationConfig(analysisId as string);
            }
            
        } else if (activeOrchestration.status == 'ERROR') {
            setBuildError(getErrorMessage(activeOrchestration.error));
            setRunning(false);
            // toast('danger', 'Error', getErrorMessage(activeOrchestration.error));
        }
    }, [activeOrchestration, loadVisualizationConfig, analysisId, analysis.id]);

    useEffect(() => {
        if (activeOrchestration) {
            checkOrchestration();
        }
    }, [activeOrchestration]);

    const [buildError, setBuildError] = useState('');

    const nodesThatNeedBuilding = useNodesThatNeedBuilding();

    const runAnalysis = useCallback(async (analysisId: string) => {
        setVizError('');
        setBuildError('');
        setLoadingMessage(getRandomLoadingMessage());
        setRunning(true);
        setIsPrepped(false);
        setVisualizationConfig(undefined);
        try {

            const orchestration = await AnalysisORM.prepAnalyses([analysisId], []);
            setActiveOrchestration(orchestration);
        } catch (err) {
            setBuildError(getErrorMessage(err));
            setRunning(false);
        }
    }, []);

    const canRunAnalysis = useMemo(() => {
        if (!analysis.measure_ids || analysis.measure_ids.length == 0) {
            return false;
        }

        // if (!analysis.dimension_ids || analysis.dimension_ids.length == 0) {
        //     return false;
        // }

        if (!analysis.visualization_type) {
            return false;
        }

        return true;
    }, [analysis.measure_ids, analysis.dimension_ids, analysis.visualization_type]);

    const [drilldownMetricId, setDrilldownMetricId] = useState('');
    const [drilldownDimensionValues, setDrilldownDimensionValues] = useState<string[]>([]);
    const [showDrilldownModal, setShowDrilldownModal] = useState(false);

    const [additionalDimensionIds, setAdditionalDimensionIds] = useState<string[]>([])

    const [drilldownParams, setDrilldownParams] = useState<DrillDownParams|undefined>(undefined);
    const [showDrilldownDisabledModal, setShowDrilldownDisabledModal] = useState(false);
    const onDrilldown = useCallback((params: DrillDownParams) => {
        if (analysis.data_source_type === 'STATIC') {
            setShowDrilldownDisabledModal(true);
            return;
        }

        console.log('Drilldown params:', params);

        setDrilldownParams(params);
        setShowDrilldownModal(true);
    }, [analysis.data_source_type]);

    const drilldownMetricNames = useMemo(() => {
        if (!drilldownParams || !measures.data) {
            return [];
        }

        return measures.data.filter(m => drilldownParams.metricIds.includes(m.id as string)).map(m => m.name);
    }, [drilldownParams, measures.dataUpdatedAt]);

    const drilldownDimensionNames = useMemo(() => {
        if (!dimensions.data) {
            return {};
        }

        const dimNames: {
            [id: string]: string
        } = {};
        dimensions.data.forEach(d => {
            dimNames[d.id as string] = d.name;
        })
        return dimNames;
    }, [dimensions.dataUpdatedAt]);

    const [showDimensionFilterModal, setShowDimensionFilterModal] = useState(false);

    const dimensionNamesById = useMemo(() => {
        if (!dimensions.data) {
            return {};
        }

        return dimensions.data.reduce((acc, d) => {
            acc[d.id as string] = d.name;
            return acc;
        }, {} as {[key: string]: string});
    }, [dimensions.dataUpdatedAt]);

    const doFurtherBreakdown = useCallback(async () => {
        if (!drilldownParams) {
            return;
        }
        const updatedAnalysis = produce(analysis, draft => {
            draft.dimension_ids = drilldownParams.dimensionIds.concat(additionalDimensionIds);
            if (!draft.dimension_filters) {
                draft.dimension_filters = [];
            }
            drilldownParams.dimensionFilters.forEach((val, idx) => {
                // Check if there's an existing filter with this value
                const existingFilter = draft.dimension_filters!.find(f => f.dimension_id === val.dimension_id);
                  

                if (existingFilter) {
                    // Update it so it just includes the new value
                    existingFilter.value = val.value
                    existingFilter.comparator = val.comparator;
                    return;
                } else {
                    draft.dimension_filters!.push(val);
                }

            });

            // For now, always switch to a table for drilldowns
            draft.visualization_type = 'TABLE';
        });

        setShowDrilldownModal(false);
    setAdditionalDimensionIds([]);
        setDrilldownParams(undefined);

        // If the current analysis is not ephemeral, we need to make an ephemeral copy to support the drilldown
        if (!analysis.ephemeral) {
            const newAnalysis = await AnalysisORM.save({
                ...updatedAnalysis,
                name: analysis.name + ' (Drilldown)',
                id: null,
                ephemeral: true,
            })
            if (!newAnalysis) {
                return;
            }
            setAnalysis(newAnalysis);
            runAnalysis(newAnalysis.id as string);
        } else {
            const savedAnalysis = await saveAnalysis(updatedAnalysis, true);
            runAnalysis(savedAnalysis.id as string);
            setAnalysis(savedAnalysis);
        }
    }, [analysis, additionalDimensionIds, drilldownDimensionValues, runAnalysis, drilldownParams]);

    const saveOrOverwrite = useCallback(async () => {
        if (pageDirty && !analysis.ephemeral) {
            // Ask the user if they want to override it or make a copy
            const answer = await getPromptAnswer('You have modified this analysis, and it may be in use by other members of your organization. How do you want to proceed?', 'Modified Analysis', false, '', [
                {
                    label: 'Overwrite Existing',
                    value: 'OVERWRITE',
                    description: 'This will update this current analysis with your changes',
                }, {
                    label: 'Make a Copy',
                    value: 'COPY',
                    description: 'This will create a copy of the analysis that you can modify without affecting the original',
                }
            ]);

            if (!answer) {
                return;
            }

            if (answer == 'OVERWRITE') {
                const savedAnalysis = await saveAnalysis(analysis, analysis.ephemeral);
                setAnalysis(savedAnalysis);
                toast('success', 'Success', 'Analysis saved');
                return savedAnalysis;

            } else {
                const newAnalysis = await createCopy(true, false);
                if (!newAnalysis) {
                    return;
                }
                toast('success', 'Copy created', 'Your analysis has been copied and is ready for editing.');
                setIsPrepped(false);
                setVisualizationConfig(undefined);
                setAnalysis(newAnalysis);
                return newAnalysis;
            }
        } else {
            const savedAnalysis = await saveAnalysis(analysis, analysis.ephemeral);
            setAnalysis(savedAnalysis);
            return savedAnalysis;
        }
    }, [analysis]);
    const runCurrentAnalysis = useCallback(async () => {
        // Save it then run it
        if (nodesThatNeedBuilding.data && nodesThatNeedBuilding.data.length > 0) {
            const nextStep = await getPromptAnswer(
                'You have changes to your data model that may affect this analysis. Do you want to run a build first?', 'Data Model Changes', false, '', [{
                    label: 'Run Build',
                    value: 'BUILD',
                    description: 'This will build your data model, ensuring everything is up to date before running your analysis.'
                }, {
                    label: 'Just Run Analysis',
                    value: 'SKIP',
                    description: 'This will run your analysis on the current version of your data model, which may be out of date.'
                }]
            );

            if (!nextStep) {
                return;
            }

            if (nextStep == 'BUILD') {
                runBuild('', false, true);
                return;
            }
        }
        let analysisIdToRun: string;
        try {
            if (pageDirty && !analysis.ephemeral) {
                const analysisToRun = await saveOrOverwrite();
                if (!analysisToRun) {
                    return;
                }
                analysisIdToRun = analysisToRun.id as string;
    
    
            } else {
                const savedAnalysis = await saveAnalysis(analysis, analysis.ephemeral);
                analysisIdToRun = savedAnalysis.id as string;
            }
            console.log('Running analysis', analysisIdToRun);
            runAnalysis(analysisIdToRun);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, [analysis, pageDirty, nodesThatNeedBuilding.dataUpdatedAt]);

    const [aiPrompt, setAiPrompt] = useState('');

    const [showDownloadModal, setShowDownloadModal] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [downloadLocation, setDownloadLocation] = useState('');
    const [downloadError, setDownloadError] = useState('');

    const downloadNodeData = useCallback(async () => {
        if (!downloadNodeId) {
            return;
        }
        setDownloadError('');
        setShowDownloadModal(true);
        setLoadingDownload(true);
        setDownloadLocation('');

        try {
            const download = await PipelineNodeORM.queueDownloadJob(downloadNodeId as string, '');
            setDownloadLocation(download.url);
            
        } catch(err_msg) {
            setDownloadError(getErrorMessage(err_msg));
            console.log(err_msg)
        } finally {
            setLoadingDownload(false);
        }
        
    }, [downloadNodeId]);

    const saveForLater = useCallback(async () => {
        // If it's ephemeral, that means nobody else is using it yet so we can just save it as-is
        if (analysis.ephemeral) {
            saveAnalysis(analysis, false);
        }

        // Otherwise, we want to ask if they want to overwrite the existing one or make a copy
        else {
            saveOrOverwrite();
        }
    }, [analysis]);

    const [saving, setSaving] = useState(false);
    const createCopy = useCallback(async (ephemeral: boolean, navigateOnSave: boolean = true) => {
        setSaving(true);
        try {
            const newAnalysis = await AnalysisORM.save({
                ...analysis,
                id: null,
                ephemeral: ephemeral,
                name: analysis.name + ' (Copy)',
            });

            setSaving(false);

            setPageDirty(false);
            if (navigateOnSave) {
                setIsPrepped(false);
                setVisualizationConfig(undefined);
                navigate(`/analysis/${newAnalysis.id}`);
                
            } else {
                return newAnalysis;
            }

        } catch (err) {
            setSaving(false);
            toast('danger', 'Error saving', getErrorMessage(err));
            return null;
        }
    }, [analysis]);

    const renameAnalysis = useCallback(async () => {
        const newName = await getPromptAnswer('Enter a new name for this analysis', 'Rename Analysis', false, analysis.name);

        if (!newName) {
            return;
        }
        
        setAnalysis(draft => {
            draft.name = newName;
        });
        setPageDirty(true);
    }, [analysis]);

    
    

    const deleteAnalysis = useCallback(async() => {
        const shouldDelete = await requireConfirmation('Are you sure you want to delete this analysis?', 'Delete Analysis');
        if (shouldDelete) {
            await AnalysisORM.deleteById(analysis.id as string);
            toast('success', 'Analysis deleted', 'Your analysis has been deleted successfully.');
            navigate('/home/t/analysis')
        }
    }, [analysis.id])

    return <PageStructure>
        <Modal show={showDrilldownDisabledModal} onHide={() => setShowDrilldownDisabledModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Drilldown Unavailable</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Drilldowns are only available for dynamic analyses. To use drilldowns, please switch to a dynamic data source type.</p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-light" onClick={() => {
                    setShowDrilldownDisabledModal(false);
                }}>Close</button>
            </Modal.Footer>
        </Modal>
        <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Download</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
                {loadingDownload && (
                    <div>
                        <LoadingCard action="Preparing your download" />
                    </div>
                )}
                {!loadingDownload && downloadError && (
                    <div className="alert alert-danger">{downloadError}</div>
                )}
                {!loadingDownload && downloadLocation && !downloadError && (
                    <div>
                        <span style={{ fontSize: 55 }}><i className="mdi mdi mdi-download"></i></span>
                        <br />
                        <a target="_blank" className="btn btn-pliable" href={downloadLocation} rel="noreferrer">Click here to download your file.</a>
                    </div>
                )}
            </Modal.Body>
        </Modal>
        <Modal size="lg" show={showDimensionFilterModal} onHide={() => setShowDimensionFilterModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Filters</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <DimensionFilterForm
                    dimensionFilters={analysis.dimension_filters || []}
                    onChange={(newVal) => {
                        setAnalysis(draft => {
                            draft.dimension_filters = newVal;
                        });
                        setPageDirty(true);
                    }}
                    dimensionOptions={dimensionOptions}
                />
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary me-1" onClick={() => {
                    setAnalysis(draft => {
                        if (!draft.dimension_filters) {
                            draft.dimension_filters = [];
                        }
                        draft.dimension_filters.push({
                            dimension_id: '',
                            comparator: 'EQUALS',
                            value: '',
                        })
                    });
                    setPageDirty(true);
                }}>Add Filter</button>
                <button className="btn btn-secondary" onClick={() => {
                    setShowDimensionFilterModal(false);
                }}>Done</button>
            </Modal.Footer>
        </Modal>
        <Modal size="lg" show={showDrilldownModal} onHide={() => setShowDrilldownModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Drilldown</Modal.Title>
                
            </Modal.Header>
            <Modal.Body>
                <div className="mb-3">
                    {drilldownMetricNames.map((m, idx) => <Badge key={idx} bg="primary" className="me-1">{m}</Badge>)}
                    {drilldownParams?.dimensionFilters.map((d, idx) => <Badge key={idx} bg="secondary" className="me-1">{drilldownDimensionNames[d.dimension_id]}: {d.value}</Badge>)}
                    
                </div>
                <Form.Group>
                    <Form.Label>
                        Select the additional dimension(s) to further break-down this row.
                    </Form.Label>
                    <MultiDropdown
                        options={dimensionOptions.filter(d => drilldownParams && !drilldownParams.dimensionIds.includes(d.value))}
                        selected={additionalDimensionIds}
                        onChange={(newVal) => {
                            setAdditionalDimensionIds(newVal);
                        }}
                        disabledOptionIds={disabledDimensionIds}
                    />
                </Form.Group>
                
                {/* <div>You can also <Link to="/">view all matching <strong>Contacts</strong>.</Link></div> */}
                
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={doFurtherBreakdown}>Drilldown</button>
                <button className="btn btn-light" onClick={() => {
                    setShowDrilldownModal(false);
                }}>Cancel</button>
               
            </Modal.Footer>
        </Modal>
        <PageSidebar className="bg-white">
            <Pane>
                <PaneContent>
                    <ReadOnly forceEnable={analysis.ephemeral}>

                    <div className="p-3">
                        <Form.Group className="mb-3">
                            <h3 className="mb-0 fw-bold hover-control clickable" onClick={renameAnalysis}>
                                {analysis.name || 'Unnamed Analysis'}
                                <i className="mdi mdi-pencil hover-only"></i>
                            
                            </h3>
                            
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Select visualization type</Form.Label>
                            <Dropdown
                                variant="button"
                                className="w-100"
                                options={[
                                    {
                                        value: 'SINGLE_VALUE',
                                        label: 'Single Value',
                                        icon: 'mdi mdi-numeric'
                                    },
                                    {
                                        value: 'TABLE',
                                        label: 'Table',
                                        icon: 'mdi mdi-table'
                                    },
                                    // {
                                    //     value: 'PIVOT_TABLE',
                                    //     label: 'Pivot Table',
                                    //     icon: 'mdi mdi-table-pivot'
                                    // },
                                    {
                                        value: 'BAR',
                                        label: 'Bar Chart',
                                        description: 'A basic bar chart',
                                        icon: 'mdi mdi-chart-bar'
                                    },
                                    {
                                        value: 'LINE',
                                        label: 'Line Chart',
                                        description: 'A basic line chart',
                                        icon: 'mdi mdi-chart-line'
                                    },
                                    {
                                        value: 'PREMIUM_LTV_PIVOT_TABLE',
                                        label: 'Premium: LTV',
                                        description: 'Something',
                                        icon: 'mdi mdi-star'
                                    }
                                ]}
                                selected={analysis.visualization_type}
                                onChange={onChangeVisualization}
                                placeholder="Select visualization type"
                            />
                        </Form.Group>
                        {!analysis.visualization_type.startsWith('PREMIUM_') && <>
                            <div className="mb-3">
                            <Form.Group>
                                <Form.Label>Data Source Type</Form.Label>
                                <Dropdown
                                    options={[
                                        { value: 'DYNAMIC', label: 'Dynamic', description: "Pliable's AI will automatically create and manage the underlying datasets for this analysis." },
                                        { value: 'STATIC', label: 'Static', description: 'Choose any existing pipeline node as the data source for this analysis.' }
                                    ]}
                                    selected={analysis.data_source_type}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.data_source_type = newVal as 'DYNAMIC' | 'STATIC';
                                            // Reset related fields when switching to dynamic
                                            if (newVal === 'DYNAMIC') {
                                                draft.static_source_pipeline_node_id = '';
                                            }
                                            draft.measure_ids = [];
                                            draft.dimension_ids = [];
                                        });
                                        setPageDirty(true);
                                    }}
                                />
                            </Form.Group>
                            {analysis.data_source_type === 'STATIC' && (
                            <Form.Group className="mt-1">
                                <Form.Label className="small">Static Data Source</Form.Label>
                                <PipelineNodeSelector


                                    selectedId={analysis.static_source_pipeline_node_id || ''}
                                    onSelect={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.static_source_pipeline_node_id = newVal ? (newVal.id as string) : '';
                                            // Reset field selections when changing node
                                            draft.dimension_ids = [];
                                            draft.measure_ids = [];
                                        });
                                        setPageDirty(true);
                                    }}
                                />
                                <Form.Text>
                                    Select the pipeline node that contains the static data for this analysis
                                </Form.Text>
                            </Form.Group>
                        )}
                        </div>
                        </>}
                        
                        

                        

                        {analysis.visualization_type == 'PIVOT_TABLE' && <>
                            <Form.Group className="mb-3">
                                <Form.Label>Metric</Form.Label>
                                <MetricSelector
                                    disableMulti
                                    options={measureOptions}
                                    selected={analysis.measure_ids}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.measure_ids = newVal;
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledMeasureIds}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Rows</Form.Label>
                                <DimensionSelector
                                    disableMulti
                                    options={dimensionOptions}
                                    selected={analysis.dimension_ids[0] ? [analysis.dimension_ids[0]] : []}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.dimension_ids[0] = newVal[0];
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledDimensionIds}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Columns</Form.Label>
                                <DimensionSelector
                                    disableMulti
                                    options={dimensionOptions}
                                    selected={analysis.dimension_ids[1] ? [analysis.dimension_ids[1]] : []}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.dimension_ids[1] = newVal[0];
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledDimensionIds}
                                />
                            </Form.Group>
                            
                            
                        </>}
                        {analysis.visualization_type == 'PREMIUM_LTV_PIVOT_TABLE' && <LTVPivotTableForm
                            analysis={analysis}
                            setAnalysis={setAnalysis}
                            measureOptions={measureOptions}
                            dimensionOptions={dimensionOptions}
                            disabledMeasureIds={disabledMeasureIds}
                            disabledDimensionIds={disabledDimensionIds}
                        />}
                        {analysis.visualization_type == 'SINGLE_VALUE' && <>
                            <Form.Group className="mb-3">
                                <Form.Label>Select a metric.</Form.Label>
                                <MetricSelector
                                    options={measureOptions}
                                    selected={analysis.measure_ids[0] ? [analysis.measure_ids[0]] : []}
                                    disableMulti
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.measure_ids = newVal;
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledMeasureIds}
                                />
                            </Form.Group>
                        </>}
                        {['BAR', 'LINE'].includes(analysis.visualization_type) && <>
                            <Form.Group className="mb-3">
                                <Form.Label>Y-Axis.</Form.Label>
                                <MetricSelector
                                    options={measureOptions}
                                    selected={analysis.measure_ids[0] ? [analysis.measure_ids[0]] : []}
                                    disableMulti
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.measure_ids = newVal;
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledMeasureIds}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>X-Axis</Form.Label>
                                <DimensionSelector
                                    disableMulti
                                    options={dimensionOptions}
                                    selected={analysis.dimension_ids[0] ? [analysis.dimension_ids[0]] : []}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.dimension_ids[0] = newVal[0];
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledDimensionIds}
                                />
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Color (optional)</Form.Label>
                                <DimensionSelector
                                    disableMulti
                                    options={dimensionOptions}
                                    selected={analysis.dimension_ids[1] ? [analysis.dimension_ids[1]] : []}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.dimension_ids[1] = newVal[0];
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledDimensionIds}
                                />
                            </Form.Group>
                        </>}
                        {analysis.visualization_type == 'TABLE' && <>
                            <Form.Group className="mb-3">
                                <Form.Label>Select metrics.</Form.Label>
                                <MetricSelector
                                    options={measureOptions}
                                    selected={analysis.measure_ids}
                                    onChange={(newVal) => {
                                        setAnalysis(draft => {
                                            draft.measure_ids = newVal;
                                        });
                                        setPageDirty(true);
                                    }}
                                    disabledOptionIds={disabledMeasureIds}
                                />
                                <Form.Text><Link to="/semantic-layer">Can't find what you're looking for?</Link></Form.Text>
                            </Form.Group>
                            <Form.Group className="mb-3">
                                <Form.Label>Select dimensions.</Form.Label>
                                {analysis.measure_ids.length > 0 && <div>
                                    <DimensionSelector
                                        options={dimensionOptions}
                                        selected={analysis.dimension_ids}
                                        onChange={(newVal) => {
                                            setAnalysis(draft => {
                                                draft.dimension_ids = newVal;
                                            });
                                            setPageDirty(true);
                                        }}
                                        disabledOptionIds={disabledDimensionIds}
                                    />
                                    <Form.Text>
                                        This configures how each metric is broken down. For example, if you're looking at sales, you might want to break it down by region, product, or date.
                                    </Form.Text>
                                </div>}
                                {analysis.measure_ids.length == 0 && <div>
                                    Select a metric to get started.
                                </div>}
                            </Form.Group>
                        </>}
                        <div className="mb-3">
                            <DateRangeSelector
                                dateRange={analysis.date_range}
                                customDateRangeStart={analysis.custom_date_range_start}
                                customDateRangeEnd={analysis.custom_date_range_end}
                                onDateRangeChange={(newVal) => {
                                    setAnalysis(draft => {
                                        draft.date_range = newVal;
                                    });
                                    setPageDirty(true);
                                }}
                                onCustomStartChange={(newVal) => {
                                    setAnalysis(draft => {
                                        draft.custom_date_range_start = newVal;
                                    });
                                    setPageDirty(true);
                                }}
                                onCustomEndChange={(newVal) => {
                                    setAnalysis(draft => {
                                        draft.custom_date_range_end = newVal;
                                    });
                                    setPageDirty(true);
                                }}
                            />
                            {analysis.data_source_type === 'STATIC' && (
                                <Form.Group className="mt-1">
                                    <Form.Label className="small">Static Date Dimension </Form.Label>
                                    <PipelineNodeColumnSelector
                                        pipelineNodeId={analysis.static_source_pipeline_node_id || ''}
                                        selectedId={analysis.static_date_dimension_field_id || ''}
                                        onSelect={(columnId) => {
                                            setAnalysis(draft => {
                                                draft.static_date_dimension_field_id = columnId;
                                            });
                                            setPageDirty(true);
                                        }}
                                        optionFilter={(opt) => {
                                            return opt.type.startsWith('DATE') || opt.type == 'TIMESTAMP';
                                        }}
                                    />
                                    <Form.Text>
                                        Select which column contains the date values for filtering. Leave empty if this data cannot be broken down by a date dimension.
                                    </Form.Text>
                                </Form.Group>
                            )}
                        </div>
                        {analysis.data_source_type === 'DYNAMIC' && (
                            <Form.Group>
                                <Form.Label>Other Filters</Form.Label>
                                <div className="card p-2 bg-light">
                                    <HumanReadableDimensionFilters filters={analysis.dimension_filters || []} />
                                    <button onClick={() => setShowDimensionFilterModal(true)} className="btn btn-light mt-2">
                                        Edit Filters
                                    </button>
                                </div>
                            </Form.Group>
                        )}
                        
                                            
                    </div>
                    </ReadOnly>

                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent hasSidebar>
            <PageContentHeader>
                <div className="d-flex center-vertically">
                    <Form.Control type="text" disabled style={{width: '400px'}} value={aiPrompt} onChange={(e) => {
                        setAiPrompt(e.target.value);
                    }} className="input-lg" placeholder="Ask our AI assistant (coming soon)"
                    />
                    <button className="btn btn-lg btn-pliable ms-1" disabled>
                        Go
                    </button>
                    <div className="flex-1">
                    </div>
                    <div>
                        <DraftOnly>
                            {!analysis.ephemeral && <button className="btn btn-outline-danger btn-lg me-1" title="Delete Analysis" onClick={deleteAnalysis}>
                                <i className="mdi mdi-delete"></i>    
                            </button>}
                        </DraftOnly>
                        
                        {visualizationConfig && visualizationConfig.render_type == 'table' && downloadNodeId && <>
                            <button className="btn btn-light btn-lg me-1" onClick={downloadNodeData} title="Download Data">
                                <i className="mdi mdi-download"></i>
                            </button>
                        </>}
                        <DraftOnly>

                            <SaveButton
                                disabled={loadingAnalysis || running || saving}
                                onClick={saveForLater}
                                className="me-1 btn-lg"
                                text="Save for Later"
                            />
                        </DraftOnly>
                        <AsyncButton
                            disabled={!canRunAnalysis}
                            text="Run"
                            className="btn-lg"
                            icon="mdi mdi-play"
                            onClick={runCurrentAnalysis}
                            loading={running}
                        />
                    </div>
                </div>
                    
            </PageContentHeader>
            <PageContentInner hasHeader noScroll>
            {showConfetti && <div style={{marginLeft: '50%'}}> <ConfettiExplosion zIndex={1061} colors={['#ff9f00', '#ffffff', '#000000', '#313A46', "#666666", "#343434"]} /></div>}

                <div className="p-4 h-100">
                    {!!backingNode.data && <div className="text-muted text-end font-13">
                        Data last updated {timeAgo(backingNode.data.last_build_completed)}. Click "Run" to get the latest data.  
                    </div>}
                    <div className="card h-100">
                        <div className="card-body h-100" >
                            <Pane>
                                <PaneContent>
                                    {(running || loadingViz) && <div>
                                        <div style={{margin: 'auto', width: '150px'}}>
                                            <PliableLoader size="lg"></PliableLoader>
                                        </div>
                                        <div className="text-center">
                                            <h3 className="mb-0 fw-bold">{loadingMessage}</h3>

                                        </div>
                                        
                                        
                                    </div>}
                                    {!running && buildError && <>
                                        <Danger>
                                            {buildError}
                                        </Danger>
                                    </>}
                                    {!loadingViz && vizError && <>
                                        <Danger>
                                            {vizError}
                                        </Danger>
                                    </>}
                                    {!!visualizationConfig && analysis.visualization_type == 'SINGLE_VALUE' && 
                                        <SingleValueVisualization 
                                            measureIds={analysis.measure_ids} 
                                            dimensionIds={analysis.dimension_ids} 
                                            sortOrder={analysis.default_sort_order || []} 
                                            onToggleSort={toggleSort} 
                                            params={visualizationConfig} 
                                            onDrillDown={onDrilldown}
                                        />
                                    }
                                    {!!visualizationConfig && analysis.visualization_type == 'TABLE' && 
                                        <TableVisualization 
                                            measureIds={analysis.measure_ids} 
                                            dimensionIds={analysis.dimension_ids} 
                                            sortOrder={analysis.default_sort_order || []} 
                                            onToggleSort={toggleSort}
                                            params={visualizationConfig} 
                                            onDrillDown={onDrilldown}
                                            onChangePage={(newPage, pageSize) => {
                                                setPageNum(newPage);
                                                setPageSize(pageSize);
                                            }}
                                            pageSize={pageSize}
                                            currentPage={pageNum}
                                        />
                                    }
                                    {!!visualizationConfig && analysis.visualization_type == 'PIVOT_TABLE' && <PivotTableVisualization 
                                        sortOrder={analysis.default_sort_order || []} 
                                        measureIds={analysis.measure_ids} 
                                        dimensionIds={analysis.dimension_ids} 
                                        onToggleSort={toggleSort} 
                                        params={visualizationConfig} 
                                        onDrillDown={onDrilldown}
                                    />}
                                    {!!visualizationConfig && analysis.visualization_type == 'PREMIUM_LTV_PIVOT_TABLE' && <LTVPivotTableVisualization 
                                        data={visualizationConfig}
                                        analysis={analysis}
                                        onDrillDown={onDrilldown}
                                    />}
                                    {!!visualizationConfig && analysis.visualization_type == 'BAR' && <ApexVisualization 
                                        measureIds={analysis.measure_ids} 
                                        dimensionIds={analysis.dimension_ids} 
                                        sortOrder={analysis.default_sort_order || []} 
                                        onToggleSort={toggleSort} 
                                        params={visualizationConfig} 
                                        onDrillDown={onDrilldown}
                                    />}
                                    {!!visualizationConfig && analysis.visualization_type == 'LINE' && <ApexVisualization 
                                        measureIds={analysis.measure_ids} 
                                        dimensionIds={analysis.dimension_ids} 
                                        sortOrder={analysis.default_sort_order || []} 
                                        onToggleSort={toggleSort} 
                                        params={visualizationConfig} 
                                        onDrillDown={onDrilldown}
                                    />}
                                </PaneContent>
                            </Pane>
                        </div>
                    </div>
                </div>
            </PageContentInner>
        </PageContent>
    </PageStructure>

}
export default AnalysisScreen
