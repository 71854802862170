import SourceIcon from "@components/sources/SourceIcon.component"
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import { Link, Navigate, useNavigate } from "react-router-dom";
import { DraftOnly } from "@components/project/DraftModeRequired.component";
import { useCallback, useMemo, useState } from "react";
import { Collapse } from "react-bootstrap";
import { usePipelineNodes, useSources } from "@stores/data.store";
import { PipelineNode } from "@models/pipelineNode";
import { summarizeNumber } from "@services/formatting.service";
import { timeAgo } from "@services/time.service";
import snowflakeIcon from '@assets/images/snowflake_logo.png';

const SourcesListWidget = () => {
    const sources = useSources();
    const nodes = usePipelineNodes();

    const allSources = useMemo(() => {
        const data = sources.data || [];
        return [
            {
                id: 'snowflake',
                name: 'Snowflake',
                description: 'Connect directly to existing data in your Snowflake account',
                type: 'snowflake',
            },
            ...data
        ];
    }, [sources.dataUpdatedAt])
    const nodesBySourceId = useMemo(() => {
        if (!nodes.data) {
            return {};
        }

        const result: Record<string, PipelineNode[]> = {};
        nodes.data.filter(n => n.node_type == 'SOURCE').forEach(n => {
            let sourceId = n.source_id;
            if (n.flat_file) {
                sourceId = 'flat_files';
            }
            if (!sourceId) {
                sourceId = 'snowflake';
            }
            if (!result[sourceId]) {
                result[sourceId] = [];
            }
            result[sourceId].push(n);
        });

        // Now sort them alphabetically
        Object.keys(result).forEach(k => {
            result[k] = result[k].sort((a, b) => a.name.localeCompare(b.name));
        });

        console.log('nodesBySourceId', result);
        return result;
    }, [nodes.dataUpdatedAt, sources.dataUpdatedAt]);
    
    const [expandedSection, setExpandedSection] = useState('');
    const toggleExpanded = useCallback((section: string) => {
        setExpandedSection(expandedSection === section ? '' : section);
    }, [expandedSection]);

    const navigate = useNavigate();
    return <div>
        {/* <div className="list-group-item">
            <div className="d-flex center-vertically">
                <div className="me-2">
                    <SourceIcon image_url={flatFileIcon}/>

                </div>
                <div>
                    <h3 className="mb-0">Flat Files</h3>
                    <div className="font-13 text-muted">Upload any file readable by Excel.</div>
                </div>
                <div className="flex-1">

                </div>
                <button onClick={() => {
                    toggleExpanded('files');
                }} className="btn btn-light me-1">View All</button>
                <DraftOnly>
                    <Link to="/wizard/data-source?sourceType=file" className="btn btn-outline-primary">Add New</Link>
                </DraftOnly>
            </div>
            <Collapse in={expandedSection === 'files'}>
                <div className="pt-3 pb-3">
                    <table className="table table-fixed">
                        <tbody>
                            {flatFiles.map(node => {
                                return <tr key={node.id}>
                                    <td>{node.name}</td>
                                    <td>{node.description}</td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
                
            </Collapse>
        </div> */}
        {(allSources).map(source => {
            let sourceId = source.id as string;
            if (source.type == 'flat_file') {
                sourceId = 'flat_files';
            }

            const count = (nodesBySourceId[sourceId] || []).length;
            return <div className="card bg-white border mb-2">
                <div className="d-flex center-vertically p-2">

                    <div className="me-2">
                        {sourceId == 'flat_files' && <SourceIcon image_url={flatFileIcon}/>}
                        {sourceId == 'snowflake' && <SourceIcon image_url={snowflakeIcon}/>}
                        {!['flat_files', 'snowflake'].includes(sourceId) &&  <SourceIcon sourceId={sourceId}/>}
                        

                    </div>
                    <div className="me-2">
                        <h3 className="mb-0">{source.name}</h3>
                        <div className="font-13 text-muted">{source.description}</div>
                    </div>
                    <div className="flex-1">

                    </div>
                    {!['snowflake', 'flat_files'].includes(sourceId) && <div className="me-2">
                        <Link to={`/source/${sourceId}`} className="btn btn-light btn-sm"><i className="mdi mdi-cog"></i> Configure</Link>
                    </div>}
                    {sourceId == 'flat_files' && <Link to="/wizard/data-source?sourceType=file" className="btn btn-light btn-sm me-2">
                        <i className="mdi mdi-upload"></i> Add New
                    </Link>}
                    {sourceId == 'snowflake' && <Link to="/wizard/data-source?sourceType=snowflake" className="btn btn-light btn-sm me-2">
                        <i className="mdi mdi-table-plus"></i> Add New
                    </Link>}

                    <div className="me-2">
                        <span className="fw-light font-13">{count} tables</span>

                    </div>
                    <button onClick={() => {
                        toggleExpanded(sourceId);
                    }} className="icon-button font-24">
                        {expandedSection === sourceId ? <i className="fas fa-chevron-down"></i> : <i className="fas fa-chevron-right"></i>}
                    </button>
                    {/* <DraftOnly>
                        <Link to="/wizard/data-source?sourceType=file" className="btn btn-outline-primary">Add New</Link>
                    </DraftOnly> */}
                </div>
                <Collapse in={expandedSection === sourceId}>
                    <div className="border-top">
                        <div className="">
                            {nodesBySourceId[sourceId] && nodesBySourceId[sourceId].length >0 && <>
                                <table className="table table-fixed no-border table-centered table-hover mb-0">
                                    <tbody>
                                        {(nodesBySourceId[sourceId] || []).map(node => {
                                            return <tr key={node.id}>
                                                <td className="p-2 pointer" onClick={() => {
                                                    navigate(`/node/${node.id}`);
                                                }}>
                                                    <h4 className="mb-0">{node.label}</h4>
                                                    <div className="font-poppins text-muted font-13">
                                                        <span><i className="mdi mdi-file-multiple"></i> {summarizeNumber(node.total_records || 0)} records</span>
                                                        <span className="ms-2 me-2">&bull;</span>
                                                        <span><i className="mdi mdi-clock"></i> Updated {timeAgo(node.last_build_completed)}
                                                        </span>
                                                    </div>
                                                </td>
                                                
                                                <td className="text-end p-2">
                                                    <Link to={`/node/${node.id}`} className="btn btn-light btn-sm me-1"><i className="mdi mdi-table"></i> Data</Link>
                                                    <Link to={`/node/${node.id}/config`} className="btn btn-light btn-sm"><i className="mdi mdi-cog"></i> Configure</Link>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </>}
                            {(!nodesBySourceId[sourceId] || nodesBySourceId[sourceId].length == 0) && <div className="p-2 text-center text-muted">
                                {sourceId == 'snowflake' && <span>No tables connected yet. <Link to="/wizard/data-source?sourceType=snowflake">Connect one now.</Link></span>}
                                {sourceId != 'snowflake' && <span>No tables found. You may need to run a sync.</span>}
                            </div>}
                            
                        </div>
                        
                    </div>
                    
                </Collapse>
            </div>
        })}
    </div>
}

export default SourcesListWidget;