import SaveButton from "@components/button/SaveButton.component";
import Dropdown, { MultiDropdown } from "@components/form/Dropdown.component";
import ComponentDescription from "@components/general/ComponentDescription.component";
import EditableText from "@components/general/EditableText.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import PipelineNodeDragAndDropMapping from "@components/pipelineNodes/mapping/PipelineNodeDragAndDropMapping.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import DataWhitelistForm from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { SingleNodeItem } from "@components/pipelineNodes/PipelineNodeList.component";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import Warning from "@components/statusIndicators/Warning.component";
import { DataWhitelist, PipelineNode, PipelineNodeField } from "@models/pipelineNode";
import { TableLoadResponse } from "@models/shared";
import { recursiveGetNodesAndEdges } from "@pages/Dag.page";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent, WizardContent, WizardFooter, WizardSteps } from "@pages/PageStructure.component";
import { DataPaneHeader } from "@pages/PipelineNode/PipelineNodeConfiguration.page";
import { runBuild } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { formatPercentage } from "@services/formatting.service";
import { shortid } from "@services/id.service";
import { reorderList } from "@services/list.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import toast from "@services/toast.service";
import { useQueryParams } from "@services/url.service";
import { getTableData, savePipelineNode, useMissionControlDataFlowData, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import { Allotment, AllotmentHandle } from "allotment";
import { Component, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { useImmer } from "use-immer";


const BusinessObjectWizardPage = () => {
    const { pipelineNodeId } = useParams();

    const queryParams = useQueryParams();

    const theNode = usePipelineNode(pipelineNodeId);

    const [step, setStep] = useState(0);

    const [nodeName, setNodeName] = useState('');
    const [nodeDescription, setNodeDescription] = useState('');

    const boContext = queryParams.get('bo_context');

    const allotmentRef = useRef<AllotmentHandle>(null);
    const [dataViewerIsOpen, setDataViewerIsOpen] = useState(false);

    const allotmentResized = useDebouncedCallback((newSizes: number[]) => {
        if (newSizes[1] === 30) {
            setDataViewerIsOpen(false);
        } else {
            setDataViewerIsOpen(true);
        }
    }, 250);

    const openDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }
        allotmentRef.current.resize([1000, 1000]);
    }, [allotmentRef]);

    const toggleDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }

        if (dataViewerIsOpen) {
            // There's a resize issue with the editor so we need to switch back to table mode first
            window.requestAnimationFrame(() => {
                allotmentRef.current!.reset();

            })
        } else {
            allotmentRef.current!.resize([1000, 1000]);

        }
        
    }, [allotmentRef, dataViewerIsOpen]);

    const [loadingTestWhitelist, setLoadingTestWhitelist] = useState(false);

    const [whitelistRecordCount, setWhitelistRecordCount] = useState<number>(0);


    

    const navigate = useNavigate();
    const goBack = useCallback(() => {
        if (step == 0) {
            navigate(`/node/${pipelineNodeId}`)
        } else {
            setStep(step - 1);
        }
    }, [step, navigate, pipelineNodeId]);

    const [saving, setSaving] = useState(false);
    

    const pipelineNodes = usePipelineNodes();
    

    const mcData = useMissionControlDataFlowData();
    const downstreamNodes = useMemo(() => {
        if (!mcData.data) {
            return [];
        }

        const thisNode = mcData.data.nodes.find(n => n.id === 'PipelineNode:' + pipelineNodeId);
        if (!thisNode) {
            return [];
        }

        const [nodes, edges] = recursiveGetNodesAndEdges(thisNode, mcData.data.nodes, mcData.data.edges, ['PipelineNode:' + pipelineNodeId], [], 'source');
        return nodes.filter(n => getGroupValueForNodeType(n.data.nodeType) === 'STAGING');
    }, [pipelineNodeId, mcData.dataUpdatedAt]);

    const showRawDataWarning = useMemo(() => {
        if (!theNode.data) {
            return false;
        }

        if (queryParams.get('ignoreWarning') == 'y') {
            return false;
        }

        if (theNode.data.node_type === 'SOURCE') {
            return true;
        }

        return false;
    }, [theNode.dataUpdatedAt, queryParams.get('ignoreWarning')]);

    const [selectedBusinessObjectId, setSelectedBusinessObjectId] = useState<string>('');
    const [fields, setFields] = useState<PipelineNodeField[]>([]);

    const [selectedBusinessObject, setSelectedBusinessObject] = useState<PipelineNode | null>(null);

    useEffect(() => {
        const theBo = pipelineNodes.data?.find(n => n.id === boContext);
        if (!theBo) {
            return;
        }

        setSelectedBusinessObject(theBo);
        setFields(theBo.fields);
    }, [boContext, pipelineNodes.dataUpdatedAt]);

    const mappedColumnsBySourceId: {
        [key: string]: string[];
    } = useMemo(() => {
        if (!theNode.data) {
            return {};
        }
        
        const mappedColumns: { [key: string]: string[] } = {};
        fields.forEach(f => {
            f.map_options.forEach(mo => {
                if (!mappedColumns[mo.attribute_id!]) {
                    mappedColumns[mo.attribute_id!] = [];
                }
                mappedColumns[mo.attribute_id!].push(f.label);
            });
        });
        return mappedColumns;
    }, [fields, theNode.dataUpdatedAt]);

    const saveNode = useCallback(async () => {
        setSaving(true);
        try {

            const boToSave = pipelineNodes.data?.find(n => n.id === boContext) as PipelineNode;
            if (!boToSave) {
                throw new Error('Node not found');
            }

            const saveData = {
                ...boToSave,
                fields: fields,
                upstream_node_ids: boToSave.upstream_node_ids.concat(pipelineNodeId as string),
            }

            
            const result = await savePipelineNode(saveData);

            await runBuild(
                '+' + result.name, 
                false,
                false,
                <>
                    <Link className="btn btn-light" to={`/node/${result.id}`}>View Data</Link>
                </>,
                <>
                    <Link className="btn btn-light" to={`/node/${result.id}/config`}>Fix Errors</Link>
                </>
                
                
            );
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
         
 
     }, [pipelineNodeId, nodeName, fields, pipelineNodes.dataUpdatedAt, boContext]);

     const nextIsEnabled = useMemo(() => {
        if (fields.length == 0) {
            return false;
        }

        if (Object.keys(mappedColumnsBySourceId).length == 0) {
            return false;
        }
        return true;
     }, [fields, mappedColumnsBySourceId]);
    
    if (!theNode.data) {
        return <PliableLoader/>
    }

    return <PageStructure>
        {/* <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-2 pt-4">
                        <h2>Last step. Let's map this cleaned data to your Business Object.</h2>
                        {theNode.data && <>
                            <div className="mb-3">
                                <h4 className="fw-normal">You are mapping data from:</h4>
                                <SingleNodeItem node={theNode.data} compact/>
                            </div>
                        </>}
                        {selectedBusinessObject && <div className="mb-3">
                            <h4 className="fw-normal">to this Business Object:</h4>
                             <SingleNodeItem node={selectedBusinessObject} compact/>
                        </div>}
                        
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar> */}
        <PageContent>
            
        <div style={{height: 'calc(100vh - 50px)'}}>

        
            <Allotment vertical  ref={allotmentRef} onChange={allotmentResized}>
                <Allotment.Pane minSize={200}>
                    <Pane>
                        <PaneContent>
                            <PageContentHeader>
                                <div className="d-flex center-vertically">
                                    <DashboardIcon icon="mdi mdi-wizard-hat" bgColor="purple"/>

                                    <div className="flex-1">
                                        <h1 className="mb-0">Data Mapping Wizard</h1>
                                        <div className="text-muted">
                                            Drag the columns in your source data to the columns in the entity. This is your opportunity to rename and clarify datapoints so that they make sense in the context of your business.

                                        </div>
                                    </div>
                                    <SaveButton
                                        className="btn-lg"
                                        onClick={saveNode}
                                        disabled={saving || !nextIsEnabled}
                                    />
                                   
                                </div>
                                
                            </PageContentHeader>
                            <PageContentInner hasHeader>
                                <div className="p-3" style={{height: '100%'}}>
                                                {showRawDataWarning && <Warning>
                                                    <div>
                                                        <p>This node is a source node. Since it has raw, uncleaned data, you might want to <Link to={`/wizard/cleaning/${pipelineNodeId}?next=business_object&bo_context=${boContext}`}>clean it up first</Link> before using it in an Entity .</p>
                                                        {downstreamNodes.length > 0 && <div>
                                                            Here are some cleaned nodes you can use instead:
                                                            <ul>
                                                                {downstreamNodes.map(n => <li key={n.id}>
                                                                    <Link to={`/wizard/mapping/${n.data.objectId}?bo_context=${boContext}`}>{n.data.title}</Link>
                                                                </li>)}
                                                            </ul>     
                                                        </div>}
                                                        <Link className="btn btn-secondary" to={`/wizard/mapping/${pipelineNodeId}?ignoreWarning=y&bo_context=${boContext}`}>I understand, continue anyway</Link>
                                                    </div>
                                                    
                                                </Warning>}
                                                {!showRawDataWarning && step == 0 && selectedBusinessObject && <>
                                                   
                                                    <PipelineNodeDragAndDropMapping
                                                        fields={fields}
                                                        onChange={setFields}
                                                        destinationNodeId={selectedBusinessObject.id as string}
                                                        sourceNodeId={pipelineNodeId as string}
                                                    />
                                                </>}

                                                {!showRawDataWarning && step == 1 && <>
                                                    <h2>Summary</h2>
                                                    {selectedBusinessObjectId != 'NEW' && <div>
                                                        Mapping data to existing entity: 
                                                        <div>
                                                            <SingleNodeItem node={selectedBusinessObject as PipelineNode} compact/>
                                                        </div>
                                                    </div>}
                                                    <hr />
                                                    <h4>Column Mappings</h4>
                                                    <ul>
                                                        {theNode.data.fields.map(f => <li key={f.id} className="font-code">
                                                            {f.label} &rarr; {mappedColumnsBySourceId[f.id] ? mappedColumnsBySourceId[f.id].join(', ') : '[Not Mapped]'}
                                                        </li>)}
                                                    </ul>

                                                </>}
                                            </div>
                            </PageContentInner>
                        </PaneContent>
                    </Pane>
                    
                </Allotment.Pane>
                <Allotment.Pane snap={false} minSize={30} preferredSize={30}>
                    <DataPaneHeader onClick={() => toggleDataViewer()}>
                        <h2>View Source Data</h2>
                        
                        
                        <button >
                            {dataViewerIsOpen && <i className="mdi mdi-chevron-down"></i>}
                            {!dataViewerIsOpen && <i className="mdi mdi-chevron-up"></i>}
                        </button>
                    </DataPaneHeader>
                    <Pane>
                        <PipelineNodeDataTable
                            pipelineNodeId={pipelineNodeId as string}
                        />
                    </Pane>
                </Allotment.Pane>
            </Allotment>
            
        </div>
        </PageContent>
        
        
    </PageStructure>
}

export default BusinessObjectWizardPage;