import { useQuery, UseQueryOptions } from "react-query";
import ORM, { BaseModel } from "./orm";
import { DataWhitelist, FilterConfig } from "./pipelineNode";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";
import { BuildOrchestration } from "./buildOrchestration";
import { DashboardDateRange } from "./dashboard";


export interface AnalysisDimensionFilter {
    dimension_id: string;
    comparator: string;
    value: string|string[];
}

export interface AnalysisSortOrder {
    // This will be "METRIC" or "DIMENSION"
    entity_type: string;
    entity_id: string;
    ascending: boolean;
}

export interface Analysis extends BaseModel {
    name: string;
    visualization_type: string;
    description: string;
    data_source_type: 'DYNAMIC' | 'STATIC';
    viz_options: any;
    dimension_ids: string[];
    measure_ids: string[];
    dimension_filters?: AnalysisDimensionFilter[];
    date_range?: string;
    custom_date_range_start?: string;
    custom_date_range_end?: string;
    user_timezone?: string;
    ephemeral: boolean;
    default_sort_order?: AnalysisSortOrder[] | null;
    
    // New static analysis fields
    static_source_pipeline_node_id?: string;
    static_date_dimension_field_id?: string;
}



export interface AnalysisVisualizationConfig {
    render_type: string;
    options: any;
}

export interface AnalysisConfigurationParams {
    analysisId: string;
    sortOrder?: AnalysisSortOrder[] | null;
    page?: number;
    perPage?: number;
    filters?: FilterConfig;
    dateRange?: DashboardDateRange;
}

interface VizConfigResponse extends SingleRecordResponse<AnalysisVisualizationConfig> {
    node_id: string;
}

export class _AnalysisORM extends ORM<Analysis>{
    public async prepAnalyses(
        analysisIds: string[],
        dimensionFilters: AnalysisDimensionFilter[] = [],
        dateRange?: string,
        customDateRangeStart?: string,
        customDateRangeEnd?: string,
        userTimezone?: string,
    ): Promise<BuildOrchestration> {
        console.log('PREPPING ANALYSES', dateRange);
        const result = await ApiService.getInstance().request('POST', '/analyses/prep', {
            'analysis_ids': analysisIds,
            'dimension_filters': dimensionFilters,
            'date_range': dateRange,
            'custom_date_range_start': customDateRangeStart,
            'custom_date_range_end': customDateRangeEnd,
            'user_timezone': userTimezone
        }) as SingleRecordResponse<BuildOrchestration>;
        return result.record
    }

    public async getVisualizationConfig(params: AnalysisConfigurationParams) : Promise<VizConfigResponse> {
        const { analysisId, sortOrder = [], page = 1, perPage = 3, filters, dateRange } = params;

        let sortArg = '';
        if (sortOrder && sortOrder.length > 0) {
            sortArg = JSON.stringify(sortOrder);
        }

        let filterArg = '';
        if (filters && filters.filters.length > 0) {
            filterArg = JSON.stringify(filters);
        }

        const requestParams: Record<string, any> = {
            'sort': sortArg,
            'page': page,
            'page_size': perPage,
            'filters': filterArg,
        };
        

        // Only add date range parameters if they have non-empty values
        if (dateRange?.date_range) {
            requestParams['date_range'] = dateRange.date_range;
        }
        if (dateRange?.custom_date_range_start) {
            requestParams['custom_date_range_start'] = dateRange.custom_date_range_start;
        }
        if (dateRange?.custom_date_range_end) {
            requestParams['custom_date_range_end'] = dateRange.custom_date_range_end;
        }
        if (dateRange?.user_timezone) {
            requestParams['user_timezone'] = dateRange.user_timezone;
        }

        const result = await ApiService.getInstance().request('GET', `/${this.endpoint}/${analysisId}/data`, requestParams) as VizConfigResponse;
        return result;
    }
};

export const AnalysisORM = new _AnalysisORM('analyses');

export const useAnalyses = () => {
    return useQuery(['analyses'], async () => {
        const result = await AnalysisORM.find({
            ephemeral: {
                '$ne': true
            }
        });
        return result.records;
    });
}

export const useAnalysis = (analysisId: string) => {
    return useQuery(['analyses', analysisId], async () => {
        if (!analysisId) {
            return undefined;
        }
        const result = await AnalysisORM.findById(analysisId);
        return result;
    })
}

export const useAnalysisConfigurationData = (
    params: AnalysisConfigurationParams,
    options?: UseQueryOptions<VizConfigResponse>
  ) => {
    const { analysisId, sortOrder, page, perPage, dateRange } = params;
  
    return useQuery<VizConfigResponse>({
      queryKey: [
        'analysisConfiguration',
        analysisId,
        sortOrder ? JSON.stringify(sortOrder) : null,
        page,
        perPage,
        params.filters ? JSON.stringify(params.filters) : null,
        dateRange ? JSON.stringify(dateRange) : null,
      ],
      queryFn: () => AnalysisORM.getVisualizationConfig(params),
      enabled: !!analysisId && (options?.enabled !== false),
      staleTime: 5 * 60 * 1000, // 5 minutes
      retry: (failureCount, error: any) => {
        // Don't retry if it's a 404 error
        if (error?.code === 404) {
          return false;
        }
        // Default retry behavior for other errors (3 times)
        return failureCount < 3;
      },
      ...options,
    });
  };
