import { useAnalysis, useAnalysisConfigurationData } from "@models/analysis";
import { AnalysisSortOrder } from "@models/analysis";
import { DrillDownParams } from "@models/analysisVisualization";
import { DashboardDateRange } from "@models/dashboard";
import { FilterConfig } from "@models/pipelineNode";
import { ApexVisualization, PivotTableVisualization, TableVisualization, SingleValueVisualization } from "@pages/Analysis/AnalysisScreen.page";
import { LTVPivotTableVisualization } from "@pages/Analysis/premium/LTVPivotTable";
import { getErrorMessage } from "@services/errors.service";
import { useState, useCallback } from "react";
import { useImmer } from "use-immer";

interface AnalysisVisualizationProps {
    analysisId: string;
    loadingDimensionFilters?: boolean;
    staticFilters?: FilterConfig;
    dateRange?: DashboardDateRange;
}

const AnalysisVisualization = (props: AnalysisVisualizationProps) => {
    const analysis = useAnalysis(props.analysisId);
    const [pageSize, setPageSize] = useState(50);
    const [page, setPage] = useState(1);
    const [sortOrder, setSortOrder] = useImmer<AnalysisSortOrder[]>([]);

    const toggleSort = useCallback((entityType: string, entityId: string) => {
        // It goes ASC -> DESC -> OFF
        setSortOrder((draft) => {
            const idx = draft.findIndex(s => s.entity_id == entityId && s.entity_type == entityType);
            if (idx >= 0) {
                if (!draft[idx].ascending) {
                    draft.splice(idx, 1);
                } else {
                    draft[idx].ascending = !draft[idx].ascending;
                }
            } else {
                draft.push({
                    entity_id: entityId,
                    entity_type: entityType,
                    ascending: true,
                });
            }
        });
    }, []);


    const vizConfig = useAnalysisConfigurationData({
        analysisId: props.analysisId,
        sortOrder: sortOrder,
        page: page,
        perPage: pageSize,
        filters: props.staticFilters,
        dateRange: props.dateRange,
    });
    const onDrilldown = useCallback((params: DrillDownParams) => {
        // Create a copy of the analysis 
    }, []);

    const renderVisualization = () => {
        if (!analysis.data || !vizConfig.data) {
            return <></>;
        }

        

        const commonProps = {
            measureIds: analysis.data.measure_ids,
            dimensionIds: analysis.data.dimension_ids,
            sortOrder: sortOrder,
            onToggleSort: toggleSort,
            onDrillDown: onDrilldown,
        };

        switch (analysis.data.visualization_type) {
            case 'SINGLE_VALUE':
                return <SingleValueVisualization
                    {...commonProps}
                    params={vizConfig.data.record}
                />;
            case 'TABLE':
                return <TableVisualization
                    {...commonProps}
                    onChangePage={(newPage: number, newPageSize: number) => {
                        setPage(newPage);
                        setPageSize(newPageSize);
                    }}
                    pageSize={pageSize}
                    currentPage={page}
                    params={vizConfig.data.record}
                />;
            case 'PIVOT_TABLE':
                return <PivotTableVisualization 
                    {...commonProps} 
                    params={vizConfig.data.record}
                />;
            case 'PREMIUM_LTV_PIVOT_TABLE':
                return <LTVPivotTableVisualization 
                    analysis={analysis.data}
                    data={vizConfig.data.record}
                    onDrillDown={onDrilldown}
                />;
            case 'BAR':
            case 'LINE':
                return <ApexVisualization 
                    {...commonProps} 
                    params={vizConfig.data.record}
                />;
            default:
                return <div className="text-center p-3">
                    Unsupported visualization type: {analysis.data.visualization_type}
                </div>;
        }
    };

    if (!!vizConfig.error && !vizConfig.isLoading) {
        return <div className="text-center p-3">
            <i className="mdi mdi-alert-circle me-1"></i>
            {getErrorMessage(vizConfig.error)}
        </div>;
    }

    if (!analysis.data || !vizConfig.data || props.loadingDimensionFilters) {
        return <div className="text-center p-3">
            <i className="mdi mdi-loading mdi-spin me-1"></i>
            Loading...
        </div>;
    }

    return (
        <div style={{
            height: '100%',
            width: '100%',
            overflow: 'auto'
        }}>
            {renderVisualization()}
        </div>
    );
};

export default AnalysisVisualization;
