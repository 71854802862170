import { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { SingleValue } from "react-select";
import Select from "react-select";
import styled from 'styled-components';
import dbtIcon from '@assets/images/dbt.png';


export interface BuildNodeModalProps {
    onConfirm: (value: "THIS" | "UPSTREAM" | "DOWNSTREAM" | "ALL", force: boolean, check_for_records: boolean) => void;
    onClose: () => void;
    show?: boolean;
    header: string;
    defaultUpstream?: boolean;
    defaultDownstream?: boolean;
    defaultForce?: boolean;
    defaultCheck?: boolean;
}

const BuildNodeModal = (props: BuildNodeModalProps) => {
    const [includeUpstream, setIncludeUpstream] = useState(!!props.defaultUpstream);
    const [includeDownstream, setIncludeDownstream] = useState(!!props.defaultDownstream);
    const [force, setForce] = useState(!!props.defaultForce);
    const [checkForNewData, setCheckForNewData] = useState(!!props.defaultCheck);

    useEffect(() => {
        setDefaults();
    }, [props.defaultUpstream, props.defaultDownstream, props.defaultForce, props.defaultCheck]);
    
    // useEffect(() => {
    //     setDefaults();
    // }, []);

    // useEffect(() => {
    //     if (val && enterPressed) {
    //         confirm();
    //         setEnterPressed(false);
    //     }
    // }, [enterPressed, val]);

    const close = useCallback(() => {
        setDefaults();
        props.onClose();
    }, [props.onClose]);

    const setDefaults = useCallback(() => {
        setIncludeUpstream(!!props.defaultUpstream);
        setIncludeDownstream(!!props.defaultDownstream);
        setForce(!!props.defaultForce);
        setCheckForNewData(!!props.defaultCheck);
    }, [props.defaultUpstream, props.defaultDownstream, props.defaultForce, props.defaultCheck]); 

    const confirm = useCallback(() => {
        let upstreamDownstreamOption: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL' = 'THIS';
        if (includeDownstream && includeUpstream) {
            upstreamDownstreamOption = 'ALL';
        } else if (includeDownstream) {
            upstreamDownstreamOption = 'DOWNSTREAM';
        } else if (includeUpstream) {
            upstreamDownstreamOption = 'UPSTREAM';
        }

        props.onConfirm(upstreamDownstreamOption, force, checkForNewData);
        
        close();

    }, [force, checkForNewData, includeUpstream, includeDownstream, props.onConfirm]);

    return (
        <Modal show={props.show} onHide={props.onClose} size="lg">
            <Modal.Header closeButton onClick={() => close()}>
                <Modal.Title>
                    <img src={dbtIcon} alt="dbt" className="me-2" style={{ height: '2rem' }}/>{props.header}
                
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Configure your DBT build.</p>
                    <h3 className="fw-bold">Dependencies</h3>
                    <div className="row mb-3">
                        <div className="col-6">
                            <button className={`text-start btn btn-lg d-flex center-vertically ${includeUpstream ? 'btn-primary' : 'btn-light'} w-100`} onClick={() => setIncludeUpstream(!includeUpstream)}>
                                <div className="flex-1">
                                    <i className="mdi mdi-arrow-left-bold"></i> Include upstream nodes

                                </div>
                                <div>
                                    {includeUpstream ? <i className="mdi mdi-checkbox-marked"></i> : <i className="mdi mdi-checkbox-blank-outline"></i>}
                                </div>
                            </button>
                            <Form.Text>If this is checked, Pliable will also build any upstream nodes that have changed.</Form.Text>
                        </div>
                        <div className="col-6">
                            <button className={`text-start btn d-flex btn-lg ${includeDownstream ? 'btn-primary' : 'btn-light'} w-100`} onClick={() => setIncludeDownstream(!includeDownstream)}>
                                <div className="flex-1">
                                    <i className="mdi mdi-arrow-right-bold"></i> Include downstream nodes

                                </div>
                                <div>
                                    {includeDownstream ? <i className="mdi mdi-checkbox-marked"></i> : <i className="mdi mdi-checkbox-blank-outline"></i>}
                                </div>
                                
                            </button>
                            <Form.Text>If this is checked, Pliable will also build all downstream nodes after building this node.</Form.Text>
                        </div>
                    </div>
                    <h3 className="fw-bold">Additional Settings</h3>
                    <div className="row">
                        <div className="col-6">
                            <button className={`text-start btn d-flex btn-lg ${force ? 'btn-primary' : 'btn-light'} w-100`} onClick={() => setForce(!force)}>
                                <div className="flex-1">
                                    <i className="mdi mdi-gavel"></i> Force 

                                </div>
                                <div>
                                    {force ? <i className="mdi mdi-checkbox-marked"></i> : <i className="mdi mdi-checkbox-blank-outline"></i>}
                                </div>
                                
                            </button>
                            <Form.Text>If this is checked, Pliable will build all selected nodes regardless of if they have changed or received new data since the last build.</Form.Text>
                        </div>
                        <div className="col-6">
                            <button className={`text-start btn d-flex btn-lg ${checkForNewData ? 'btn-primary' : 'btn-light'} w-100`} onClick={() => setCheckForNewData(!checkForNewData)}>
                                <div className="flex-1">
                                    <i className="mdi mdi-database-search"></i> Check for new data

                                </div>
                                <div>
                                    {checkForNewData ? <i className="mdi mdi-checkbox-marked"></i> : <i className="mdi mdi-checkbox-blank-outline"></i>}
                                </div>
                                
                            </button>
                            <Form.Text>If this is checked, Pliable will check your data sources for new data before building. Note that this happens automatically for scheduled builds in production.</Form.Text>
                        </div>
                    </div>
                    
                    
            
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Cancel</button>
                <button className="btn btn-pliable" onClick={() => confirm()} autoFocus>Build</button>
            </Modal.Footer>
        </Modal>

    )
}

export default BuildNodeModal;