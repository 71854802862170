import { useQuery } from "react-query";
import ORM, { BaseModel } from "./orm";
import { DataWhitelist, PipelineNodeMeasureJoinTree } from "./pipelineNode";
import { queryClient } from "@stores/data.store";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";

export interface ReportBuilderDimension extends BaseModel {
    name: string;
    pipeline_node_id: string;
    field_id: string;
    description: string;
    column_name: string;
}

export const ReportBuilderDimensionORM = new ORM<ReportBuilderDimension>('dimensions', []);

export const invalidateReportBuilderData = () => {
    queryClient.invalidateQueries('measures');
}
export const useDimensions = () => {
    return useQuery(['dimensions'], async () => {
        const result = await ReportBuilderDimensionORM.find({});
        return result.records;
    });
}

export const useMeasureJoinPathOptions = (measureId: string) => {
    return useQuery(['join_path_options', measureId], async () => {
        const result = await ApiService.getInstance().request('GET', `/measures/${measureId}/join_path_options`) as ListRecordsResponse<ReportBuilderMeasureJoinPathOption>;
        return result.records;
    });
}

export interface ReportBuilderMeasureJoinPathOption {
    pipeline_node_id: string;
    measure_id: string;
    possible_paths: PipelineNodeMeasureJoinTree[];
}

export interface ReportBuilderDimensionJoinTree {
    pipeline_node_id: string;
    join_tree: PipelineNodeMeasureJoinTree|null;
    label?: string;
    ignore?: boolean;

}

export interface ReportBuilderMeasure extends BaseModel {
    name: string;
    description: string;
    column_name: string;

    is_calculation?: boolean;
    pipeline_node_id?: string;
    field_id?: string;
    aggregator?: string;
    formatter?: string;
    data_whitelist?: DataWhitelist;
    dimension_join_trees?: ReportBuilderDimensionJoinTree[];

    formula?: string;
    parent_measure_ids?: string[];

    diagram_x?: number;
    diagram_y?: number;
}

export const ReportBuilderMeasureORM = new ORM<ReportBuilderMeasure>('measures', []);

export const useMeasures = () => {
    return useQuery(['measures'], async () => {
        const result = await ReportBuilderMeasureORM.find({});
        return result.records;
    }, {
        staleTime: Infinity,
        refetchOnMount: false,
    });
}

export const useMeasure = (measureId: string) => {
    return useQuery(['measures', measureId], async () => {
        if (!measureId) {
            return null;
        }
        const result = await ReportBuilderMeasureORM.findById(measureId);
        return result;
    });
}


export const useUniqueValuesInDimension = (dimensionId: string, query?: string) => {
    return useQuery(['dimension_values', dimensionId, query], async () => {
        if (!dimensionId) {
            return [];
        }
        const params = new URLSearchParams();
        if (query) {
            params.append('q', query);
        }
        const queryString = params.toString();
        const url = `/dimensions/${dimensionId}/values${queryString ? `?${queryString}` : ''}`;
        
        const result = await ApiService.getInstance().request('GET', url) as ListRecordsResponse<string>;
        return result.records;
    }, {
        staleTime: 10 * 60 * 1000, // 10 minutes
        enabled: !!dimensionId
    });
}
