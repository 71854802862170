import BusinessObjectFieldTypeSelector from "@components/businessObjects/BusinessObjectFieldTypeSelector.component";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import ComponentDescription from "@components/general/ComponentDescription.component";
import EditableText from "@components/general/EditableText.component";
import PliableLoader from "@components/loaders/PliableLoader.component";
import PipelineNodeFieldTransformer from "@components/pipelineNodes/mapping/PipelineNodeFieldTransformer.component";
import PipelineNodeColumnTransformer from "@components/pipelineNodes/PipelineNodeColumnTransformer.component";
import PipelineNodeColumnValues from "@components/pipelineNodes/PipelineNodeColumnValues.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import DataWhitelistForm from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import { NodeList, SingleNodeItem } from "@components/pipelineNodes/PipelineNodeList.component";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import Warning from "@components/statusIndicators/Warning.component";
import { ColumnStats, DataWhitelist, DedupeRule, PipelineNode, PipelineNodeField, PipelineNodeFieldTranslation } from "@models/pipelineNode";
import { TableLoadResponse } from "@models/shared";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent, WizardContent, WizardFooter, WizardStep, WizardStepDivider, WizardSteps } from "@pages/PageStructure.component";
import { DataPaneHeader } from "@pages/PipelineNode/PipelineNodeConfiguration.page";
import { getErrorMessage } from "@services/errors.service";
import { convertToTitleCase, formatPercentage, summarizeNumber } from "@services/formatting.service";
import { shortid } from "@services/id.service";
import { humanReadableJoin, reorderList } from "@services/list.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import toast from "@services/toast.service";
import { useQueryParams } from "@services/url.service";
import { getTableData, savePipelineNode, usePipelineNode, usePipelineNodes } from "@stores/data.store";
import { cleanup } from "@testing-library/react";
import { Allotment, AllotmentHandle } from "allotment";
import { Component, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DragDropContext, Draggable, DraggingStyle, Droppable, NotDraggingStyle } from "react-beautiful-dnd";
import { Badge, Form, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { act } from "react-dom/test-utils";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled, { css } from "styled-components";
import { useDebouncedCallback } from "use-debounce";
import { useImmer } from "use-immer";
import { BigSelectorComponent, Grid } from "./shared";
import PipelineNodeIcon, { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeColumnCleanerUpper, { PipelineNodeFieldCleaningBadges, SummaryOptionPicker } from "@components/pipelineNodes/configuration/PipelineNodeColumnCleanerUpper.component";
import { timeAgo } from "@services/time.service";
import AsyncButton from "@components/button/AsyncButton.component";
import DBTEditor from "@components/pipelineNodes/configuration/DBTEditor.component";
import { requireConfirmation, runBuild } from "@services/alert/alert.service";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import FullScreenTakeover from "@components/general/FullScreenTakeover.component";
import SaveButton from "@components/button/SaveButton.component";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import ApiService, { SingleRecordResponse } from "@services/api/api.service";

interface GroupingBehaviorColumnOption {
    field_id: string;
    name: string;
}

interface GroupingBehaviorFormProps {
    groupingType: string;
    setGroupingType: (v: string) => void;

    dupeLogicGate: string;
    setDupeLogicGate: (v: string) => void;
    dupeColumnNames: string[];
    children?: React.ReactNode;
    disabled?: boolean;
    emptyOK?: boolean;
    justMergeAndStack: boolean;
    dedupeRules: DedupeRule[];
    allColumnOptions: GroupingBehaviorColumnOption[];
    changeDedupeRules: (newRules: DedupeRule[]) => void;
}

export const GroupingBehaviorForm = ({ emptyOK, disabled, children, groupingType, setGroupingType, dupeColumnNames, dupeLogicGate, setDupeLogicGate, justMergeAndStack, dedupeRules, allColumnOptions, changeDedupeRules}: GroupingBehaviorFormProps) => {
    const groupingText = useMemo(() => {
        const cols = dupeColumnNames
        
        const joined = humanReadableJoin(cols, ', ', dupeLogicGate == 'AND' ? ' and ' : ' or ');

        switch (groupingType) {
            case 'MERGE':
                return <>Pliable will merge records with the same values in <strong>{joined}</strong></>;
            case 'DEDUPLICATE':
                return <>Pliable will deduplicate records with the same values in <strong>{joined}</strong></>;
            case 'SUMMARIZE':
                return <>Pliable will summarize records with the same values in <strong>{joined}</strong></>;
            case 'IDENTIFY':
                return <>Pliable will assign the same identity to records with the same values in <strong>{joined}</strong></>;
        }
        return <></>;
    }, [dupeColumnNames, groupingType, dupeLogicGate]);

    const changeGroupingType = useCallback((newType: string) => {
        setGroupingType(newType);
        if (newType == 'DEDUPLICATE') {
            // Set the default rules
            changeDedupeRules([{
                field_id: '_PLB_LOADED_AT',
                desc: true,
            }]);
        }
    }, [setGroupingType, changeDedupeRules])


    const dropdownOptions = useMemo(() => {
        if (justMergeAndStack) {
            return [
                { label: 'Stack', value: 'STACK', description: 'Stack records from multiple sources without merging'},
                { label: 'Merge', value: 'MERGE', description: 'Merge matching records from your input data'},
            ]
        }

        return [
            { 
                label: 'No grouping', 
                value: 'STACK', 
                description: 'Process records without merging or aggregating',
                
            },
            { label: 'Deduplicate', value: 'DEDUPLICATE', description: 'Remove duplicate records from your input data.'},
            { label: 'Summarize', value: 'SUMMARIZE', description: 'Aggregate records based on shared criteria'},
            { label: 'Identify', value: 'IDENTIFY', description: "Identify (but don't merge) records that share common attributes"},
        ];

        
    }, [justMergeAndStack]);

    const updateDedupeRule = useCallback((index: number, selectedOption: string) => {
        const updatedRules = dedupeRules.map((r, i) => {
            if (i == index) {
                const parsed = parseSelectedRuleOption(selectedOption);
                return parsed;
            }
            return r;
        });
        changeDedupeRules(updatedRules);
    }, [dedupeRules, changeDedupeRules]);

    const parseSelectedRuleOption = (value: string): DedupeRule => {
        const parts = value.split(':');
        if (parts.length == 1) {
            return {
                field_id: parts[0],
                desc: false,
            }
        }

        if (['ASC', 'DESC'].includes(parts[1])) {
            return {
                field_id: parts[0],
                desc: parts[1] == 'DESC',
            }
        }

        if (parts[1] == 'NON_EMPTY') {
            return {
                field_id: parts[0],
                comparator: 'NOT_EMPTY',
                desc: true,
            }
        }

        throw new Error('Could not parse selected dedupe rule');
    }

    const dedupeRuleToRuleOption = (rule: DedupeRule): string => {
        if (rule.comparator == 'NOT_EMPTY') {
            return rule.field_id + ':NON_EMPTY';
        }

        return rule.field_id + ':' + (rule.desc ? 'DESC' : 'ASC');
    }


    const dedupeRuleOptions = useMemo(() => {
        const rules: Option[] = [{
            label: 'Keep most recent',
            value: '_PLB_LOADED_AT:DESC',
        }, {
            label: 'Keep first',
            value: '_PLB_LOADED_AT:ASC',
        }];

        allColumnOptions.forEach(c => {
            rules.push({
                label: c.name + ' (ascending)',
                value: c.field_id + ':ASC',
            });
            rules.push({
                label: c.name + ' (descending)',
                value: c.field_id + ':DESC',
            });
            rules.push({
                label: c.name + ' (keep non-empty)',
                value: c.field_id + ':NON_EMPTY',
            });
        });

        return rules;

    }, [allColumnOptions]);

    const parsedDedupeRules = useMemo(() => {
        /** Create a list of the corresponding "rule option" value for each selected dedupe rule */
        return dedupeRules.map(r => dedupeRuleToRuleOption(r));
    }, [dedupeRules]);

       
   return <>
        <Dropdown
            disabled={disabled}
            options={dropdownOptions}
            onChange={changeGroupingType}
            selected={groupingType}
            isClearable={false}
        />
        {children}

        {groupingType && dupeColumnNames.length > 1 && <div className="mt-2">
            <Form.Check
                type="switch"
                checked={dupeLogicGate == 'AND'}
                label={dupeLogicGate == 'AND' ? 'Match ALL columns' : 'Match ANY columns'}
                onChange={(e) => setDupeLogicGate(e.target.checked ? 'AND' : 'OR')}
            />  
        </div>}
        <div className="mt-2">
            {groupingType && groupingType != 'STACK' && dupeColumnNames.length == 0 && !emptyOK && <Warning>
                You must select at least one column to group records.    
            </Warning>}
            {groupingType && groupingType != 'STACK' && dupeColumnNames.length > 0 && <InfoAlert noIcon>
                <div>{groupingText}</div>
            </InfoAlert>}
        </div>
        {groupingType == 'DEDUPLICATE' && <div className="mt-3">
            <Form.Label>Pick winner</Form.Label>
            <p className="text-muted">How should Pliable pick the "winner" if there are duplicates?</p>
            <div>
                {dedupeRules.length == 0 && <Warning>
                    <div>You must have at least one rule for picking the winning record if there are duplicates.</div>    
                </Warning>}
                <table className="table table-small table-bordered table-centered">
                    <thead className="bg-light">
                        <tr>
                            <th>Rule</th>
                            <th className="text-end">
                                <button className="icon-button font-18" onClick={() => changeDedupeRules([...dedupeRules, { field_id: '', desc: false}])}>
                                    <i className="mdi mdi-plus-thick"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {dedupeRules.map((rule, i) => {
                            const selectedVal = parsedDedupeRules[i];
                            return <tr>
                                <td>
                                    <Dropdown
                                        options={dedupeRuleOptions}
                                        selected={selectedVal}
                                        onChange={(v) => {
                                            updateDedupeRule(i, v);
                                        }}
                                    />
                                </td>
                                <td className="text-end">
                                    <button className="icon-button font-18" onClick={() => {
                                        changeDedupeRules(dedupeRules.filter((r, index) => index != i));
                                    }}>
                                        <i className="mdi mdi-delete"></i>
                                    </button>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </div>}
        
    </>
}


const ColumnOptionCard = styled.div<{excluded?: boolean}>`


 -webkit-transition: background-color 0.15s ease-in-out;
    -moz-transition: background-color 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out;

.manage-opacity {
    -webkit-transition: opacity 0.15s ease-in-out, max-height 0.15s ease-in-out;
    -moz-transition: opacity 0.15s ease-in-out, max-height 0.15s ease-in-out;
    transition: opacity 0.15s ease-in-out, max-height 0.15s ease-in-out;
}


${props => props.excluded && css`
background-color: var(--ct-danger-bg);
.manage-opacity {
    opacity: 0.5;

}
`}
`

interface ColumnOption {
    sourceFieldId: string;
    sourceFieldName: string;
    sampleValues?: string[];
    density?: number;
    uniqueness?: number;
    datatype?: string;
    renameTo?: string;
    description?: string;
    transform?: string;
    customTransform?: string;
    aggregator?: string;
    translations?: PipelineNodeFieldTranslation[];
    exclude?: boolean;
    enhanced?: boolean;
}




const CleaningWizardPage = () => {
    const { pipelineNodeId } = useParams();

    const theNode = usePipelineNode(pipelineNodeId);

    const [step, setStep] = useState(0);

    const [columnOptions, setColumnOptions] = useImmer<ColumnOption[]>([]);
    const [usedColumnOptions, setUsedColumnOptions] = useImmer<ColumnOption[]>([]);

    const [customSQL, setCustomSQL] = useState('');

    const [writeDBT, setWriteDBT] = useState(false);

    const [dedupeRules, setDedupeRules] = useImmer<DedupeRule[]>([]);

    const pipelineNodes = usePipelineNodes();

    

    // Note that this whitelist will be using the columns
    // in the SOURCE node, so we'll need to parse this and
    // add columns appropriately to the new node before saving.
    const [whitelist, setWhitelist] = useImmer<DataWhitelist>({
        entries: [],
        logic_gate: 'AND',
    });

    const [whitelistForDataViewer, setWhitelistForDataViewer] = useState<DataWhitelist>({
        entries: [],
        logic_gate: 'AND',
    });

    useEffect(() => {
        if (!theNode.data) {
            return;
        }
        setColumnOptions(theNode.data.fields.map(f => {
            const shape = theNode.data!.shape?.columns.find(c => c.key === f.name);
            return {
                sourceFieldId: f.id,
                sourceFieldName: f.name,
                renameTo: f.name,
                description: '',
                transform: '',
                density: shape?.density,
                uniqueness: shape?.uniqueness,
                sampleValues: shape?.samples,
                datatype: f.type,
                enhanced: shape?.enhanced,
            }
        }));

        setNodeName(theNode.data.label + ' - Cleaned');
        setWhitelistRecordCount(theNode.data.shape?.total_records || 0);
    }, [theNode.isFetched]);

    const sourceColumns = useMemo(() => {
        const usedFieldIds = usedColumnOptions.map(c => c.sourceFieldId);
        return columnOptions.filter(c => !usedFieldIds.includes(c.sourceFieldId)).sort();
    }, [columnOptions])


    const onUse = useCallback((sourceFieldId: string) => {
        setUsedColumnOptions(draft => {
            const col = columnOptions.find(c => c.sourceFieldId === sourceFieldId);
            if (!col) {
                return;
            }
            draft.push(col);
        });
       
    }, [columnOptions]);

    const onRemove = useCallback((sourceFieldId: string) => {
        console.log('Current used column options', usedColumnOptions);
        setUsedColumnOptions(draft => {
            const col = draft.findIndex(c => c.sourceFieldId === sourceFieldId);
            if (col < 0) {
                return;
            }
            draft.splice(col, 1);
        });
        
    }, [usedColumnOptions]);

    const usedSourceColumnIds = useMemo(() => {
        return usedColumnOptions.map(c => c.sourceFieldId);
    }, [usedColumnOptions]);

    const onChangeColumnKey = useCallback((sourceFieldId: string, key: keyof ColumnOption, value: any) => {
        setColumnOptions(draft => {
            const col = draft.find(c => c.sourceFieldId === sourceFieldId);
            if (!col) {
                return;
            }

            // @ts-ignore
            col[key] = value;
        });
    }, []);

    const onColumnReorder = useCallback((result: any) => {
        if (!result.destination) {
            return;
        }

        setUsedColumnOptions(draft => {
            return reorderList(draft, result.source.index, result.destination.index);;
        });
            

    }, []);

    const [loadingTestWhitelist, setLoadingTestWhitelist] = useState(false);

    const [whitelistRecordCount, setWhitelistRecordCount] = useState<number>(0);

    const [testingWhitelist, setTestingWhitelist] = useState(false);

    const onTestWhitelist = useCallback(async () => {
        setWhitelistForDataViewer(whitelist);
    }, [whitelist]);

    const onWhitelistRecordCountUpdated = useCallback((response: number) => {
        setWhitelistRecordCount(response);
    }, []);

    const [hasDupes, setHasDupes] = useState(false);
    const [dupeMethod, setDupeMethod] = useState<'most_recent' | 'squash'>('most_recent');
    const [dupeColumns, setDupeColumns] = useState<string[]>([]);
    const [dupeLogicGate, setDupeLogicGate] = useState('AND');
    const [nodeName, setNodeName] = useState('');
    const [nodeDescription, setNodeDescription] = useState('');

    const dedupeWarning = useMemo(() => {
        if (!theNode.data) {
            return <></>;
        }

        const columnsWithMissingValues: string[] = [];

        const columnNames: {[sourceFieldId: string]: string} = {};
        const columnNamesList: string[] = []
        usedColumnOptions.filter(f => dupeColumns.includes(f.sourceFieldId)).forEach(c => {
            columnNames[c.sourceFieldName] = c.renameTo || c.sourceFieldName;
            columnNamesList.push(c.sourceFieldName);
        });

        if (!theNode.data.shape) {
            return <></>;
        }

        theNode.data.shape!.columns.forEach(c => {
            if (!columnNamesList.includes(c.key)) {
                return;
            }

            if (c.density < 1.0) {
                columnsWithMissingValues.push(columnNames[c.key]);
            }
        });

        if (columnsWithMissingValues.length === 0) {
            return <></>;
        }

        return <Warning>
            <div><strong>Warning:</strong> {columnsWithMissingValues.length} columns ({columnsWithMissingValues.join(', ')}) have empty values in your source data. These records will not be deduplicated.</div>
        </Warning>
    }, [dupeColumns, theNode.dataUpdatedAt]);

    const sourceFieldNames: {
        [fieldId: string]: string
    } = useMemo(() => {
        if (!theNode.data) {
            return {};
        }

        const fieldNames: {
            [fieldId: string]: string
        } = {}
        theNode.data.fields.forEach(f => {
            fieldNames[f.id] = f.name;
        });
        return fieldNames;


    }, [theNode.dataUpdatedAt]);

    const targetFieldNames: {
        [fieldId: string]: string
    } = useMemo(() => {
        if (usedColumnOptions.length == 0) {
            return {};
        }

        const fieldNames: {
            [fieldId: string]: string
        } = {}
        usedColumnOptions.forEach(f => {
            fieldNames[f.sourceFieldId] = f.renameTo || f.sourceFieldName;
        })
        
        return fieldNames;


    }, [usedColumnOptions]);


    const navigate = useNavigate();
    const goBack = useCallback(() => {
        if (step == 0) {
            navigate(`/node/${pipelineNodeId}`)
        } else {
            setStep(step - 1);
        }
    }, [step, navigate, pipelineNodeId]);

    const [saving, setSaving] = useState(false);

    const queryParams = useQueryParams();

    const boContext = queryParams.get('bo_context');
    const next = queryParams.get('next');

    const [groupingType, setGroupingType] = useState('STACK');
    const boNode = usePipelineNode(boContext || undefined);


    const saveNode = useCallback(async () => {
        setSaving(true);
        const mappedColumns: {
            [sourceColumnId: string]: string;
        } = {};
        const newNodeFields: PipelineNodeField[] = columnOptions.filter(c => !c.exclude).map(c => {
            const newId = shortid();
            mappedColumns[c.sourceFieldId] = newId;
            return {
                id: newId,
                type: c.datatype || 'STRING',
                taxonomic_id: '',
                cell_actions: [],
                label: c.renameTo as string,
                name: c.renameTo as string,
                description: c.description || '',
                transformer: c.transform,
                custom_transform_sql: c.customTransform,
                translations: c.translations,
                merge_behavior: (c.aggregator || 'PICK_ONE'),
                
                part_of_composite_key: dupeColumns.includes(c.sourceFieldId) || c.aggregator == 'GROUP_BY',
                map_options: [
                    {
                        id: shortid(),
                        source_node_id: pipelineNodeId,
                        attribute_id: c.sourceFieldId
                    }
                ],

            }
        });

        const updatedDedupeRules = dedupeRules.map(rule => {
            if (rule.field_id === '_PLB_LOADED_AT') {
                return rule;
            }
            return {
                ...rule,
                field_id: mappedColumns[rule.field_id] || rule.field_id
            };
        });

        try {
            let nodeType = 'STAGING';

            if (writeDBT) {
                nodeType = 'CUSTOM';
            }

            const saveData = {
                id: null,
                node_type: nodeType,
                fields: newNodeFields,
                grouping_behavior: groupingType,
                name: nodeName,
                label: nodeName,
                description: nodeDescription,
                custom_sql: customSQL,
                upstream_node_ids: [pipelineNodeId as string],
                upstream_pre_filters: {
                    [pipelineNodeId as string]: whitelist,
                },
                table_name: '',
                dedupe_rules: updatedDedupeRules,
            };
            const response = await savePipelineNode(saveData);
            
            await runBuild(
                '+' + response.name, 
                false,
                false,
                <>
                    <Link className="btn btn-light" to={`/node/${response.id}`}>View Data</Link>
                    {boContext && <Link className="btn btn-success ms-1" to={`/wizard/mapping/${response.id}?bo_context=${boContext}`}>Map to Entity</Link>}
                </>,
                <>
                    <Link className="btn btn-light" to={`/node/${response.id}/config`}>Fix Error</Link>
                    
                </>
                
            );
            
            
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        

    }, [dedupeRules, groupingType, pipelineNodeId, writeDBT, customSQL, whitelist, dupeColumns, dupeMethod, nodeName, nodeDescription, next, boContext, boNode.dataUpdatedAt, columnOptions]);
    

    const [cleaningType, setCleaningType] = useState('BASIC');

    const [cleanupColumnId, setCleanupColumnId] = useState<string>('');
    
    const [sourceColumnSearch, setSourceColumnSearch] = useState('');

    const [showHiddenColumns, setShowHiddenColumns] = useState(false);

    const filteredColumnOptions = useMemo(() => {
        return columnOptions.filter(c => (showHiddenColumns || !c.exclude) && c.sourceFieldName.toLowerCase().includes(sourceColumnSearch.toLowerCase())).sort((a, b) => {
            return a.sourceFieldName.localeCompare(b.sourceFieldName);
        });
    }, [columnOptions, sourceColumnSearch, showHiddenColumns])


    const numberOfSteps = useMemo(() => {
        if (['DEDUPE', 'SPLIT', 'IDENTIFY'].includes(cleaningType)) {
            return 6;
        }

        if (cleaningType == 'CUSTOM') {
            return 2;
        }
        return 5;
    }, [cleaningType]);

    const nextIsEnabled = useMemo(() => {
        if (step == 2) {
            return usedColumnOptions.length > 0;
        }

        if (cleaningType == 'SUMMARIZE' && step == 3) {
            const groupByColumns = usedColumnOptions.filter(c => c.aggregator == 'GROUP_BY');
            return groupByColumns.length > 0;
        }
        return true;
    }, [cleaningType, step, usedColumnOptions]);

    // For animation management
    const [excludingFieldId, setExcludingFieldId] = useState('');

    const includeField = useCallback((fieldId: string) => {
        setColumnOptions(draft => {
            const col = draft.find(c => c.sourceFieldId === fieldId);
            if (!col) {
                return;
            }
            col.exclude = false;
        });
    }, []);

    const excludeField = useCallback((fieldId: string) => {
        setExcludingFieldId(fieldId);
        setTimeout(() => {
            setExcludingFieldId('');
            setColumnOptions(draft => {
                const col = draft.find(c => c.sourceFieldId === fieldId);
                if (!col) {
                    return;
                }
                col.exclude = true;
                
            });
        }, 250);
        
    }, []);

    const hiddenColumns = useMemo(() => {
        return columnOptions.filter(c => c.exclude);
    }, [columnOptions]);

    // Translates it into a PipelineNodeField so we can use it in the shared ColumnCleanerUpper component
    const cleanupColumn: PipelineNodeField|undefined = useMemo(() => {
        if (!cleanupColumnId) {
            return;
        }

        const columnOpt = columnOptions.find(c => c.sourceFieldId === cleanupColumnId);

        if (!columnOpt) {
            return;
        }

        return {
            id: cleanupColumnId,
            type: columnOpt.datatype || 'STRING',
            taxonomic_id: '',
            cell_actions: [],
            label: columnOpt.renameTo || '',
            name: columnOpt.renameTo || '',
            description: columnOpt.description || '',
            transformer: columnOpt.transform || '',
            custom_transform_sql: columnOpt.customTransform || '',
            translations: columnOpt.translations || [],
            part_of_composite_key: false,
            map_options: [],
        }
    }, [columnOptions, cleanupColumnId]);

    const [showFilterModal, setShowFilterModal] = useState(false);

    const [showFilters, setShowFilters] = useState(false);

    const filterOnField = useCallback((fieldId: string) => {
        const theField = columnOptions.find(c => c.sourceFieldId == fieldId);
        if (!theField) {
            return;
        }

        setShowSourceData(true);

        // If the field is already in the whitelist, don't add it again
        if (whitelist.entries.find(e => e.field_id == fieldId)) {
            return;
        }

        // Otherwise add an empty filter for that field and show the modal
        setWhitelist(draft => {
            draft.entries.push({
                pipeline_node_id: pipelineNodeId as string,
                field_id: fieldId,
                value: '',
                comparator: 'EQUALS',
            });
        });
    }, [columnOptions, whitelist, pipelineNodeId]);

    const fieldIdsWithFilters = useMemo(() => {
        return whitelist.entries.map(e => e.field_id);
    }, [whitelist]);

    const fieldIdsWithTransforms = useMemo(() => {
        return columnOptions.filter(c => !!c.transform).map(e => e.sourceFieldId);
    }, [columnOptions]);

    const [activeSummaryColumnId, setActiveSummaryColumnId] = useState('');

    const summaryColumn = useMemo(() => {
        return columnOptions.find(c => c.sourceFieldId === activeSummaryColumnId);
    }, [activeSummaryColumnId, columnOptions]);

    const toggleDedupe = useCallback((sourceFieldId: string) => {
        if (dupeColumns.includes(sourceFieldId)) {
            setDupeColumns(dupeColumns.filter(c => c !== sourceFieldId));
        } else {
            setDupeColumns([...dupeColumns, sourceFieldId]);
        }
    }, [dupeColumns]);

    const groupingText = useMemo(() => {
        const cols = columnOptions.filter(c => dupeColumns.includes(c.sourceFieldId)).map(c => c.renameTo || c.sourceFieldName);
        
        const joined = humanReadableJoin(cols, ', ', dupeLogicGate == 'AND' ? ' and ' : ' or ');

        switch (groupingType) {
            case 'MERGE':
                return <>Pliable will squash records with the same values in <strong>{joined}</strong></>;
            case 'SUMMARIZE':
                return <>Pliable will summarize records with the same values in <strong>{joined}</strong></>;
            case 'IDENTIFY':
                return <>Pliable will assign the same identity to records with the same values in <strong>{joined}</strong></>;
        }
        return <></>;
    }, [columnOptions, dupeColumns, groupingType, dupeLogicGate])


    const revertDBT = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to switch back to the no-code editor? You will lose any changes you made in the DBT editor.', 'Switch to No-Code Editor', 'Yes');
        if (confirmed) {
            setWriteDBT(false);
        }

    }, []);

    const ensureDedupe = useCallback((sourceFieldId: string) => {
        if (!dupeColumns.includes(sourceFieldId)) {
            setDupeColumns(dupeColumns.concat([sourceFieldId]));
        }
        
    }, [dupeColumns]);

    const ensureNoDedupe = useCallback((sourceFieldId: string) => {
        if (dupeColumns.includes(sourceFieldId)) {
            setDupeColumns(dupeColumns.filter(c => c !== sourceFieldId));
        }
        
    }, [dupeColumns]);

    const [showExistingModal, setShowExistingModal] = useState(false);
    const [existingCleaningSteps, setExistingCleaningSteps] = useState<PipelineNode[]>([]);

    const [checkedExistingModal, setCheckedExistingModal] = useState(false);

    useEffect(() => {
        
        if (!pipelineNodes.data) {
            return;
        }
        const existing = pipelineNodes.data.filter(n => {
            if (n.id == pipelineNodeId) {
                return false;
            }

            if (getGroupValueForNodeType(n.node_type) != 'STAGING') {
                return false;
            }

            if (n.upstream_node_ids.includes(pipelineNodeId as string)) {
                return true;
            }
            return false;
        });
        setExistingCleaningSteps(existing);
        
        if (existing.length > 0 && !checkedExistingModal) {
            setShowExistingModal(true);
        }
        setCheckedExistingModal(true);
    }, [pipelineNodes.dataUpdatedAt, pipelineNodeId, checkedExistingModal])

    const enableDBT = useCallback(() => {
        const allColumns = columnOptions.map(c => c.sourceFieldName).map(o => '"' + o + '"').join(',\n\t\t');
        setCustomSQL(`{{config(materialized='table')}}

WITH cleaned_data as (
    select 
        -- Transform your data here
        ${allColumns} 
    from {{ref('${theNode.data?.name}')}}
    -- Filter your data here
    where 1=1
)

-- Every pliable record needs a unique _PLB_UUID and a _PLB_LOADED_AT timestamp
select 
    hash(*) as _PLB_UUID, 
    CURRENT_TIMESTAMP() as _PLB_LOADED_AT,
    * 
from cleaned_data
        `);
        setWriteDBT(true);
    }, [columnOptions, theNode.dataUpdatedAt]);

    const [showSourceData, setShowSourceData] = useState(false);

    const [showFilteredRecordsInDataViewer, setShowFilteredRecordsInDataViewer] = useState(false);


    const hasEnhancedShape = useMemo(() => {
        // The shape is enhanced if every one of the columns is enhanced. If they're enhanced individually that means we skipped the auto-enhanced during the build process due to there being too many columns.
        if (!theNode.data) {
            return true;
        }

        if (!theNode.data.shape) {
            return false;
        }

        return theNode.data.shape.columns.every(c => c.enhanced);
    }, [theNode.dataUpdatedAt]);

    const [gettingShapeForColumn, setGettingShapeForColumn] = useState('');
    const getShapeForColumn = useCallback(async (columnId: string) => {
        setGettingShapeForColumn(columnId);
        try {
            const result = await ApiService.getInstance().request('GET', `/pipelinenodes/${pipelineNodeId}/column/${columnId}/shape`) as SingleRecordResponse<ColumnStats>;
            const shape = result.record;
            setColumnOptions(draft => {
                const col = draft.find(c => c.sourceFieldId === columnId);
                if (!col) {
                    return;
                }
                col.density = shape.density;
                col.uniqueness = shape.uniqueness;
                col.sampleValues = shape.samples;
                col.enhanced = true;
            });
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setGettingShapeForColumn('');
        }
    }, [pipelineNodeId]);

    if (!theNode.data) {
        return <PliableLoader/>
    }

    return <PageStructure>
        
        <PageContent hasSidebar>
            <FullScreenTakeover show={showSourceData} onHide={() => setShowSourceData(false)} noCloseButton>
                <div className="d-flex flex-column" style={{height: '100%'}}>
                    <div className="ps-3 pt-2 pb-2 pe-3 border-bottom">
                        <div className="d-flex center-vertically ">
                            <h2 className="mb-0 flex-1"><span className="fw-light">Viewing data from</span> {theNode.data.label}</h2>
                            <button className="btn btn-success" onClick={() => setShowSourceData(false)}>
                                <i className="mdi mdi-check-bold"></i> Back to Cleaning
                            </button>

                        </div>  
                    </div>
                    
                    <div className="flex-1">
                        <div className="row" style={{height: '100%'}}>
                            <div className="col-3">
                                <Pane>
                                    <PaneContent>
                                        <div className="p-3">
                                            <h3 className="mb-0">Edit Filters</h3>
                                            <p>Define rules to determine which records come through from your data source. For example, you might want to exclude test records, or records in a different country.</p>
                                            <AsyncButton

                                                onClick={onTestWhitelist}
                                                text="Test Filters"
                                                loading={testingWhitelist}
                                            />
                                            <hr />
                                            <DataWhitelistForm
                                                compact
                                                config={whitelist}
                                                nodeIds={[pipelineNodeId as string]}
                                                onChange={(config) => {
                                                    setWhitelist(config);
                                                    
                                                }}
                                                onTest={onTestWhitelist}
                                            />
                                        </div>
                                        
                                    </PaneContent>
                                </Pane>
                                
                            </div>
                            <div className="col-9 pt-3 pe-3" style={{height: '100%'}}>
                                <PipelineNodeDataTable
                                    hideDigIn
                                    pipelineNodeId={theNode.data.id as string}
                                    whitelist={whitelistForDataViewer}
                                    onRecordCountUpdated={onWhitelistRecordCountUpdated}
                                />
                            </div>
                        </div>
                    </div>
                    
                </div>
                    
                    
                    
                    
                    
                
                
            </FullScreenTakeover>
        <Modal show={!!activeSummaryColumnId} onHide={() => setActiveSummaryColumnId('')}>
            <Modal.Header closeButton>
                <Modal.Title>Summarize</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {summaryColumn && <>
                    <Form.Group className="mb-3">
                        <Form.Label>Datatype</Form.Label>
                        <BusinessObjectFieldTypeSelector
                            selected={summaryColumn.datatype || ''}
                            onSelect={v => onChangeColumnKey(summaryColumn.sourceFieldId, 'datatype', v)}
                        />
                        <Form.Text>Your summarization options depend on the data type of this column.</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>How should we summarize the data in this column?</Form.Label>
                        <SummaryOptionPicker
                            fieldType={summaryColumn.datatype || ''}
                            aggregator={summaryColumn.aggregator || ''}
                            onChangeAggregator={v => {
                                onChangeColumnKey(summaryColumn.sourceFieldId, 'aggregator', v);
                                if (v == 'GROUP_BY') {
                                    ensureDedupe(summaryColumn.sourceFieldId);
                                } else {
                                    ensureNoDedupe(summaryColumn.sourceFieldId);
                                }
                            }}
                        />
                    </Form.Group>
                </>
                    }
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-success" onClick={() => setActiveSummaryColumnId('')}>Done</button>
            </Modal.Footer>
        </Modal>
            <Modal show={showExistingModal } onHide={() => setShowExistingModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Existing Cleaning Steps</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>There are existing cleaning steps that use the data from this node. Do you want to use the data from one of those instead?</p>
                        {existingCleaningSteps.map(e => {
                            return <SingleNodeItem node={e} compact onClick={() => {
                                if (boContext) {
                                    navigate(`/wizard/mapping/${e.id}?bo_context=${boContext}`)
                                } else {
                                    navigate(`/node/${e.id}/config`)
                                }
                            }}/>
                        })}         
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-success" onClick={() => setShowExistingModal(false)}>Clean from scratch</button>
                </Modal.Footer>
            </Modal>
            <Modal size="xl" show={showFilterModal} onHide={() => setShowFilterModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Filters</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Define rules to determine which records come through from your data source. For example, you might want to exclude test records, or records in a different country.</p>

                    <DataWhitelistForm
                        config={whitelist}
                        nodeIds={[pipelineNodeId as string]}
                        onChange={(config) => {
                            setWhitelist(config);
                            // if (config.entries.length == 0) {
                            //     setWhitelistRecordCount(theNode.data?.shape?.total_records || 0);
                            // }
                        }}
                        onTest={onTestWhitelist}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <div className="flex-1 text-start">
                        <AsyncButton 
                            variant="primary"
                            className="me-2" 
                            onClick={onTestWhitelist} 
                            text="Test Filters" 
                            loading={testingWhitelist} 
                            disabled={whitelist.entries.length == 0}
                        />
                        {theNode.data && theNode.data.shape && <span>
                        {theNode.data.shape.total_records - whitelistRecordCount} out of {theNode.data.shape.total_records} records removed.
        
                        </span>}
                    </div>
                    <div className="text-end">
                        <button className="btn btn-success" onClick={() => {
                            setShowFilterModal(false);
                        }}>Done</button>
                    </div>
                        
                </Modal.Footer>
            </Modal>
            <PipelineNodeColumnCleanerUpper
                sourcePipelineNodeId={pipelineNodeId as string}
                sourceFieldId={cleanupColumnId}
                show={!!cleanupColumnId}
                field={cleanupColumn}
                onChange={(newField: PipelineNodeField) => {
                    setColumnOptions(draft => {
                        const col = draft.find(c => c.sourceFieldId === cleanupColumnId);
                        if (!col) {
                            return;
                        }
                        col.datatype = newField.type;
                        col.transform = newField.transformer;
                        col.customTransform = newField.custom_transform_sql;
                        col.translations = newField.translations;
                        
                    })
                    
                    setCleanupColumnId('');
                }}

                onClose={() => setCleanupColumnId('')}
            />
        
        
                            <PageContentHeader>
                                <div className="d-flex center-vertically">
                                    <DashboardIcon icon="mdi mdi-wizard-hat" bgColor="dark"/>

                                    <div className="flex-1">
                                        <h1 className="mb-0">Data Cleaning Wizard</h1>
                                        <div className="text-muted font-13">
                                            Set up automated rules to filter, clean, and format your source data.
                                        </div>
                                    </div>
                                    {writeDBT && <>
                                        <button className="btn btn-light btn-lg me-1" onClick={revertDBT}>Switch to No-Code Editor</button>

                                    </>}
                                    {!writeDBT && <>
                                        <button className="btn btn-light btn-lg me-1" onClick={enableDBT}>Switch to Code Editor</button>
                                    </>}
                                    <SaveButton
                                        className="btn-lg"
                                        onClick={saveNode}
                                        disabled={saving}
                                    />
                                    {/* {boNode.data && <>
                                        <div>
                                            <div className="small">Connecting To</div>
                                            <SingleNodeItem node={boNode.data} compact rounded/>
                                        </div>
                                    </>} */}
                                    
                                </div>
                                
                            </PageContentHeader>
                            <PageContentInner hasHeader>
                                {writeDBT && <div style={{height: '100%'}}>
                                            <DBTEditor
                                                code={customSQL}
                                                onChange={setCustomSQL}
                                            />
                                </div>}
                                {!writeDBT && <div className="p-3">
                                    <input type="text" className="form-control input-rounded mb-2" placeholder="Search columns..." value={sourceColumnSearch} onChange={(e) => setSourceColumnSearch(e.target.value)}/>
                                    {!hasEnhancedShape && <InfoAlert>
                                        <div>
                                            <strong>Heads up:</strong> your data source has a lot of columns, so Pliable didn't automatically analyze each one. You can click on <Badge><i className="mdi mdi-auto-fix"></i> Analyze</Badge> to analyze the columns you want to know more about.
                                        </div>
                                    </InfoAlert>}
                                    {filteredColumnOptions.map(c => {
                                        return <ColumnOptionCard key={c.sourceFieldId} className="card mb-3" excluded={c.exclude || excludingFieldId == c.sourceFieldId}>
                                            <div className="card-body p-2">
                                                <div className="d-flex center-vertically">
                                                    <i className="mdi mdi-table-column font-24"></i>
                                                    <div className="mb-0 ms-3" style={{maxWidth: '50%'}}>
                                                        <h3 className="mb-0">
                                                            {c.exclude && (c.renameTo || c.sourceFieldName)}
                                                            {!c.exclude && <EditableText
                                                                value={c.renameTo || c.sourceFieldName}
                                                                onChange={(v) => onChangeColumnKey(c.sourceFieldId, 'renameTo', v)}
                                                            />}
                                                        </h3>
                                                        
                                                        {c.renameTo != c.sourceFieldName && <div className="font-13">
                                                        (Originally {c.sourceFieldName})</div>}
                                                    </div>
                                                    
                                                    <div className="flex-1"></div>
                                                    <div className="font-24">
                                                        {!c.enhanced && <button className="btn btn-primary me-3" onClick={() => {
                                                            getShapeForColumn(c.sourceFieldId);
                                                        }}
                                                            disabled={!!gettingShapeForColumn}>
                                                            <i className="mdi mdi-auto-fix"></i> Analyze    
                                                        </button>}
                                                        <button className="me-1 btn btn-light" onClick={() => {
                                                            c.exclude ? includeField(c.sourceFieldId) : excludeField(c.sourceFieldId);
                                                        }}>
                                                            {c.exclude && <><i className="mdi mdi-eye"></i> Include</>}
                                                            {!c.exclude && <><i className="mdi mdi-eye-off"></i> Exclude</>}
                                                        </button>
                                                        <button className={`btn ${fieldIdsWithTransforms.includes(c.sourceFieldId) ? 'btn-pliable' : 'btn-light'} me-1`} disabled={c.exclude} onClick={() => {
                                                            setCleanupColumnId(c.sourceFieldId);
                                                        }}>
                                                            <i className="mdi mdi-broom"></i> Format
                                                        </button>
                                                        <button className={`btn ${fieldIdsWithFilters.includes(c.sourceFieldId) ? 'btn-pliable' : 'btn-light'} me-1`} disabled={c.exclude} onClick={() => {
                                                            filterOnField(c.sourceFieldId)
                                                        }}>
                                                            <i className="mdi mdi-filter"></i> Filter
                                                        </button>
                                                        {groupingType == 'DEDUPLICATE' && <>
                                                            <button className={`btn ${dupeColumns.includes(c.sourceFieldId) ? 'btn-pliable' : 'btn-light'} me-1`} disabled={c.exclude} onClick={() => {
                                                                toggleDedupe(c.sourceFieldId);
                                                            }}>
                                                                <i className="mdi mdi-set-merge"></i> Dedupe
                                                            </button>
                                                        </>}
                                                        {groupingType == 'SUMMARIZE' && <>
                                                            <button onClick={() => {
                                                                setActiveSummaryColumnId(c.sourceFieldId);
                                                            }} className={`btn me-1 ${c.aggregator == 'GROUP_BY' ? 'btn-pliable': 'btn-light'}`} disabled={c.exclude}>
                                                                <i className="mdi mdi-vector-combine"></i> {convertToTitleCase(c.aggregator || 'Summarize')}
                                                            </button>
                                                        </>}
                                                        {groupingType == 'IDENTIFY' && <>
                                                            <button className={`btn ${dupeColumns.includes(c.sourceFieldId) ? 'btn-pliable' : 'btn-light'} me-1`} disabled={c.exclude} onClick={() => {
                                                                toggleDedupe(c.sourceFieldId);
                                                            }}>
                                                                <i className="mdi mdi-card-account-details"></i> ID
                                                            </button>
                                                        </>}
                                                        
                                                        
                                                    </div>
                                                </div>
                                                <div className="bg-light p-2 mt-2 manage-opacity">
                                                    <div className="row">
                                                        <div className="col-2">
                                                            <h6>Data Type</h6>
                                                            {c.datatype}
                                                        </div>
                                                        <>
                                                            <div className="col-2">
                                                                <h6>Uniqueness</h6>
                                                                {c.enhanced && formatPercentage(c.uniqueness || 0)}
                                                                {!c.enhanced && gettingShapeForColumn == c.sourceFieldId && <i className="mdi mdi-loading mdi-spin"/>}
                                                                {!c.enhanced && gettingShapeForColumn != c.sourceFieldId && <span>N/A</span>}
                                                            </div>
                                                            <div className="col-2">
                                                                <h6>Missing Values</h6>
                                                                {c.enhanced && formatPercentage(1 - (c.density || 0))}
                                                                {!c.enhanced && gettingShapeForColumn == c.sourceFieldId && <i className="mdi mdi-loading mdi-spin"/>}
                                                                {!c.enhanced && gettingShapeForColumn != c.sourceFieldId && <span>N/A</span>}
                                                            </div>
                                                            <div className="col-6">
                                                            <h6>Samples</h6>
                                                                {c.enhanced && <>
                                                                    {c.sampleValues?.slice(0, 5).map((v) => {
                                                                        return v ? (
                                                                            <Badge bg="secondary" title={v} className="me-1">
                                                                                {v.slice(0, 15)}
                                                                                {v.length > 15 ? '...' : ''}
                                                                            </Badge>
                                                                        ) : null;
                                                                    })}
                                                                </>}
                                                                {!c.enhanced && gettingShapeForColumn == c.sourceFieldId && <i className="mdi mdi-loading mdi-spin"/>}
                                                                {!c.enhanced && gettingShapeForColumn != c.sourceFieldId && <span>N/A</span>}
                                                                
                                                            </div>
                                                        </>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </ColumnOptionCard>
                                    })}
                                </div>}
                                
                            
                            </PageContentInner>
        </PageContent>
        <PageSidebar right>
            <Pane>
                <PaneContent>
                    <div className="p-2">
                        <h4>Now: Cleaning</h4>
                        <SingleNodeItem node={theNode.data} compact />
                        <button className="btn btn-primary mt-1 w-100" onClick={() => {
                            setShowSourceData(true);
                        }}>
                            <i className="mdi mdi-table"></i> View Source Data
                        </button>
                        {boContext && boNode.data && <div className="mt-3">
                            <h4>Next: Mapping</h4>
                            <SingleNodeItem node={boNode.data} compact />
                        </div>}
                        <hr />
                        <Form.Group className="mb-3">
                            <Form.Label>Node Name</Form.Label>
                            <Form.Control
                                value={nodeName}
                                onChange={(e) => setNodeName(e.target.value)}
                            />
                        </Form.Group>
                        {!writeDBT && <>
                            <hr />
                            <Form.Group className="mb-3">
                                <Form.Label>Grouping Behavior</Form.Label>
                                <GroupingBehaviorForm
                                    justMergeAndStack={false}
                                    groupingType={groupingType}
                                    setGroupingType={setGroupingType}
                                    dupeLogicGate={dupeLogicGate}
                                    setDupeLogicGate={setDupeLogicGate}
                                    dupeColumnNames={columnOptions.filter(c => dupeColumns.includes(c.sourceFieldId)).map(c => c.renameTo || c.sourceFieldName)}
                                    allColumnOptions={columnOptions.map(o => {
                                        return {field_id: o.sourceFieldId, name: o.renameTo || o.sourceFieldName}
                                    })}
                                    dedupeRules={dedupeRules}
                                    changeDedupeRules={setDedupeRules}
                                />
                                {dedupeWarning}
                            
                            </Form.Group>
                            {hiddenColumns.length > 0 && <div>
                                Excluding {hiddenColumns.length} columns. 
                                <Form.Check
                                    type="switch"
                                    id="show-hidden-columns"
                                    label="Show excluded columns"
                                    checked={showHiddenColumns}
                                    onChange={(e) => setShowHiddenColumns(e.target.checked)}
                                />
                            </div>}
                        </>}
                        
                        
                    </div>                    
                </PaneContent>
            </Pane>
            
        </PageSidebar>
        
    </PageStructure>
}

export default CleaningWizardPage;