import AsyncButton from "@components/button/AsyncButton.component";
import Dropdown from "@components/form/Dropdown.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import { NodeList, SingleNodeItem } from "@components/pipelineNodes/PipelineNodeList.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import Warning from "@components/statusIndicators/Warning.component";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent, WizardContent, WizardFooter, WizardStep, WizardStepDivider, WizardSteps } from "@pages/PageStructure.component";
import DataSourceImporter from "@pages/Sources/DataSourceImporter.component";
import SnowflakeDataSourceSelector, { SnowflakeDataSourceViewer, SnowflakeTableSelector } from "@pages/Sources/SnowflakeDataSource.page";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import toast from "@services/toast.service";
import { useQueryParams } from "@services/url.service";
import { invalidatePipelineNode, savePipelineNode, useAvailableSnowflakeTables, useFile, usePipelineNode, usePipelineNodes, useSourceRecordType } from "@stores/data.store";
import { get } from "http";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Form, Modal } from "react-bootstrap";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { BigSelectorComponent, Grid } from "./shared";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import { shortid } from "@services/id.service";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import PliableLoader from "@components/loaders/PliableLoader.component";
import Danger from "@components/statusIndicators/Danger.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import styled from "styled-components";
import { BlockInProd } from "@components/project/DraftModeRequired.component";
import { sampleData, SampleDataNode  } from "../../constants/samples";
import { runBuild } from "@services/alert/alert.service";




const DataSourceWizardPage = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    const [sourceType, setSourceType] = useState('');

    const queryParams = useQueryParams();
    const [step, setStep] = useState(0);


    const next = queryParams.get('next');
    const boContext = queryParams.get('bo_context');
    const querySourceType = queryParams.get('sourceType');

    const [processedQuerySourceType, setProcessedQuerySourceType] = useState(false);    


    const [loadFromUrl, setLoadFromUrl] = useState('');
    const urlQueryParam = searchParams.get('url') || '';
    useEffect(() => {
        if(!!urlQueryParam) {
            setLoadFromUrl(urlQueryParam);
            setSourceType('file')
            setStep(1);
        }
    }, [urlQueryParam]);

    useEffect(() => {
        if (querySourceType && !processedQuerySourceType) {
            setSourceType(querySourceType);
            setProcessedQuerySourceType(true);
            setStep(1);
        }
    }, [querySourceType, processedQuerySourceType]);

    const boNode = usePipelineNode(boContext || undefined);

    const allNodes = usePipelineNodes();

    const navigate = useNavigate();

    const [selectedOtherNode, setSelectedOtherNode] = useState<PipelineNode|undefined>(undefined);

    const continueWithOtherNode = useCallback(async () => {
        if (!selectedOtherNode) {
            return false;
        }


        // We've chosen an existing node to map to this one we're currently on.
        // For now, we should just add it as upstream_node_ids and go to the config
        // screen. Eventually we may want to have some intelligence around knowing whether
        // you're going right from a source to an entity etc.
        await PipelineNodeORM.patch(boContext as string, {
            upstream_node_ids: [selectedOtherNode.id, ...(boNode.data?.upstream_node_ids || [])]
        });
        invalidatePipelineNode(boContext as string);
        navigate(`/node/${boContext}/config`);


        
        return true;
    }, [selectedOtherNode, boContext, boNode.dataUpdatedAt]);

    const [newSRTAdded, setNewSRTAdded] = useState('');
    const [newFileAdded, setNewFileAdded] = useState('');

    const newFile = useFile(newFileAdded);
    const newSRT = useSourceRecordType(newSRTAdded);


    const onSourceAdded = useCallback((fileId: string, srtId: string) => {
        setNewSRTAdded(srtId);
        setNewFileAdded(fileId);
        setStep(step + 1);
    }, [next, boContext, step]);


    useEffect(() => {
        setNewNodeName(newFile.data?.name.split('.')[0] || '');
    }, [newFile.dataUpdatedAt]);



    const [snowflakeTableName, setSnowflakeTableName] = useState('');

    useEffect(() => {
        const spl = snowflakeTableName.split('.');

        setNewNodeName(spl[1] + '_' + spl[2]);
    }, [snowflakeTableName]);

    const [showExistingSourceNodeModal, setShowExistingSourceNodeModal] = useState(false);
    const existingSourceNodes = useMemo(() => {
        if (!allNodes.data) {
            return [];
        }
        return allNodes.data.filter(node => node.table_name == snowflakeTableName);
    }, [allNodes.dataUpdatedAt, snowflakeTableName]);

    
    const nextIsEnabled = useMemo(() => {
        if (step == 0) {
            if (sourceType.startsWith('sample:')) {
                return true;
            }
            if (sourceType == 'snowflake') {
                return true;
            }
            if (sourceType == 'file') {
                return true;
            }
            if (sourceType == 'other') {
                return true;
            }
            return false;
        } else if (step == 1) {
            
            if (sourceType == 'snowflake' && snowflakeTableName) {
                return true;
            }
            if (sourceType == 'file') {
                return false;
            }
            if (sourceType == 'other' && selectedOtherNode) {
                return true;
            }
            return false;
        }
        
    }, [sourceType, snowflakeTableName, selectedOtherNode, step, newSRTAdded, newFile.dataUpdatedAt]);

    const [newNodeName, setNewNodeName] = useState('');
    const [newNodeDescription, setNewNodeDescription] = useState('');


    const isSaveable = useMemo(() => {
        if (sourceType == 'snowflake') {
            return !!snowflakeTableName && !!newNodeName;
        } else if (sourceType == 'file') {
            return !!newFile.data && !!newSRT.data && !!newNodeName;
        } else if (sourceType == 'other') {
            return !!selectedOtherNode;
        }
        return false;
    }, [newNodeName, sourceType, selectedOtherNode, snowflakeTableName, newFile.dataUpdatedAt, newSRT.dataUpdatedAt]);
    
    const [activeOrchestration, setActiveOrchestration] = useState<BuildOrchestration|undefined>(undefined);
    
    const [newPipelineNode, setNewPipelineNode] = useState<PipelineNode|undefined>(undefined);


   
    const [building, setBuilding] = useState(false);

    const onSave = useCallback(async () => {
        setBuilding(true);
        const runTheBuild = async (nodeName: string, nodeId: string) => {
            return await runBuild(
                nodeName,
                false,
                false,
                <>
                    <Link className="btn btn-light me-1" to={`/node/${nodeId}`}>View Data</Link>
                    {boContext && <Link to={`/wizard/cleaning/${nodeId}?next=business_object&bo_context=${boContext}`} className="btn btn-success">Continue to Cleaning</Link>}
                    {!boContext && <Link to={`/wizard/cleaning/${nodeId}`} className="btn btn-success">Continue to Cleaning</Link>}
                </>,
                <>
                    <Link className="btn btn-light me-1" to={`/node/${nodeId}/config`}>Fix Errors</Link>

                </>
                
            );
        }
        if (sourceType == 'snowflake') {
            try {
                const savedNode = await savePipelineNode({
                    id: null,
                    node_type: 'SOURCE',
                    table_name: snowflakeTableName,
                    name: newNodeName,
                    label: newNodeName,
                    upstream_node_ids: [],
                    description: newNodeDescription,
                    fields: [],
                });
                setNewPipelineNode(savedNode);


                await runTheBuild(savedNode.name, savedNode.id as string);

                

            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
        } else if (sourceType == 'file') {
            if (!newSRT.data || !newFile.data) {
                return;
            
            }
            try {
                const savedNode = await savePipelineNode({
                    id: null,
                    node_type: 'SOURCE',
                    source_record_type_id: newSRTAdded,
                    name: newNodeName,
                    label: newNodeName,
                    upstream_node_ids: [],
                    description: newNodeDescription,
                    flat_file: true,
                    table_name: `_PLB_FILE_UPLOADS.FILE_${newSRTAdded}`,

                    // Shows fields for downstream mapping without needing a build
                    fields: (newSRT.data.columns || []).map(col => {
                        return {
                            name: col.key,
                            label: col.key,
                            id: shortid(),
                            description: '',
                            map_options: [],
                            type: 'STRING',
                            taxonomic_id: '',
                            part_of_composite_key: false,
                            cell_actions: [],
                        }
                    })
                });
                setNewPipelineNode(savedNode);

                await runTheBuild(savedNode.name, savedNode.id as string);
               
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
        } else if (sourceType == 'other') {
            continueWithOtherNode();
        }
        setBuilding(false);
    }, [sourceType, newSRT.dataUpdatedAt, newSRTAdded, newFile.dataUpdatedAt, selectedOtherNode, snowflakeTableName, newNodeName, newNodeDescription, runBuild, continueWithOtherNode])

    const goNext = useCallback(() => {
        if (step == 0 && sourceType.startsWith('sample:')) {
            const sampleName = sourceType.split(':')[1];
            const sample = sampleData.find(s => s.name == sampleName);
            setLoadFromUrl(sample?.url || '');
            setSourceType('file')
            setStep(1);
        } else {
            setStep(step + 1);
        }
    }, [step, sourceType]);
    return <PageStructure>
        
{/* 
        <Modal show={showBuildModal} backdrop="static" onHide={() => {}}>
            <Modal.Header>
                <Modal.Title>Building</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {building && <>
                    <p>Pliable is building and analyzing your source data so it can be used in your data model. This may take a few seconds.</p>
                    <PliableLoader/>
                </>}
                {!building && buildStatus == 'complete' && <>
                    <SuccessAlert>
                        <div>Your source data has been processed and is ready to use.</div>
                    </SuccessAlert>
                    <Link className="btn btn-light me-1" to={`/node/${newPipelineNode?.id}`}>View Data</Link>
                    {next == 'cleaning' && <Link className="btn btn-success" to={`/wizard/cleaning/${newPipelineNode?.id}?next=business_object&bo_context=${boContext}`}>
                        Continue to Cleaning
                    </Link>}
                    {next != 'cleaning' && <Link className="btn btn-primary" to={`/wizard/cleaning/${newPipelineNode?.id}`} onClick={() => invalidatePipelineNodes()}>
                        Clean Data
                    </Link>}
                </>}
                {!building && buildStatus == 'error' && <>
                    <Danger>
                        <div><div>There was an error processing your source data.</div>
                        <div>{buildError}</div></div>
                    </Danger>
                </>}
                
            </Modal.Body>
        </Modal> */}

        
        <PageContent>
            <Pane>
                <PaneContent>
                    <PageContentHeader>
                        <div className="d-flex center-vertically" style={{height: '100%'}}>
                            <DashboardIcon icon="mdi mdi-wizard-hat" bgColor="pliable"/>

                            <div className="">
                                <h1 className="mb-0">Data Source Wizard</h1>
                                <div className="text-muted font-13">
                                    Load data into Pliable.
                                </div>
                            </div>
                            <div className="flex-1" style={{maxWidth: 'calc(60vw)'}}>
                                <WizardSteps>
                                    <WizardStep
                                        stepNumber="1"
                                        icon="mdi mdi-database"
                                        stepDescription="Select Source Type"
                                        active={step == 0}
                                        complete={step >= 0}
                                    />
                                    <WizardStepDivider />
                                    <WizardStep
                                        stepNumber="2"
                                        icon="mdi mdi-view-list"
                                        stepDescription="Select Data"
                                        active={step == 1}
                                        complete={step >= 1}
                                    />
                                    <WizardStepDivider />
                                    <WizardStep
                                        stepNumber="3"
                                        icon="mdi mdi-information"
                                        stepDescription="Review"
                                        active={step == 2}
                                    />

                                </WizardSteps>
                            </div>
                            
                            {boNode.data && <>
                                <div>
                                <div className="small">Connecting source data to:</div>
                                    <SingleNodeItem node={boNode.data} compact rounded/>
                                </div>
                            </>}
                            
                            
                        </div>
                    </PageContentHeader>
                    <PageContentInner hasHeader noScroll>
                    <BlockInProd>
                        <Pane>
                            
                            <WizardContent className="mt-3">
                                <div className="card">
                                    <div className="card-body overflow-hidden scroll-vertical">
                                        {step == 0 && <>
                                            {boNode.data && <>
                                                <h4>
                                                    <i className="mdi mdi-connection text-pliable"></i> <span className="fw-light">You are connecting a new data source to </span><strong><PipelineNodeName pipelineNode={boNode.data}/></strong>
                                                </h4>
                                                <hr />
                                            </>}
                                            <h2 className="mb-0">Where is your data?</h2>
                                            <p className="text-muted">Connect your own data.</p>
                                            <Grid>
                                                <BigSelectorComponent
                                                    title="Snowflake"
                                                    description="Pull in data that you've already loaded into your Snowflake data warehouse."
                                                    selected={sourceType == 'snowflake'}
                                                    onClick={() => setSourceType('snowflake')}
                                                    icon="mdi mdi-database"

                                                />
                                                <BigSelectorComponent
                                                    title="File"
                                                    description="Upload any Excel-compatible file"
                                                    selected={sourceType == 'file'}
                                                    onClick={() => setSourceType('file')}
                                                    icon="mdi mdi-file"

                                                />
                                                {boNode.data && 
                                                <BigSelectorComponent
                                                    title="Existing Node"
                                                    description="Select a data source you have already connected to Pliable"
                                                    selected={sourceType == 'other'}
                                                    onClick={() => setSourceType('other')}
                                                    icon="mdi mdi-table"

                                                />}
                                            </Grid>
                                            <hr />
                                            <h4 className="mb-0">Sample Data</h4>
                                            <p className="text-muted">Use existing data from Pliable's library of samples.</p>
                                            <Grid>
                                                {sampleData.map((s, idx) => {
                                                    return <BigSelectorComponent
                                                        title={s.name}
                                                        icon={s.icon}
                                                        description={s.description}
                                                        selected={sourceType == 'sample:' + s.name}
                                                        onClick={() => setSourceType('sample:' + s.name)}
                                                    />
                                                })}
                                            </Grid>
                                           
                                            
                                            
                                        </>}
                                        {step == 2 && <>
                                            <div className="row">
                                                <div className="col-3"></div>
                                                <div className="col-6">
                                                    <h2>Review</h2>
                                                    {newFileAdded && newFile.data && <>
                                                        <SuccessAlert>
                                                            <div>File <strong>{newFile.data.name}</strong> uploaded and processed successfully.</div>
                                                        </SuccessAlert>
                                                    </>}
                                                    {sourceType == 'snowflake' && <>
                                                        <div>You are about to create a new source node for the table <strong>{snowflakeTableName}</strong>.</div>
                                                        {existingSourceNodes.length > 0 && !building && <div className="mt-2">
                                                            <Warning>
                                                                There are existing source nodes using this table. It's probably best if you use data from there instead of creating a new node.
                                                            </Warning>
                                                            <NodeList nodes={existingSourceNodes} onClick={(pn: PipelineNode) => {
                                                                if (boContext) {
                                                                    setSelectedOtherNode(pn);
                                                                    setSourceType('other');
                                                                } else {
                                                                    navigate(`/node/${pn.id}`);
                                                                }
                                                                
                                                            }}/>
                                                        </div>}
                                                        
                                                        
                                                            
                                                        
                                                        
                                                    </>}
                                                    
                                                    {['snowflake', 'file'].includes(sourceType) && <>
                                                        <hr />
                                                        
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Name this source</Form.Label>
                                                            <input type="text" className="form-control" value={newNodeName} onChange={e => setNewNodeName(e.target.value)} />
                                                            <Form.Text>You can name it however you'd like, including spaces and special characters. We'll automatically generate a DBT-safe model name.</Form.Text>
                                                            
                                                        </Form.Group>
                                                        <Form.Group className="mb-3">
                                                            <Form.Label>Describe this source</Form.Label>
                                                            <Form.Control as="textarea" className="form-control" value={newNodeDescription} onChange={e => setNewNodeDescription(e.target.value)} />
                                                            <Form.Text>Give it a good description so your colleages can find data in Pliable.</Form.Text>
                                                            
                                                        </Form.Group>
                                                    </>}
                                                    {sourceType == 'other' && selectedOtherNode && <div className="mb-3">
                                                        <p>Using data from existing node:</p>
                                                        <SingleNodeItem node={selectedOtherNode} rounded/>
                                                    </div>}

                                                    <button onClick={onSave} disabled={!isSaveable} className="btn btn-huge btn-success">Done</button>
                                                </div>
                                                <div className="col-3">
                                                    
                                                </div>
                                            </div>
                                            
                                            
                                        </>}
                                        {step == 1 && <>
                                            {sourceType == 'snowflake' && <>
                                                <div className="row" style={{height: '100%'}}>
                                                    <div className="col-3 border-righ overflow-auto" style={{height: '100%'}}>
                                                        <h2>Select a table.</h2>
                                                        <SnowflakeTableSelector
                                                            onSelect={setSnowflakeTableName}
                                                            selectedTable={snowflakeTableName}
                                                        />
                                                    </div>
                                                    <div className="col-9 overflow-auto" style={{height: '100%'}}>
                                                        <SnowflakeDataSourceViewer
                                                            tableName={snowflakeTableName}
                                                            onUseTable={() => {
                                                                // onUseSnowflakeTable();
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </>}

                                            {sourceType == 'file' && <div>
                                                <h2>Upload File</h2>
                                                
                                                <DataSourceImporter
                                                    onSourceAdded={onSourceAdded}
                                                    loadFromUrl={loadFromUrl}
                                                    // allowSelectExisting={props.allowSelectExisting}
                                                    // selectExistingBlacklist={props.selectExistingBlacklist}
                                                    // allowSystemConnectors={props.allowSystemConnectors}
                                                    // pipelineNodeId={props.existingPipelineNodeId}
                                                />
                                            </div>}

                                            {sourceType == 'other' && <>
                                                <div className="row" style={{height: '100%'}}>
                                                    <div className="col-3 border-right" style={{height: '100%'}}>
                                                        <h2>Select an existing node.</h2>
                                                        <Form.Group>
                                                            <PipelineNodeSelector
                                                                onSelect={setSelectedOtherNode}
                                                                selectedId={selectedOtherNode?.id || ''}
                                                                optionFilter={(node) => ['SOURCE', 'STAGING'].includes(getGroupValueForNodeType(node.node_type))}
                                                            />
                                                        </Form.Group>
                                                    </div>
                                                    <div className="col-9" style={{height: '100%'}}>
                                                        {!selectedOtherNode && <>
                                                            Select a node to view its data.
                                                        </>}
                                                        {selectedOtherNode && <>
                                                            <div style={{height: '80px'}} className="border-bottom">
                                                                <div className="d-flex center-vertically mb-2">
                                                                    
                                                                    <div className="overflow-ellipsis flex-1">
                                                                        <>
                                                                            <h1 className="overflow-ellipsis mb-0">{selectedOtherNode?.label}</h1>
                                                                            <div className="mb-1 font-poppins text-muted font-13">
                                                                                <span className="me-1">
                                                                                    <Badge>{selectedOtherNode?.node_type}</Badge>
                                                                                </span>
                                                                                <span>
                                                                                    {selectedOtherNode?.last_build_completed == null && <Badge bg="warning">Never Built</Badge>}
                                                                                </span>
                                                                                <span className="me-3">&nbsp;</span>
                                                                                <span>
                                                                                    <i className="mdi mdi-file-multiple"></i> Records: {summarizeNumber(selectedOtherNode?.total_records || 0)}
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div style={{height: 'calc(100% - 80px)'}}>
                                                                
                                                                <PipelineNodeDataTable
                                                                    justTable
                                                                    pipelineNodeId={selectedOtherNode?.id || ''}
                                                                    searchQuery=""
                                                                />
                                                                
                                                                
                                                            </div>
                                                        </>}
                                                    </div>
                                                
                                                </div>
                                            </>}
                                        </>}
                                    </div>
                                </div>
                            </WizardContent>
                            <WizardFooter>
                                <div className="row">
                                    <div className="col-6">
                                        {step > 0 && <button onClick={() => {
                                            setStep(step - 1);
                                        }} className="btn btn-outline-secondary">&larr; Back</button>}
                                    </div>
                                    <div className="col-6 text-end">
                                        {step < 2 && <button onClick={goNext} disabled={!nextIsEnabled} className="btn btn-dark">Next &rarr;</button>}
                                        {/* {step == 2 && <button onClick={onSave} disabled={!isSaveable} className="btn btn-success">Done</button>} */}
                                    </div>
                                </div>
                            </WizardFooter>
                        </Pane>
                    </BlockInProd>
                    </PageContentInner>
                    
                                                    
                       
                       
                        
                        
                </PaneContent>
            </Pane>
        </PageContent>
        
    </PageStructure>
}

export default DataSourceWizardPage;