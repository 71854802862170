import { useEntitlements } from "@frontegg/react";
import PageStructure, { PageContent } from "@pages/PageStructure.component";
import ApiService, { ListRecordsResponse } from "@services/api/api.service";
import { useFeatureFlag } from "@services/featureFlags/featureFlag.service";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

export interface Template {
    id: string;
    name: string;
    type: string;
    included_nodes: {
        pipeline_node_id: string;
        included_field_ids: string[];
    }[];
    include_relationship_ids: string[];
    version: number;
    data_model_template_id?: string;
    taxonomic_id?: string;
}

export const useMyTemplates = () => {
    return useQuery('my_templates', async () => {
        const response = await ApiService.getInstance().request('GET', '/templates/mine') as ListRecordsResponse<Template>;
        return response.records;
    }, {
        // 30 mins
        staleTime: 60 * 30 * 1000
    });
}

const MyTemplatesPage = () => {
    const templates = useMyTemplates();

    const isTemplateManagementEnabled = useFeatureFlag('template-management', false);

    if (!isTemplateManagementEnabled) {
        return <>Access denied</>
    }
    
    return <PageStructure>
        <PageContent>
            <div className="p-5">
                <div className="d-flex mb-1">
                    <h1 className="mb-0 flex-1">Your Templates</h1>
                    <Link className="btn btn-primary" to="/templates/export">New Template</Link>
                </div>
                <p>View templates managed by this account.</p>
                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Type</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {templates.data && templates.data.map(t => {
                            return <tr>
                                <td>
                                    {t.name}
                                </td>
                                <td>
                                    {t.type}
                                </td>
                                <td>
                                    <Link to={`/templates/edit/${t.id}`}>Edit</Link>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
        </PageContent>
    </PageStructure>
}

export default MyTemplatesPage;