import toast from '@services/toast.service';
import { toggleThenaWidget, usePipelineNodes } from '@stores/data.store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getPromptAnswer, runBuild } from '@services/alert/alert.service';
import PageStructure, { PageContent, Pane, PaneContent} from './PageStructure.component';
import { getErrorMessage } from '@services/errors.service';
import { getGroupValueForNodeType } from '@services/modeling.service';
import { DashboardIcon } from '@components/pipelineNodes/PipelineNodeIcon.component';
import { timeAgo } from '@services/time.service';
import { savePipelineNode, useIsInDraftMode, useProjectConfig, useSources } from "@stores/data.store";
import SourceIcon from "@components/sources/SourceIcon.component";
import flatFileIcon from '@assets/images/connectors/flatfile.png';
import snowflakeIcon from '@assets/images/snowflake_logo.png';
import PipelineNodeList, { NodeList, SingleMeasureItem } from "@components/pipelineNodes/PipelineNodeList.component";
import { Modal, Tabs, Tab, Form } from "react-bootstrap";

import { ReportBuilderMeasureORM, useMeasures } from "@models/reportBuilder";
import { Grid } from "./Wizard/shared";
import { useAuthState } from "@services/auth/auth.service";
import { DraftOnly, ProdOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import HeaderAlert from "@components/alert/HeaderAlert.component";
import { ReportsEmptyState } from "./PipelineNode/Reports.page";
import TOSModal from "@components/alert/TOSModal.component";
import LoadingCard from '@components/card/LoadingCard.component';
import { confirmRunBuild, NotificationsList } from '@components/Inbox.component';
import SourcesListWidget from './Home/SourcesListWidget.component';
import CleaningStepsListWidget from './Home/CleaningStepsListWidget.component';
import EntitiesListWidget from './Home/EntitiesListWidget.component';
import MetricsListWidget from './Home/MetricsListWidget.component';
import AnalysisListWidget from './Home/AnalysisListWidget.component';
import { useAnalyses } from '@models/analysis';
import Dropdown from '@components/form/Dropdown.component';
import PipelineNodeSelector from '@components/pipelineNodes/PipelineNodeSelector.component';
import { PipelineNode } from '@models/pipelineNode';
import { set } from 'immer/dist/internal';
import DashboardsListWidget from './Home/DashboardsListWidget.component';
import { useFeatureFlag } from '@services/featureFlags/featureFlag.service';




const HomePage = () => {
    const pipelineNodes = usePipelineNodes();
    const inDraftMode = useIsInDraftMode();
    const sources = useSources();
    const isLoading = pipelineNodes.isLoading
    const projectConfig = useProjectConfig();

    const bookedOnboardingCall = useMemo(() => {
        if (!projectConfig.data) {
            return false;
        }

        return !!projectConfig.data.onboarding_info?.onboardingEventLink;
    }, [projectConfig.dataUpdatedAt]);

    const sourcesToShow = useMemo(() => {
        if (!sources.data) {
            return [];
        }
        return sources.data.filter(s => s.type != 'flat_file');
    }, [sources.dataUpdatedAt]);

    const reportNodes = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'REPORTING').sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt]);

    const businessObjects = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'DATA_MODELING').sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt]);

    const dataSources = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => getGroupValueForNodeType(n.node_type) == 'SOURCE');
    }, [pipelineNodes.dataUpdatedAt]);

    const navigate = useNavigate();

    const [nodeViewMode, setNodeViewMode] = useState<'LIST' | 'COLUMNS' | 'DIAGRAM'>('COLUMNS');

    const [nodeSearch, setNodeSearch] = useState('');

    const checkForNodeSearchEnterKey = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key == 'Enter') {
            setNodeSearch(e.currentTarget.value);
        }
    }, []);

    const nodesToShowInList = useMemo(() => {
        if (!pipelineNodes.data) {
            return [];
        }
        return pipelineNodes.data.filter(n => n.label.toLowerCase().includes(nodeSearch.toLowerCase())).sort((a, b) => a.label.localeCompare(b.label));
    }, [pipelineNodes.dataUpdatedAt, nodeSearch]);

    const addNewReport = useCallback(async () => {
        const reportName = await getPromptAnswer('What do you want to name this report?', 'New Report');
        if (reportName) {
            try {
                const result = await savePipelineNode({
                    id: null,
                    node_type: 'REPORT',
                    name: reportName,
                    label: reportName,
                    description: '',
                    upstream_node_ids: [],
                    fields: [],
                    table_name: '',
                });
                navigate(`/node/${result.id}/config`);
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
            
        }
    }, []);

    const [showWelcomeModal, setShowWelcomeModal] = useState(false);

    const measures = useMeasures();

    const measuresForList = useMemo(() => {
        if (!measures.data) {
            return [];
        }

        return [...measures.data].sort((a, b) => a.name.localeCompare(b.name));
    }, [measures.dataUpdatedAt]);

    useEffect(() => {
        if (businessObjects.length == 0) {
            setShowWelcomeModal(true);
        }
    }, [businessObjects]);

    // if (pipelineNodes.data && pipelineNodes.data.length == 0 && measures.data && measures.data.length == 0) {
    //     return <PageStructure>
    //         <PageContent>
    //             <div className="p-onboarding pt-5">
    //                 <div className="empty-state text-center">
    //                     <h1>Welcome to Pliable</h1>
    //                 </div>
    //             </div>
    //         </PageContent>
    //     </PageStructure>
    // }

    const authState = useAuthState();

    const [timesOnHomeScreen, setTimesOnHomeScreen] = useState(0);

    useEffect(() => {
        const currentTimes = localStorage.getItem('timesOnHomeScreen');
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnHomeScreen(parsed);
        } else {
            setTimesOnHomeScreen(0);
        }

        localStorage.setItem('timesOnHomeScreen', ((parsed || 0) + 1).toString());
    }, []);

    

    const hasSampleData = useMemo(() => {
        return !!projectConfig.data?.onboarding_info?.sampleData
    }, [projectConfig.dataUpdatedAt]);


    const [showOnboardingWizard, setShowOnboardingWizard] = useState(true);
    const [onboardingStepsComplete, setOnboardingStepsComplete] = useState<{[step: string]: boolean}>({});

    useEffect(() => {
        if (hasSampleData) {
            const timesOnSnowflakeScreen = parseInt(localStorage.getItem('timesOnSnowflakePage') || '0', 10);
            const timesOnSemanticLayerPage = parseInt(localStorage.getItem('timesOnSemanticLayerPage') || '0', 10);
            const timesOnReportingPage = parseInt(localStorage.getItem('timesOnReportingPage') || '0', 10);

            setOnboardingStepsComplete({
                'sources': timesOnSnowflakeScreen >= 1,
                'semantic_layer': timesOnSemanticLayerPage >= 1,
                'analysis': timesOnReportingPage >= 1,
            });

            if (timesOnSnowflakeScreen >= 1 && timesOnSemanticLayerPage >= 1 && timesOnReportingPage >= 1) {
                setShowOnboardingWizard(false);
            }
        } else if (pipelineNodes.data && measures.data) {
            let entities = 0;
            let metrics = 0;
            let sources = 0;
            let reports = 0; 

            const ranAnalysis = parseInt(localStorage.getItem('timesRanAnalysis') || '0', 10);

            pipelineNodes.data.forEach(n => {
                if (n.node_type == 'DIMENSION') {
                    entities++;
                }
                if (n.node_type == 'SOURCE') {
                    sources++;
                }
                if (n.node_type == 'REPORT') {
                    reports++;
                }
            });

            metrics = measures.data.length;

            setOnboardingStepsComplete({
                'sources': sources > 0,
                'entities': entities > 0,
                'reports': reports > 0,
                'analysis': ranAnalysis > 0,
                'metrics': metrics > 0,
            });

            if (sources > 0 && entities > 0 && ranAnalysis > 0 && metrics > 0) {
                setShowOnboardingWizard(false);
            }
        }
    }, [hasSampleData, pipelineNodes.dataUpdatedAt, measures.dataUpdatedAt]);

    const inOnboarding = true;
    const isInTrial = projectConfig.data && ['paused', 'trialing'].includes(projectConfig.data.subscription_status as string);
    const daysLeftInTrial = (isInTrial) ? projectConfig.data.days_left_in_trial : 0;



    const headerAlertContent = useMemo(() => {
        // if (timesOnHomeScreen < 3 && !bookedOnboardingCall) {
        if (isInTrial && (daysLeftInTrial || 0) < 5) {
            return <HeaderAlert style={{maxWidth: '50%'}} red>
                <strong>You have {daysLeftInTrial} days left in your trial!</strong> <button className="btn btn-primary btn-xs ms-5" onClick={() => navigate('/settings#billing')}>Upgrade now</button> <button onClick={toggleThenaWidget} className="btn btn-pliable btn-xs">Chat with us</button>
            </HeaderAlert>
        }
        
        if (isInTrial && !bookedOnboardingCall) {
            return <HeaderAlert style={{maxWidth: '50%'}}>
                <p className="mb-0"><strong>Have you booked your onboarding call?</strong>&nbsp;
                    Did you know you get a free 1-on-1 call with a Pliable data modeling expert? <Link to="/onboarding-call">Schedule call now</Link>
                </p>
                
            </HeaderAlert>
        }

        if (timesOnHomeScreen >= 3 && isInTrial) {
            return <HeaderAlert style={{maxWidth: '50%'}} red>
                <strong>You have {daysLeftInTrial} days left in your trial!</strong> <button className="btn btn-primary btn-xs ms-5" onClick={() => navigate('/settings#billing')}>Upgrade now</button> <button onClick={toggleThenaWidget} className="btn btn-pliable btn-xs">Chat with us</button>
            </HeaderAlert>
        }
        return <HeaderAlert style={{maxWidth: '50%'}}>
            <strong>Need some help?</strong> Click on the chat button <i className="mdi mdi-chat"/> at the top right of your screen to talk to a real live human.
        </HeaderAlert>
    }, [inOnboarding, isInTrial, bookedOnboardingCall, timesOnHomeScreen, daysLeftInTrial]);

    const isInDraft = useIsInDraftMode();

    const [timesOnHomeScreenInThisMode, setTimesOnHomeScreenInThisMode] = useState(0);

    const [showModeModal, setShowModeModal] = useState(false);

    const pliableVisualizationsEnabled = useFeatureFlag('pliable-visualizations', false);
    
    useEffect(() => {
        const key = isInDraft ? 'timesOnHomeScreenDraft' : 'timesOnHomeScreenProd';
        const currentTimes = localStorage.getItem(key);
        const parsed = currentTimes ? parseInt(currentTimes, 10) : 0; 
        if (currentTimes) {
            setTimesOnHomeScreenInThisMode(parsed);
        } else {
            setTimesOnHomeScreenInThisMode(0);
        }

        if (parsed == 1) {
            setShowModeModal(true);
        }

        localStorage.setItem(key, ((parsed || 0) + 1).toString());
    }, [isInDraft]);

    const { tab } = useParams<{tab: string}>();
    
    const activeTab = tab || 'sources';
    const setActiveTab = useCallback((tab: string) => {
        navigate(`/home/t/${tab}`);
    }, []);

    

    


   
    const top = <>
        <div className="bg-white border-bottom p-4 pt-2 pb-2">

            <div className="d-flex center-vertically">
                <DashboardIcon icon="mdi mdi-home" bgColor="blue"/>
                <h1 className="mb-0 flex-1">
                    Welcome, {authState?.user?.name}
                    
                </h1>
                
                {headerAlertContent}
                


            </div>
            {showOnboardingWizard && <>
                {hasSampleData && <section>
                    <p className="font-18">We've put some sample e-commerce data in your account.:</p>
                        <div className="row">
                            <div className="col-3">
                                <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['sources'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                        <div>
                                            <h5 className="mb-1">Check out data sources</h5>
                                            <div className=" lh-compact">
                                                Some stuff <Link to="/sources/snowflake">Go <i className="mdi mdi-arrow-right-bold"></i></Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                            <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['semantic_layer'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                        <div>
                                            <h5 className="mb-1">Check out the semantic layer</h5>
                                            <div className=" lh-compact">
                                                Entities and metrics make it easy to asdfasdf <Link to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['analysis'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                        <div>
                                            <h5 className="mb-1">Analysis</h5>
                                            <div className=" lh-compact">
                                                Run an analysis using your entities and metrics. <Link to="/analysis">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['build'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                        <div>
                                            <h5 className="mb-1">Run a build</h5>
                                            <div className=" lh-compact">
                                                Build build build <Link to="/reports">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                </section>}
                {!hasSampleData && <section>
                    <DraftOnly>
                        <p className="font-18">Now that you've connected to Snowflake, here are some things you should do to get started:</p>
                        <div className="row">
                            <div className="col-3">
                                <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['sources'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                        <div className="flex-1">
                                            <h5 className="mb-1">Connect a Data Source</h5>
                                            <div className=" font-13 lh-compact">
                                                Upload a file or connect directly to a Snowflake table.
                                            </div>
                                            
                                        </div>
                                        <div className="text-end">
                                            <Link className="btn btn-pliable btn-xs" to="/wizard/data-source">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                            <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['entities'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/> 
                                        <div className="flex-1">
                                            <h5 className="mb-1">Create an Entity</h5>
                                            <div className=" font-13 lh-compact">
                                                Entities represent core concepts in your data and are used to build reports.
                                            </div>
                                        </div>
                                        <div>
                                            <Link className="btn btn-pliable btn-xs" to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>                                       
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                            <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['metrics'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                        <div className="flex-1">
                                            <h5 className="mb-1">Create a Metric</h5>
                                            <div className=" font-13 lh-compact">
                                                Metrics are KPIs (key performance indicators) calculated using your Entities. 
                                            </div>
                                            
                                        </div>
                                        <div className="text-end">
                                            <Link className="btn btn-pliable btn-xs" to="/semantic-layer">Go <i className="mdi mdi-arrow-right-bold"></i></Link>                                                  
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                            <div className="shadow-box p-2 h-100">
                                    <div className="d-flex">
                                        <DashboardIcon bgColor={onboardingStepsComplete['analysis'] ? 'success' : 'secondary'} icon="mdi mdi-check-bold"/>
                                        <div className="flex-1">
                                            <h5 className="mb-1">Analysis</h5>
                                            <div className=" font-13 lh-compact">
                                               Run an analysis using your entities and metrics. No SQL required.
                                            </div>
                                            
                                        </div>
                                        <div className="text-end">
                                            <Link className="btn btn-pliable btn-xs" to="/analysis">Go <i className="mdi mdi-arrow-right-bold"></i></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DraftOnly>
                </section>}
            </>}
            </div>
    </>
    return <PageStructure>
        <TOSModal/>
        
        <Modal show={showModeModal} onHide={() => setShowModeModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    {isInDraft ? 'You are in Develop Mode' : 'You are in Production Mode'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    {isInDraft && <>
                        This is where you can make changes to your data model and reports. Changes you make here are not visible to end users until you publish to production and run a build.
                    </>}
                    {!isInDraft && <>
                        This is where you can view your reports and data models and explore your data. Production Mode is read-only. To make changes, switch to Develop Mode.
                    </>}
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-primary" onClick={() => setShowModeModal(false)}>Got it</button>
            </Modal.Footer>
        </Modal>
        <PageContent>
            <Pane>
                <PaneContent>
                    {showOnboardingWizard && top}

                        <div className="p-4">
                            <div className="row">
                                <div className="col-3">
                                    <div className="card">
                                        
                                        <NotificationsList
                                            onRunBuild={confirmRunBuild}
                                        />
                                    </div>
                                </div>
                                <div className="col-9">
                                    <Tabs
                                        activeKey={activeTab}
                                        onSelect={(k) => setActiveTab(k as string)}
                                    >
                                        <Tab eventKey="sources" title="Sources" className="pt-2">
                                            <div className="mb-2">
                                                Contact your account manager to add new data sources.
                                            </div>
                                            <SourcesListWidget/>
                                        </Tab>
                                        <Tab eventKey="cleaning" title="Cleaning" className="pt-2">
                                            
                                            <CleaningStepsListWidget/>
                                        </Tab>
                                        <Tab eventKey="entities" title="Entities" className="pt-2">
                                            
                                            
                                            <EntitiesListWidget/>
                                        </Tab>
                                        <Tab eventKey="metrics" title="Metrics" className="pt-2">
                                            
                                            <MetricsListWidget/>
                                        </Tab>
                                        <Tab eventKey="analysis" title="Analyses (Beta)" className="pt-2">
                                            
                                            <AnalysisListWidget/>
                                        </Tab>
                                        <Tab hidden={!pliableVisualizationsEnabled} eventKey="dashboards" title="Dashboards" className="pt-2">
                                            <DashboardsListWidget/>
                                        </Tab>
                                        
                                        <Tab eventKey="all" title="All Nodes" className="pt-2">
                                            
                                            <PipelineNodeList/>
                                        </Tab>
                                    </Tabs>
                                    
                                        
                                    
                                </div>
                                
                            </div>
                                
                        </div>

                </PaneContent>
            </Pane>
            
            
        </PageContent>
        
        
    </PageStructure>
}

export default HomePage;
