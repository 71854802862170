import Dropdown from "@components/form/Dropdown.component";
import PipelineNodeIcon from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import { DraftOnly, ProdOnly } from "@components/project/DraftModeRequired.component";
import { PipelineNode } from "@models/pipelineNode";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import toast from "@services/toast.service";
import { savePipelineNode, usePipelineNodes } from "@stores/data.store";
import { node } from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";



const CLEANING_STEP_OPTIONS = [{
    label: 'Start from scratch',
    value: 'STAGING',
    description: 'Start from a clean slate using our familiar mapping interface'
}, {
    label: 'DBT Model',
    value: 'CUSTOM',
    description: 'Write and execute custom DBT code right from Pliable',
}, {
    label: 'Special: SPLIT',
    value: 'SPLIT',
    description: 'Split records into multiple values based on a delimiter or JSON array',
}, {
    label: 'Use the cleaning wizard',
    value: 'WIZARD',
    description: 'Clean up source data using the Cleaning Wizard',
}];

const CleaningStepsListWidget = () => {
    const nodes = usePipelineNodes();
    const [search, setSearch] = useState('');
    
    const cleaningSteps = useMemo(() => {
        return (nodes.data?.filter(n => getGroupValueForNodeType(n.node_type) == 'STAGING' && n.label.toLowerCase().indexOf(search.toLowerCase()) >= 0) || []).sort((a, b) => a.name.localeCompare(b.name));
    }, [nodes.dataUpdatedAt, search]);

    const [showNewCleaningStepModal, setShowNewCleaningStepModal] = useState(false);
    const [selectedCleaningStepType, setSelectedCleaningStepType] = useState('NEW');
    const [selectedNodeToClean, setSelectedNodeToClean] = useState('');

    const [newCleaningStepName, setNewCleaningStepName] = useState('');
    
    const canClean = useMemo(() => {
        if (!selectedCleaningStepType || !newCleaningStepName) {
            return false;
        }

        if (selectedCleaningStepType == 'WIZARD' && !selectedNodeToClean) {
            return false;
        }
        return true;
    }, [selectedCleaningStepType, selectedNodeToClean, newCleaningStepName]);

    const navigate = useNavigate();
    const saveCleaningStep = useCallback(async () => {
        try {
        
            if (['STAGING', 'CUSTOM', 'SPLIT'].includes(selectedCleaningStepType)) {
                const newNodeData: PipelineNode = {
                    id: null,
                    node_type: selectedCleaningStepType,
                    name: newCleaningStepName,
                    label: newCleaningStepName,
                    description: '',
                    upstream_node_ids: [],
                    fields: [],
                    table_name: '',
                };
                const newNode = await savePipelineNode(newNodeData);
                navigate('/node/' + newNode.id + '/config');
            } else {
                navigate(`/wizard/cleaning/${selectedNodeToClean}`);
            }
            setShowNewCleaningStepModal(false);
            setNewCleaningStepName('');
            setSelectedCleaningStepType('NEW');
            setSelectedNodeToClean('');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, [selectedCleaningStepType, selectedNodeToClean, newCleaningStepName]);

    if (nodes.isFetchedAfterMount && cleaningSteps.length == 0) {
        return <>
            <DraftOnly>
                <div className="empty-state">
                    <h3 className="mb-0">You haven't created any cleaning steps yet.</h3>
                    <p>Pliable makes it easy to clean your data using our familiar mapping interface.</p>
                    <button className="btn btn-primary" onClick={() => {
                        setShowNewCleaningStepModal(true);
                    }}>
                        <i className="mdi mdi-plus-thick"></i> New Cleaning Step
                    </button>
                </div>
            </DraftOnly>
            <ProdOnly>
                <div className="empty-state">
                    <p>You are currently in <strong>Production Mode.</strong> To create cleaning steps, please enter Develop Mode.</p>

                </div>
            </ProdOnly>
        </>
    }
    return <>
        <Modal show={showNewCleaningStepModal} onHide={() => setShowNewCleaningStepModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    New Cleaning Step
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Name your cleaning step</Form.Label>
                    <Form.Control type="text" value={newCleaningStepName} onChange={(e) => setNewCleaningStepName(e.currentTarget.value)}/>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>How do you want to clean your data?</Form.Label>
                    <Dropdown
                        options={CLEANING_STEP_OPTIONS}
                        selected={selectedCleaningStepType}
                        onChange={(val) => setSelectedCleaningStepType(val)}
                    />
                </Form.Group>
                {selectedCleaningStepType == 'WIZARD' && <>
                    <Form.Group className="mb-3">
                        <Form.Label>Select table to clean</Form.Label>
                        <PipelineNodeSelector
                            selectedId={selectedNodeToClean}
                            onSelect={(node) => setSelectedNodeToClean(node ? node.id as string : '')}
                            optionFilter={(node) => node.node_type == 'SOURCE' || getGroupValueForNodeType(node.node_type) == 'STAGING'}
                        />
                    </Form.Group>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-light" onClick={() => setShowNewCleaningStepModal(false)}>Cancel</button>
                <button className="btn btn-primary" disabled={!canClean} onClick={saveCleaningStep}>Start cleaning</button>
            </Modal.Footer>
        </Modal>
        <div className="d-flex center-vertically mb-2">
            <input type="text" className="form-control input-rounded" style={{width: '50%'}} placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <div className="flex-1"></div>
            <DraftOnly>
                <button className="btn btn-outline-primary" onClick={() => {
                    setShowNewCleaningStepModal(true);
                }}>
                    <i className="mdi mdi-plus-thick"></i> New Cleaning Step
                </button>
            </DraftOnly>
            
        </div>
        <div className="list-group">
            {cleaningSteps.length == 0 && <div className="list-group-item">
                No cleaning steps found.
            </div>}
            {cleaningSteps.map((node, i) => {
                return <div key={node.id} className="list-group-item">
                    <div className="d-flex center-vertically">
                        <PipelineNodeIcon node={node} />
                        <div>
                            <h3 className="mb-0">{node.label}</h3>
                            <div className="font-poppins text-muted font-13">
                                <span><i className="mdi mdi-file-multiple"></i> {summarizeNumber(node.total_records || 0)} records</span>
                                <span className="ms-2 me-2">&bull;</span>
                                <span><i className="mdi mdi-clock"></i> Updated {timeAgo(node.last_build_completed)}
                                </span>
                            </div>
                        </div>
                        <div className="flex-1"></div>
                        <div>
                            <Link to={`/node/${node.id}`} className="btn btn-light btn-sm me-1"><i className="mdi mdi-table"></i> Data</Link>
                            <Link to={`/node/${node.id}/config`} className="btn btn-light btn-sm"><i className="mdi mdi-cog"></i> Configure</Link>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}

export default CleaningStepsListWidget;