import { useNavigate, useParams } from "react-router-dom";
import PageStructure, { PageContent, PageContentHeader, PageContentInner, PageSidebar, Pane, PaneContent } from "./PageStructure.component";
import { invalidateReportBuilderData, ReportBuilderDimensionJoinTree, ReportBuilderMeasureORM, useMeasure, useMeasureJoinPathOptions, useMeasures } from "@models/reportBuilder";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataWhitelist, PipelineNode, PipelineNodeMeasureJoinTree } from "@models/pipelineNode";
import { Badge, Form, Offcanvas, Tab, Tabs } from "react-bootstrap";
import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import PipelineNodeName from "@components/pipelineNodes/PipelineNodeName.component";
import PipelineNodeJoinTreeLabel from "@components/pipelineNodes/mapping/PipelineNodeJoinTreeLabel.component";
import { useImmer } from "use-immer";
import DataWhitelistForm from "@components/pipelineNodes/PipelineNodeDataWhitelist.component";
import { useRouteBlocker } from "@services/routing.service";
import SaveButton from "@components/button/SaveButton.component";
import { getPromptAnswer, prompt, requireConfirmation } from "@services/alert/alert.service";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import Warning from "@components/statusIndicators/Warning.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeColumnSelector from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import { useMissionControlDataFlowData, usePipelineNodes } from "@stores/data.store";
import SourceIcon from "@components/sources/SourceIcon.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { getAllRelatedNodeIds } from "@components/missionControl/dataflow/utils";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { getAggregationOptionsForFieldType } from "@services/metrics.service";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import HumanReadableWhitelist from '@components/pipelineNodes/HumanReadableWhitelist.component';

const joinPathLength = (node: PipelineNodeMeasureJoinTree): number => {
    let rv = 1;
    if (node.downstream) {
        node.downstream.forEach(d => {
            rv += joinPathLength(d);
        });
    }
    return rv;
}

const FORMAT_OPTIONS = [{
    label: 'Percent',
    value: 'PERCENT',
}, {
    label: 'Financial',
    value: 'FINANCIAL',
}, {
    label: 'Numeric',
    value: 'NUMERIC',
}]

interface JoinTreeWithSize {
    tree: PipelineNodeMeasureJoinTree;
    size: number;
}
const MetricDetailsPage = () => {
    const { metricId } = useParams();

    const allMeasures = useMeasures();
    const joinPathOptions = useMeasureJoinPathOptions(metricId as string);

    const joinPathOptionsByPipelineNodeId = useMemo(() => {
        const result: {[key: string]: PipelineNodeMeasureJoinTree[]} = {};

        joinPathOptions.data?.forEach(jpo => {
            const withSizes = jpo.possible_paths.map(jp => {
                return {
                    tree: jp,
                    size: joinPathLength(jp),
                };
            });
            result[jpo.pipeline_node_id] = withSizes.sort((a, b) => {
                return a.size - b.size;
            }).map(jp => jp.tree) || [];
        });
        return result;
    }, [joinPathOptions.dataUpdatedAt]);


    const upstreamMeasureOptions = useMemo(() => {
        return (allMeasures.data?.filter(m => m.id != metricId) || []).map(m => {
            return {
                label: m.name,
                value: m.id as string,
                description: m.description,
            }
        });
    }, [metricId, allMeasures.dataUpdatedAt]);



    const [name, setName] = useState('');
    const [columnHeader, setColumnHeader] = useState('');
    const [description, setDescription] = useState('');
    const [isCalculation, setIsCalculation] = useState(false);

    const [parentMetricIds, setParentMetricIds] = useState<string[]>([]);

    const [formula, setFormula] = useState('');
    const [aggregator, setAggregator] = useState('');
    const [formatter, setFormatter] = useState('');
    const [dataWhitelist, setDataWhitelist] = useImmer<DataWhitelist>({
        entries: [],
        logic_gate: 'AND',
    });
    const [dimensionJoinTrees, setDimensionJoinTrees] = useImmer<ReportBuilderDimensionJoinTree[]>([]);
    const [pipelineNodeId, setPipelineNodeId] = useState('');
    const [fieldId, setFieldId] = useState('');

    const measure = useMeasure(metricId as string);

    const graphData = useMissionControlDataFlowData();

    const nodeIdsForWhitelist = useMemo(() => {
        // Get all the pipeline node ids that are connected to the root node
        if (!graphData.data) {
            console.log('No graph data');
            return [];
        }

        const thisNode = graphData.data.nodes.find(n => n.id == 'PipelineNode:' + pipelineNodeId);

        if (!thisNode) {
            return;
        }
        const allRelatedNodeIds = getAllRelatedNodeIds(thisNode, graphData.data.nodes, graphData.data.edges, (nodeType: string) => {
            return getGroupValueForNodeType(nodeType) == 'DATA_MODELING';
        });

        return [pipelineNodeId].concat(allRelatedNodeIds);
    }, [graphData.dataUpdatedAt, pipelineNodeId]);

    const formulaVariables = useMemo(() => {
        return allMeasures.data?.filter(m => parentMetricIds.includes(m.id as string)).map(m => '"' + m.column_name + '"') || [];
    }, [parentMetricIds, allMeasures.dataUpdatedAt]);

    const disableDimension = useCallback((idx: number) => {
        setDimensionJoinTrees(draft => {
            draft[idx].ignore = true;
        });
        setPageDirty(true);
    }, []);

    const enableDimension = useCallback((idx: number) => {
        setDimensionJoinTrees(draft => {
            draft[idx].ignore = false;
        });
        setPageDirty(true);
    }, []);

    const saveMetric = useCallback(async () => {
        try {
            await ReportBuilderMeasureORM.save({
                id: metricId as string,
                name,
                column_name: columnHeader,
                description,
                is_calculation: isCalculation,
                parent_measure_ids: parentMetricIds,
                formula,
                aggregator,
                formatter,
                data_whitelist: dataWhitelist,
                dimension_join_trees: dimensionJoinTrees,
                pipeline_node_id: pipelineNodeId,
                field_id: fieldId,
            });
            toast('success', 'Success', 'Metric saved');
            invalidateReportBuilderData();
            setPageDirty(false);

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, [name, columnHeader, description, isCalculation, parentMetricIds, formula, aggregator, formatter, dataWhitelist, dimensionJoinTrees, pipelineNodeId, fieldId]);
    const { pageDirty, setPageDirty } = useRouteBlocker(saveMetric);

    const [activeTab, setActiveTab] = useState('basic');

    useEffect(() => {
        if (measure.data) {
            setName(measure.data.name);
            setColumnHeader(measure.data.column_name);
            setIsCalculation(!!measure.data.is_calculation);
            setFormula(measure.data.formula || '');
            setAggregator(measure.data.aggregator || '');
            setFormatter(measure.data.formatter || '');
            setDataWhitelist(measure.data.data_whitelist ? measure.data.data_whitelist : {entries: [], logic_gate: 'AND'});
            setDimensionJoinTrees(measure.data.dimension_join_trees || []);
            setPipelineNodeId(measure.data.pipeline_node_id || '');
            setFieldId(measure.data.field_id || '');
            setDescription(measure.data.description || '');
            setParentMetricIds(measure.data.parent_measure_ids || []);
        }
    }, [measure.dataUpdatedAt]);

    const [activeDimensionPipelineNodeId, setActiveDimensionPipelineNodeId] = useState('');

    const activeDimensionJoinTree = useMemo(() => {
        return dimensionJoinTrees.find(djt => djt.pipeline_node_id == activeDimensionPipelineNodeId);
    }, [activeDimensionPipelineNodeId, dimensionJoinTrees]);

    const onSelectActiveDimensionJoinPath = useCallback((pathIdx: number) => {
        setDimensionJoinTrees(draft => {
            draft.forEach(djt => {
                if (djt.pipeline_node_id == activeDimensionPipelineNodeId) {
                    djt.join_tree = joinPathOptionsByPipelineNodeId[activeDimensionPipelineNodeId]![pathIdx];
                }
            });
        });
        setActiveDimensionPipelineNodeId('');
        setPageDirty(true);
    }, [activeDimensionPipelineNodeId, dimensionJoinTrees, joinPathOptionsByPipelineNodeId]);

    const navigate = useNavigate();
    const copyMetric = useCallback(async () => {
        const newName = await getPromptAnswer('Enter a new name for the copied metric', 'Copy Metric', false, name + ' (Copy)');
        if (newName) {
            const result = await ReportBuilderMeasureORM.save({
                ...measure.data,
                id: null,
                name: newName,
                column_name: newName,
                description: 'Copy of ' + name,
            });
            invalidateReportBuilderData();
            navigate(`/metric/${result.id}`);
        }
    }, [name, measure.dataUpdatedAt])

    const deleteMetric = useCallback(async () => {
        const confirmed = await requireConfirmation('Are you sure you want to delete this metric?');
        if (confirmed) {
            await ReportBuilderMeasureORM.deleteById(metricId as string);
            invalidateReportBuilderData();
            navigate(`/`);
        }
    }, [metricId]);

    const pipelineNodes = usePipelineNodes();

    const sourceField = useMemo(() => {
        return pipelineNodes.data?.find(n => n.id == pipelineNodeId)?.fields.find(f => f.id == fieldId);
    }, [pipelineNodeId, fieldId, pipelineNodes.dataUpdatedAt]);

    // COPY PASTE ALERT from DataModelWizard
    const aggregationOptions = useMemo(() => {
        let fieldType: string;

        const selectedField = pipelineNodes.data?.find(n => n.id == pipelineNodeId)?.fields.find(f => f.id == fieldId);
        fieldType = selectedField?.type || 'STRING';
        
        return getAggregationOptionsForFieldType(fieldType);

    }, [fieldId, pipelineNodeId, pipelineNodes.dataUpdatedAt]);

    const highlightNodeIds = useMemo(() => {
        return [activeDimensionPipelineNodeId, pipelineNodeId].concat(dataWhitelist.entries.map(e => e.pipeline_node_id));
    }, [activeDimensionPipelineNodeId, pipelineNodeId, dataWhitelist])



    return <PageStructure>
        <Offcanvas placement="end" show={!!activeDimensionPipelineNodeId} onHide={() => {
            setActiveDimensionPipelineNodeId('');
        }}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Select Join Path</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Pane>
                    <PaneContent>
                        {activeDimensionJoinTree && <>
                            <div className="p-2">
                                <p>Select the join path for this metric to the selected dimension, and any filters you have set up.</p>
                                <div className="list-group">
                                    {joinPathOptionsByPipelineNodeId[activeDimensionPipelineNodeId]?.map((path, i) => {
                                        return <div className="list-group-item">
                                            <div className="d-flex center-vertically">
                                                <div className="flex-1">
                                                    <PipelineNodeJoinTreeLabel highlightNodeIds={highlightNodeIds} key={i} joinTree={path}/>
                                                </div>
                                                <div>
                                                    <button className="btn btn-primary btn-sm" onClick={() => {
                                                        onSelectActiveDimensionJoinPath(i);
                                                    }}>Select</button>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    })}
                                </div>
                                    
                            </div>
                        </>}
                        
                    </PaneContent>
                </Pane>
            </Offcanvas.Body>
        </Offcanvas>
        <PageContent>
            <PageContentHeader>
                <div className="d-flex center-vertically" style={{height: '100%'}}>
                    <div>
                        <DashboardIcon bgColor="success" icon="mdi mdi-pound"/>
                    </div>
                    <div className="flex-1">
                        <div className="small text-muted">Metric Details</div>
                        <h1 className="mb-0">{measure.data?.name}</h1>
                    </div>
                    <DraftOnly>
                        <button onClick={deleteMetric} className="btn btn-outline-danger btn-lg me-1">
                            <i className="mdi mdi-delete"></i> Delete Metric
                        </button>
                        <button onClick={copyMetric} className="btn btn-light btn-lg me-1">
                            <i className="mdi mdi-content-copy"></i> Copy Metric
                        </button>
                        <SaveButton
                            className="btn-lg"
                            disabled={!pageDirty}
                            onClick={saveMetric}
                        />
                    </DraftOnly>
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
            <Pane>
                <PaneContent>
                    <ReadOnly>
                        <div className="p-4">
                            <Tabs activeKey={activeTab} onSelect={(k) => setActiveTab(k as string)}>
                                <Tab eventKey="basic" title="Basic Information" className="pt-3">
                                    <div className="card">
                                        <div className="card-body">
                                            <h2>
                                                <i className="mdi mdi-information-outline"></i> Basic Information
                                            </h2>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Metric Name</Form.Label>
                                                <Form.Control className="w-100" value={name} onChange={(e) => {
                                                    setName(e.target.value);
                                                    setPageDirty(true);
                                                }}/>
                                                <Form.Text>Give your metric a name so it's easy to find.</Form.Text>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Column Header</Form.Label>
                                                <Form.Control className="w-100" value={columnHeader} onChange={(e) => {
                                                    setColumnHeader(e.target.value);
                                                    setPageDirty(true);
                                                }}/>
                                                <Form.Text>This is what will be used as the column header when this metric is in a report.</Form.Text>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control as="textarea" className="w-100" value={description} onChange={(e) => {
                                                    setDescription(e.target.value);
                                                    setPageDirty(true);
                                                }}/>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Display Format</Form.Label>
                                                <Dropdown
                                                    options={FORMAT_OPTIONS}
                                                    selected={formatter}
                                                    onChange={(v) => {
                                                        setFormatter(v);
                                                        setPageDirty(true);
                                                    }}
                                                />
                                                <Form.Text>This controls how your metric is displayed in reports and other analyses.</Form.Text>
                                            </Form.Group>
                                            
                                            
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="source" title="Calculation" className="pt-3">
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <Form.Group className="">
                                                <Form.Check type="switch" label="Calculate this metric using other metrics" checked={isCalculation} onChange={(e) => {
                                                    setIsCalculation(e.target.checked);
                                                    setPageDirty(true);
                                                }}/>
                                                <Form.Text>If enabled, this metric can use other metrics as part of a mathematical formula.</Form.Text>
                                            </Form.Group>
                                        </div>
                                    </div>
                                {isCalculation && <div className="card mb-3">
                                        <div className="card-body ">
                                            <h2>Calculation Details</h2>
                                            <Form.Group className="mb-3">
                                                <Form.Label>Calculation Dependencies</Form.Label>
                                                <MultiDropdown
                                                    options={upstreamMeasureOptions}
                                                    selected={parentMetricIds}
                                                    onChange={(newIds) => {
                                                        setPageDirty(true);
                                                        setParentMetricIds(newIds);
                                                    }}
                                                />
                                                <Form.Text>Which other metrics do you need to calculate this one?</Form.Text>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <div className="row">
                                                    <div className="col-8">
                                                        <Form.Label>Formula</Form.Label>
                                                        <Form.Control className="w-100 font-code" value={formula} as="textarea" onChange={(e) => {
                                                            setFormula(e.target.value);
                                                            setPageDirty(true);
                                                        }}/>
                                                        <Form.Text>You can use any Snowflake SQL here that can operate in a <code>SELECT</code> statement.</Form.Text>
                                                    </div>
                                                    <div className="col-4">
                                                        <Form.Label>Variables</Form.Label>
                                                        <div className="font-code">
                                                            {formulaVariables.join(', ')}
                                                        </div>
                                                    </div>
                                                </div>
                                                    
                                            </Form.Group>
                                        </div>
                                    </div>}
                                    {!isCalculation && <>
                                        <div className="card mb-3">
                                            <div className="card-body ">
                                                <h2>Connected Entity</h2>
                                                
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Select Entity</Form.Label>
                                                    <PipelineNodeSelector
                                                        selectedId={pipelineNodeId}
                                                        onSelect={(newNode: PipelineNode | undefined) => {
                                                            setPageDirty(true);
                                                            if (!newNode) {
                                                                setPipelineNodeId('');
                                                            } else {
                                                            
                                                                setPipelineNodeId(newNode.id as string);
                                                            }
                                                        }}
                                                        optionFilter={(pn) => {
                                                            return pn.node_type == 'DIMENSION';
                                                        }}
                                                    />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>
                                                        Select Column
                                                    </Form.Label>
                                                    <PipelineNodeColumnSelector
                                                        disabled={!pipelineNodeId}
                                                        pipelineNodeId={pipelineNodeId}
                                                        selectedId={fieldId}
                                                        onSelect={(newFieldId) => {
                                                            setPageDirty(true);
                                                            setFieldId(newFieldId);
                                                        }}
                                                        includeRecordId
                                                        excludeForeignKeys
                                                        
                                                    />
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label>Select Aggregator</Form.Label>
                                                    <Dropdown
                                                        disabled={!fieldId}
                                                        options={aggregationOptions}
                                                        selected={aggregator}
                                                        onChange={(v) => {
                                                            setAggregator(v);
                                                            setPageDirty(true);
                                                        }}
                                                    />
                                                    <Form.Text>How should Pliable calculate this metric?</Form.Text>
                                                    
                                                </Form.Group>
                                                    
                                            </div>
                                        </div>
                                        <div className="card">
                                            <div className="card-body">
                                                <h2 className="mb-0"><i className="mdi mdi-filter"></i> Filters (Optional)</h2>
                                                <p className="text-muted font-13">Only include <PipelineNodeName pipelineNodeId={pipelineNodeId}/> records that match these rules. You can select columns from <PipelineNodeName pipelineNodeId={pipelineNodeId}/> as well as any connected entities.</p>
                                                <DataWhitelistForm config={dataWhitelist} onChange={(newWhitelist) => {
                                                    setPageDirty(true);
                                                    setDataWhitelist(newWhitelist);
                                                }} nodeIds={nodeIdsForWhitelist}/>
                                            </div>
                                        </div>
                                    </>}
                                    
                                </Tab>
                                {!isCalculation && <Tab eventKey="dimensions" title={`Dimension Connections (${dimensionJoinTrees.length})`} className="pt-3">
                               
                            <div className="card">
                                <div className="card-body">
                                    <h2 className="mb-0">Dimension Connections</h2>
                                    <p className="text-muted font-13">Tell Pliable how this metric should be connected to all of your available dimensions. This controls the "joins" generated by Pliable's report builder. In many cases there is only one option, but oftentimes there are multiple paths to connect one object to another.</p>
                                    <DraftOnly>
                                        {dimensionJoinTrees.length == 0 && <Warning>
                                            This metric is not connected to any entities! Select an entity in the "Calculation" tab and save the metric to load available dimensions.
                                        </Warning>}
                                    </DraftOnly>
                                    <table className="table table-bordered table-centered table-fixed">
                                        <thead className="bg-light">
                                            <tr>
                                                <th>Entity</th>
                                                <th>Join Path</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {dimensionJoinTrees.map((djt, i) => {
                                                const possiblePaths = joinPathOptionsByPipelineNodeId[djt.pipeline_node_id];
                                                return <tr key={i}>
                                                    <th><PipelineNodeName pipelineNodeId={djt.pipeline_node_id}/></th>
                                                    <td>
                                                        {!djt.ignore && djt.join_tree && (!possiblePaths?.length || possiblePaths.length > 1) && <>
                                                            <Badge className="clickable mb-1" bg="primary" onClick={() => {
                                                                setActiveDimensionPipelineNodeId(djt.pipeline_node_id);
                                                            }}>{possiblePaths?.length} Options</Badge>
                                                            
                                                        </>}
                                                        {djt.ignore && <Badge bg="secondary">Disabled</Badge>}
                                                        {!djt.ignore && djt.join_tree && <>
                                                            <PipelineNodeJoinTreeLabel joinTree={djt.join_tree}
                                                                highlightNodeIds={[djt.pipeline_node_id, pipelineNodeId, ...dataWhitelist.entries.map(e => e.pipeline_node_id)]}
                                                            />
                                                        </>}
                                                        {!djt.ignore && !djt.join_tree && <>
                                                            <Badge className="me-1 clickable" onClick={() => {
                                                                setActiveDimensionPipelineNodeId(djt.pipeline_node_id);
                                                            }} bg="danger">None ({possiblePaths?.length} Options)</Badge>
                                                        </>}
                                                        
                                                        
                                                    </td>
                                                    <td className="text-end">
                                                        {djt.ignore && <>
                                                            <button className="btn btn-outline-primary btn-sm me-1" onClick={() => {
                                                                enableDimension(i);
                                                            }}>
                                                                <i className="mdi mdi-eye-outline"></i> Enable
                                                            </button>
                                                        </>}
                                                        {!djt.ignore && <>
                                                            <button className="btn btn-outline-secondary btn-sm me-1" onClick={() => {
                                                                disableDimension(i);
                                                            }}>
                                                                <i className="mdi mdi-eye-off-outline"></i> Disable
                                                            </button>
                                                        </>}
                                                        
                                                        
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                                </Tab>}
                            </Tabs>
                            
                                                    
                                                    
                                                    
                            
                        </div>
                    </ReadOnly>
                </PaneContent>
            </Pane>
            </PageContentInner>
            
        </PageContent>
        
    </PageStructure>
}

    export default MetricDetailsPage;
