import ApiService, { JobEnqueueResponse, ListRecordsResponse, SingleRecordResponse } from '@services/api/api.service';
import ORM, { BaseModel } from "./orm";
import BackgroundService from '@services/bg.service';
import { parseDateString } from '@services/time.service';
import { ColumnRef, TableShape } from './shared';
import { useSourceRecordTypes } from '@stores/data.store';
import { useCallback, useMemo } from 'react';
import { useQuery } from 'react-query';
import { ExecutionSchedule } from "@models/executionSchedule";

export interface FivetranConnectorStatus {
    is_historical_sync: boolean;
    schema_status: string;
    setup_state: string;
    sync_state: string;
}

export interface FivetranConnector {
    status: FivetranConnectorStatus;
    paused: boolean;
}

export interface FivetranSourceConnector {
    connector: FivetranConnector;
    connector_config: any;
    first_sync_complete: boolean;
    last_sync_completed_at: string;
    paused: boolean;
    most_recent_test_results: {
        [key: string]: any;
    }
    setup_tests_succeeded: boolean;
    api_error?: {
        [key: string]: any;
    }
}


export enum SyncStatus { 
    Idle = "IDLE",
    Queued = "QUEUED",
    Syncing = "SYNCING"
} 

export interface ConnectorSyncInfo {
    first_sync_complete: boolean;
    last_sync_completed_at: string;
    status: SyncStatus;
    sync_schedule: ExecutionSchedule;
}


export interface Source extends BaseModel{
    name: string;
    type: string;
    description?: string;
    connector_name?: string;
    config?: any;
    secureConfig?: any;
    last_loaded?: Date | null;
    fivetran?: FivetranSourceConnector;
    sync_info?: ConnectorSyncInfo;
    oauth_last_authorized?: string;
}



export interface Identifier {
    reference: 'SELF'|'RELATED';
    column_ref: ColumnRef;
    related_source_record_type_id?: string;
    related_source_record_type_column?: ColumnRef;
}

export interface ColumnPreference {
    key: string;
    visible: boolean;
    default_value: string;
    static_value: string;
    rename: string;
    is_static?: boolean;
    is_key?: boolean;
    description?: string;
}

export interface SourceRecordTypeColumn {
    key: string;
    label: string;
    is_system_field: boolean;
    is_synthetic_field: boolean;
}


export interface SourceRecordType extends BaseModel {
    name: string;
    source_id: string;
    description: string;
    resource_name: string;
    column_preferences: ColumnPreference[];
    composite_key?: string[];
    last_modified_column?: string;
    identifiers?: Identifier[];
    shape?: TableShape; 
    image_url?: string;
    total_records?: number;
    last_build_completed?: string;
    columns?: SourceRecordTypeColumn[]; 

    columns_to_import?: string[];
}


export interface SourceRecordTypeColumnOption {
    value: string;
    label: string;
}

export interface SourceRecordTypeOption extends BaseModel {
    type: string;
    name: string;
    label: string;
    columns: SourceRecordTypeColumnOption[];
    default_primary_key?: string[];
}

export interface ConfigField {
    field_type: string;
    label: string;
    name: string;
    description: string;
}

export interface RecordTypeOptionResponse {
    type: string;
    label: string;
    name: string;
    columns: {
        value: string;
        label: string;
    }[];
    default_primary_key: string[];
}


export interface SourceType {
    id: string;
    title: string;
    provider: string;
    description: string;
    icon_path: string;
    // config_fields: ConfigField[];
    // secure_config_fields: ConfigField[];
    // category: 'file_upload'|'api'|'form'|'snowflake_table'|'connector';
    oauth: boolean;
    exposed_record_types?: RecordTypeOptionResponse[];
    additional_config?: ConfigField[];
    additional_secret_config?: ConfigField[];

}

// @deprecated
export interface SourceDataRelationshipRef {
    source_id: string;
    source_record_type_id: string;
    column_ref: ColumnRef;
}

// @deprecated
export interface SourceDataRelationship extends BaseModel {
    name: string;
    references: SourceDataRelationshipRef[];
}

export interface SourceDataConnection extends BaseModel {
    srt1_id: string;
    srt2_id: string;
    srt1_column: string;
    srt2_column: string;
}

export interface FivetranConnectorCard extends BaseModel {
    connect_card_url: string;
}

export interface ConnectorAuth extends BaseModel {
    authorization_url: string;
}

export interface ShareableAuth extends BaseModel {
    shareable_auth_url: string;
}

interface RecordTypeOptionsResponse {
    records?: SourceRecordTypeOption[];
    job_id: string;
}



export class _SourceORM extends ORM<Source>{

    public async getEnabledSourceTypes() {
        const result = await this.apiService.request('GET', `/${this.endpoint}/source-types`) as ListRecordsResponse<SourceType>;
        return result.records;
    }

    public async getConnectorCatalog(sourceId: string, forceSchemaReload: boolean = false) {
        const sourceRecordTypeOptionsResp = await this.apiService.request('GET', `/sources/${sourceId}/record-type-options`, 
            {
                force_schema_reload: forceSchemaReload
            }
        ) as RecordTypeOptionsResponse;

        if (sourceRecordTypeOptionsResp.records) {
            return sourceRecordTypeOptionsResp.records;
        }

        const jobResults = await BackgroundService.getInstance().waitForJob(sourceRecordTypeOptionsResp.job_id);

        return jobResults.record_type_options;
        
    }

    public async init(sourceId: string, postData: any) {
        const initResult = await this.apiService.request('POST', `/sources/${sourceId}/init`, postData) as JobEnqueueResponse;
        const jobResults = await BackgroundService.getInstance().waitForJob(initResult.job_id);
        return jobResults;
        
    }

    public async recalculateRecordTypeShape(sourceId: string, sourceRecordTypeId: string) {
        const job = await this.apiService.request('POST', `/sources/${sourceId}/record-type/${sourceRecordTypeId}/shape`) as JobEnqueueResponse;
        const jobResult = await BackgroundService.getInstance().waitForJob(job.job_id);
    }

    public async getConnectorCardUrl(sourceId: string, return_path: string = ''){
        const connectorCardResp = await this.apiService.request('POST', `/sources/${sourceId}/pbf-connector-card`, {return_path}) as JobEnqueueResponse;
        const jobResult = await BackgroundService.getInstance().waitForJob(connectorCardResp.job_id);
        return jobResult as FivetranConnectorCard;
    }

    public async upsertConnectorForType(sourceType: string, params: any){
        const connectorUpsertResp = await this.apiService.request('POST', `/sources/connectors/${sourceType}/upsert`, params) as SingleRecordResponse<any>;
        return connectorUpsertResp.record;
    }

    public async getConnectorAuthorizationUrlForType(sourceType: string, sourceId: string, return_path: string = ''){
        const connectorAuthResp = await this.apiService.request('GET', `/sources/connectors/${sourceType}/authorization-url`, {state: sourceId}) as SingleRecordResponse<ConnectorAuth>;
        return connectorAuthResp.record;
    }

    public async getConnectorAuthorizationUrlSource(sourceId: string, return_path: string = ''){
        const connectorAuthResp = await this.apiService.request('GET', `/sources/connectors/source/${sourceId}/authorization-url`) as SingleRecordResponse<ConnectorAuth>;
        return connectorAuthResp.record;
    }

    public async createShareableAuthorizationUrl(sourceId: string){
        const connectorAuthResp = await this.apiService.request('POST', `/sources/connectors/source/${sourceId}/shareable-authorization-url`, {}) as SingleRecordResponse<ShareableAuth>;
        return connectorAuthResp.record;
    }

    public async toggleSync(sourceId: string, paused: boolean){
        await this.apiService.request('POST', `/sources/${sourceId}/enable-sync`, {paused});
    }

    public async triggerManualSync(sourceId: string){
        await this.apiService.request('POST', `/sources/${sourceId}/trigger-sync`, {});
    }
}
const SourceORM = new _SourceORM('sources', ['last_loaded']);
export default SourceORM;

class _SourceRecordTypeORM extends ORM<SourceRecordType>{
    public convertDates(record: SourceRecordType) {
        const rv = super.convertDates(record);
        if (rv.shape) {
            // @ts-ignore
            rv.shape.updated_at = parseDateString(rv.shape.updated_at);
        }
        return rv;
    }
}

export const SourceRecordTypeORM = new _SourceRecordTypeORM('sourcerecordtypes');

export const SourceDataRelationshipORM = new ORM<SourceDataRelationship>('sourcedatarelationships');

export const SourceDataConnectionORM = new ORM<SourceDataConnection>('sourcedataconnections');


export function useColumnLabels() {
    const sourceRecordTypes = useSourceRecordTypes();

    return useMemo(() => {
        // We need to keep track of what columns are ACTUALLY called so we can show the correct names in the mapping. Underneath we're still using the keys so this is just for display.
        if (!sourceRecordTypes.data) {
            return {};
        }

        const labels: {
            [key: string]: {
                [key: string]: string;
            }
        } = {};

        sourceRecordTypes.data.forEach(srt => {
            const theseLabels: {
                [key: string]: string;
            } = {}

            srt.columns!.forEach(c => {
                theseLabels[c.key] = c.label;
            });

            labels[srt.id as string] = theseLabels;
        });

        return labels;
    }, [sourceRecordTypes.dataUpdatedAt]);
}

export function useColumnLabeler() {
    const labels = useColumnLabels();

    return useCallback((sourceRecordTypeId: string, columnKey: string) => {
        if (sourceRecordTypeId in labels && columnKey in labels[sourceRecordTypeId]) {
            return labels[sourceRecordTypeId][columnKey];
        }

        return '<NO LABEL>';
    }, [labels]);
}