import AsyncButton from "@components/button/AsyncButton.component";
import SaveButton from "@components/button/SaveButton.component";
import GithubConnectorConfig from "@components/connectors/GithubConnectorConfig.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import SourceORM, { ConfigField, RecordTypeOptionResponse, SourceRecordType, SyncStatus } from "@models/source";
import ApiService, { JobEnqueueResponse, SingleRecordResponse } from "@services/api/api.service";
import BackgroundService from "@services/bg.service";
import { invalidateEverything, useDataLoadsForSource, usePipelineNodes, useSourceRecordTypes, useSourceSetupScript, useSourceTypeConfig } from "@stores/data.store";
import { useSource } from "@stores/sources.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Col, Dropdown, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import styled from 'styled-components';
import { useImmer } from "use-immer";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "./PageStructure.component";
import RecordInfo from "@components/card/RecordInfo.component";
import { SidebarNodeList } from "@components/nav/DagDataLibrary.component";
import { atomOneLight, CopyBlock } from "react-code-blocks";
import toast from "@services/toast.service";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import DataLoadList from "@components/dataload/DataLoadList.component";
import { getErrorMessage } from "@services/errors.service";
import CronScheduleSelect from "@components/scheduler/CronSchedulerSelect.component";
import { closeWaiting, requireConfirmation, shareText, showWaiting } from "@services/alert/alert.service";
import NotFound from "@components/alert/NotFound.component";
import { FullPageLoader } from "@components/loaders/FullPageLoader.component";


interface RecordTypeOptionSettings {
    enabled: boolean;
    primaryKey: string[];
    recordTypeInfo: RecordTypeOptionResponse;
}

interface RecordTypeOptionProps {
    rto: RecordTypeOptionResponse;
    enabled?: boolean;
    primaryKey?: string[];
    onToggle?: (val: boolean) => void;
}

const RecordTypeOptionStyles = styled.div`
display: flex;



.switch {
    width: 100px;
}

.label {
    flex: 1;
    font-weight: 600;
    font-family: "Poppins";
}

.primary-key {
    width: 250px;
}

&.disabled .label {
    color: var(--ct-text-muted) !important;
}
`


const Styles = styled.div`

.btn-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: right;
    gap: 15px;
    width: 100%;
    align-items: center;
}
`


interface ConfigFieldProps {
    onChange: (newVal: string) => any;
    configField: ConfigField;
    secure?: boolean;
    value: string;
}

const ConfigFieldEditor = (props: ConfigFieldProps) => {
    if (props.configField.field_type == 'LONG_TEXT') {
        return <div className="col-md-12"><Form.Group className="mb-2">
            <Form.Label>{props.configField.label}</Form.Label>
            <Form.Control as="textarea" onChange={(e) => {
                props.onChange(e.target.value)
            }} value={props.value}/>
        </Form.Group></div>
    }

    return <div className="col-md-6"><Form.Group className="mb-2">
        <Form.Label>{props.configField.label}</Form.Label>
        <Form.Control type={(props.secure) ? 'password': 'text'} onChange={(e) => {
            props.onChange(e.target.value)
        }} value={props.value}/>
    </Form.Group> </div>
}


const RecordTypeOption = (props: RecordTypeOptionProps) => {
    return <RecordTypeOptionStyles className={props.enabled ? 'enabled' : 'disabled'}>
        <div className="switch">
            <Form.Check
                type="switch"
                label=""
                checked={!!props.enabled}
                onChange={(e) => {
                    if (props.onToggle) {
                        props.onToggle!(!props.enabled);
                    }
                }}
            />
        </div>
        <div className="label">
            {props.rto.label}
        </div>
    </RecordTypeOptionStyles>
}

const SourceConfigPage = () => {
    const navigate = useNavigate();
    const [query, setQuery] = useSearchParams();
    const {sourceId} = useParams();

    const source = useSource(sourceId as string);

    const [triggeringSync, setTriggeringSync] = useState(false);

    const {refetch: refetchDataloads} = useDataLoadsForSource(sourceId as string);

    const sourceRecordTypes = useSourceRecordTypes(sourceId as string);
    const sourceTypeConfig = useSourceTypeConfig(source.data ? source.data.type : '');
    const sourceSetupScript = useSourceSetupScript(source.data ? source.data.id as string: '');

    const refreshSourceFivetranStatus = useCallback(async () => {
        const result = await ApiService.getInstance().request('GET', `/sources/${sourceId}/refresh`)
        source.refetch();
    }, [sourceId]);

    const [rtoSettings, setRtoSettings] = useImmer<{
        [key: string]: RecordTypeOptionSettings,
    }>({});

    const [config, setConfig] = useImmer<{
        [key: string]: any
    }>({});

    const [secretConfig, setSecretConfig] = useImmer<{
        [key: string]: any
    }>({});

    const [syncCronExpression, setSyncCronExpression] = useState('');

    useEffect(() => {
        if (query.get('authorized') == 'true' && config && Object.keys(config).length > 0) {
            toast('success', 'Success', 'Authorization successful.');
            setQuery({});
            // need to wait for config to be set
            save();
            return;
        }

        if (query.get('error') == 'authorization_error') {
            toast('error', 'Error', 'Error authorizing connection.');
            setQuery({});
            return;
        }
        
    }, [query, config]);

    useEffect(() => {
        if (source.data) {
            setConfig(source.data.config);

            if (source.data.sync_info && source.data.sync_info.sync_schedule.cron_expression) {
                setSyncCronExpression(source.data.sync_info.sync_schedule.cron_expression);
            }
        }
    }, [source.dataUpdatedAt]);

    useEffect(() => {
        if (!sourceTypeConfig.data || !sourceRecordTypes.data || !sourceTypeConfig.data || !sourceTypeConfig.data.exposed_record_types) {
            return;
        }

        setRtoSettings(draft => {
            sourceTypeConfig.data!.exposed_record_types?.forEach((rt: RecordTypeOptionResponse) => {
                // Is there an existing record type?
                const existing = sourceRecordTypes.data.find(srt => srt.resource_name === rt.name);

                draft[rt.name] = {
                    enabled: !!existing,
                    primaryKey: rt.default_primary_key,
                    recordTypeInfo: rt,
                }
            });
        });
    }, [sourceTypeConfig.dataUpdatedAt, sourceRecordTypes.dataUpdatedAt]);

    const isSyncing = useMemo(() => {
        return [SyncStatus.Queued, SyncStatus.Syncing].includes(source.data?.sync_info?.status as SyncStatus);
    }, [source.dataUpdatedAt]);

    const syncButtonText = useMemo(() => {
        if (triggeringSync) {
            return 'Queuing';
        }else if (source.data?.sync_info?.status == SyncStatus.Queued) {
            return 'Queued';
        }else if (source.data?.sync_info?.status == SyncStatus.Syncing) {
            return 'Syncing';
        }else{
            return 'Sync Now';
        }
    }, [triggeringSync, source.dataUpdatedAt]);

    const [maxPollingDuration, setMaxPollingDuration] = useState(7200000); // 2 hours in milliseconds
    const [pollingStartTime, setPollingStartTime] = useState<number | null>(null);
    
    // Configuration for exponential backoff to track sync 
    const INITIAL_INTERVAL = 5000; // Start with 5 seconds
    const MAX_INTERVAL = 30000; // Max of 30 seconds
    const BACKOFF_RATE = 1.5; // Each interval will be 1.5x longer
    const [currentInterval, setCurrentInterval] = useState(INITIAL_INTERVAL);
    
    // useEffect to handle the polling for sync updates with exponential backoff
    useEffect(() => {
        let timeoutId: NodeJS.Timeout | null = null;
    
        const pollForUpdates = async () => {
            if (!pollingStartTime || !isSyncing) {
                return;
            }
    
            const currentTime = Date.now();
            if (currentTime - pollingStartTime > maxPollingDuration) {
                // Stop polling if we've exceeded the max duration
                setPollingStartTime(null);
                setCurrentInterval(INITIAL_INTERVAL); // Reset interval for next time
                return;
            }
    
            // Refresh the data
            await source.refetch();
            await refetchDataloads();
            await nodes.refetch();
    
            // Calculate next interval with exponential backoff
            const nextInterval = Math.min(currentInterval * BACKOFF_RATE, MAX_INTERVAL);
            setCurrentInterval(nextInterval);
    
            // Schedule next poll
            timeoutId = setTimeout(pollForUpdates, nextInterval);
        };
    
        if (isSyncing && pollingStartTime) {
            // Initial poll
            timeoutId = setTimeout(pollForUpdates, currentInterval);
        } else {
            // Reset interval when sync stops
            setCurrentInterval(INITIAL_INTERVAL);
        }
    
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [isSyncing, pollingStartTime, currentInterval, maxPollingDuration]);
    
    const triggerManualSync = useCallback(async () => {
        setTriggeringSync(true);
        try {
            await SourceORM.triggerManualSync(sourceId as string);
            // Reset interval and start polling
            setCurrentInterval(INITIAL_INTERVAL);
            setPollingStartTime(Date.now());
        } catch (error) {
            toast('danger', 'Error', getErrorMessage(error));
        } finally {
            setTriggeringSync(false);
            // Initial data refresh
            source.refetch();
            refetchDataloads();
        }
    }, [sourceId]);

    const changeConfig = useCallback((key: string, val: any) => {
        setConfig(draft => {
            draft[key] = val;
        });
    }, []);

    const changeSecretConfig = useCallback((key: string, val: any) => {
        setSecretConfig(draft => {
            draft[key] = val;
        });
    }, []);

    const isPaused = useMemo(() => {
        if (source.data?.fivetran) {
            return !!source.data?.fivetran?.connector.paused
        }else{
            return !source.data?.sync_info?.sync_schedule.enabled;
        }
    }, [source.dataUpdatedAt]);

    const save = useCallback(async () => {
        const srts = Object.keys(rtoSettings).map(k => {
            if (rtoSettings[k].enabled) {
                return {
                    composite_key: rtoSettings[k].primaryKey,
                    resource_name: k,
                    source_id: '',
                    name: rtoSettings[k].recordTypeInfo.label,
                };
            }
            return null;
            
        }).filter(d => !!d);
        try {
            let hasSecureConfig = false;
            Object.keys(secretConfig).forEach(k => {
                if (!!secretConfig[k]) {
                    hasSecureConfig = true;
                }
            })
            const result = await ApiService.getInstance().request('POST', `/sources/${sourceId}/init`, {
                source_record_types: srts,
                config: config,
                secure_config: hasSecureConfig ? secretConfig : null,
                sync_info: {
                    sync_schedule: {
                        cron_expression: syncCronExpression
                    }
                }
            }) as JobEnqueueResponse;
    
            const jobResult = await BackgroundService.getInstance().waitForJob(result.job_id);
            console.log(jobResult);

        } catch (err) {
            console.error(err);
        } finally {
            source.refetch();

        }

    }, [source.dataUpdatedAt, config, secretConfig, rtoSettings, syncCronExpression]);

    const [pausing, setPausing] = useState(false);
    const togglePause = useCallback(async () => {
        if (!source.data) {
            return;
        }
        setPausing(true);
        await SourceORM.toggleSync(source.data.id as string, !isPaused)
        setPausing(false);

        source.refetch();
    }, [source.dataUpdatedAt, sourceId, isPaused])

    const checkForRefresh = useCallback(() => {
        console.log('Checking for refresh', source.data?.fivetran?.connector.status.sync_state);
        if (source.data && (['scheduled', 'syncing'].includes(source.data.fivetran?.connector.status.sync_state as string))) {
            refreshSourceFivetranStatus();
        }
    }, [refreshSourceFivetranStatus, source.dataUpdatedAt]);

    useEffect(() => {
        const interval = setInterval(() => {
            checkForRefresh();
        }, 5000)
        
        return () => {
            clearInterval(interval);
        }
    }, []);

    const deleteSource = useCallback(async () => {
        const confirmed = await requireConfirmation(`Are you sure you want to delete this source and all its data? Type out the full source name ("${source.data?.name}") to continue.`, 'Delete Source', 'Delete', 'Cancel', source.data?.name);
        if (confirmed) {
            showWaiting({message: `Deleting Source ${source.data?.name}...`});
            await SourceORM.deleteById(sourceId as string);
            invalidateEverything();
            closeWaiting();
            navigate('/');
            toast('success', 'Source deleted', 'Source deleted successfully.');
        }
    }, [sourceId, source.dataUpdatedAt]);


    const [loadingConnectorCard, setLoadingConnectorCard] = useState(false);

    const startConnectorCardFlow = useCallback(async () => {
        setLoadingConnectorCard(true);
        const resp = (await SourceORM.getConnectorCardUrl(sourceId as string, '/'));
        window.location.href = resp.connect_card_url;
        setLoadingConnectorCard(false);
    }, [sourceId]);

    const startAuthorizationFlow = useCallback(async () => {
        setLoadingConnectorCard(true);
        await save();
        const resp = (await SourceORM.getConnectorAuthorizationUrlSource(sourceId as string, '/'));
        window.location.href = resp.authorization_url;
        setLoadingConnectorCard(false);
    }, [sourceId, source.data, save]);

    const [loadingShareableLink, setLoadingShareableLink] = useState(false);
    const [showShareableLinkModal, setShowShareableLinkModal] = useState(false);
    const [shareableLink, setShareableLink] = useState('');

    const generateShareableLink = useCallback(async () => {
        setLoadingShareableLink(true);
        setShowShareableLinkModal(true);
        // auto save
        await save();
        const resp = await SourceORM.createShareableAuthorizationUrl(sourceId as string);
        setShareableLink(resp.shareable_auth_url);
        console.log(resp.shareable_auth_url);
        shareText({
            onClose: () => { },
            header: 'Shareable Authorization Link',
            label: 'Authorization Link',
            text: "\n" +resp.shareable_auth_url,
        })
        setLoadingShareableLink(false);

    }, [sourceId, source.data, save]);

    const nodes = usePipelineNodes();

    const nodesInThisSource = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => n.source_id == sourceId);
    }, [nodes.dataUpdatedAt, sourceId])

    if (source.isLoading || sourceTypeConfig.isLoading) {
        return <FullPageLoader />
    }

    let innerContent = <div>An error occurred</div>;

    if (source.isError || source.data == null) {
        let errMesaage = <div>An error occurred</div>;
        const httpError = source.error as { code: number } ;
        // Check if it's a 404 error
        if (httpError && httpError.code === 404) {
            errMesaage = <NotFound resourceName="Source" />;
        }
        
        // Handle other errors
        innerContent = <PageContent>
            <Pane>
                <PaneContent>
                    {errMesaage}
                </PaneContent>
            </Pane>
        </PageContent>

    }else if (!sourceTypeConfig.data) {
        return <div>Missing Resources</div>;
    }else{
        innerContent = (<>
        <PageSidebar>
            <Pane>
                <PaneContent>
                    <div className="p-3">
                        <RecordInfo
                            title={source.data.name}
                            description={""}
                            stats={[]}
                            iconComponent={<img src={sourceTypeConfig.data.icon_path}/>}
                            badges={<>
                                {source.data.fivetran?.connector.status.setup_state == 'connected' && (
                                    <Badge
                                        pill
                                        bg="success"
                                    >Connected</Badge>
                                )}

                                {source.data.fivetran?.connector.status.sync_state == 'syncing' && (
                                    <Badge
                                        pill
                                        bg="info"
                                    >
                                        <i className="mdi mdi-loading mdi-spin"></i> Syncing
                                    </Badge>
                                )}
                            </>}
                            showIcon
                        />
                        <hr />
                        {nodesInThisSource.length == 0 && <>
                            <></>
                        </>}
                        <SidebarNodeList nodes={nodesInThisSource}/>
                    </div>
                </PaneContent>
            </Pane>
        </PageSidebar>
        <PageContent>
        
            <Pane>
                
                <PaneContent>
                    <Styles>
                    
                    
                    <div className="p-3">
                        <div className="btn-actions mb-3">
                            <button onClick={deleteSource} className="btn btn-outline-danger me-1">
                                <i className="mdi mdi-delete"></i> Delete Source
                            </button>
                            {sourceTypeConfig.data.provider == 'meltano' && <AsyncButton
                                icon={`mdi mdi-reload`}
                                variant={(triggeringSync || isSyncing) ? 'success' : 'light'}
                                disabled={triggeringSync}
                                onClick={triggerManualSync}
                                text={syncButtonText}
                                loading={triggeringSync || isSyncing}
                                testId="trigger-manual-sync"
                            />}
                            <SaveButton onClick={save}/>
                            
                        </div>
                        {sourceTypeConfig.data.provider == 'fivetran' && <>
                            <h2>Configuration</h2>
                            <InfoAlert>
                                <div>
                                    This data source is managed by our partners at Fivetran. Please contact your Pliable account rep if you'd like to make changes to the configuration.
                                </div>
                            </InfoAlert>
                            
                            <AsyncButton
                                loading={loadingConnectorCard}
                                text="Authorize Connection"
                                className="btn-pliable" 
                                onClick={() => startConnectorCardFlow()}
                            />
                        </>}
                        {sourceTypeConfig.data.provider == 'pliable' && <>
                            {!sourceTypeConfig.data.additional_config && !sourceTypeConfig.data.additional_secret_config && <>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        No configuration needed!
                                    </div>
                                </div>
                            </>}
                            {(sourceTypeConfig.data.additional_config || []).length > 0 && <>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h2>Configuration</h2>
                                        {sourceTypeConfig.data.additional_config?.map(c => {
                                            return <div className="col-md-6"><ConfigFieldEditor
                                                onChange={(newVal: string) => {
                                                    changeConfig(c.name, newVal);
                                                }}
                                                value={config.hasOwnProperty(c.name) ? config[c.name] : ''}
                                                configField={c}
                                            /> </div>
                                        })}
                                    </div>
                                </div>
                                
                            </>}
                            {(sourceTypeConfig.data.additional_secret_config || []).length > 0 && <>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h2>Secure Configuration</h2>
                                        <p>This data is stored securely and cannot be displayed. You can change the values by entering in new data here.</p>
                                        {sourceTypeConfig.data.additional_secret_config?.map(c => {
                                            return <ConfigFieldEditor
                                                onChange={(newVal: string) => {
                                                    changeSecretConfig(c.name, newVal);
                                                }}
                                                value={secretConfig.hasOwnProperty(c.name) ? secretConfig[c.name] : ''}
                                                configField={c}
                                            />
                                        })}
                                    </div>
                                </div>
                                
                            </>}

                            {!!sourceSetupScript.data && <>  
                                <div className="card">
                                    <div className="card-body">
                                        <h2>Setup Script</h2>

                                        <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                                        <CopyBlock
                                            text={sourceSetupScript.data as string}
                                            language="sql"
                                            showLineNumbers={false}
                                            theme={atomOneLight}
                                            codeBlock={true}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </>}
                        </>}
                        {sourceTypeConfig.data.provider == 'meltano' && <>
                            {(sourceTypeConfig.data.additional_config || []).length > 0 && <>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h2>Configuration</h2>
                                        <div className="row mb-2">
                                        {sourceTypeConfig.data.additional_config?.map(c => {
                                            return <ConfigFieldEditor
                                                onChange={(newVal: string) => {
                                                    changeConfig(c.name, newVal);
                                                }}
                                                value={config.hasOwnProperty(c.name) ? config[c.name] : ''}
                                                configField={c}
                                            />  
                                        })}
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {(sourceTypeConfig.data.additional_secret_config || []).length > 0 && <>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <h2>Secure Configuration</h2>
                                            <p>This data is stored securely and cannot be displayed. You can change the values by entering in new data here.</p>
                                            {sourceTypeConfig.data.additional_secret_config?.map(c => {
                                                return <ConfigFieldEditor
                                                    onChange={(newVal: string) => {
                                                        changeSecretConfig(c.name, newVal);
                                                    }}
                                                    value={secretConfig.hasOwnProperty(c.name) ? secretConfig[c.name] : ''}
                                                    configField={c}
                                                />
                                            })}
                                        </div>
                                    </div>
                                </>}

                            {source.data.oauth_last_authorized && <>
                                <div className="card mb-3">
                                    <div className="card-body d-flex">
                                        <div className="flex-fill">
                                            <CronScheduleSelect
                                                label="Sync Schedule"
                                                scheduleEnabled={!isPaused}
                                                value={syncCronExpression} 
                                                isDisabled={pausing}
                                                onChange={setSyncCronExpression}
                                                onToggle={togglePause}
                                             />
                                        </div>
                                        <div className="flex-fill" style={{padding: '1.2rem'}}>
                                            <div className="btn-actions">
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                            Connected
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item onClick={() => startAuthorizationFlow()}>{`Re-Authorize ${sourceTypeConfig.data.title}`}</Dropdown.Item>
                                                            <Dropdown.Item onClick={() => generateShareableLink()}>{`Create Shareable Link`}</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>}

                            {!source.data.oauth_last_authorized && sourceTypeConfig.data.oauth &&  <>
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <div className="btn-actions">
                                            {sourceTypeConfig.data.oauth && (
                                                <Dropdown>
                                                <Dropdown.Toggle variant="pliable" id="dropdown-basic">
                                                    Auth Required
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Dropdown.Item onClick={() => startAuthorizationFlow()}>{`Authorize ${sourceTypeConfig.data.title}`}</Dropdown.Item>
                                                    <Dropdown.Item onClick={() => generateShareableLink()}>{`Create Shareable Link`}</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        )}
                                        </div>
                                    </div>
                                </div>
                            </>}
                            
                            

                            {!!sourceSetupScript.data && <>  
                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h2>Setup Script</h2>

                                        <div style={{maxHeight: 400, overflowX: 'scroll'}} className="mb-3">
                                        <CopyBlock
                                            text={sourceSetupScript.data as string}
                                            language="sql"
                                            showLineNumbers={false}
                                            theme={atomOneLight}
                                            codeBlock={true}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </>}
                            <DataLoadList sourceId={sourceId as string}/> 
                        </>}
                    </div>
                    </Styles>
                </PaneContent>
            </Pane>
        </PageContent>
        </>);
    }

    return <PageStructure
        pageTitle="Configure Data Source"
    >
        {innerContent}
    </PageStructure>
}

export default SourceConfigPage;