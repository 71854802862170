import { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

interface Props {
    loading: boolean;
    text: string;
    icon?: string;
    variant?: string;
    onClick: () => any;
    disabled?: boolean;
    className?: string;
    testId?: string;
}

export default class AsyncButton extends Component<Props> {
    render() {
        const { 
            variant = 'primary', 
            icon = 'mdi mdi-arrow-right-bold-circle-outline' 
        } = this.props;

        let buttonIcon = (<i className={icon}></i>);
        if (this.props.loading) {
            buttonIcon = <i className="mdi mdi-loading mdi-spin"></i>
        }

        let buttonClassName = 'btn btn-' + variant;
        if (this.props.className){
            buttonClassName = buttonClassName + " " + this.props.className;
        }
        // if (['danger', 'warning', 'dark'].includes(this.props.variant)) {
        //     className = className + ' text-white';
        // }

        return (
            <button className={buttonClassName} disabled={this.props.loading || this.props.disabled} onClick={(e) => {this.props.onClick(); e.preventDefault()} } data-testid={this.props.testId}>
                                    
                {buttonIcon}
                <span className="ms-1">{this.props.text}</span>
            </button>
        )
    }
}