import Dropdown, { Option } from "@components/form/Dropdown.component";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { SingleValue } from "react-select";
import Select from "react-select";


const customStyles = {
    input: (provided: any) => ({
      ...provided,
      caretColor: 'transparent', // Hides the caret by making it transparent
    }),
  };

export interface PromptModalProps {
    onConfirm: (value: string) => void;
    onClose: () => void;
    promptText: string;
    promptDefaultValue?: string;
    dropdownOptions?: Option[]; 
    show?: boolean;
    header: string;
    useTextArea?: boolean;
}

const PromptModal = (props: PromptModalProps) => {
    const [val, setVal] = useState<string>('');
    const [inputValue, setInputValue] = useState('');
    const [enterPressed, setEnterPressed] = useState(false);
    
    useEffect(() => {
        if (props.promptDefaultValue) {
            setVal(props.promptDefaultValue);
        } else if (props.dropdownOptions && props.dropdownOptions.length > 0) {
            setVal(props.dropdownOptions[0].value);
        }
    }, [props.promptDefaultValue, props.dropdownOptions]);

    useEffect(() => {
        if (val && enterPressed) {
            confirm();
            setEnterPressed(false);
        }
    }, [enterPressed, val]);

    const close = useCallback(() => {
        setVal(props.promptDefaultValue ?? '');
        props.onClose();
    }, [props.onClose]);

    const confirm = useCallback(() => {
        props.onConfirm(val as string);
    }, [val, props.onConfirm]);

    const watchForEnterPress = (event: any) => {
        if (event.key === 'Enter' && val) {
            setEnterPressed(true);
        }
    };

    return (
        <Modal show={props.show} onHide={props.onClose} backdrop="static">
            <Modal.Header closeButton onClick={() => close()}>
                <Modal.Title>{props.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize: '1.4em'}}>
                <Form.Group>
                    <Form.Label>{props.promptText}</Form.Label>
                    {props.dropdownOptions ? (
                        <Dropdown
                            selected={val}
                            options={props.dropdownOptions}
                            onChange={setVal}
                            onKeyPress={watchForEnterPress}
                            autoFocus
                        />
                            
                        // <Select 
                        //     value={val as { value: string, label: string }} 
                        //     onChange={(selectedOption: SingleValue<{ value: string, label: string }>) => setVal(selectedOption!)} 
                        //     options={props.dropdownOptions} 
                        //     inputValue={inputValue}
                        //     onInputChange={(value) => setInputValue(value)}
                        //     onMenuOpen={() => {}}
                        //     onMenuClose={() => {}} 
                        //     onKeyDown={watchForEnterPress}
                        //     styles={customStyles}
                        //     autoFocus 
                        // />
                    ) : (
                        <Form.Control 
                            value={val as string} 
                            as={props.useTextArea ? 'textarea' : 'input'} 
                            autoFocus 
                            onChange={(e) => setVal(e.target.value)} 
                            onKeyDown={watchForEnterPress} 
                        />
                    )}
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Close</button>
                <button className="btn btn-pliable" onClick={() => confirm()}>Go</button>
            </Modal.Footer>
        </Modal>

    )
}

export default PromptModal;