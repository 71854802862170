import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Dropdown, { Option } from '@components/form/Dropdown.component';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Offcanvas } from 'react-bootstrap';
import { useMeasures } from '@models/reportBuilder';
import PipelineNodeName, { PipelineNodeFieldName } from '@components/pipelineNodes/PipelineNodeName.component';
import { reorderList } from '@services/list.service';
import { Pane, PaneContent } from '@pages/PageStructure.component';
import { MetricsList, MetricItem, InfoSection } from './shared.styles';
import HumanReadableWhitelist from '@components/pipelineNodes/HumanReadableWhitelist.component';

interface MetricSelectorProps {
    options: Option[];
    selected: string[];
    onChange: (newValue: string[]) => void;
    disabledOptionIds?: string[];
    disableMulti?: boolean;
}

const MetricSelector: React.FC<MetricSelectorProps> = ({
    options,
    selected,
    onChange,
    disabledOptionIds,
    disableMulti = false
}) => {
    const [dropdownValue, setDropdownValue] = useState('');
    const [infoMetricId, setInfoMetricId] = useState<string>();
    const measures = useMeasures();

    const handleAdd = (newValue: string) => {
        if (disableMulti) {
            // In single selection mode, replace the existing selection
            onChange([newValue]);
        } else {
            // In multi selection mode, add to the list if not already present
            if (!selected.includes(newValue)) {
                onChange([...selected, newValue]);
            }
        }
        setDropdownValue('');
    };

    const handleRemove = (valueToRemove: string) => {
        onChange(selected.filter(value => value !== valueToRemove));
    };

    const getOptionLabel = (value: string) => {
        return options.find(opt => opt.value === value)?.label || value;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination || disableMulti) {
            return;
        }

        const newOrder = reorderList(
            selected,
            result.source.index,
            result.destination.index
        );
        onChange(newOrder);
    };

    const activeMetric = measures.data?.find(m => m.id === infoMetricId);

    return (
        <div>
            <Dropdown
                options={options}
                selected={dropdownValue}
                onChange={handleAdd}
                disabledOptionIds={[...disabledOptionIds || [], ...selected]}
                placeholder={disableMulti ? "Select a new metric" : "Add metric..."}
            />
            
            {selected.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="metrics-list">
                        {(provided) => (
                            <MetricsList
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {selected.map((value, index) => (
                                    <Draggable 
                                        key={value} 
                                        draggableId={value} 
                                        index={index}
                                        isDragDisabled={disableMulti}
                                    >
                                        {(provided, snapshot) => (
                                            <MetricItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={snapshot.isDragging ? 'dragging' : ''}
                                            >
                                                {!disableMulti && <i className="mdi mdi-drag drag-handle"></i>}
                                                <span className="metric-name">{getOptionLabel(value)}</span>
                                                <button
                                                    className="icon-button"
                                                    onClick={() => setInfoMetricId(value)}
                                                >
                                                    <i className="mdi mdi-information"></i>
                                                </button>
                                                <button 
                                                    className="icon-button delete-button"
                                                    onClick={() => handleRemove(value)}
                                                >
                                                    <i className="mdi mdi-delete"></i>
                                                </button>
                                            </MetricItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </MetricsList>
                        )}
                    </Droppable>
                </DragDropContext>
            )}

            <Offcanvas 
                show={!!infoMetricId} 
                onHide={() => setInfoMetricId(undefined)} 
                placement="end"
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title className="flex-1">Metric Details</Offcanvas.Title>
                    {activeMetric && (
                        <Link 
                            to={`/metric/${activeMetric.id}`}
                            className="btn btn-light btn-sm me-2"
                        >
                            <i className="mdi mdi-pencil"></i> Edit
                        </Link>
                    )}
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Pane>
                        <PaneContent>
                            <div className="p-2">
                                {activeMetric && (
                                    <>
                                        <InfoSection>
                                            <h6>Name</h6>
                                            <p>{activeMetric.name}</p>
                                        </InfoSection>

                                        <InfoSection>
                                            <h6>Description</h6>
                                            <p>{activeMetric.description || <em>No description provided</em>}</p>
                                        </InfoSection>

                                        {activeMetric.is_calculation ? (
                                            <InfoSection>
                                                <h6>Formula</h6>
                                                <p><code>{activeMetric.formula}</code></p>
                                            </InfoSection>
                                        ) : (
                                            <>
                                                <InfoSection>
                                                    <h6>Source Table</h6>
                                                    <p>
                                                        <PipelineNodeName 
                                                            pipelineNodeId={activeMetric.pipeline_node_id} 
                                                        />
                                                    </p>
                                                </InfoSection>

                                                <InfoSection>
                                                    <h6>Column</h6>
                                                    <p>
                                                        {activeMetric.field_id === '_PLB_UUID' && 'Pliable Record ID'}
                                                        {activeMetric.field_id !== '_PLB_UUID' && <PipelineNodeFieldName 
                                                            pipelineNodeId={activeMetric.pipeline_node_id as string} 
                                                            fieldId={activeMetric.field_id as string} 
                                                        />}
                                                    </p>
                                                </InfoSection>

                                                <InfoSection>
                                                    <h6>Aggregation</h6>
                                                    <p>{activeMetric.aggregator}</p>
                                                </InfoSection>
                                                <InfoSection>
                                                    <h6>Filters</h6>
                                                    <HumanReadableWhitelist whitelist={activeMetric.data_whitelist || {entries: [], logic_gate: 'AND'}}/>

                                                </InfoSection>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        </PaneContent>
                    </Pane>
                </Offcanvas.Body>
            </Offcanvas>
        </div>
    );
};

export default MetricSelector;
