import { timeAgo } from "@services/time.service";
import { useDashboards } from "@models/dashboard";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import { DraftOnly } from "@components/project/DraftModeRequired.component";

const DashboardsListWidget = () => {
    const dashboards = useDashboards();
    const [search, setSearch] = useState('');
    
    const dashboardsToShow = useMemo(() => {
        if (!dashboards.data) {
            return [];
        }
        return dashboards.data
            .filter(d => d.name.toLowerCase().indexOf(search.toLowerCase()) >= 0)
            .sort((a, b) => a.name.localeCompare(b.name));
    }, [dashboards.dataUpdatedAt, search]);

    if (dashboards.isFetchedAfterMount && dashboardsToShow.length == 0 && !search) {
        return (
            <div className="empty-state">
                <h3 className="mb-0">You haven't created any dashboards yet.</h3>
                <p>Dashboards allow you to combine multiple analyses and datasets into a single view.</p>
                <DraftOnly>
                    <Link to="/dashboards" className="btn btn-primary">
                        <i className="mdi mdi-plus-thick"></i> New Dashboard
                    </Link>
                </DraftOnly>
                
            </div>
        );
    }

    return (
        <>
            <div className="d-flex center-vertically mb-2">
                <input 
                    type="text" 
                    className="form-control input-rounded" 
                    style={{width: '50%'}} 
                    placeholder="Search..." 
                    value={search} 
                    onChange={(e) => setSearch(e.target.value)} 
                />
                <div className="flex-1"></div>
                <DraftOnly>
                    <Link to="/dashboards" className="btn btn-outline-primary">
                        <i className="mdi mdi-plus-thick"></i> New Dashboard
                    </Link>
                </DraftOnly>
                
            </div>
            <div className="list-group">
                {dashboardsToShow.length == 0 && 
                    <div className="list-group-item">
                        No dashboards found.
                    </div>
                }
                {dashboardsToShow.map((dashboard) => {
                    return (
                        <div key={dashboard.id} className="list-group-item">
                            <div className="d-flex center-vertically">
                                <DashboardIcon icon="mdi mdi-view-dashboard" bgColor="blue"/>
                                <div>
                                    <h3 className="mb-0">{dashboard.name}</h3>
                                    <div className="font-poppins text-muted font-13">
                                        <span><i className="mdi mdi-chart-box"></i> {dashboard.components?.length || 0} components</span>
                                        {/* <span className="ms-2 me-2">&bull;</span>
                                        <span><i className="mdi mdi-clock"></i> Updated {timeAgo(dashboard.updated_at)}
                                        </span> */}
                                    </div>
                                </div>
                                <div className="flex-1"></div>
                                <div>
                                    <Link to={`/dashboards/${dashboard.id}`} className="btn btn-light btn-sm me-1">
                                        <i className="mdi mdi-eye"></i> View
                                    </Link>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    );
}

export default DashboardsListWidget;