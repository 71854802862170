import { AuthState } from '@frontegg/redux-store';
import FeatureFlagService from '@services/featureFlags/featureFlag.service';
import * as LDClient from 'launchdarkly-js-client-sdk';

export default class AuthService {
    private static instance: AuthService;

    authState: AuthState | null;
    authUrl: string;
    tenantName: string | null;
    feClientId: string | undefined;
    feAppId: string | undefined;
    ldClient: LDClient.LDClient | null;
    ldClientId: string | undefined;

    constructor() {
        this.authState = null;
        this.tenantName = null;
        this.ldClient = null;

        this.authUrl = '';
        this.feClientId = undefined;
        this.feAppId = undefined;
        const host = window.location.host;
        if (host.startsWith('local.')) {
            this.authUrl = "https://auth-dev.whetstonedata.com";
            this.feClientId = '692d0655-fa36-4d1a-8dde-8d45c64bf1ef';
            this.feAppId = '72fa4f93-2543-4600-ba03-7de81decd30a';
            this.ldClientId = '67d055939e55d809955930fb';
        } else if (host == "snowflake.pliable.co") {
            this.authUrl = `https://auth.app.pliable.co`;
            this.feClientId = 'b1c62231-3a21-48ed-857e-cde8ce0c2303';
            this.feAppId = '75c936eb-5473-4aae-a3cf-0f3edefc55e8';
        } else if (host == "dev-beta.pliable.co") {
            this.authUrl = `https://auth.${window.location.host}`
            this.feClientId = '512d51ea-262c-496b-8aeb-7644d1dc12dd';
            this.feAppId = '07a2c076-3948-4aa0-ae6f-851bfec6ddff';
            this.ldClientId = '67db334b41f39109971063f9';
        } else if (host.startsWith('app.')) {
            this.authUrl = "https://auth.app.pliable.co";
            this.feClientId = 'b1c62231-3a21-48ed-857e-cde8ce0c2303';
            this.ldClientId = '67d055939e55d809955930fc';
            this.feAppId = 'da24bc6c-0351-4e5f-b262-91d924f6de71';
        } else {
            this.authUrl = `https://auth.${window.location.host}`;
        }

        // Initialize LaunchDarkly client with a default context
        const defaultContext: LDClient.LDContext = {
            kind: 'user',
            key: 'anonymous',
            name: 'Anonymous',
            email: 'anonymous@example.com',
            custom: {
                tenant: 'Tenant'
            }
        };
        if (this.ldClientId) {
            this.ldClient = LDClient.initialize(this.ldClientId, defaultContext);
        } else {
            console.error('LaunchDarkly client ID is not defined');
        }
    }

    setAuthState(authState: AuthState) {
        this.authState = authState;

        let found = this.authState?.tenantsState.tenants.find((t) => t.tenantId == this.authState?.user?.tenantId);
        if (found) {
            this.tenantName = found.name;
        } else {
            this.tenantName = 'Tenant';
        }

        try {
            // Log rocket needs an identify call on each page load or the session shows up anonymous
            window.analytics.identify(this.authState.user?.id, {
                name: this.authState.user?.name,
                email: this.authState.user?.email,
            });

        } catch (ex) {
            console.log(ex);
            //pass
        }
        try {
            (window as any).thena?.setEmail({
                email: authState.user?.email, // add user email here
                hashedEmail: (authState.user as any).customClaims.thena_hmac, // the hashed email returned by the hmac function
            })
        } catch (ex) {
            console.log(ex)
        }

        // Update LaunchDarkly context with authenticated user information
        const context: LDClient.LDContext = {
            kind: 'user',
            key: this.authState.user?.id,
            name: this.authState.user?.name,
            email: this.authState.user?.email,
            custom: {
                tenant: this.tenantName
            }
        };

        this.ldClient?.identify(context).then(() => {
            FeatureFlagService.getInstance().initialize(this);
        }).catch((error) => {
            console.error('Error updating LaunchDarkly context:', error);
        });
    }

    getAccessToken() {
        return this.authState?.user?.accessToken;
    }

    getTenantName() {
        return this.tenantName;
    }

    getTenantId() {
        return this.authState?.user?.tenantId;
    }

    hasRole(role: string) {
        if (!this.authState?.isAuthenticated) {
            return false;
        }

        return this.authState?.user?.roles.some(r => r.key === role)
    }

    public static getInstance(): AuthService {
        if (!AuthService.instance) {
            AuthService.instance = new AuthService();
        }

        return AuthService.instance;
    }

    async getUserTenants() {
        const response = await fetch(this.authUrl + '/identity/resources/users/v2/me/tenants', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + this.getAccessToken(),
            },
            mode: 'cors',
            credentials: 'include',
        });
        return await response.json();
    }
}

export const useAuthState = () => {
    return AuthService.getInstance().authState;
}