import React, { useMemo } from 'react';
import styled from 'styled-components';
import { humanReadableJoin } from '@services/list.service';
import { useDimensions } from '@models/reportBuilder';
import { AnalysisDimensionFilter } from '@models/analysis';

const FilterText = styled.span`
    color: var(--ct-gray-600);
    font-style: italic;
`;

interface Props {
    filters: AnalysisDimensionFilter[];
}

const getComparatorText = (comparator: string, value: any): string => {
    switch (comparator) {
        case 'EQUALS':
            return 'is';
        case 'NOT_EQUALS':
            return 'is not';
        case 'CONTAINS':
            return 'contains';
        case 'NOT_CONTAINS':
            return 'does not contain';
        case 'IN':
            return Array.isArray(value) && value.length === 1 ? 'is' : 'is one of';
        case 'NOT_IN':
            return Array.isArray(value) && value.length === 1 ? 'is not' : 'is not one of';
        case 'IS_NULL':
            return 'is blank';
        case 'IS_NOT_NULL':
            return 'is not blank';
        case 'EMPTY':
            return 'is blank';
        case 'NOT_EMPTY':
            return 'is not blank';
        default:
            return comparator.toLowerCase().replace(/_/g, ' ');
    }
};

const HumanReadableDimensionFilters: React.FC<Props> = ({ filters }) => {
    const dimensions = useDimensions();

    const humanReadableEntries = useMemo(() => {
        if (!dimensions.data || filters.length === 0) {
            return [];
        }

        return filters.map((filter: AnalysisDimensionFilter) => {
            const dimension = dimensions.data.find(d => d.id === filter.dimension_id);
            const dimensionName = dimension?.name || filter.dimension_id;
            const comparator = getComparatorText(filter.comparator, filter.value);
            
            let valueText = '';
            if (Array.isArray(filter.value)) {
                valueText = humanReadableJoin(filter.value, ', ', ' or ');
            } else if (filter.value !== undefined && filter.value !== null) {
                valueText = filter.value.toString();
            }

            if (['IS_NULL', 'IS_NOT_NULL', 'EMPTY', 'NOT_EMPTY'].includes(filter.comparator)) {
                return `${dimensionName} ${comparator}`;
            }

            return `${dimensionName} ${comparator} "${valueText}"`;
        });
    }, [dimensions.data, filters]);

    if (!filters.length) {
        return <FilterText>No filters applied</FilterText>;
    }

    return (
        <FilterText>
            Where {humanReadableJoin(humanReadableEntries, ', ', ' and ')}
        </FilterText>
    );
};

export default HumanReadableDimensionFilters;
