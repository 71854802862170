import PipelineNodeIcon, { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import PipelineNodeName, { PipelineNodeFieldName } from "@components/pipelineNodes/PipelineNodeName.component";
import { useAnalyses } from "@models/analysis";
import { useDimensions, useMeasures } from "@models/reportBuilder";
import { summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import { usePipelineNodes } from "@stores/data.store";
import { node } from "prop-types";
import { useMemo, useState } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

interface MetricNameProps {
    measure_id: string;
}
const MetricName = (props: MetricNameProps) => {
    const measures = useMeasures();
    const measure = useMemo(() => measures.data?.find(m => m.id == props.measure_id), [measures.dataUpdatedAt]);
    if (!measure) {
        return <span className="text-muted"><i className="mdi mdi-loading mdi-spin"></i></span>;
    }
    return <span>{measure.name}</span>
}

interface DimensionNameProps {
    dimension_id: string;
}

const DimensionName = (props: DimensionNameProps) => {
    const dimensions = useDimensions();
    const dimension = useMemo(() => dimensions.data?.find(d => d.id == props.dimension_id), [dimensions.dataUpdatedAt]);
    if (!dimension) {
        return <span className="text-muted"><i className="mdi mdi-loading mdi-spin"></i></span>;
    }
    return <span>
        <PipelineNodeName pipelineNodeId={dimension.pipeline_node_id} />: <PipelineNodeFieldName pipelineNodeId={dimension.pipeline_node_id} fieldId={dimension.field_id} />
    </span>
}

const AnalysisListWidget = () => {
    const analyses = useAnalyses();

    const [search, setSearch] = useState('');
    const analysesToShow = useMemo(() => {
        if (!analyses.data) {
            return [];
        }
        return analyses.data.filter(a => a.name.toLowerCase().indexOf(search.toLowerCase()) >= 0).sort((a, b) => {
            // Compare using last_updated
            return (b.last_updated as Date).getTime() - (a.last_updated as Date).getTime();
        });
    }, [analyses.dataUpdatedAt, search]);
    
    if (analyses.isFetchedAfterMount && analyses.data && analyses.data.length == 0 ) {
        return <div className="empty-state">
            <h3 className="mb-0">You haven't created any analyses yet.</h3>
            <p>Analyses allow to you to create custom reports and charts, and explore the underlying data, all without writing any SQL.</p>
            <Link to="/analysis" className="btn btn-primary">
                <i className="mdi mdi-plus-thick"></i> New Analysis
            </Link>
        </div>
    }
    return <>
        <div className="d-flex center-vertically mb-2">
            <input type="text" className="form-control input-rounded" style={{width: '50%'}} placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <div className="flex-1"></div>
            <Link to="/analysis" className="btn btn-outline-primary">
                <i className="mdi mdi-plus-thick"></i> New Analysis
            </Link>
        </div>
        <div className="list-group">
            {analysesToShow.length == 0 && <div className="list-group-item">
                No analyses found.
            </div>}
        {analysesToShow.map((analysis, i) => {
                return <div className="list-group-item" key={analysis.id}>
                    <div className="d-flex center-vertically">
                        <div className="me-2">
                            {analysis.visualization_type == 'TABLE' && <DashboardIcon icon="mdi mdi-table" bgColor="blue"/> }
                            {analysis.visualization_type == 'BAR' && <DashboardIcon icon="mdi mdi-chart-bar" bgColor="blue"/> }
                            {analysis.visualization_type == 'LINE' && <DashboardIcon icon="mdi mdi-chart-line" bgColor="blue"/> }
                            {analysis.visualization_type == 'PIVOT_TABLE' && <DashboardIcon icon="mdi mdi-table-pivot" bgColor="blue"/> }
                            {analysis.visualization_type == 'SINGLE_VALUE' && <DashboardIcon icon="mdi mdi-numeric" bgColor="blue"/> }
                            {analysis.visualization_type.startsWith('PREMIUM_') && <DashboardIcon icon="mdi mdi-star" bgColor="blue"/> }
                        </div>
                        <div>
                            <h3 className="mb-0">{analysis.name || 'Unnamed Analysis'}</h3>
                            <div className="font-poppins text-muted font-13">
                                Last Run {timeAgo(analysis.last_updated as Date|undefined)}
                            </div>
                        </div>
                        <div className="flex-1"></div>
                        <div className="text-end">
                            <Link to={`/analysis/${analysis.id}`} className="btn btn-light btn-sm"><i className="mdi mdi-eye"></i> View</Link>
                        
                        </div>
                    </div>
                    

                        
                        
                </div>
            })}
        </div>
        
    </>;
}

export default AnalysisListWidget;