import styled from 'styled-components';

export const InfoSection = styled.div`
    margin-bottom: 1.5rem;
    
    h6 {
        color: var(--ct-text-muted);
        text-transform: uppercase;
        font-size: 0.75rem;
        margin-bottom: 0.25rem;
    }
    
    p {
        margin-bottom: 0;
    }
`;

export const MetricsList = styled.div`
    margin-top: 0.5rem;
    overflow: hidden;

`;

export const MetricItem = styled.div`
    padding: 0.5rem 0.75rem;
    margin-bottom: .125rem;
    background-color: var(--ct-dark);
    border-radius: 5px;
    color: white;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--ct-border-color);

    &:last-child {
        border-bottom: none;
    }

    &.dragging {
        background-color: var(--ct-dark);
    }

    .drag-handle {
        opacity: 0.5;
        margin-right: 0.5rem;
    }

    .metric-name {
        flex: 1;
    }

    .icon-button {
        background: none;
        border: none;
        padding: 0;
        color: var(--ct-menu-item);
        cursor: pointer;
        margin-left: 0.5rem;

        &:hover {
            color: var(--pliable-blue);
        }

        &.delete-button:hover {
            color: var(--ct-danger);
        }
    }
`;
