import ComponentDescription from "@components/general/ComponentDescription.component";
import { NODE_RANK } from "@components/nav/DagDataLibrary.component";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { invalidateEverything, invalidatePipelineNodes, MissionControlDataFlowEdge, MissionControlDataFlowNode, useMissionControlDataFlowData, usePipelineNode, usePipelineNodeRelationship, usePipelineNodeRelationships, usePipelineNodes } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import styled from 'styled-components';
import PipelineNodeName from "./PipelineNodeName.component";
import { getImmediateUpstreamNodes } from "@components/missionControl/dataflow/utils";
import { PipelineNode, PipelineNodeORM } from "@models/pipelineNode";
import { NodeList, SingleMeasureItem } from "./PipelineNodeList.component";
import { Form, Dropdown as BSDropdown, Modal } from "react-bootstrap";
import toast from "@services/toast.service";
import { getErrorMessage } from "@services/errors.service";
import useGlobalState from "@stores/global.state";
import { getPromptAnswer, requireConfirmation, runBuild } from "@services/alert/alert.service";
import PipelineNodeSelector from "./PipelineNodeSelector.component";
import { useMeasures } from "@models/reportBuilder";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import { emit } from '@nextcloud/event-bus'
import ApiService from "@services/api/api.service";
import { useHotkeys } from "react-hotkeys-hook";
import BuildNodeModal from "./modal/BuildNodeModal.component";
import Danger from "@components/statusIndicators/Danger.component";



const Section = styled.div`
margin-bottom: 1.5rem;
`
interface Props {
    pipelineNodeId: string;
}

const recursiveGetNodesAndEdges = (
    thisNode: MissionControlDataFlowNode, 
    allNodes: MissionControlDataFlowNode[], 
    allEdges: MissionControlDataFlowEdge[], 
    nodeIdsWeSaw: string[], 
    edgeIdsWeSaw: string[],
    limitDirection?: string,
): [MissionControlDataFlowNode[], MissionControlDataFlowEdge[]]=> {
    let nodes: MissionControlDataFlowNode[] = [];
    let edges: MissionControlDataFlowEdge[] = [];


    // Get edges pointing at this node
    allEdges.forEach(e => {
        if (e.source == thisNode.id && !nodeIdsWeSaw.includes(e.target) && (!limitDirection || limitDirection == 'source')) {
            const relatedNode = allNodes.find(n => n.id === e.target);
            if (!relatedNode) {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'source');
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if (e.target == thisNode.id && !nodeIdsWeSaw.includes(e.source) && (!limitDirection || limitDirection == 'target')) {
            const relatedNode = allNodes.find(n => n.id === e.source);
            if (!relatedNode) {
                return;
            }
            nodes.push(relatedNode);
            edges.push(e);
            edgeIdsWeSaw.push(e.id);
            nodeIdsWeSaw.push(relatedNode.id);
            const [moreNodes, moreEdges] = recursiveGetNodesAndEdges(relatedNode, allNodes, allEdges, nodeIdsWeSaw, edgeIdsWeSaw, 'target');
            moreNodes.forEach(n => nodeIdsWeSaw.push(n.id));
            moreEdges.forEach(e => edgeIdsWeSaw.push(e.id));

            nodes = nodes.concat(moreNodes);
            edges = edges.concat(moreEdges);
        } else if ((e.target == thisNode.id || e.source == thisNode.id) && !edgeIdsWeSaw.includes(e.id)) {
            edgeIdsWeSaw.push(e.id);
            edges.push(e);
        }
    });

    return [nodes, edges];
}



const PipelineNodeHelper = (props: Props) => {

    const thisNode = usePipelineNode(props.pipelineNodeId);
    const dataFlowData = useMissionControlDataFlowData();

    const [cleaningNodes, setCleaningNodes] = useState<MissionControlDataFlowNode[]>([]);
    const [businessObjects, setBusinessObjects] = useState<MissionControlDataFlowNode[]>([]);
    const [reportNodes, setReportNodes] = useState<MissionControlDataFlowNode[]>([]);
    const [upstreamNodes, setUpstreamNodes] = useState<MissionControlDataFlowNode[]>([]);
    const [downstreamNodes, setDownstreamNodes] = useState<MissionControlDataFlowNode[]>([]);

    const allNodes = usePipelineNodes();

    const relationships = usePipelineNodeRelationships(props.pipelineNodeId);

    const sortNodes = useCallback((a: MissionControlDataFlowNode, b: MissionControlDataFlowNode) => {
        const arank = NODE_RANK[getGroupValueForNodeType(a.data.nodeType)];
        const brank = NODE_RANK[getGroupValueForNodeType(b.data.nodeType)];

        if (arank > brank) {
            return 1;
        } else if (brank > arank) {
            return -1;
        }

        return a.data.title > b.data.title ? 1: -1;
    }, []);

    const upstreamNodesAsObjects = useMemo(() => {
        if (!allNodes.data) {
            return [];
        }

        const nodesById: {[key: string]: PipelineNode} = {};
        allNodes.data.forEach(n => {
            nodesById[n.id as string] = n;
        });
        return upstreamNodes.map(n => nodesById[n.data.objectId]);
    }, [allNodes.dataUpdatedAt, upstreamNodes]);

    const downstreamBusinessObjects = useMemo(() => {
        if (!allNodes.data) {
            return [];
        }

        const nodesById: {[key: string]: PipelineNode} = {};
        allNodes.data.forEach(n => {
            nodesById[n.id as string] = n;
        });
        return businessObjects.map(n => nodesById[n.data.objectId]);
    }, [allNodes.dataUpdatedAt, businessObjects]);

    const downstreamReports = useMemo(() => {
        if (!allNodes.data) {
            return [];
        }

        const nodesById: {[key: string]: PipelineNode} = {};
        allNodes.data.forEach(n => {
            nodesById[n.id as string] = n;
        });
        return reportNodes.map(n => nodesById[n.data.objectId]);
    }, [allNodes.dataUpdatedAt, reportNodes]);

    const cleaningNodesAsObjects = useMemo(() => {
        if (!allNodes.data) {
            return [];
        }

        const nodesById: {[key: string]: PipelineNode} = {};
        allNodes.data.forEach(n => {
            nodesById[n.id as string] = n;
        });
        return cleaningNodes.map(n => nodesById[n.data.objectId]);
    }, [allNodes.dataUpdatedAt, cleaningNodes]);

    useEffect(() => {
        if (!dataFlowData.data) {
            setCleaningNodes([]);
            setBusinessObjects([]);
            setReportNodes([]);
        } else {
            const theNode = dataFlowData.data.nodes.find(n => n.data.objectId === props.pipelineNodeId);
            if (theNode) {
                const [nodesDownstream] = recursiveGetNodesAndEdges(theNode, dataFlowData.data.nodes, dataFlowData.data.edges, ['PipelineNode:' + props.pipelineNodeId], [], 'source');
                setCleaningNodes(nodesDownstream.filter(n => getGroupValueForNodeType(n.data.nodeType) === 'STAGING').sort(sortNodes));
                setBusinessObjects(nodesDownstream.filter(n => getGroupValueForNodeType(n.data.nodeType) === 'DATA_MODELING').sort(sortNodes));
                setReportNodes(nodesDownstream.filter(n => getGroupValueForNodeType(n.data.nodeType) === 'REPORTING').sort(sortNodes));

                const [nodesUpstream] = getImmediateUpstreamNodes(theNode, dataFlowData.data.nodes, dataFlowData.data.edges);

                // Find all edges mentionining this
                const allConnectedEdges = dataFlowData.data.edges.filter(e => e.source == theNode.id || e.target == theNode.id);
                
                setUpstreamNodes((nodesUpstream as MissionControlDataFlowNode[]).sort(sortNodes));

                // const [sourceNodes] = recursiveGetNodesAndEdges(theNode, preFilteredNodes, data.data.edges, [props.activeNodeId], [], 'source');

                // setDownstreamNodes(sourceNodes.sort(sortNodes));

                // const nodeIds = sourceNodes.map(n => n.id).concat(downstreamNodes.map(n => n.id));

                // setOtherNodes(preFilteredNodes.filter(n => !nodeIds.includes(n.id) && n.id != props.activeNodeId).sort(sortNodes));
            }
        }
    }, [dataFlowData.dataUpdatedAt, props.pipelineNodeId]);

    const relatedNodes = useMemo(() => {
        if (!relationships.data) {
            return [];
        }

        return relationships.data.map(r => {
            let otherNode: PipelineNode;
            if (r.child_node_id == props.pipelineNodeId) {
                otherNode = allNodes.data?.find(n => n.id == r.parent_node_id) as PipelineNode;
            } else {
                otherNode = allNodes.data?.find(n => n.id == r.child_node_id) as PipelineNode;
            }
            return otherNode;
        })
       
    }, [relationships.dataUpdatedAt, props.pipelineNodeId])

    const nodeGroupType = useMemo(() => {
        if (!thisNode.data) {
            return '';
        }
        return getGroupValueForNodeType(thisNode.data.node_type);
    }, [thisNode.dataUpdatedAt]);

    const { pageDirty } = useGlobalState();


    const checkFactory = useCallback(async () => {
        await ApiService.getInstance().request('GET', `/pipelinenodes/${props.pipelineNodeId}/factory`);
    
    }, [props.pipelineNodeId]);

    const [building, setBuilding] = useState(false);


    const triggerBuild = useCallback(async (selector: 'THIS' | 'UPSTREAM' | 'DOWNSTREAM' | 'ALL', force: boolean = false, check_for_records: boolean = false) => {
        if (!thisNode.data) {
            return;
        }
        

        if (pageDirty) {
            const confirmed = await requireConfirmation('You have unsaved changes. Are you sure you want to build this node?', 'Rebuild Node', 'Continue Building', 'Go Back');
            if (!confirmed) {
                return;
            }
        }

        setBuilding(true);
        try {
            let selectorValue: string = '';
            switch (selector) {
                case 'THIS':
                    selectorValue = thisNode.data.name;
                    break;
                case 'UPSTREAM':
                    selectorValue = '+' + thisNode.data.name;
                    break;
                case 'DOWNSTREAM':
                    selectorValue = thisNode.data.name + '+';
                    break;
                case 'ALL':
                    selectorValue = '+' + thisNode.data.name + '+'
                    break;
            }
            await runBuild(
                selectorValue, 
                force,
                check_for_records,
                <>
                    <button onClick={() => {
                        navigate(`/node/${props.pipelineNodeId}`);
                        emit('pliable:alerts:closebuild', {});
                    }} className="btn btn-primary me-1">View Data</button>
                    <button onClick={() => {
                        emit('pliable:alerts:closebuild', {});
                    }} className="btn btn-light">Continue Editing</button>
                </>,
                <>
                    <button onClick={() => {
                        emit('pliable:alerts:closebuild', {});
                    }} className="btn btn-light">Continue Editing</button>
                </>
            );


        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setBuilding(false);
        }
    }, [thisNode.dataUpdatedAt, pageDirty]);

    useHotkeys('meta+enter', () => {
        setShowBuildNodeModal(true);
    }, [triggerBuild]);

    const navigate = useNavigate();

    const copyNode = useCallback(async () => {
        const newLabel = await getPromptAnswer('Enter new name for the copied node', 'Copy Node', false, `${thisNode.data?.label} copy`);
        if (!newLabel) {
            return;
        }
        const newNode = await PipelineNodeORM.copyPipelineNode(props.pipelineNodeId, newLabel);
        navigate(`/node/${newNode.id}/config`);
    }, [props.pipelineNodeId, thisNode.dataUpdatedAt]);

    const { setPageDirty } = useGlobalState();

    const deleteNode = useCallback(async () => {
        const confirmed = await requireConfirmation(`Are you sure you want to delete this node? Type out the full node name ("${thisNode.data?.label}") to continue.`, 'Delete Node', 'Delete', 'Cancel', thisNode.data?.label);
        if (confirmed) {
            setPageDirty(false);
            await PipelineNodeORM.deleteById(props.pipelineNodeId);
            navigate('/');
            invalidateEverything();
            toast('success', 'Node deleted', 'Node deleted successfully.');
        }
    }, [props.pipelineNodeId, thisNode.dataUpdatedAt]);

    const [showAddToBizObjModal, setShowAddToBizObjModal] = useState(false);
    const [showBuildNodeModal, setShowBuildNodeModal] = useState(false);

    const [selectedBusinessObjectId, setSelectedBusinessObjectId] = useState('');
    const [newBusinessObjectName, setNewBusinessObjectName] = useState('');

    const [addingToBO, setAddingToBO] = useState(false);
    const addToBusinessObject = useCallback(async () => {
        setAddingToBO(true);
        try {

        
            if (selectedBusinessObjectId == 'NEW') {
                const newBusinessObject = await PipelineNodeORM.save({
                    id: null,
                    name: newBusinessObjectName,
                    label: newBusinessObjectName,
                    node_type: 'DIMENSION',
                    fields: [],
                    table_name: '',
                    description: '',
                    upstream_node_ids: [],
                });
                setShowAddToBizObjModal(false);
                invalidatePipelineNodes();
                navigate(`/wizard/mapping/${props.pipelineNodeId}?bo_context=${newBusinessObject.id}`);
            } else {
                navigate(`/wizard/mapping/${props.pipelineNodeId}?bo_context=${selectedBusinessObjectId}`);
            }
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setAddingToBO(false);
        }
    }, [newBusinessObjectName, selectedBusinessObjectId, props.pipelineNodeId]);

    const beginBuild = useCallback(async () => {
        setShowBuildNodeModal(true);
    }, [props.pipelineNodeId]);

    const measures = useMeasures();

    const sourceMeasures = useMemo(() => {
        if (!measures.data || !thisNode.data) {
            return [];
        }


        return measures.data.filter(m => (thisNode.data?.measure_ids || []).includes(m.id as string));
    }, [thisNode.dataUpdatedAt])

    return <>
        <BuildNodeModal 
            show={showBuildNodeModal} 
            onClose={() => setShowBuildNodeModal(false)}
            defaultCheck={thisNode.data?.node_type == 'SOURCE'}
            defaultUpstream={thisNode.data?.node_type != 'SOURCE'}
            header={`Build: ${thisNode.data?.label || ''}`}
            onConfirm={(selector: "THIS" | "UPSTREAM" | "DOWNSTREAM" | "ALL", force: boolean, check_for_records: boolean) => {triggerBuild(selector, force, check_for_records)}}
            />
        <Modal show={showAddToBizObjModal} onHide={() => setShowAddToBizObjModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add to Entity</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Select an Entity</Form.Label>
                    <PipelineNodeSelector
                        selectedId={selectedBusinessObjectId}
                        onSelect={(pn) => setSelectedBusinessObjectId(pn?.id as string)}
                        optionFilter={(pn) => pn.node_type == 'DIMENSION'}
                        allowCreateNew
                    />

                </Form.Group>
                {selectedBusinessObjectId == 'NEW' && <>
                    <Form.Group>
                        <Form.Label>Enter Object Name</Form.Label>
                        <Form.Control type="text" value={newBusinessObjectName} onChange={(e) => setNewBusinessObjectName(e.target.value)}/>
                    </Form.Group>
                </>}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-light me-1" onClick={() => setShowAddToBizObjModal(false)}>Close</button>
                <button className="btn btn-success" onClick={addToBusinessObject} disabled={addingToBO || !selectedBusinessObjectId || (selectedBusinessObjectId == 'NEW' && !newBusinessObjectName)}>
                    {addingToBO ? 'Adding...' : 'Add'}
                </button>
            </Modal.Footer>
        </Modal>
        
        <div className="p-2">
           
            <h3>Actions</h3>
            <Section>
                {/* <button onClick={checkFactory}>Factory</button> */}
                    <div className="btn-group w-100">
                        <button className="btn btn-pliable" disabled={building} onClick={beginBuild} title="Build this node (Command + Enter)">{thisNode.data?.last_build_completed ? 'Rebuild' : 'Build'}</button>
                    </div>
                
                
                <ComponentDescription>Update this node with the latest data and configurations.</ComponentDescription>
            </Section>
            {thisNode.data?.node_type != 'DATE_DIMENSION' && <Section>
                <DraftOnly>
                    <button onClick={deleteNode} className="btn btn-outline-danger me-1">
                        <i className="mdi mdi-delete"></i> Delete Node
                    </button>
                    <button onClick={copyNode} className="btn btn-light">
                        <i className="mdi mdi-content-copy"></i> Copy Node
                    </button>
                </DraftOnly>
            </Section>}
            <hr />
            <div className="d-flex center-vertically mb-2">
                <h3 className="mb-0 flex-1">Connections</h3>
                <Link className="btn btn-xs btn-rounded btn-primary" to={`/dag?focusNodeId=PipelineNode:${props.pipelineNodeId}`}>
                    <i className="mdi mdi-sitemap mdi-rotate-270"></i> All
                </Link>

            </div>
            {['SOURCE', 'STAGING'].includes(nodeGroupType) && <div className="mb-3">
                <h5>Cleaning Steps ({cleaningNodes.length})</h5>
                {cleaningNodesAsObjects.length === 0 && <div className="mb-2">
                    <DraftOnly>
                        <Link to={`/wizard/cleaning/${props.pipelineNodeId}`}>Start cleaning.</Link>
                    </DraftOnly>
                </div>}
                <NodeList nodes={cleaningNodesAsObjects} compact/>
                {cleaningNodesAsObjects.length > 0 && <div>
                    <DraftOnly>
                        <Link to={`/wizard/cleaning/${props.pipelineNodeId}`}>Create another cleaning step.</Link>
                    </DraftOnly>
                </div>}
            </div>}
            {['STAGING', 'DATA_MODELING', 'REPORTING'].includes(nodeGroupType) && thisNode.data?.node_type != 'DATE_DIMENSION' && <div className="mb-3">
                <h5>Data Sources ({upstreamNodes.length})</h5>
                <NodeList nodes={upstreamNodesAsObjects} compact onClick={(node: PipelineNode) => {
                    navigate(`/node/${node.id}`);
                }}/>
                {thisNode.data?.node_type == 'DIMENSION' && <>
                    <Link to={`/wizard/data-source?next=cleaning&bo_context=${props.pipelineNodeId}`}>
                        Connect {upstreamNodes.length > 0 ? 'another' : 'your first'} data source.
                    </Link>

                </>}
                
            </div>}
            {nodeGroupType == 'REPORTING' && <div className="mb-3">
                <h5>Metrics ({sourceMeasures.length})</h5>
                {sourceMeasures.map(m => {
                    return <SingleMeasureItem
                        key={m.id}
                        measure={m}
                        compact
                        onClick={() => {
                            navigate(`/metric/${m.id}`);
                        }}
                    />
                })}
            </div>}
            {['SOURCE', 'STAGING'].includes(nodeGroupType) && <div className="mb-3">
                <h5>Entities ({downstreamBusinessObjects.length})</h5>
                <ReadOnly>
                    <a className="force-link" role="button" onClick={() => {
                        setShowAddToBizObjModal(true);
                        setSelectedBusinessObjectId('');
                    }}>Add this data to an Entity.</a>
                </ReadOnly>
                <NodeList nodes={downstreamBusinessObjects} compact/>
            </div>}
            {['DATA_MODELING', 'STAGING'].includes(nodeGroupType) && <div>
                <div className="mb-3">
                    <h5>Relationships ({relationships.data?.length})</h5>
                    
                    <div className="list-group">
                        {relationships.data && relationships.data
                            .filter(r => r.parent_node_id && r.child_node_id) // Add this line to filter relationships
                            .map(r => {
                                return (
                                    <div className="list-group-item list-group-item-action d-flex center-vertically" onClick={() => {
                                        navigate(`/relationship/${r.id}?afterSave=/node/${props.pipelineNodeId}/config`);
                                    }}>
                                        <div className="me-1">
                                            <i className={`mdi mdi-relation-${r.child_node_id == props.pipelineNodeId ? 'many-to-one' : 'one-to-many'} font-24`}></i>
                                        </div>
                                        <div className="flex-1">
                                            <div className="font-13 text-muted">
                                                {r.child_node_id == props.pipelineNodeId ? 'Many-to-One' : 'One-to-Many'}
                                            </div>
                                            <div>
                                                <span className="fw-bold me-1"><PipelineNodeName pipelineNodeId={r.child_node_id == props.pipelineNodeId ? r.parent_node_id : r.child_node_id}/></span>
                                                <span>({r.name || 'No Name'})</span>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <DraftOnly>
                        <Link to={`/relationship/new?pipelineNodeId=${props.pipelineNodeId}&afterSave=/node/${props.pipelineNodeId}/config`}>Create relationship</Link>
                    </DraftOnly>
                </div>
            </div>}
            {nodeGroupType == 'DATA_MODELING' && <div>
                <div className="mb-3">
                    <h5>Reports ({downstreamReports.length})</h5>
                    {downstreamReports.length == 0 && <>
                        This entity is not used in any reports.
                    </>}
                    <NodeList nodes={downstreamReports} compact onClick={(node: PipelineNode) => {
                        navigate(`/node/${node.id}`);
                    }}/>
                </div>
                
                {/* <h5>Semantic Layer</h5>
                <p>This entity is used in 2 dimensions and 5 metrics in your semantic layer.</p> */}
            </div>}

            



        </div>
    </>
};

export default PipelineNodeHelper;