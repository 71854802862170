import BuildOrchestrationORM, { BuildException, BuildOrchestration } from '@models/buildOrchestration';
import toast from '@services/toast.service';
import { MissionControlDataFlowEdge, MissionControlDataFlowNode, enterDraftMode, exitDraftMode, invalidateDataMarts, invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, invalidateSourceRecordTypes, queryClient, saveDataMart, useDraftVersionId, useMissionControlDataFlowData, usePipelineNodes, useTemplates } from '@stores/data.store';
import { MouseEvent, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Form, Modal, Offcanvas, Spinner } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getPromptAnswer, prompt, requireConfirmation } from '@services/alert/alert.service';
import DataSourceSelectModal from './Sources/DataSourceSelectModal.component';
import Dropdown from '@components/form/Dropdown.component';
import { useDebounce } from 'use-debounce';
import { useQueryParams } from '@services/url.service';

import Danger from '@components/statusIndicators/Danger.component';
import ConfettiExplosion from 'react-confetti-explosion';
import PageStructure, { PageContent, PageContentNoSidebar, PageSidebar, Pane, PaneContent, PaneContentWithSubnav } from './PageStructure.component';
import { getErrorMessage } from '@services/errors.service';
import DataMartORM from '@models/dataMart';
import { MissionControlDataFlowDiagramManager } from '@components/missionControl/dataflow/MissionControlDataFlow';
import PipelineNodeInfo from '@components/pipelineNodes/PipelineNodeInfo.component';
import PipelineNodeSelector from '@components/pipelineNodes/PipelineNodeSelector.component';
import { PipelineNode, PipelineNodeORM, PipelineNodeRelationshipORM, useNodesThatNeedBuilding } from '@models/pipelineNode';
import produce from 'immer';
import AsyncButton from '@components/button/AsyncButton.component';
import DataLibrary from '@components/nav/DataLibrary.component';
import { requireDraftMode } from '@components/branch/help';
import TrackingService, { Events } from '@services/tracking.service';
import PipelineNodeName from '@components/pipelineNodes/PipelineNodeName.component';
import PipelineNodeConnector from '@components/pipelineNodes/PipelineNodeConnector.component';
import { DraftModeRequired, DraftOnly, ProdModeRequired } from '@components/project/DraftModeRequired.component';
import LoadingCard from '@components/card/LoadingCard.component';
import PipelineOrchestration from '@components/orchestration/PipelineOrchestration.component';
import SuccessAlert from '@components/statusIndicators/SuccessAlert.component';
import Inbox from '@components/Inbox.component';
import PliableLoader from '@components/loaders/PliableLoader.component';
import PipelineNodeCard from '@components/pipelineNodes/PipelineNodeCard.component';
import { NODE_RANK } from '@components/nav/DagDataLibrary.component';
import { getGroupValueForNodeType } from '@services/modeling.service';
import { invalidateNotifications } from '@models/notification';
import PipelineNodeIcon, { DashboardIcon } from '@components/pipelineNodes/PipelineNodeIcon.component';
import { timeAgo } from '@services/time.service';
import { summarizeNumber } from '@services/formatting.service';
import BigMetricCard from '@components/metric/BigMetricCard.component';
import { useVisualizations } from '@models/visualization';
import { DashboardORM, useDashboards } from '@models/dashboard';
import FeatureFlagWrapper from '@components/featureflags/FeatureFlagWrapper.component';
import { useEntitlements } from '@frontegg/react';
import { useFeatureFlag } from '@services/featureFlags/featureFlag.service';

const Legend = () => {
    return <div className="d-flex center-vertically font-poppins" >
        <div className="ms-2">
            <i className="mdi mdi-circle text-success"></i> Report
        </div>
        <div className="ms-2">
            <i className="mdi mdi-circle text-purple"></i> Entity
        </div>
        <div className="ms-2">
            <i className="mdi mdi-circle text-dark"></i> Cleaning Step
        </div>
        <div className="ms-2">
            <i className="mdi mdi-circle text-pliable"></i> Data Source
        </div>
    </div>
}

interface NodeListProps {
    nodes: PipelineNode[];
    hasSearchQuery?: boolean;
}

const NodeList = (props: NodeListProps) => {
    return <table className="table table-centered table-fixed">
        <tbody>
            {props.nodes.map(n => {
                return <tr>
                    <td width={80}>
                        <PipelineNodeIcon node={n}/>
                    </td>
                    <td>
                        <h5 className="mb-0 fw-bold overflow-ellipsis">
                            <Link className="no-color" to={`/node/${n.id}`}>{n.name}</Link>
                        </h5>
                        <div className="text-muted text-13">{n.description || 'No description'}</div>
                    </td>
                    <td width={100}>
                        <div className="text-muted text-13">
                            <i className="mdi mdi-clock"></i> Last Built
                        </div>
                        <strong>{n.last_build_completed ? timeAgo(n.last_build_completed, 'mini-minute-now') : 'never'}</strong>
                    </td>
                    <td width={100}>
                        <div className="text-muted text-13">
                            <i className="mdi mdi-file-multiple"></i> Records
                        </div>
                        <strong>{n.total_records ? summarizeNumber(n.total_records) : '0'}</strong>
                    </td>
                    <td className="text-end ps-3" width={150}>
                        <Link title="View Data" to={`/node/${n.id}`} className="btn btn-sm btn-outline-primary">
                            <i className="mdi mdi-table"></i>
                        </Link>
                        <DraftModeRequired justHideChildren>
                            <Link to={`/node/${n.id}/config`} title="Configure" className="btn btn-sm btn-outline-secondary ms-1">
                                <i className="mdi mdi-cog"></i>
                            </Link>
                        </DraftModeRequired>
                        
                    </td>
                </tr>
            })}
            {props.nodes.length == 0 && <tr>
                <td colSpan={5}>
                    {props.hasSearchQuery && <>
                        No datasets match your search
                    </>}
                    {!props.hasSearchQuery && <>
                        No datasets available.
                        <DraftOnly>
                            &nbsp;<Link to="/node/new">Create one</Link>

                        </DraftOnly>
                    </>}
                    
                </td>    
            </tr>}
        </tbody>
    </table>
}

const SearchInput = styled.input`
font-size: 14px;
width: 100%;
border-radius: 20px;
border: solid 1px #ccc;
padding: .5rem 1rem;
margin-right: .5rem;
`
const NodeGrid = styled.div`
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: auto;
    column-gap: 5%;
    row-gap: 30px;
`
const EjectTemplatePage = () => {
    const nodes = usePipelineNodes();
    const nodesThatNeedBuilding = useNodesThatNeedBuilding();

    const [searchInput, setSearchInput] = useState('');
    const [debouncedSearchInput] = useDebounce(searchInput, 500);


    const sortNodes = useCallback((a: PipelineNode, b: PipelineNode) => {
        const arank = NODE_RANK[getGroupValueForNodeType(a.node_type)];
        const brank = NODE_RANK[getGroupValueForNodeType(b.node_type)];

        if (arank > brank) {
            return 1;
        } else if (brank > arank) {
            return -1;
        }

        return a.name > b.name ? 1: -1;
    }, []);

    const theNodes = useMemo(() => {
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => n.name.toLowerCase().indexOf(debouncedSearchInput) >= 0).sort(sortNodes);
    }, [nodes.dataUpdatedAt, sortNodes, debouncedSearchInput]);
    const getNodeStatus = useCallback((node: PipelineNode) => {
        if (!nodesThatNeedBuilding.data) {
            return 'ok';
        }
        if (nodesThatNeedBuilding.data.includes(node.id as string)) {
            return 'needs_building';
        }
        return 'ok';
    }, [nodesThatNeedBuilding.dataUpdatedAt]);

    const somethingNeedsBuilding = useMemo(() => {
        return nodesThatNeedBuilding.data && nodesThatNeedBuilding.data.length > 0;
    }, [nodesThatNeedBuilding.dataUpdatedAt]);

    const [building, setBuilding] = useState(false);
    const navigate = useNavigate();
    const onBuildAll = useCallback(async () => {
        try{
            setBuilding(true);
            const orchestration = await BuildOrchestrationORM.buildAll();
            navigate(`/dag?watchOrchestrationId=${orchestration.id}`);
        } catch(ex) {
            toast('danger', 'Error', `${getErrorMessage(ex)}`);
        }
        
    }, [navigate]);

    const onBuildNode = useCallback(async (node: PipelineNode) => {
        
        try {
            setBuilding(true);
            
            const orchestration = await BuildOrchestrationORM.buildWithSelector('+' + node.name);
            navigate(`/dag?watchOrchestrationId=${orchestration.id}&focusNodeId=PipelineNode:${node.id}`);
        } catch(ex) {
            toast('danger', 'Error', `${getErrorMessage(ex)}`);
        }
        
    }, [navigate]);

    const [connectingNode, setConnectingNode] = useState<PipelineNode|undefined>(undefined);

    const [loading, setLoading] = useState(false);

    const doNodeDeletion = useCallback(async (node: PipelineNode) => {
        setLoading(true);

        try {
            const apiCall = PipelineNodeORM.deleteById(node.id as string);

            await apiCall;
            invalidatePipelineNodes();
            invalidateDataMarts();
            invalidateMissionControlDataFlowData();
            invalidateNotifications();
            

            nodes.refetch();

        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setLoading(false);
        }
        
    }, []);

    const reportNodes = useMemo(() => {
        return theNodes.filter(n => getGroupValueForNodeType(n.node_type) == 'REPORTING');
    }, [theNodes]);

    const businessObjects = useMemo(() => {
        return theNodes.filter(n => getGroupValueForNodeType(n.node_type) == 'DATA_MODELING');
    }, [theNodes]);

    const cleaningSteps = useMemo(() => {
        return theNodes.filter(n => getGroupValueForNodeType(n.node_type) == 'STAGING');
    }, [theNodes]);

    const dataSources = useMemo(() => {
        return theNodes.filter(n => getGroupValueForNodeType(n.node_type) == 'SOURCE');
    }, [theNodes]);

    const onDeleteNodeClick = useCallback(async (node: PipelineNode) => {
        let message: string | JSX.Element = 'Are you sure you want to delete this?';

        const confirmed = await requireConfirmation(message, 'Confirm Deletion', 'Delete', 'Cancel');
        if (confirmed) {
            doNodeDeletion(node);
        }
    }, [doNodeDeletion]);

    const totalSourceRecords = useMemo(() => {
        if (nodes.data) {
            return nodes.data.filter(n => n.node_type === 'SOURCE').map(n => n.total_records || 0).reduce((prev: number, cur: number) => {
                return prev + cur;
            }, 0);
        }
        return undefined;
    }, [nodes.dataUpdatedAt]);

    const visualizations = useVisualizations();

    const dashboards = useDashboards();

    const theDashboards = useMemo(() => {
        if (!dashboards.data) {
            return [];
        }

        return dashboards.data.filter(d => d.name.toLowerCase().indexOf(debouncedSearchInput.toLowerCase()) >= 0);
    }, [debouncedSearchInput, dashboards.dataUpdatedAt])

    const makeNewDashboard = useCallback(async () => {
        const name = await getPromptAnswer('Enter dashboard name', 'New Dashboard');
        if (name) {
            const result = await DashboardORM.save({
                id: null,
                name: name,
                description: '',
                components: [],
            });
            navigate(`/dashboard/${result.id}`);
        }
    }, []);

    const addNewReport = useCallback(async () => {
        const name = await getPromptAnswer('Enter report name', 'New Report');
        if (name) {
            try {
                const result = await PipelineNodeORM.save({
                    id: null,
                    name: name,
                    label: name,
                    description: '',
                    node_type: 'REPORT',
                    table_name: '',
                    upstream_node_ids: [],
                    fields: [],
                });
                navigate(`/node/${result.id}/config`);
            } catch (err) {
                toast('danger', 'Error', getErrorMessage(err));
            }
            
        }
    }, []);

    const pivotTablesEnabled = useFeatureFlag('pivot-tables', false);
    
    return <PageStructure
        pageTitle="Dashboard"
    >
        {!!connectingNode && (
            <PipelineNodeConnector 
                connectingNodeId={connectingNode.id as string}
                onHide={() => setConnectingNode(undefined)}
                show
            />
        )}
        <PageContentNoSidebar>
            <Pane>
                <PaneContent>
                   
                    <div className="p-3">
                        
                        <div className="row">
                            <div className="col-12">
                                <div className="row">
                                        <div className="col-3 pe-2">
                                            
                                            <Inbox/>
                                        </div>
                                        <div className="col-9 ps-2">

                                            <div className="d-flex center-vertically mb-3">
                                                <div className="flex-1">
                                                    <SearchInput type="text" placeholder="Search your data..." value={searchInput} onChange={(e) => setSearchInput(e.target.value)}/>
                                                </div>
                                                <div>
                                                    <div className="d-flex center-vertically">
                                                        <div className="flex-1">&nbsp;</div>
                                                        <DraftOnly>
                                                            <Link role="button" to="/node/new" className="btn btn-outline-primary me-1">
                                                                <i className="mdi mdi-plus-circle"></i> New Node
                                                            </Link>
                                                        </DraftOnly>
                                                        
                                                        <Link to="/dag" className="btn btn-outline-secondary me-1">
                                                            <i className="mdi mdi-sitemap mdi-rotate-90"></i> Diagram
                                                        </Link>
                                                        {somethingNeedsBuilding && (
                                                            <AsyncButton 
                                                                variant="pliable" 
                                                                onClick={onBuildAll}
                                                                loading={building}
                                                                icon="mdi mdi-hammer"
                                                                text="Build All"
                                                            />
                                                        )}
                                                        {!somethingNeedsBuilding && (
                                                            <span className="ms-3">Everything up to date!</span>
                                                        )}
                                                    </div>
                                                </div>

                                            </div>
                                            <FeatureFlagWrapper requiredFlags={['visualizations']}>
                                                <h3>Dashboards</h3>
                                                <table className="table table-centered table-fixed">
                                                    <tbody>
                                                        {theDashboards.map(d => {
                                                            return <tr>
                                                                <td width={80}>
                                                                    <DashboardIcon
                                                                        bgColor="blue"
                                                                        icon="mdi mdi-view-dashboard"
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <h5 className="mb-0 fw-bold">
                                                                        <Link className="no-color" to={`/dashboard/${d.id}`}>{d.name}</Link>
                                                                    </h5>
                                                                    <div className="text-muted text-13">
                                                                        {d.description || 'No description'}
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        })}
                                                        {theDashboards.length == 0 && <tr>
                                                            <td colSpan={2}>
                                                                {debouncedSearchInput.length > 0 && <>
                                                                    No dashboards match your search
                                                                </>}
                                                                {debouncedSearchInput.length == 0 && <>
                                                                    No dashboards available.
                                                                    <DraftOnly>
                                                                        &nbsp;<a className="colorize" onClick={makeNewDashboard}>Create one</a>

                                                                    </DraftOnly>
                                                                </>}
                                                                
                                                            </td>    
                                                        </tr>}
                                                    </tbody>
                                                </table>
                                            </FeatureFlagWrapper>
                                            
                                            <div className="d-flex center-vertically mb-2">
                                                <div className="flex-1">
                                                    <h3 className="mb-0">Reports</h3>
                                                    <p className="mb-0">Reports are your views into your data. Build as many as you need to run your business. They will be automatically updated as new data is available in your data sources.</p>
                                                </div>
                                                {pivotTablesEnabled && <DraftOnly>
                                                    <div className="ms-3">
                                                        <button className="btn btn-outline-primary" onClick={() => {
                                                            addNewReport();
                                                        }}>Create New Report</button>
                                                    </div>
                                                </DraftOnly>}
                                                
                                                
                                            </div>
                                            
                                            <NodeList nodes={reportNodes} hasSearchQuery={debouncedSearchInput.length > 0}/>
                                            <hr />
                                            <h3>Entities</h3>
                                            <p>Entities are the building blocks for your reports. They represent core concepts in your business, like a customer, product, or retail location.</p>              
                                            <NodeList nodes={businessObjects} hasSearchQuery={debouncedSearchInput.length > 0}/>
                                            
                                            <DraftOnly>
                                                <hr />
                                                <h3>Cleaning Steps</h3>
                                                <NodeList nodes={cleaningSteps} hasSearchQuery={debouncedSearchInput.length > 0}/>
                                            </DraftOnly>
                                            <hr />
                                            <h3>Data Sources</h3>
                                            <p>These are all of the datasets coming from data sources you have connected to Pliable.</p>              
                                            <NodeList nodes={dataSources} hasSearchQuery={debouncedSearchInput.length > 0}/>
                                            
                                            
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </PaneContent>
            </Pane>
        </PageContentNoSidebar>
    </PageStructure>
}

export default EjectTemplatePage;

