import Dropdown, { MultiDropdown } from "@components/form/Dropdown.component";
import { PipelineNodeField } from "@models/pipelineNode";
import { usePipelineNode } from "@stores/data.store";
import { useMemo } from "react";

interface Props {
    pipelineNodeId: string;
    onSelect: (fieldId: string) => any;
    selectedId?: string;
    disabled?: boolean;
    includeRecordId?: boolean;
    excludeForeignKeys?: boolean;
    optionFilter?: (field: PipelineNodeField) => boolean;
}

const PipelineNodeColumnSelector = (props: Props) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);

    const columnOptions = useMemo(() => {
        if (!pipelineNode.data) {
            return [];
        }

        const options = pipelineNode.data.fields.filter(f => {
            if (props.excludeForeignKeys) {
                return f.type !== 'FOREIGN_KEY';
            }
            if (props.optionFilter && !props.optionFilter(f)) {
                return false;
            }
            return true;
        }).map(f => {
            return {
                value: f.id,
                label: f.name,
            }
        });

        if (props.includeRecordId) {
            options.unshift({
                value: '_PLB_UUID',
                label: 'Pliable Record ID',
            });
        }
        return options;
    }, [pipelineNode.dataUpdatedAt, props.excludeForeignKeys, props.optionFilter, props.includeRecordId]);

    return <Dropdown
        disabled={!props.pipelineNodeId || !!props.disabled}
        selected={props.selectedId}
        options={columnOptions}
        onChange={props.onSelect}
        placeholder="Select column"
    />
}

export default PipelineNodeColumnSelector;

interface MultiProps {
    pipelineNodeId: string;
    onSelect: (fieldIds: string[]) => any;
    selectedIds: string[];
    disabled?: boolean;
}
export const PipelineNodeMultiColumnSelector = (props: MultiProps) => {
    const pipelineNode = usePipelineNode(props.pipelineNodeId);

    const columnOptions = useMemo(() => {
        if (!pipelineNode.data || !pipelineNode.data.fields) {
            return [];
        }

        return pipelineNode.data.fields.map(f => {
            return {
                value: f.id,
                label: f.name,
            }
        });
    }, [pipelineNode.dataUpdatedAt]);

    return <MultiDropdown
        disabled={!props.pipelineNodeId || !!props.disabled}
        selected={props.selectedIds}
        options={columnOptions}
        onChange={props.onSelect}
    />
}