import PipelineNodeIcon from "@components/pipelineNodes/PipelineNodeIcon.component";
import { DraftOnly, ProdOnly } from "@components/project/DraftModeRequired.component";
import { getPromptAnswer } from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import { summarizeNumber } from "@services/formatting.service";
import { getGroupValueForNodeType } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import toast from "@services/toast.service";
import { savePipelineNode, usePipelineNodes } from "@stores/data.store";
import { node } from "prop-types";
import { useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const EntitiesListWidget = () => {
    const nodes = usePipelineNodes();
    const [search, setSearch] = useState('');
    
    const entities = useMemo(() => {
        return (nodes.data?.filter(n => getGroupValueForNodeType(n.node_type) == 'DATA_MODELING' && n.label.toLowerCase().indexOf(search.toLowerCase()) >= 0) || []).sort((a, b) => a.name.localeCompare(b.name));
    }, [nodes.dataUpdatedAt, search]);

    const navigate = useNavigate();
    const createNewEntity = useCallback(async () => {
        const newName = await getPromptAnswer('What do you want to name this entity?', 'New Entity');
        if (!newName) {
            return;
        }

        try {
            const newEntity = await savePipelineNode({
                id: null,
                node_type: 'DIMENSION',
                name: newName,
                label: newName,
                description: '',
                upstream_node_ids: [],
                fields: [],
                table_name: '',
            });
            navigate('/node/' + newEntity.id + '/config');
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        }
        
    }, []);

    if (nodes.isFetchedAfterMount && entities.length == 0 && !search) {
        return <>
            <DraftOnly>
                <div className="empty-state">
                    <h3 className="mb-0">You haven't created any entities yet.</h3>
                    <p>Entities allow you to connect your data sources to key concepts in your business.</p>
                    <button className="btn btn-primary" onClick={createNewEntity}>
                        <i className="mdi mdi-plus-thick"></i> New Entity
                    </button>
                </div>
            </DraftOnly>
            <ProdOnly>
                <div className="empty-state">
                    <p>You are currently in <strong>Production Mode.</strong> To create entities, please enter Develop Mode.</p>

                </div>
            </ProdOnly>
        </>
    }
    return <>
        <div className="d-flex center-vertically mb-2">
            <input type="text" className="form-control input-rounded" style={{width: '50%'}} placeholder="Search..." value={search} onChange={(e) => setSearch(e.target.value)} />
            <div className="flex-1"></div>
            <div className="btn-group">
                <Link to="/semantic-layer" className="btn btn-outline-primary">
                    <i className="mdi mdi-star"></i> Visual Editor
                </Link>
                <DraftOnly>
                    <button className="btn btn-outline-primary" onClick={createNewEntity}>
                        <i className="mdi mdi-plus-thick"></i> New Entity
                    </button>
                </DraftOnly>
                
            </div>
        </div>
        <div className="list-group">
            {entities.length == 0 && <div className="list-group-item">
                No entities found.
            </div>}
            {entities.map((node, i) => {
                return <div key={node.id} className="list-group-item">
                    <div className="d-flex center-vertically">
                        <PipelineNodeIcon node={node} />
                        <div>
                            <h3 className="mb-0">{node.label}</h3>
                            <div className="font-poppins text-muted font-13">
                                <span><i className="mdi mdi-file-multiple"></i> {summarizeNumber(node.total_records || 0)} records</span>
                                <span className="ms-2 me-2">&bull;</span>
                                <span><i className="mdi mdi-clock"></i> Updated {timeAgo(node.last_build_completed)}
                                </span>
                            </div>
                        </div>
                        <div className="flex-1"></div>
                        <div>
                            <Link to={`/node/${node.id}`} className="btn btn-light btn-sm me-1"><i className="mdi mdi-table"></i> Data</Link>
                            <Link to={`/node/${node.id}/config`} className="btn btn-light btn-sm"><i className="mdi mdi-cog"></i> Configure</Link>
                        </div>
                    </div>
                </div>
            })}
        </div>
    </>;
}

export default EntitiesListWidget;