import { FullPageLoader } from "@components/loaders/FullPageLoader.component";
import SourceORM from "@models/source";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

const BeginConnectorTypePage = () => {
    const { connectorType } = useParams();

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    const upsertConnector = useCallback(async (connectorType: string) => {
        const resp = await SourceORM.upsertConnectorForType(connectorType, Object.fromEntries(searchParams.entries()));

        //console.log('upsertConnector', resp);

        if (resp.authorization_url) {
            window.location.href = resp.authorization_url;
        }else{
            navigate(`/source/${resp.source.id}`);
        }
        
    }, [connectorType, searchParams]);

    
    useEffect(() => {
        if (connectorType) {
            upsertConnector(connectorType);
        }
    }, [searchParams, connectorType]);

    return <FullPageLoader />
}

export default BeginConnectorTypePage;