import styled from 'styled-components';
import React, {useEffect} from "react";
import PageStructure, { PageContent, PageContentNoSidebar, PaneContent } from '@pages/PageStructure.component';
import AuthService from '@services/auth/auth.service';
import 'rapidoc';
import ApiService from '@services/api/api.service';
import { RedocStandalone } from 'redoc';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';
import SwaggerUIComponent from './CustomSwaggerUI.component';

// Custom theme object to override default Swagger UI styles
const swaggerCustomTheme = {
  customCss: `
    /* Operation (HTTP method) colors */
    .opblock-get .opblock-summary-method { background-color: #61affe !important; }
    .opblock-post .opblock-summary-method { background-color: #49cc90 !important; }
    .opblock-put .opblock-summary-method { background-color: #fca130 !important; }
    .opblock-delete .opblock-summary-method { background-color: #f93e3e !important; }
    .opblock-patch .opblock-summary-method { background-color: #50e3c2 !important; }
    
    /* Operation block background colors */
    .opblock-get { background: rgba(97, 175, 254, 0.1) !important; }
    .opblock-post { background: rgba(73, 204, 144, 0.1) !important; }
    .opblock-put { background: rgba(252, 161, 48, 0.1) !important; }
    .opblock-delete { background: rgba(249, 62, 62, 0.1) !important; }
    .opblock-patch { background: rgba(80, 227, 194, 0.1) !important; }

    /* Response section colors */
    .response-col_status { color: #3b4151 !important; }
    .response-col_description__inner div.markdown { color: #3b4151 !important; }
    
    /* Schema section colors */
    .model-box { background-color: rgba(0, 0, 0, 0.1) !important; }
    .property.primitive { color: #55a !important; }
    
    /* Tags section colors */
    .opblock-tag { background-color: #f0f0f0 !important; }
    .opblock-tag:hover { background-color: #e8e8e8 !important; }
  `,
  // You can also customize specific components
  components: {
    operationsSectionTag: {
      container: {
        backgroundColor: '#f0f0f0',
        color: '#3b4151'
      }
    }
  }
};


interface RapiDocProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  // General
  'spec-url': string;
  'update-route'?: boolean;
  'route-prefix'?: string;
  'sort-tags'?: boolean;
  'sort-endpoints-by'?: 'path' | 'method' | 'summary' | 'none';
  'heading-text'?: string;
  'goto-path'?: string;
  'fill-request-fields-with-example'?: boolean;
  'persist-auth'?: boolean;
  // UI Colors and Fonts
  theme?: 'light' | 'dark';
  'bg-color'?: string;
  'text-color'?: string;
  'header-color'?: string;
  'primary-color'?: string;
  'load-fonts'?: boolean;
  'regular-fonts'?: string;
  'mono-fonts'?: string;
  'font-size'?: 'default' | 'large' | 'largest';
  // Navigation
  'use-path-in-nav-bar'?: boolean;
  'nav-bg-color'?: string;
  'nav-text-color'?: string;
  'nav-hover-bg-color'?: string;
  'nav-hover-text-color'?: string;
  'nav-accent-color'?: string;
  'nav-item-spacing'?: 'default' | 'compact' | 'relaxed';
  // UI Layout & Placement
  layout?: 'row' | 'column';
  'render-style'?: 'read' | 'view' | 'focused';
  'on-nav-tag-click'?: 'expand-collapse' | 'show-description';
  'schema-style'?: 'tree' | 'table';
  'schema-expand-level'?: number;
  'schema-description-expanded'?: boolean;
  'schema-hide-read-only'?: 'always' | 'never' | string;
  'default-schema-tab'?: 'model' | 'example';
  'response-area-height'?: string;
  // Hide/Show Sections
  'show-info'?: boolean;
  'info-description-headings-in-navbar'?: boolean;
  'show-components'?: boolean;
  'show-header'?: boolean;
  'allow-authentication'?: boolean;
  'allow-spec-url-load'?: boolean;
  'allow-spec-file-load'?: boolean;
  'allow-spec-file-download'?: boolean;
  'allow-search'?: boolean;
  'allow-advanced-search'?: boolean;
  'allow-try'?: boolean;
  'allow-server-selection'?: boolean;
  'allow-schema-description-expand-toggle'?: boolean;
  // API Server & calls
  'server-url'?: string;
  'default-api-server'?: string;
  'api-key-name'?: string;
  'api-key-location'?: 'header' | 'query';
  'api-key-value'?: string;
  'fetch-credentials'?: 'omit' | 'same-origin' | 'include';
  // Events
  beforeRender?: (spec: any) => void;
  specLoaded?: (spec: any) => void;
  beforeTry?: (request: any) => any;
  afterTry?: (data: any) => any;
  apiServerChange?: (server: any) => any;
}

declare global {
  interface HTMLElementTagNameMap {
    'rapi-doc': HTMLDivElement;
  }
  /* eslint-disable @typescript-eslint/no-namespace */
  namespace JSX {
    interface IntrinsicElements {
      'rapi-doc': RapiDocProps;
    }
  }
}

export const RapiDocReact = React.forwardRef<HTMLDivElement, RapiDocProps>(
  (
    {
      beforeRender,
      specLoaded,
      beforeTry,
      afterTry,
      apiServerChange,
      children,
      ...props
    }: RapiDocProps,
    ref
  ) => {
    const localRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
      const rapiDocRef =
        typeof ref === 'object' && ref?.current
          ? ref?.current
          : localRef.current;

      const handleBeforeRender = (spec: any) => {
        beforeRender && beforeRender(spec);
      };

      const handleSpecLoaded = (spec: any) => {
        specLoaded && specLoaded(spec);
      };

      const handleBeforeTry = (request: any) => {
        beforeTry && beforeTry(request);
      };

      const handleAfterTry = (data: any) => {
        afterTry && afterTry(data);
      };

      const handleApiServerChange = (server: any) => {
        apiServerChange && apiServerChange(server);
      };

      console.log(`rapiDocRef`, rapiDocRef);
      if (rapiDocRef) {
        beforeRender &&
          rapiDocRef.addEventListener('before-render', handleBeforeRender);
        specLoaded &&
          rapiDocRef.addEventListener('spec-loaded', handleSpecLoaded);
        beforeTry && rapiDocRef.addEventListener('before-try', handleBeforeTry);
        afterTry && rapiDocRef.addEventListener('after-try', handleAfterTry);
        apiServerChange &&
          rapiDocRef.addEventListener(
            'api-server-change',
            handleApiServerChange
          );
      }
      return () => {
        if (rapiDocRef) {
          beforeRender &&
            rapiDocRef.removeEventListener('before-render', handleBeforeRender);
          specLoaded &&
            rapiDocRef.removeEventListener('spec-loaded', handleSpecLoaded);
          beforeTry &&
            rapiDocRef.removeEventListener('before-try', handleBeforeTry);
          afterTry &&
            rapiDocRef.removeEventListener('after-try', handleAfterTry);
          apiServerChange &&
            rapiDocRef.removeEventListener(
              'api-server-change',
              handleApiServerChange
            );
        }
      };
    }, [
      ref,
      localRef,
      specLoaded,
      beforeRender,
      beforeTry,
      afterTry,
      apiServerChange,
    ]);

    return (
      <rapi-doc {...props} ref={ref || localRef}>
        {children}
      </rapi-doc>
    );
  }
);


interface OpenApiExplorerProps {
  collapse?: boolean;
  table?: boolean;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'openapi-explorer': OpenApiExplorerProps & React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const ApiDocsPage = () => {
    return <PageStructure
            pageTitle={"API Documentation"}
    >
    
      
    <div style={{height: 'calc(100vh - 50px)', width: '100%', overflowY: 'auto'}}>
        <SwaggerUIComponent
          url={`${ApiService.getInstance().hostname}/api/v1/docs/openapi.json`}
        />
        {/* <RedocStandalone 
          specUrl={`${ApiService.getInstance().hostname}/api/v1/docs/openapi.json`} 
          /> */}
      </div>
       
    
    
</PageStructure>
}

export default ApiDocsPage;