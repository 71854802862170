import React, { useCallback, useMemo, useState } from 'react';
import { DrillDownParams } from '@models/analysisVisualization';
import { Analysis } from '@models/analysis';
import { Updater } from 'use-immer';
import { Form } from 'react-bootstrap';
import MetricSelector from '../MetricSelector.component';
import DimensionSelector from '../DimensionSelector.component';
import styled from 'styled-components';
import { formatFinancial, formatPercentage, integerFormatter, summarizeNumber } from '@services/formatting.service';

interface PremiumAnalysisFormProps {
    analysis: Analysis;
    setAnalysis: Updater<Analysis>;
    measureOptions: Array<{ value: string; label: string }>;
    dimensionOptions: Array<{ value: string; label: string }>;
    disabledMeasureIds?: string[];
    disabledDimensionIds?: string[];
}

interface PremiumAnalysisVisualizationProps<T> {
    data: T;
    analysis: Analysis;
    onDrillDown?: (params: DrillDownParams) => void;
}

export const LTVPivotTableForm: React.FC<PremiumAnalysisFormProps> = ({
    analysis,
    setAnalysis,
    measureOptions,
    dimensionOptions,
    disabledMeasureIds,
    disabledDimensionIds
}) => {
    return (
        <>
            <Form.Group className="mb-3">
                <Form.Label>Total Sales Metric</Form.Label>
                <MetricSelector
                    disableMulti
                    options={measureOptions}
                    selected={analysis.measure_ids[0] ? [analysis.measure_ids[0]] : []}
                    onChange={(newVal) => {
                        setAnalysis(draft => {
                            draft.measure_ids[0] = newVal[0];
                        });
                    }}
                    disabledOptionIds={disabledMeasureIds}
                />
                <Form.Text>Select the metric that represents the total sales revenue. This metric must be connected to your customers dimension and will be used to calculate LTV.</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Ad Spend Metric (Optional)</Form.Label>
                <MetricSelector
                    disableMulti
                    options={measureOptions}
                    selected={analysis.measure_ids[1] ? [analysis.measure_ids[1]] : []}
                    onChange={(newVal) => {
                        setAnalysis(draft => {
                            draft.measure_ids[1] = newVal[0];
                        });
                    }}
                    disabledOptionIds={disabledMeasureIds}
                />
                <Form.Text>Select the metric that represents your ad spend. If set, this metric will be used to calculate the payback period for your customer acquisition efforts.</Form.Text>
            </Form.Group>

            

            <Form.Group className="mb-3">
                <Form.Label>Date Dimension</Form.Label>
                <DimensionSelector
                    disableMulti
                    options={dimensionOptions}
                    selected={analysis.dimension_ids[0] ? [analysis.dimension_ids[0]] : []}
                    onChange={(newVal) => {
                        setAnalysis(draft => {
                            draft.dimension_ids[0] = newVal[0];
                        });
                    }}
                    disabledOptionIds={disabledDimensionIds}
                />
                <Form.Text>Select the dimension to group customers by cohort</Form.Text>
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Customer Dimension</Form.Label>
                <DimensionSelector
                    disableMulti
                    options={dimensionOptions}
                    selected={analysis.dimension_ids[1] ? [analysis.dimension_ids[1]] : []}
                    onChange={(newVal) => {
                        setAnalysis(draft => {
                            draft.dimension_ids[1] = newVal[0];
                        });
                    }}
                    disabledOptionIds={disabledDimensionIds}
                />
                <Form.Text>Select the dimension representing each customer. This allows us to calculate things like repurchase rate and properly group customers into their acquisition cohort.</Form.Text>
            </Form.Group>
        </>
    );
};


interface LTVVisualizationRow {
    ACQUISITION_COHORT: string;
    PERIODS_SINCE_FIRST_PURCHASE: number;
    AVERAGE_TOTAL_SALES: string;
    CUMULATIVE_VALUE: number;
    NUMBER_OF_CUSTOMERS: number;
    NUMBER_OF_CUSTOMERS_IN_COHORT: number;
}

interface LTVAdSpendRow {
    ACQUISITION_COHORT: string;
    AD_SPEND: number;
}
interface LTVVisualizationData {
    options: {
        dataset1: LTVVisualizationRow[];
        dataset2: LTVAdSpendRow[];
    }
}

const Styles = styled.table`
width: 100%;

div.controls {
    display: flex;
    border-radius: 5px;
    justify-content: space-between;
    align-items: center;
    padding: .5rem 1rem;
    border: solid 1px var(--ct-border-color);

    div.label {
        font-weight: bold;
    }
}
table {
    width: 100%;
    border-collapse: collapse;
    th, td {
        border: solid 1px white;
        padding: .5rem 1rem;
    }



    

    tbody td {
        background-color: var(--pliable-navy);
        color: white;
    }
}
`

function generateColor(min: number, max: number, value: number): string {
    if (min === max) {
        return '#3366cc';
    }
    const range = max - min;
    const percentage = (value - min) / range;
    
    // Start with a medium blue (rgb(51, 102, 204)) and go to dark blue (rgb(0, 51, 153))
    const red = Math.floor(51 - (51 * percentage));
    const green = Math.floor(102 - (51 * percentage));
    const blue = Math.floor(204 - (51 * percentage));
    
    const redHex = red.toString(16).padStart(2, '0');
    const greenHex = green.toString(16).padStart(2, '0');
    const blueHex = blue.toString(16).padStart(2, '0');
    
    return `#${redHex}${greenHex}${blueHex}`;
}

export const LTVPivotTableVisualization: React.FC<PremiumAnalysisVisualizationProps<LTVVisualizationData>> = ({
    data,
    analysis,
    onDrillDown
}) => {
    const periodHeaders = useMemo(() => {
        if (!data || !data.options || !data.options.dataset1) {
            return [];
        }

        // Get all unique periods
        const periods = new Set(data.options.dataset1.map(d => d.PERIODS_SINCE_FIRST_PURCHASE));
        return Array.from(periods).sort((a, b) => a - b);
    }, [data]);

    const [cumulative, setCumulative] = useState(true);
    const [metricToUse, setMetricToUse] = useState<keyof LTVVisualizationRow>('AVERAGE_TOTAL_SALES');

    const drilldown = useCallback((params: DrillDownParams) => {
        if (onDrillDown) {
            onDrillDown(params);
        }
    }, [onDrillDown]);

    const rows = useMemo(() => {
        const rows: LTVVisualizationRow[][] = [];
        if (!data || !data.options || !data.options.dataset1) {
            return [];
        }

        data.options.dataset1.forEach(row => {
            const idx = rows.findIndex(r => r[0].ACQUISITION_COHORT === row.ACQUISITION_COHORT);
            if (idx === -1) {
                rows.push([row]);
            } else {
                rows[idx].push(row);
            }
        });

        // Now sort each row by period
        rows.forEach(row => {
            row.sort((a, b) => a.PERIODS_SINCE_FIRST_PURCHASE - b.PERIODS_SINCE_FIRST_PURCHASE);
        });

        // Now sort the rows so that the first row is the one with the earliest acquisition cohort
        rows.sort((a, b) => a[0].ACQUISITION_COHORT > b[0].ACQUISITION_COHORT ? 1 : -1)
        
        // Now if it's cumulative we modify the rows so that each column is the sum of all previous columns
        if (cumulative) {
            rows.forEach(row => {
                row[0].CUMULATIVE_VALUE = parseFloat(row[0].AVERAGE_TOTAL_SALES);
                for (let i = 1; i < row.length; i++) {
                    row[i].CUMULATIVE_VALUE = parseFloat(row[i].AVERAGE_TOTAL_SALES) + row[i-1].CUMULATIVE_VALUE;
                }
            });
        }

        return rows;

    }, [data, cumulative]);

    /**
     * Generates a dict of minimum values by period
     */
    const allValsByPeriod = useMemo(() => {
        if (!data || !data.options || !data.options.dataset1) {
            return {};
        }
        const allValsByPeriod: {[key: number]: number[]} = {};
        rows.forEach(row => {
            row.forEach(c => {
                if (!allValsByPeriod[c.PERIODS_SINCE_FIRST_PURCHASE]) {
                    allValsByPeriod[c.PERIODS_SINCE_FIRST_PURCHASE] = [];
                }
                if (metricToUse == 'NUMBER_OF_CUSTOMERS') {
                    allValsByPeriod[c.PERIODS_SINCE_FIRST_PURCHASE].push(c.NUMBER_OF_CUSTOMERS);
                }
                else if (metricToUse == 'AVERAGE_TOTAL_SALES') {
                    if (cumulative) {
                        allValsByPeriod[c.PERIODS_SINCE_FIRST_PURCHASE].push(c.CUMULATIVE_VALUE);
                    } else {
                        allValsByPeriod[c.PERIODS_SINCE_FIRST_PURCHASE].push(parseFloat(c.AVERAGE_TOTAL_SALES));
                    }
                }
            });
        });
        return allValsByPeriod;
    }, [data, metricToUse, cumulative])
    const minVals = useMemo(() => {

        const rv: {[key: number]: number} = {};
        Object.keys(allValsByPeriod).forEach(k => {
            rv[parseInt(k)] = Math.min(...allValsByPeriod[parseInt(k)]);
        });
        return rv;

        
        
    }, [allValsByPeriod]);

    const maxVals = useMemo(() => {

        const rv: {[key: number]: number} = {};
        Object.keys(allValsByPeriod).forEach(k => {
            rv[parseInt(k)] = Math.max(...allValsByPeriod[parseInt(k)]);
        });
        return rv;

        
        
    }, [allValsByPeriod]);

    const cacByCohort = useMemo(() => {
        if (!data || !data.options || !data.options.dataset2) {
            return {};
        }

        // IF the analysis has a second metric, we can calculate CAC
        if (analysis.measure_ids.length < 2) {
            return {};
        }

        const newCustomersByCohort: {[key: string]: number} = {};
        rows.forEach(row => {
            newCustomersByCohort[row[0].ACQUISITION_COHORT] = row[0].NUMBER_OF_CUSTOMERS_IN_COHORT;
        })

        const rv: {[key: string]: number} = {};
        data.options.dataset2.forEach(row => {
            rv[row.ACQUISITION_COHORT] = row.AD_SPEND / newCustomersByCohort[row.ACQUISITION_COHORT];
        });

        return rv;

    }, [data, analysis])


    // Implement visualization logic here
    return (
        <div style={{
            height: '100%',
            width: '100%',
            overflow: 'auto'
        }}>
                <Styles>
                    <div className="controls">
                        <div className="flex-1 label">
                            {metricToUse == 'NUMBER_OF_CUSTOMERS' && <span>
                                Showing: # of customers who purchased in each period
                            </span>}
                            {metricToUse == 'AVERAGE_TOTAL_SALES' && <span>
                                Showing: {cumulative ? 'Cumulative' : 'Average'} LTV by period
                            </span>}
                        </div>
                        {metricToUse == 'AVERAGE_TOTAL_SALES' && <>
                            <div className="me-3">
                                <Form.Check
                                    type="switch"
                                    label="Cumulative"
                                    checked={cumulative}
                                    onChange={(e) => setCumulative(e.target.checked)}
                                />
                            </div>
                        </>}
                        <div className="btn-group">
                            <button className={`btn btn-light ${metricToUse == 'AVERAGE_TOTAL_SALES' ? 'active' : ''}`} onClick={() => setMetricToUse('AVERAGE_TOTAL_SALES')} title="Average Total Sales">
                                <i className="mdi mdi-currency-usd"></i>
                            </button>
                            <button className={`btn btn-light ${metricToUse == 'NUMBER_OF_CUSTOMERS' ? 'active' : ''}`} onClick={() => setMetricToUse('NUMBER_OF_CUSTOMERS')} title="# of Purchasers">
                                <i className="mdi mdi-account-multiple"></i>
                            </button>
                        </div>
                       
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Cohort</th>
                                <th className="last">Size</th>
                                {analysis.measure_ids.length > 1 && <th className="last">CAC</th>}
                                {periodHeaders.map((p, idx) => <th key={idx}>Period {p}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, idx) => {
                                return <tr key={idx}>
                                    <th>
                                        {/* <a onClick={() => {
                                            drilldown({
                                                dimensionIds: [analysis.dimension_ids[0]],
                                                dimensionFilters: [
                                                    {
                                                        dimension_id: analysis.dimension_ids[0],
                                                        comparator: 'IN',
                                                        value: [row[0].ACQUISITION_COHORT]
                                                    }
                                                ],
                                                metricIds: [analysis.measure_ids[0]]
                                            })
                                        }}> */}
                                            {row[0].ACQUISITION_COHORT}
                                        {/* </a> */}
                                    </th>
                                    <th className="last">{integerFormatter(row[0].NUMBER_OF_CUSTOMERS_IN_COHORT)}</th>
                                    {analysis.measure_ids.length > 1 && <th className="last">{formatFinancial(cacByCohort[row[0].ACQUISITION_COHORT])}</th>}
                                    {row.map((r, idx) => {
                                        // Determine the bg color to use
                                        const color = generateColor(minVals[idx], maxVals[idx], metricToUse == 'NUMBER_OF_CUSTOMERS' ? r.NUMBER_OF_CUSTOMERS : (cumulative ? r.CUMULATIVE_VALUE : parseFloat(r.AVERAGE_TOTAL_SALES)));
                                        return <td key={idx} style={{backgroundColor: color}}>
                                            {/* <a role="button" onClick={() => {
                                                drilldown({
                                                    dimensionIds: [analysis.dimension_ids[0]],
                                                    metricIds: [analysis.measure_ids[0]],
                                                    dimensionFilters: [
                                                        {
                                                            dimension_id: analysis.dimension_ids[0],
                                                            comparator: 'IN',
                                                            value: [row[0].ACQUISITION_COHORT]
                                                        },
                                                    ]
                                                })
                                            }}> */}
                                                {metricToUse == 'NUMBER_OF_CUSTOMERS' && summarizeNumber(r.NUMBER_OF_CUSTOMERS)}
                                                {metricToUse == 'AVERAGE_TOTAL_SALES' && <>
                                                    {cumulative ? formatFinancial(r.CUMULATIVE_VALUE) : formatFinancial(r.AVERAGE_TOTAL_SALES)}

                                                </>}
                                            {/* </a> */}
                                            
                                        </td>
                                    })}
                                </tr>
                            })}
                        </tbody>
                    </table>
                    
                </Styles>
                {/* Implement your visualization here */}
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
        </div>
    );
};
