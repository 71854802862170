import { Modal } from "react-bootstrap";
import { Pane, PaneContent } from "@pages/PageStructure.component";
import React, { ReactNode } from "react";
import { ListGroup, Row, Col } from 'react-bootstrap';

const KeyboardShortcuts = () => {
  const shortcuts = [
    { keys: 'Ctrl + Shift + A', description: 'build All nodes' },
    { keys: 'Command + Enter', description: 'Build the node you are on' },
    // Add more shortcuts as needed
  ];

  return (
    <ListGroup>
      {shortcuts.map((shortcut, index) => (
        <ListGroup.Item key={index}>
          <Row>
            <Col xs={4} md={3}>
              <kbd>{shortcut.keys}</kbd>
            </Col>
            <Col>{shortcut.description}</Col>
          </Row>
        </ListGroup.Item>
      ))}
    </ListGroup>
  );
};



export interface BuildModalProps {
    show: boolean;
    onClose: () => void;

    compactMode?: boolean;
}

const HotKetsHelpModal = (props: BuildModalProps) => {
    

    return <Modal size={props.compactMode ? undefined : 'xl'} show={props.show} onHide={props.onClose} backdrop="static">
    <Modal.Header>
        <Modal.Title>
            Pliable Keyboard Shortcuts
        </Modal.Title>
    </Modal.Header>
    <Modal.Body className="p-0" style={{height: props.compactMode ? ' calc(40vh)': 'calc(70vh)'}}>
            <Pane>
                <PaneContent className="noscroll-h">
                    <KeyboardShortcuts />

                </PaneContent>
            </Pane>
        
        
    </Modal.Body>
    <Modal.Footer>
    <button onClick={() => {
                    props.onClose();
                }} className="btn btn-light">Close</button>
        
    </Modal.Footer>
</Modal>
}

export default HotKetsHelpModal;