
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Button, Form, Overlay, OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import Select, { SingleValue } from 'react-select';
import TrackingService, {Events} from "@services/tracking.service";
import styled from 'styled-components';
import produce from "immer";
import Dropdown, { Option as DropdownOption } from "@components/form/Dropdown.component";
import { DOMContainer } from "@restart/ui/esm/useWaitForDOMRef";
import { Updater, useImmer } from "use-immer";
import { useDebouncedCallback } from "use-debounce";
import { DataWhitelist, DataWhitelistEntry, PipelineNodeField } from "@models/pipelineNode";
import Editor from "@monaco-editor/react";
import { DraftOnly, ReadOnly } from "@components/project/DraftModeRequired.component";
import { useIsInDraftMode, usePipelineNodes } from "@stores/data.store";


export const comparatorOptions = [
    {
        label: 'is equal to',
        value: 'EQUALS',
    }, 
    {
        label: 'is not equal to',
        value: 'NOT_EQUALS',
    },
    {
        label: 'contains',
        value: 'CONTAINS',
    },
    {
        label: 'does not contain',
        value: 'NOT_CONTAINS',
    },
    {
        label: 'is greater than',
        value: 'GT',
    }, {
        label: 'is greater than or equal to',
        value: 'GTE',
    }, {
        label: 'is less than',
        value: 'LT',
    }, {
        label: 'is less than or equal to',
        value: 'LTE',
    }, {
        label: 'is blank',
        value: 'EMPTY',
    }, {
        label: 'is not blank',
        value: 'NOT_EMPTY',
    },{
        label: 'starts with',
        value: 'STARTS_WITH',
    },{
        label: 'does not start with',
        value: 'NOT_STARTS_WITH',
    },{
        label: 'ends with',
        value: 'ENDS_WITH',
    },{
        label: 'does not end with',
        value: 'NOT_ENDS_WITH',
    }, {
        label: 'is not a valid date',
        value: 'INVALID_DATE',
    }, {
        label: 'is a valid date',
        value: 'VALID_DATE',
    }, {
        label: 'is a valid number',
        value: 'VALID_NUMBER',
    }, {
        label: 'is not a valid number',
        value: 'INVALID_NUMBER',
    }
];

export const comparatorsWithValues = [
    'EQUALS', 'NOT_EQUALS', 'CONTAINS', 'NOT_CONTAINS', 'GT', 'GTE', 'LT', 'LTE', 'STARTS_WITH', 'NOT_STARTS_WITH', 'ENDS_WITH', 'NOT_ENDS_WITH'
];


const EntryForm = styled.div`
width: 100%;
`

const SingleEntryStyles = styled.div`
    background-color: #eef2f7;
    color: #313a46;
    border-radius: 5px;
    padding: .5rem;
    margin-bottom: .5rem;
    display: flex;

    &:last-child {
        margin-bottom: 0px;
    }

    &:not(.highlight):hover {
        background-color: white;
        color: black;
        cursor: pointer;
    }

    &.highlight {
        outline: solid 2px var(--pliable-yellow);


    }
`

interface SingleEntryProps {
    entry: DataWhitelistEntry;
    columnOptions: DropdownOption[];
   
    onClick: () => void;
    onRemove: () => void;
    highlight: boolean;
}

const SingleEntry = ( props : SingleEntryProps) => {
    const columnName = useMemo(() => {
        if (props.columnOptions.length == 0 || !props.entry.field_id || !props.entry.pipeline_node_id) {
            return '[none]';
        }

        const selectedValue = `${props.entry.pipeline_node_id}:${props.entry.field_id}`;

        
        let rv = `${props.columnOptions.find(co => co.value === selectedValue)?.label}`;


        return rv;
    }, [props.columnOptions, props.entry.field_id, props.entry.pipeline_node_id]);

    const value = useMemo(() => {
        if (!comparatorsWithValues.includes(props.entry.comparator)) {
            return '';
        }
        
        return `"${props.entry.value}"`;
    }, [props.entry.comparator, props.entry.value]);

    const comparator = useMemo(() => {
        return comparatorOptions.find(c => c.value === props.entry.comparator)?.label;

    }, [props.entry.comparator]);
    const className = useMemo(() => {
        if (props.highlight) {
            return 'highlight';
        }
        return ''
    }, [props.highlight])
    return <SingleEntryStyles className={className} onClick={() => props.onClick()}>
        <div className="flex-1">
            <strong>{columnName}</strong> <span>{comparator}</span> <strong>{value}</strong>
        </div>
        
        <DraftOnly>
            <span>
                <button onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    props.onRemove()
            }} className="icon-button"><i className="mdi mdi-close-thick"></i></button>
            </span>
        </DraftOnly>
       

    </SingleEntryStyles>
}

interface ColumnOption {
    pipelineNodeId: string;
    fieldId: string;
    fieldLabel: string;
    nodeLabel: string;
    fieldDescription?: string;
}

interface Props {
    config: DataWhitelist;
    nodeIds?: string[];
    columnOptionOverrides?: ColumnOption[];
    onChange: (whitelist: DataWhitelist) => void;
    onTest?: () => void;
    compact?: boolean;
}



const logicGateOptions = [
    {
        label: 'Match ALL',
        description: 'Include records that match all of the filters below',
        value: 'AND',
    }, {
        label: 'Match ANY',
        description: 'Include records that match at least one of the filters below',
        value: 'OR',
    }, {
        label: 'Custom SQL',
        description: 'Write custom SQL for more advanced filtering options',
        value: 'CUSTOM',
    }
]

const CompactItem = styled.div`
position: relative;
button.remove {
    position: absolute;
    right: 5px;
    top: 5px;
}
`

const EditorStyles = styled.div`
.monaco-editor .suggest-widget { 
    width: 280px !important; 
    left: 0px !important;
}
`
const DataWhitelistForm = (props: Props) => {

    const nodes = usePipelineNodes();

    const availableColumns: ColumnOption[] = useMemo(() => {
        if (props.columnOptionOverrides) {
            return props.columnOptionOverrides;
        }
        if (!nodes.data) {
            return [];
        }

        return nodes.data.filter(n => props.nodeIds?.includes(n.id as string)).map(n => {
            return n.fields.filter(f => f.type != 'FOREIGN_KEY').map(f => {
                return {
                    pipelineNodeId: n.id as string,
                    fieldId: f.id,
                    fieldLabel: f.label,
                    fieldDescription: f.description,
                    nodeLabel: n.label,
                }
            })
        }).flat()
    }, [nodes.dataUpdatedAt, props.nodeIds, props.columnOptionOverrides]);

    const dropdownOptions: DropdownOption[] = useMemo(() => {
        return availableColumns.map(ac => {
            return {
                value: `${ac.pipelineNodeId}:${ac.fieldId}`,
                label: `${ac.nodeLabel} - ${ac.fieldLabel}`,
                description: ac.fieldDescription,
            };
        });
    }, [availableColumns]);


    const changedEntryValue = useCallback((idx: number, newVal: string) => {
        props.onChange(produce(props.config, draft => {
            draft.entries[idx].value = newVal;
        }))
       
    }, [props.onChange, props.config]);

    const changedEntryColumn = useCallback((idx: number, newVal: string) => {
        if (newVal) {
            const [pipelineNodeId, fieldId] = newVal.split(':');
            props.onChange(produce(props.config, draft => {
                draft.entries[idx].pipeline_node_id = pipelineNodeId;
                draft.entries[idx].field_id = fieldId;
            }));
        } else {
            props.onChange(produce(props.config, draft => {
                draft.entries[idx].pipeline_node_id = '';
                draft.entries[idx].field_id = '';
            }));
        }
        
    }, [props.onChange, props.config]);

    const changedEntryComparator = useCallback((idx: number, comparator: string) => {
        props.onChange(produce(props.config, draft => {
            draft.entries[idx].comparator = comparator;
        }));
    }, [props.onChange, props.config]);
    

    const changeLogicGate = useCallback((gate: string) => {
        props.onChange(produce(props.config, draft => {
            draft.logic_gate = gate;
        }));
    }, [props.onChange, props.config]);

    const addNewEntry = useCallback(() => {
        props.onChange(produce(props.config, draft => {
            draft.entries.push({
                pipeline_node_id: '',
                field_id: '',
                comparator: '',
                value: '',
            });
        }));
    }, [props.onChange, props.config]);
   

    const inDraftMode = useIsInDraftMode();


    return <div>
        
        
        {props.config.logic_gate == 'CUSTOM' && <div><Form.Group className="mb-2">
            <Form.Label>Custom SQL</Form.Label>
            <Form.Control as="textarea" className="font-code " value={props.config.custom_sql} onChange={(e) => {
                props.onChange(produce(props.config, draft => {
                    draft.custom_sql = e.target.value;
                }));
            }}/>
            <Form.Text>You can write anything that would go in a <code>WHERE</code> clause. Wrap column names in double quotes.</Form.Text>
            </Form.Group>
            <ReadOnly>
                <button onClick={() => {
                    changeLogicGate('AND');
                }} className="btn btn-light">
                    <i className="mdi mdi-undo"></i> Use no-code filters
                </button>
            </ReadOnly>
        </div>}
        {props.config.logic_gate != 'CUSTOM' && <>
            {props.config.entries.length == 0 && <div className="">
                <p>No filters applied (all records will be included.)</p>
                <ReadOnly>
                    <button onClick={addNewEntry} className="btn btn-light me-1">
                        <i className="mdi mdi-plus-thick"></i> Add Rule
                    </button>
                    <button onClick={() => {
                        changeLogicGate('CUSTOM');
                    }} className="btn btn-light">
                        <i className="mdi mdi-code-braces"></i> Write Custom SQL
                    </button>
                </ReadOnly>
            </div>}

            {props.config.entries.length > 0 && <>
            {props.config.entries.length > 1 && <div className="mb-1">
                <Form.Check
                    type="switch"
                    label={props.config.logic_gate == 'AND' ? 'Match ALL' : 'Match ANY'}
                    checked={props.config.logic_gate === 'AND'}
                    onChange={(e) => {
                        props.config.logic_gate == 'AND' ? changeLogicGate('OR') : changeLogicGate('AND');
                    }}
                />
            </div>}


            {props.compact && <>
            <div className="mb-2">
                <button className="btn btn-sm btn-light me-1" onClick={addNewEntry}>
                    <i className="mdi mdi-plus-thick"></i> Add Rule
                </button>
                <button onClick={() => {
                    changeLogicGate('CUSTOM');
                }} className="btn btn-sm btn-light">
                    <i className="mdi mdi-code-braces"></i> Write Custom SQL
                </button>
                    
            </div>
                
                <div>
                    {props.config.entries.map((e, idx) => {
                        return <CompactItem className="card p-2 mb-2 bg-light">
                            <button 
                                className="icon-button font-18 remove" onClick={() => {
                                props.onChange(produce(props.config, draft => {
                                    draft.entries.splice(idx, 1);
                                }));
                                
                            }}>
                                <i className="mdi mdi-delete"></i>
                            </button>
                            <Form.Group className="mb-2">
                                <Form.Label className="small">Column</Form.Label>
                                <Dropdown
                                    size="sm"
                                    options={dropdownOptions}
                                    selected={e.pipeline_node_id + ':' + e.field_id}
                                    onChange={(newval) => {

                                        changedEntryColumn(idx, newval);
                                        
                                    }}
                                />
                            </Form.Group>
                            <Form.Group className="mb-2">
                                <Form.Label className="small">Comparator</Form.Label>

                                <Dropdown
                                    size="sm"
                                    options={comparatorOptions}
                                    selected={e.comparator}
                                    onChange={(newval) => {
                                        changedEntryComparator(idx, newval);
                                        
                                        
                                    }}
                                />
                            </Form.Group>
                            {comparatorsWithValues.includes(e.comparator) && <Form.Group>
                                <Form.Label className="small">Value</Form.Label>
                                <input type="text" className="form-control" value={e.value} onChange={(e) => {
                                    changedEntryValue(idx, e.target.value);
                                    }}/>
                            </Form.Group>}
                            
                        </CompactItem>

                    })}
                </div>
                
            </>}
            {!props.compact && <>
                <table className="table table-fixed table-condensed table-bordered table-compact table-centered">
                    <thead className="bg-light font-poppins">
                        <tr>
                            <th style={{width: '30%'}}>Column</th>
                            <th style={{width: '30%'}}>Comparator</th>
                            <th style={{width: '30%'}}>Value</th>
                            <th className="text-end">
                                <button className="icon-button font-18" onClick={addNewEntry}>
                                    <i className="mdi mdi-plus-thick"></i>
                                </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {props.config.entries.map((e, idx) => {
                            return <tr>
                                    <td style={{width: '30%'}}>
                                    <Dropdown
                                        size="sm"
                                        options={dropdownOptions}
                                        selected={e.pipeline_node_id + ':' + e.field_id}
                                        onChange={(newval) => {

                                            changedEntryColumn(idx, newval);
                                            
                                        }}
                                    />
                                </td>
                                <td style={{width: '30%'}}>
                                    <Dropdown
                                        size="sm"
                                        options={comparatorOptions}
                                        selected={e.comparator}
                                        onChange={(newval) => {
                                            changedEntryComparator(idx, newval);
                                            
                                            
                                        }}
                                    />
                                </td>
                                <td style={{width: '50%'}}>
                                    {comparatorsWithValues.includes(e.comparator) && <input type="text" className="form-control" value={e.value} onChange={(e) => {
                                        changedEntryValue(idx, e.target.value);
                                    }}/>}
                                </td>
                                <td className="text-end">
                                    <button className="icon-button font-18" onClick={() => {
                                        props.onChange(produce(props.config, draft => {
                                            draft.entries.splice(idx, 1);
                                        }));
                                    
                                    }}>
                                        <i className="mdi mdi-delete"></i>
                                    </button>
                                </td>
                            </tr>;
                        })}
                            
                                
                    </tbody>
                </table></>}  
            </>}
        </>}
        
          
            
            
            
    </div>
}

export default DataWhitelistForm;
