import Dropdown, { MultiDropdown, Option } from "@components/form/Dropdown.component";
import PipelineNodeDataTable from "@components/pipelineNodes/PIpelineNodeDataTable";
import PipelineNodeSubnav from "@components/pipelineNodes/PipelineNodeSubnav.component";
import { DraftOnly, ProdOnly } from "@components/project/DraftModeRequired.component";
import BuildOrchestrationORM, { BuildExecution, BuildOrchestration } from "@models/buildOrchestration";
import { PipelineNode, PipelineNodeField, PipelineNodeORM } from "@models/pipelineNode";
import PageStructure, { PageContent, PageSidebar, Pane, PaneContent } from "@pages/PageStructure.component";
import { showWaiting, closeWaiting} from "@services/alert/alert.service";
import { getErrorMessage } from "@services/errors.service";
import toast from "@services/toast.service";
import { enterDraftMode, invalidateEverything, invalidateMissionControlDataFlowData, invalidatePipelineNodes, useIsInDraftMode, usePipelineNode, useSourceColumns } from "@stores/data.store";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Form, Modal, Dropdown as BSDropdown, ButtonGroup, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import Editor from "@monaco-editor/react";
import { Allotment, AllotmentHandle } from "allotment";
import PipelineOrchestration from "@components/orchestration/PipelineOrchestration.component";
import styled from 'styled-components';
import { Updater, useImmer } from "use-immer";
import { useRouteBlocker } from "@services/routing.service";
import { ApiError } from "@services/api/api.service";
import PliableLoader from "@components/loaders/PliableLoader.component";
import PipelineNodeSelector from "@components/pipelineNodes/PipelineNodeSelector.component";
import PipelineNodeColumnSelector, { PipelineNodeMultiColumnSelector } from "@components/pipelineNodes/PipelineNodeColumnSelector.component";
import PipelineNodeFieldsMapping from "@components/pipelineNodes/mapping/PipelineNodeFieldsMapping.component";
import SaveButton from "@components/button/SaveButton.component";
import PipelineNodeNotFound from "@components/pipelineNodes/PipelineNodeNotFound.component";
import PipelineNodeReportEditor from "@components/pipelineNodes/PipelineNodeReportEditor";
import PipelineNodeReportBuilder from "@components/pipelineNodes/PipelineNodeReportBuilder.component";
import PipelineNodeMappingConfigForm from "@components/pipelineNodes/PipelineNodeMappingConfigForm.component";
import PipelineNodeBasicConfiguration from "@components/pipelineNodes/configuration/PipelineNodeBasicConfiguration.component";
import PipelineNodeExtrasMenu from "@components/pipelineNodes/PipelineNodeExtrasMenu.component";
import PipelineNodeWhitelistConfiguration from "@components/pipelineNodes/configuration/PipelineNodeWhitelistConfiguration.component";
import PipelineNodeOutputConfiguration from "@components/pipelineNodes/configuration/PipelineNodeOutputConfiguration.component";
import SourceSelector from "@components/sources/SourceSelector.component";
import PipelineNodeColumnOrder from "@components/pipelineNodes/PipelineNodeColumnOrder.component";
import { SnowflakeTableSelection } from "./NewPipelineNode.page";
import PipelineNodeHelper from "@components/pipelineNodes/PipelineNodeHelper.component";
import Danger from "@components/statusIndicators/Danger.component";
import FullScreenTakeover from "@components/general/FullScreenTakeover.component";

export const DataPaneHeader = styled.div`
background: var(--ct-light);
height: 30px;
line-height: 30px;
padding: 0px 1rem;
display: flex;
flex-direction: row;
cursor: pointer;
border-bottom: 1px solid var(--ct-border-color);

&:hover {
    background-color: var(--ct-border-color);
}

h2 {
    margin: 0px;
    padding: 0px;
    line-height: 30px;
    font-weight: 200;
    font-size: 16px;
    flex: 1;
}

button {
    border: none;
    background: none;
    padding: 0px;
    margin: 0px;
    font-size: 24px;
    font-weight: bold;
    color: var(--ct-text-muted);

    &:hover {
        color: black;
        cursor: pointer;
    }
}
`

interface NodeConfigProps {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

const ViewConfig = (props: NodeConfigProps) => {
    const inDraftMode = useIsInDraftMode();
    const upstreamNode = usePipelineNode(props.node.upstream_node_ids[0]);
    return <div>
        <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>
        <hr />
        <h2>View Settings</h2>
        <Form.Group className="mb-3">
            <Form.Label>Upstream Node</Form.Label>
            <PipelineNodeSelector
                selectedId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                onSelect={(selected: PipelineNode | undefined) => {
                    props.onChange(draft => {
                        if (selected) {
                            draft.upstream_node_ids = [selected.id as string]
                        } else {
                            draft.upstream_node_ids = [];
                        }
                        draft.fields = [];
                    });
                }}
                
            />

        </Form.Group>
        {upstreamNode.isLoading && <i className="mdi mdi-loading mdi-spin"></i>}
        {upstreamNode.data && <>
            <Form.Group className="mb-3">
                <Form.Label>Select Column(s)</Form.Label>
                <PipelineNodeMultiColumnSelector
                    pipelineNodeId={upstreamNode.data.id as string}
                    onSelect={(fieldIds: string[]) => {
                        props.onChange(draft => {
                            draft.upstream_column_ids = fieldIds;
                        })
                    }}
                    selectedIds={props.node.upstream_column_ids || []}
                    disabled={!inDraftMode}
                />

            </Form.Group>
            {upstreamNode.data.include_in_snapshots && <Form.Group className="mb-3">
                <Form.Check
                    checked={props.node.include_historical_data}
                    label="Include historical snapshot data"
                    onChange={() => {
                        props.onChange(draft => {
                            draft.include_historical_data = !draft.include_historical_data;
                        })
                    }}
                />
                {inDraftMode && <Form.Text>
                    Note that snapshots do not run on a schedule in develop mode, so you may have gaps in your data. In production mode a snapshot is taken after every scheduled build.
                </Form.Text>}
            </Form.Group>}
            
            
        </>}
        <hr />
        <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
        <div className="mb-5"></div>
    </div>
}

const DateDimConfig = (props: NodeConfigProps) => {
    const inDraftMode = useIsInDraftMode();
    return <div>
        {/* <Form.Group className="mb-3">
            <Form.Label>Fiscal Year Start</Form.Label>
            <div className="row">
                <div className="col-6 pe-1">
                    <Form.Group>
                        <Form.Label className="small">Month</Form.Label>
                        <select className="form-control" value={props.node.special_node_args?.fiscal_year_month} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.fiscal_year_month = e.target.value;
                            })
                        }}>
                            <option value="01">January</option>
                            <option value="02">February</option>
                            <option value="03">March</option>
                            <option value="04">April</option>
                            <option value="05">May</option>
                            <option value="06">June</option>
                            <option value="07">July</option>
                            <option value="08">August</option>
                            <option value="09">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>
                    </Form.Group>
                </div>
                <div className="col-6 ps-1">
                    <Form.Group>
                        <Form.Label className="small">Day</Form.Label>
                        <input type="number" className="form-control" min="1" max="31" value={props.node.special_node_args?.fiscal_year_day} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.fiscal_year_day = e.target.value;
                            })
                        }}/>
                        
                    </Form.Group>
                </div>
            </div>
            
        </Form.Group> */}
        <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>
        <hr />
        <h2><i className="mdi mdi-calendar"></i> Calendar Configuration</h2>
        <Form.Group className="mb-3">
            <Form.Label>Date Range</Form.Label>
            <div className={`row ${!inDraftMode ? 'disabled' : ''}`}>
                <div className="col-6 pe-1">
                    <Form.Group>
                        <Form.Label className="small">Start</Form.Label>
                        <Form.Control type="date" value={props.node.special_node_args?.start_date} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.start_date = e.target.value;
                            })
                        }} />
                    </Form.Group>
                    
                </div>
                <div className="col-6 ps-1">
                    <Form.Group>
                        <Form.Label className="small">End</Form.Label>
                        <Form.Control type="date" value={props.node.special_node_args?.end_date} onChange={(e) => {
                            props.onChange(draft => {
                                if (!draft.special_node_args) {
                                    draft.special_node_args = {};
                                }
                                draft.special_node_args.end_date = e.target.value;
                            })
                        }} />
                    </Form.Group>
                </div>
            </div>
            <Form.Text>Defaults to <code>1/1/2020</code> - <code>TODAY</code> if empty</Form.Text>

            
        </Form.Group>
    </div>
}

const SplitConfig = (props: NodeConfigProps) => {
    const inDraftMode = useIsInDraftMode();
    return <>
        <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>
        <hr />
        <h2><i className="mdi mdi-set-split"></i> Split Configuration</h2>
            <Form.Group className="mb-3">
                <Form.Label>Select source model</Form.Label>
                <PipelineNodeSelector
                    disabled={!inDraftMode}
                    selectedId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                    onSelect={(selected: PipelineNode | undefined) => {
                        if (selected) {
                            props.onChange(draft => {
                                draft.upstream_node_ids = [selected.id as string];
                            })
                        } else {
                            props.onChange(draft => {
                                draft.upstream_node_ids = [];
                            })
                        }
                    }}
                />
            </Form.Group>
            <Form.Group className="mb-3">
                <Form.Label>Select source model column to split on</Form.Label>
                <PipelineNodeColumnSelector
                    disabled={!inDraftMode}
                    pipelineNodeId={props.node.upstream_node_ids.length > 0 ? props.node.upstream_node_ids[0] : ''}
                    selectedId={props.node.special_node_args?.column_id}
                    onSelect={(selected: string) => {
                        props.onChange(draft => {
                            if (!draft.special_node_args) {
                                draft.special_node_args = {};
                            }
                            draft.special_node_args.column_id = selected;
                        });
                    }}
                />

            </Form.Group>
            <Form.Group className="mb-2">
                <Form.Label>Column Format</Form.Label>
                <Dropdown
                    disabled={!inDraftMode}
                    selected={props.node.special_node_args?.column_format}
                    onChange={(newVal: string) => {
                        props.onChange(draft => {
                            if (!draft.special_node_args) {
                                draft.special_node_args = {};
                            }
                            draft.special_node_args.column_format = newVal;
                        });
                    }}
                    options={[
                        {
                            label: 'Delimited String',
                            value: 'DELIMITED_STRING',
                        }, {
                            label: 'JSON Array',
                            value: 'ARRAY',
                        }
                    ]}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Delimiter</Form.Label>
                <Form.Control disabled={!inDraftMode || (props.node.special_node_args?.column_format != 'DELIMITED_STRING')} value={props.node.special_node_args?.delimiter} onChange={(e) => {
                    props.onChange(draft => {
                        draft.special_node_args!.delimiter = e.target.value;
                    })
                }}
                />
            </Form.Group>
        <hr />
        <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>
        <hr />
        <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>
        <div className="mb-5"></div>
    </>
}

const SourceTableConfig = (props: NodeConfigProps) => {
    
    const [compositeKeyOptions, setCompositeKeyOptions] = useState<Option[]>([]);
    const [fields, setFields] = useState<PipelineNodeField[]>(props.node.fields);
    const [showColumn, setShowColumn] = useState<boolean>(false);
    
    const pipelineNode = usePipelineNode(props.node.id as string);
    const sourceColumns = useSourceColumns(props.node.id as string);

    const inDraftMode = useIsInDraftMode();
    const [selectedTable, setSelectedTable] = useState('');
    const [showDbTableHelp, setShowDbTableHelp] = useState(false);

    useEffect(() => {
        if (pipelineNode.data && pipelineNode.data.fields) {
            setFields(pipelineNode.data.fields);
            setSelectedTable(pipelineNode.data.table_name);
        }
    }, [pipelineNode.data, pipelineNode.dataUpdatedAt]);

    const onColumnToggle = useCallback((field: PipelineNodeField) => {
        if (!props.node) return;
        const updatedShowColumn = field.show_column ?? false;
        setShowColumn(updatedShowColumn);
    }, [props.node]);
    
    const onNodeFieldChange = useCallback((newFields: PipelineNodeField[]) => {
        if (!props.node) return;
        setFields(newFields);
        props.onChange(draft => {
            draft.fields = newFields;
        });

    }, [props]);
    
    // const saveColumnView = useCallback(async (newFields: PipelineNodeField[]) => {
    //     try {
    //         setFields(newFields);
    //         const selectedField = newFields.find(field => field.id === props.node.id);
    //         if (selectedField) {
    //             setShowColumn(selectedField.show_column ?? false);
    //         }
    //         await PipelineNodeORM.patch(props.node.id as string, {
    //             fields: newFields,
    //         });
    //         await pipelineNode.refetch();
    //     } catch (error) {
    //         toast('error', 'Error', 'Failed to save column view.');
    //     }
    // }, [props.node.id, pipelineNode, props.node.fields, fields]);

    useEffect(() => {
        if (sourceColumns.data) {
            setCompositeKeyOptions(sourceColumns.data.map(c => {
                return {
                    value: c.name,
                    description: c.type,
                    label: c.name,
                }
            }))

            const selection = sourceColumns.data.filter(tc => tc.primary_key === 'Y').map(tc => tc.name);


            if (!!props.node.id && !props.node.composite_key) {
                props.onChange(draft => {
                    draft.composite_key = selection;
                });
            }
        }
    }, [sourceColumns.dataUpdatedAt, props.node.composite_key, props.onChange]);

    if (sourceColumns.isLoading) {
        return <PliableLoader/>
    }
    return <>
        <div>
            <div className="card">
                <div className="card-body">
                    <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>

                </div>
            </div>
            <hr />
            <div className="card">
                <div className="card-body">
                    <h2><i className="mdi mdi-database"></i> Configure Source</h2>
                    <Form.Group className="mb-3">
                        <Form.Label>Primary Key</Form.Label>
                        <MultiDropdown
                            disabled={!inDraftMode}
                            selected={props.node.composite_key ? props.node.composite_key : []}
                            options={compositeKeyOptions}
                            onChange={(newVal: string[]) => props.onChange(draft => {
                                draft.composite_key = newVal;
                            })}
                        />
                        <Form.Text>Pliable uses this to automatically assign a unique ID to each record. If multiple records have the same primary key, all but the most recently updated record will be removed.</Form.Text>
                    </Form.Group>
                    { !props.node.flat_file && (
                        <Form.Group className="mb-3">
                            <Form.Label>Select database table</Form.Label>
                            <SnowflakeTableSelection
                                onSelect={(newVal: string) => {
                                    props.onChange(draft => {
                                        setSelectedTable(newVal);
                                        draft.table_name = newVal;
                                    });
                                }} 
                                currentSelection={selectedTable}
                            />
                            <Form.Text><a className="force-link" onClick={() => setShowDbTableHelp(true)}>Don't see the table you're looking for?</a></Form.Text>
                        </Form.Group>
                    )}

                    <Form.Group className="mb-3">
                        <Form.Label>Last Modified Column</Form.Label>
                        <Dropdown
                            disabled={!inDraftMode}
                            selected={props.node.last_modified_column}
                            options={compositeKeyOptions}
                            onChange={(newVal: string) => props.onChange(draft => {
                                draft.last_modified_column = newVal;
                            })}
                        />
                        <Form.Text>Provide a <code>DATE</code> or <code>DATETIME</code> column here so Pliable can determine which records have changed. Without this, Pliable will reprocess the entire table whenever you run a build.</Form.Text>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Associated Data Source</Form.Label>
                        <SourceSelector
                            onSelect={(selectedId: string) => {
                                props.onChange(draft => {
                                    draft.source_id = selectedId;
                                });
                            }}
                            selectedId={props.node.source_id || ''}
                            disabled={!inDraftMode}
                        />
                    </Form.Group>
                </div>
            </div>
            
            <hr/>
            {fields.length > 0 && (
                <div className="card">
                    <div className="card-body">
                        <PipelineNodeColumnOrder
                            fields={fields}
                            shape={props.node.shape as any}
                            onClickColumn={onColumnToggle}
                            onChangeNodeField={onNodeFieldChange}
                        />
                    </div>
                </div>
                
            )} 
            <hr />
            <div className="card">
                <div className="card-body">
                    <PipelineNodeWhitelistConfiguration node={props.node} onChange={props.onChange}/>

                </div>
            </div>
            <hr />
            <div className="card">
                <div className="card-body">
                    <PipelineNodeOutputConfiguration node={props.node} onChange={props.onChange}/>

                </div>
            </div>
            <div className="mb-5"></div>
        </div>
    </>

}

const CustomSQLConfig = (props: NodeConfigProps) => {
    const editorRef = useRef<any>();
    const inDraftMode = useIsInDraftMode();
    const [showPliableColumnModal, setShowPliableColumnModel] = useState(false);
    const [completionDisposable, setCompletionDisposable] = useState<any>();

    useEffect(() => {
        if (props.node.id && !props.node.ignore_pliable_columns_in_custom_sql && !!props.node.needs_pliable_columns_in_custom_sql && inDraftMode) {
           
            setShowPliableColumnModel(true);
        }
    }, [props.node.ignore_pliable_columns_in_custom_sql, props.node.needs_pliable_columns_in_custom_sql, inDraftMode])
    
    const editorDidMount = useCallback((editor: any, monacoParam: any) => {
        editorRef.current = editor;

        // setCompletionDisposable(
        //     monacoParam.languages.registerCompletionItemProvider('*', {
        //         provideCompletionItems: function(model: any, position: any) {

        //             // add BO objects
        //             const suggestions: any[] = props.otherFieldsForAutocomplete.map(f => {
        //                 return {
        //                     label: f,
        //                     kind: monacoParam.languages.CompletionItemKind.Variable,
        //                     insertText: `"${f}"`

        //                 }
        //             })
    

        //             return {
        //                 suggestions: suggestions
        //             };
        //         }
        //     })
        // );
    }, []);

    const addColumns = useCallback(() => {
        props.onChange(draft => {

            draft.custom_sql = 'with original_model as (\n' + draft.custom_sql + `\n)\n\nselect *, ('plb:::m:${draft.name}::r:' || sha1(hash(*)::string)) as _plb_uuid, current_timestamp() as _plb_loaded_at from original_model`;
        });
        setShowPliableColumnModel(false);
    }, [props.onChange]);

    const ignore = useCallback(() => {
        props.onChange(draft => {
            draft.ignore_pliable_columns_in_custom_sql = true;
        });
        setShowPliableColumnModel(false);
    }, [props.onChange]);

    const [showFullScreen, setShowFullScreen] = useState(false);
    return <>
        <Modal show={showPliableColumnModal} onHide={() => setShowPliableColumnModel(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                This node needs a unique ID column called <code>_plb_uuid</code> and a timestamp column called <code>_plb_loaded_at</code> in order to be used in other Pliable models. Do you want to automatically add those columns?
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-light me-1" onClick={ignore}>Ignore</button>
                <button className="btn btn-pliable" onClick={addColumns}>Add Columns</button>
            </Modal.Footer>
        </Modal>
        <FullScreenTakeover show={showFullScreen} onHide={() => setShowFullScreen(false)}>
            {showFullScreen && <Editor
                onChange={(value) => props.onChange(draft => {
                    draft.custom_sql = value ? value as string : '';
                })}
                height="100%"
                defaultLanguage="sql"
                options={{
                    readOnly: !inDraftMode,
                    minimap: { enabled: false },
                }}
                value={props.node.custom_sql}
                onMount={(editor, monaco) => editorDidMount(editor, monaco)}
            />}
        </FullScreenTakeover>
        <div className="card mb-2">
            <div className="card-body">
                <PipelineNodeBasicConfiguration node={props.node} onChange={props.onChange}/>

            </div>
        </div>
        
        <div className="card">
            
            <div className="card-body" >
                <div className="d-flex center-vertically mb-2">
                    <h2 className="mb-0 flex-1">
                        <i className="mdi mdi-code-braces"></i> Custom SQL
                        
                    </h2>
                    <button className="icon-button font-24 float-right" title="Fullscreen" onClick={() => {
                        setShowFullScreen(true);
                    }}>
                        <i className="mdi mdi-fullscreen"></i>
                    </button>
                </div>
                
                <Editor
                    onChange={(value) => props.onChange(draft => {
                        draft.custom_sql = value ? value as string : '';
                    })}
                    height="500px"
                    defaultLanguage="sql"
                    options={{
                        readOnly: !inDraftMode,
                        minimap: { enabled: false },
                    }}
                    value={props.node.custom_sql}
                    onMount={(editor, monaco) => editorDidMount(editor, monaco)}
                />
            </div>
        </div>
        <div className="mb-5"></div>
    </>
}

const PipelineNodeConfigurationPage = () => {
    const { pipelineNodeId } = useParams();
    const pipelineNode = usePipelineNode(pipelineNodeId as string);

    const allotmentRef = useRef<AllotmentHandle>(null);
    const [dataViewerIsOpen, setDataViewerIsOpen] = useState(false);
    const isInDraft = useIsInDraftMode();

    const [showSql, setShowSql] = useState(false);


    const [editingNode, setEditingNode] = useImmer<PipelineNode>({
        id: null,
        name: '',
        label: '',
        description: '',
        table_name: '',
        upstream_node_ids: [],
        fields: [],
        node_type: '',
        flat_file: false
    });
    
    const onEditNodeChange = useCallback((n: any) => {
        setEditingNode(n);

        // Weird change, haven't figured out why this was happening,
        // but there was an infinite loop in dev that this somehow fixed.
        setTimeout(() => {
            setPageDirty(true);
        })
        
    }, [pipelineNode.dataUpdatedAt]);


    useEffect(() => {
        if (pipelineNode.data) {
            setEditingNode(pipelineNode.data);
            setPageDirty(false);
        }
    }, [pipelineNode.dataUpdatedAt]);

    const allotmentResized = useDebouncedCallback((newSizes: number[]) => {
        if (newSizes[1] === 30) {
            setDataViewerIsOpen(false);
        } else {
            setDataViewerIsOpen(true);
        }
    }, 250);

    const openDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }
        allotmentRef.current.resize([1000, 1000]);
    }, [allotmentRef]);

    const toggleDataViewer = useCallback(() => {
        if (!allotmentRef.current) {
            return;
        }

        if (dataViewerIsOpen) {
            // There's a resize issue with the editor so we need to switch back to table mode first
            setShowSql(false);
            window.requestAnimationFrame(() => {
                allotmentRef.current!.reset();

            })
        } else {
            allotmentRef.current!.resize([1000, 1000]);

        }
        
    }, [allotmentRef, dataViewerIsOpen]);


    const [saving, setSaving] = useState(false);
    const save = useCallback(async () => {
        setSaving(true);
        try {
            await PipelineNodeORM.save(editingNode);
            invalidatePipelineNodes();
            invalidateMissionControlDataFlowData();
            
            setPageDirty(false);
        } catch (err) {
            toast('danger', 'Error', getErrorMessage(err));
        } finally {
            setSaving(false);
        }
        
    }, [pipelineNode.dataUpdatedAt, editingNode]);

    const { pageDirty, setPageDirty } = useRouteBlocker(save);

    const onChange = useCallback((attr: keyof PipelineNode, newVal: any) => {
        setEditingNode(draft => {
            // @ts-ignore
            draft[attr] = newVal;
        })
        // setEditingNode(draft => {
        //     // @ts-ignore
        //     draft[attr] = newVal;
        // });
    }, [editingNode, setEditingNode, setPageDirty]);

   


    useEffect(() => {
        // Switch back to table view if they change the node ID
        setShowSql(false);
    }, [pipelineNodeId]);

    const topPane = <div style={{padding: '2.25rem 2.25rem 0 2.25rem', height: '100%', overflowY: 'scroll'}}>
        {editingNode && editingNode.build_error && <div className="mb-2">
            <Danger hideIcon>
            <div className="w-100">
                    <h4>Build Error</h4>
                    <div className="font-13 font-code">{editingNode.build_error}</div>
                </div>    
            </Danger>
        </div>}
        {editingNode.node_type == 'CUSTOM' && (
            <>
                <CustomSQLConfig node={editingNode} onChange={onEditNodeChange}/>
            </>
        )}

        {pipelineNode.data && pipelineNode.data.node_type == 'SOURCE' && (
            <>
                <SourceTableConfig node={editingNode} onChange={onEditNodeChange}/>
            </>
        )}

        {editingNode.node_type == 'STACK' && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'SEED' && (
            <>
                <div className="p-3">
                    This is a read-only seed file.
                </div>
            </>
        )}

        {pipelineNode.data && ['STAGING', 'BUSINESS_OBJECT'].includes(pipelineNode.data.node_type) && (
            <>
                {/* Support for legacy without migration since otherwise we'd have to interpret what each one meant. */}
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    enableMerge
                />
            </>
        )}

        {['MERGE', 'DEDUPLICATE'].includes(editingNode.node_type) && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    mergeTextMode="MERGE"
                    enableMerge
                />
            </>   
        )}
        {editingNode.node_type == 'SUMMARIZE' && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    enableMerge
                    limitToSingleSource
                    mergeTextMode='GROUP_BY'
                />
            </>   
        )}
        {editingNode.node_type == 'IDENTIFY' && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    enableMerge
                    limitToSingleSource
                    mergeTextMode="IDENTIFY"
                    requireMerge
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'DIMENSION' && (
            <>
            
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    enableMerge
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'FACT' && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    enableMerge
                />
            </>   
        )}
        {editingNode.node_type == 'SPLIT' && (
            <>
                <SplitConfig
                    node={editingNode}
                    onChange={onEditNodeChange}
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'DATE_DIMENSION' && (
            <>
                <DateDimConfig
                    node={editingNode}
                    onChange={onEditNodeChange}
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'VIEW' && (
            <>
                <ViewConfig
                    node={editingNode}
                    onChange={onEditNodeChange}
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'DESTINATION' && (
            <>
                <PipelineNodeMappingConfigForm
                    node={editingNode}
                    onChange={onEditNodeChange}
                    limitToSingleSource
                    enableMerge={false}
                />
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'DATAMART' && (
            <>
                <PipelineNodeBasicConfiguration node={editingNode} onChange={onEditNodeChange}/>
                <hr />
                <PipelineNodeFieldsMapping
                    node={editingNode}
                    onChange={onEditNodeChange}
                    includeRelationships
                    enableMerge
                    requireMerge
                    mergeTextMode='GROUP_BY'
                    limitToSingleFieldMap
                    
                />
                
            </>   
        )}
        {pipelineNode.data && pipelineNode.data.node_type == 'REPORT' && (
            <>
                {/* If the report has measures or dimensions and NOT dimension_ids/measure_ids, show the old form */}
                {((pipelineNode.data.measures && pipelineNode.data.measures.length > 0 && !pipelineNode.data.measure_ids) || (pipelineNode.data.dimensions && pipelineNode.data.dimensions.length > 0 && !pipelineNode.data.dimension_ids)) && <>
                    <PipelineNodeReportEditor
                        node={editingNode}
                        onChange={onEditNodeChange}
                    />
                </>}
                {(pipelineNode.data.measure_ids || pipelineNode.data.dimension_ids || ((!pipelineNode.data.measures || pipelineNode.data.measures.length == 0) && (!pipelineNode.data.dimensions || pipelineNode.data.dimensions.length == 0))) && <>
                    <PipelineNodeReportBuilder
                        node={editingNode}
                        onChange={onEditNodeChange}
                    />
                </>}
                
                
            </>   
        )}
    </div>;



    if (pipelineNode.status == 'error' && (pipelineNode.error as ApiError).code == 404) {
        return <PipelineNodeNotFound/>
    }

    if (!pipelineNode.data) {
        return <></>;
    }
    return <>
            <PipelineNodeSubnav pipelineNodeId={pipelineNodeId as string}>
                <DraftOnly>
                    <SaveButton
                        className=""
                        disabled={!pageDirty}
                        onClick={save}
                    />
                    </DraftOnly>  
                    <ProdOnly>
                        <button className="btn btn-outline-secondary hover-only" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            enterDraftMode('edit-button');
                        }}>
                            <i className="mdi mdi-pencil"></i> Edit
                        </button>
                    </ProdOnly>
               
                 
            </PipelineNodeSubnav>
            <div style={{height: 'calc(100vh - 150px)'}}>
                <Allotment vertical ref={allotmentRef} onChange={allotmentResized}>
                    <Allotment.Pane minSize={200}>
                        
                        {topPane}
                    </Allotment.Pane>
                    <Allotment.Pane snap={false} minSize={30} preferredSize={30}>
                        <DataPaneHeader onClick={() => toggleDataViewer()}>
                            <h2>View Data</h2>
                            {dataViewerIsOpen && <>
                                <button onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    setShowSql(!showSql)
                                }} className="me-3">
                                    {showSql && <i className="mdi mdi-table"/>}
                                    {!showSql && <i className="mdi mdi-code-braces"/>}
                                </button>
                            </>}
                            
                            <button >
                                {dataViewerIsOpen && <i className="mdi mdi-chevron-down"></i>}
                                {!dataViewerIsOpen && <i className="mdi mdi-chevron-up"></i>}
                            </button>
                        </DataPaneHeader>
                        <Pane>
                        {dataViewerIsOpen && <PaneContent>
                            {showSql && <>
                                <Editor
                                    height="100%"
                                    defaultLanguage="sql"
                                    options={{
                                        readOnly: true,
                                        minimap: { enabled: false },
                                    }}
                                    value={editingNode.cached_generated_sql}
                                />
                            </>}
                            {!showSql && <>
                                
                                <PipelineNodeDataTable
                                    pipelineNodeId={pipelineNodeId as string}
                                />
                            </>}
                            
                            </PaneContent> 
                        }
                        </Pane>
                    </Allotment.Pane>
                </Allotment>
            </div>
            
       </>;
                  
                            
                        
}

export default PipelineNodeConfigurationPage;