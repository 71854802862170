import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

// Pages
import AuthenticatedRootPage from '@pages/AuthenticatedRootPage.page';
import UnauthenticatedRootPage from '@pages/UnauthenticatedRootPage.page';
import ErrorPage from '@pages/Error.page';
import ApiConnectionErrorPage from '@pages/ApiConnectionErrorPage.page';
import Welcome from '@pages/Welcome.page';
import CreateNewAccount from '@pages/CreateNewAccount.page';
import AfterLogin from '@pages/AfterLogin.page';
import SourceConfigPage from '@pages/SourceConfig.page';
import AccountSignup from '@pages/AccountSignup.page';
import NoNavWrapperPage from '@pages/Onboarding/OnboardingWrapper.page';
import DatabaseSetupPage from '@pages/Onboarding/DatabaseSetup.page';
import NewSourcePage from '@pages/NewSourcePage.page';

import StartTemplatePage from '@pages/Template/StartTemplate.page';
import ViewTemplateGroupPage from '@pages/Template/ViewTemplateGroup.page';
import FivetranSourceStepReturnPage from '@pages/Template/steps/FivetranSourceStepReturn.page';

import SourceFinishFivetranPage from '@pages/SourceFinishFivetran.page';

import PipelineNodeDataPage from '@pages/PipelineNode/PipelineNodeData';
import PipelineNodeRelationshipPage from '@pages/PipelineNode/PipelineNodeRelationship.page';
import PipelineNodesListPage from '@pages/PipelineNode/PipelineNodesList.page';
import TenantReturn from '@pages/TenantReturn.page';
import TenantBuildSchedulerPage from '@pages/TenantConfig/TenantBuildScheduler.page';
import DataAssistantPage from '@pages/Assistant.page';

import NewPipelineNodePage from '@pages/PipelineNode/NewPipelineNode.page';
import GithubIntegrationPage from '@pages/Onboarding/GithubIntegration.page';
import PipelineNodeConfigurationPage from '@pages/PipelineNode/PipelineNodeConfiguration.page';
import SeedPage from '@pages/Seeds/Seed.page';
import BuildHistoryPage from '@pages/BuildHistory.page';
import DagPage from '@pages/Dag.page';
import OnboardingWizardPage from '@pages/Onboarding/OnboardingWizard.page';
import TenantAuthorizeSourcesPage from '@pages/TenantConfig/TenantAuthorizeSources.page';
import DashboardEditorPage from '@pages/Dashboard/DashboardEditor.page';
import HomePage from '@pages/Home.page';
import PipelineNodeRecordPage from '@pages/PipelineNode/PipelineNodeRecord.page';
import PipelineNodeFilesPage from '@pages/PipelineNode/PipelineNodeFiles.page';
import TenantListSourcesPage from '@pages/TenantConfig/TenantListSources.page';
import EjectTemplatePage from '@pages/EjectTemplate.page';
import PipelineNodeWrapper from '@pages/PipelineNode/PipelineNodeWrapper.page';
import DataModelBusinessObjectsPage from '@pages/DataModel/DataModelBusinessObjects.page';
import DataModelSourcesPage from '@pages/DataModel/DataModelSources.page';
import DataModelReportingPage from '@pages/DataModel/DataModelReporting.page';
import DataModelSemanticLayer from '@pages/DataModel/DataModelSemanticLayer.page';
import SupersetBiPage from '@pages/SupersetBi.page';
import LogoutPage from '@pages/Logout.page';
import SupersetSetupPage from '@pages/TenantConfig/TenantSupersetSetup.pages';
import ExportTemplatePage from '@pages/Template/ExportTemplate.page';
import MyTemplatesPage from '@pages/Template/MyTemplates.page';
import InstallTemplatePage from '@pages/Template/InstallTemplate.page';
import AccountDisabledPage from '@pages/AccountDisabled.page';
import SnowflakeNativeAppAuthorization from '@pages/SnowflakeNativeAppAuthorization.page';
import CleaningWizardPage from '@pages/Wizard/CleaningWizard.page';
import BusinessObjectWizardPage from '@pages/Wizard/BusinessObjectWizard.page';
import RelationshipWizardPage from '@pages/Wizard/RelationshipWizard.page';
import PipelineNodeHomePage from '@pages/PipelineNode/PipelineNodeHome.page';
import SourcesWrapperPage from '@pages/Sources/SourcesWrapper.page';
import SourcesDiagramPage from '@pages/Sources/SourcesDiagram.page';
import SnowflakeDataSourcePage from '@pages/Sources/SnowflakeDataSource.page';
import FlatFilesDataSource from '@pages/Sources/FlatFilesDataSource.page';
import DataModelWizard from '@pages/Wizard/DataModelWizard';
import DataSourceWizardPage from '@pages/Wizard/DataSourceWizard.page';
import MetricDetailsPage from '@pages/MetricDetails.page';
import BillingAccountPage from '@pages/TenantConfig/billing/BillingAccount.page';
import AllSourcesPage from '@pages/Sources/AllSources.page';
import ReportsPage from '@pages/PipelineNode/Reports.page';
import SettingsWrapperPage from '@pages/TenantConfig/SettingsWrapper.page';
import ReportWizardPage from '@pages/Wizard/ReportWizard.page';
import BookOnboardingCallPage from '@pages/Onboarding/BookOnboardingCall.page';
import ResendVerification from '@pages/ResendVeritification.page';
import VisualizationsPage from '@pages/Viz/Visualizations.page';
import VisualizationDetailsPage from '@pages/Viz/VisualizationDetails.page';
import BackOfficePage from '@pages/BackOffice.page';
import AnalysisScreen from '@pages/Analysis/AnalysisScreen.page';

import ApiDocsPage from '@pages/docs/ApiDocs.page';
import BeginConnectorTypePage from '@pages/Sources/BeginConnection.page';
import AuthorizationComplete from '@pages/Sources/AuthorizationComplete';
import Page404 from '@pages/NotFound.page';


const createRoutes = () => {
    return (
    <Route path="" errorElement={<ErrorPage/>}>
        <Route element={<AuthenticatedRootPage/>}>
            <Route index element={<HomePage/>}/>
            <Route path="home/t/:tab" element={<HomePage/>}/>
            <Route path="dag" element={<DagPage/>}/>
            <Route path="bi" element={<SupersetBiPage />}/>
            <Route path="bi/*" element={<SupersetBiPage />}/>
            <Route path="viz" element={<VisualizationsPage/>}/>
            <Route path="viz/chart/:visualizationId" element={<VisualizationDetailsPage/>}/>
            <Route path="viz/dashboard/:dashboardId" element={<DashboardEditorPage/>}/>
            <Route path="welcome" element={<Welcome/>}/>
            <Route path="create-account" element={<CreateNewAccount />}/>   
            <Route path="unable-to-connect" element={<ApiConnectionErrorPage />}/>
            <Route path="afterlogin" element={<AfterLogin />}/>  
            <Route path="tenant-return/:tenantUuid" element={<TenantReturn />}/> 
            <Route path="account" element={<TenantBuildSchedulerPage />}/> 
            <Route path="account/build-schedule" element={<TenantBuildSchedulerPage />}/> 
            <Route path="account/authorize-sources" element={<TenantAuthorizeSourcesPage />}/> 
            <Route path="account/sources" element={<TenantListSourcesPage />}/> 
            <Route path="account/bi-setup" element={<SupersetSetupPage />}/> 
            <Route path="account/billing" element={<BillingAccountPage />}/> 
            
            <Route path="model/semantic-layer" element={<DataModelSemanticLayer/>}/>
            <Route path="source/new" element={<NewSourcePage/>}></Route>
            <Route path="source/:sourceId" element={<SourceConfigPage/>}/>
            <Route path="source/:sourceId/finish-fivetran" element={<SourceFinishFivetranPage/>}/>
            <Route path="reports" element={<ReportsPage/>}/>

            <Route path="analysis" element={<AnalysisScreen/>}/>
            <Route path="analysis/:analysisId" element={<AnalysisScreen/>}/>
            <Route path="dashboards/:dashboardId" element={<DashboardEditorPage/>}/>
            <Route path="dashboards" element={<DashboardEditorPage/>}/>
            <Route path="connect/:connectorType" element={<BeginConnectorTypePage />}/>

            <Route path="sources" element={<SourcesWrapperPage/>}>
                <Route index element={<AllSourcesPage/>}/>
                <Route path="snowflake" element={<SnowflakeDataSourcePage/>}/>
                <Route path="files" element={<FlatFilesDataSource/>}/>
            </Route>

            <Route path="onboarding" element={<NoNavWrapperPage/>}>
                <Route index element={<OnboardingWizardPage/>}/>
                <Route path=":onboardingStepName" element={<OnboardingWizardPage/>}/>
                <Route path="database" element={<DatabaseSetupPage/>}/>
                <Route path="github" element={<GithubIntegrationPage/>}/>
            </Route>

            <Route path="onboarding-call" element={<BookOnboardingCallPage/>}/>

            {/* <Route path="database" element={<DataExplorerPage/>}/> */}
            <Route path="nodes" element={<PipelineNodesListPage/>}/>
            <Route path="node/new" element={<NewPipelineNodePage/>}/>
            <Route path="node/:pipelineNodeId" element={<PipelineNodeWrapper/>}>
                <Route index element={<PipelineNodeDataPage/>}/>
                <Route path="data" element={<PipelineNodeDataPage/>}/>
                <Route path="data/:recordUuid" element={<PipelineNodeRecordPage/>}/>
                <Route path="config" element={<PipelineNodeConfigurationPage/>}/>
                <Route path="files" element={<PipelineNodeFilesPage/>}/>
            </Route>

            <Route path="relationship/:relationshipId" element={<PipelineNodeRelationshipPage/>}/>

            {/* <Route path="node/:pipelineNodeId/config" element={<PipelineNodeConfigurationPage/>}/>
            <Route path="node/:pipelineNodeId/relationships" element={<PipelineNodeRelationshipPage/>}/>
            <Route path="node/:pipelineNodeId/relationships/:relationshipId" element={<PipelineNodeRelationshipPage/>}/>
            <Route path="node/:pipelineNodeId/data/:recordUuid" element={<PipelineNodeRecordPage/>}/>
            <Route path="node/:pipelineNodeId/visualizations/:visualizationId" element={<PipelineNodeVisualizationPage/>}/>
            <Route path="node/:pipelineNodeId/visualizations" element={<PipelineNodeVisualizationsPage/>}/>
            <Route path="node/:pipelineNodeId/files" element={<PipelineNodeFilesPage/>}/> */}

            
            <Route path="advanced">
                <Route path="new" element={<NewSourcePage/>} />
                <Route path="new/:sourceTypeId" element={<NewSourcePage/>}/>
            </Route>
            <Route path="seeds/:seedId" element={<SeedPage/>}/>
            <Route path="eject" element={<EjectTemplatePage/>} />

            <Route path="templates/export" element={<ExportTemplatePage/>}/>
            <Route path="templates/edit/:templateId" element={<ExportTemplatePage/>}/>
            <Route path="templates/admin" element={<MyTemplatesPage/>}/>
            <Route path="templates/install" element={<InstallTemplatePage/>}/>
            <Route path="template/:templateId" element={<StartTemplatePage />}/>
            
            <Route path="template-group/:templateGroupId" element={<ViewTemplateGroupPage />}/>
            <Route path="template-group/:templateGroupId/source/:sourceId/finish-fivetran" element={<FivetranSourceStepReturnPage />}/>
            <Route path="data-assistant" element={<DataAssistantPage/>}/>
            <Route path="builds" element={<BuildHistoryPage/>}/>
            <Route path="account-disabled" element={<AccountDisabledPage/>}/>
            <Route path="snowflake_authorize" element={<SnowflakeNativeAppAuthorization />}/>

            <Route path="metric/:metricId" element={<MetricDetailsPage/>}/>

            <Route path="wizard/cleaning/:pipelineNodeId" element={<CleaningWizardPage/>}/>
            <Route path="wizard/mapping/:pipelineNodeId" element={<BusinessObjectWizardPage/>}/>
            <Route path="semantic-layer" element={<DataModelWizard/>}/>
            <Route path="wizard/data-source" element={<DataSourceWizardPage/>}/>
            <Route path="wizard/report" element={<ReportWizardPage/>}/>

            <Route path="settings" element={<SettingsWrapperPage/>}>
            </Route>

            <Route path="docs/swagger" element={<ApiDocsPage />}/>

            <Route path="boffice" element={<BackOfficePage />}>
            </Route>
        </Route>
        <Route element={<UnauthenticatedRootPage/>}>
            <Route path="signup" element={<AccountSignup/>}/>   
            <Route path="logout" element={<LogoutPage />}/>   
            <Route path="source/auth-complete" element={<AuthorizationComplete />}/>   
            <Route path="sign-up-thank-you" element={<AccountSignup/>}/>
            <Route path="resend-verification" element={<ResendVerification/>}/>           
        </Route>
        
        <Route path="*" element={<Page404 />}/>
    </Route>
    );
}

export default createRoutes;