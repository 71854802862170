import React from 'react';
import styled from 'styled-components';

const Styles = styled.div`
    padding: 0.3rem;
    font-size: 0.8rem;

    .btn {
        padding: 0.2rem 0.6rem;
        margin: 0 0.2rem;
        border-color: var(--ct-border-color);
    }

    .page-label {
        display: inline-block;
        margin: 0 .8rem;
    }

    select {
        color: var(--ct-body-color);
        border-color: var(--ct-border-color);
    }
`


interface PaginationProps {
    pageIndex: number;
    pageCount: number;
    pageSize: number;
    canPreviousPage: boolean;
    canNextPage: boolean;
    gotoPage: (page: number) => void;
    previousPage: () => void;
    nextPage: () => void;
    setPageSize: (size: number) => void;
}

const DataTablePagination = (props: PaginationProps) => {
    const {
        pageIndex,
        pageCount,
        pageSize,
        canPreviousPage,
        canNextPage,
        gotoPage,
        previousPage,
        nextPage,
        setPageSize,
    } = props;

    return (<Styles>
        <div className="pagination">
            <button 
                className="btn btn-outline-secondary"
                onClick={() => gotoPage(0)} 
                disabled={!canPreviousPage}
                type="button"
                title="First page"
            >
                {'<<'}
            </button>{' '}
            <button 
                className="btn btn-outline-secondary"
                onClick={() => previousPage()} 
                disabled={!canPreviousPage}
                type="button"
                title="Previous page"
            >
                {'<'}
            </button>{' '}
            <div className="page-label d-flex align-items-center justify-content-center">
                <span>
                Page{' '}
                <strong>
                    {pageIndex + 1} of {pageCount}
                </strong>{' '}
                </span>
            </div>
            <button 
                className="btn btn-outline-secondary"
                onClick={() => nextPage()} 
                disabled={!canNextPage}
                type="button"
                title="Next page"
            >
                {'>'}
            </button>{' '}
            <button 
                className="btn btn-outline-secondary"
                onClick={() => gotoPage(pageCount - 1)} 
                disabled={!canNextPage}
                type="button"
                title="Last page"
            >
                {'>>'}
            </button>{' '}
            <select
                value={pageSize}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                    setPageSize(Number(e.target.value));
                }}
            >
                {[25, 100, 1000, 10000].map(size => (
                    <option key={size} value={size}>
                        Show {size}
                    </option>
                ))}
            </select>
            
        </div>
    </Styles>);
};

export default DataTablePagination;