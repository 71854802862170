import Spinner from 'react-bootstrap/Spinner';
import { timeAgo } from '../../services/time.service';
import type { DataLoad } from '@models/dataload';
import type { FC, ReactElement } from 'react';
import { useDataLoadsForSource } from '@stores/data.store';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import InfoAlert from '@components/statusIndicators/InfoAlert.component';
import { summarizeNumber } from '@services/formatting.service';

interface RowProps {
    load: DataLoad;
}

const DataLoadRow: FC<RowProps> = ({ load }): ReactElement => {
    const popoverHoverFocus = (
        <Popover id={`dl-${load.id}`} title="Records Loaded" style={{padding: '7px' }}>
            <strong>Records Loaded:</strong><br />
            
                {Object.entries(load.stream_load_counts).map(([stream, count]) => (
                    
                        <>- {stream}: {summarizeNumber(count as number)} <br /></>
                    
                    ))}
            
        </Popover>
      );
    return (
        <tr>
            <td>
                <span className="text-muted font-13">Started</span>
                <h5 className="font-14 mt-1 fw-normal">{timeAgo(load.started_at)}</h5>
            </td>
            <td>
                {load.status && (
                    <>
                        <span className="text-muted font-13">Status</span>
                        <h5 className="font-14 mt-1 fw-normal">{load.status}</h5>
                    </>
                )}
            </td>
            <td>
                {load.completed_at && (
                    <>
                        <span className="text-muted font-13">Completed</span>
                        <h5 className="font-14 mt-1 fw-normal">{timeAgo(load.completed_at)}</h5>
                    </>
                )}
            </td>
            <td className="table-action text-end">
                {Object.keys(load.stream_load_counts).length > 0 && (
                    <OverlayTrigger
                    trigger={['hover', 'focus']}
                    placement="left"
                    overlay={popoverHoverFocus}
                  >
                    <i className="mdi mdi-file-multiple"></i>
                  </OverlayTrigger>
                )}
            </td>
        </tr>
    );
};

interface DataLoadListProps {
    sourceId?: string;
}

const DataLoadList: FC<DataLoadListProps> = ({ sourceId }): ReactElement => {
    const { 
        data: loads, 
        isLoading, 
        error 
    } = useDataLoadsForSource(sourceId ?? '');

    let innerContent: ReactElement;
    
    if (isLoading) {
        innerContent = (
            <div>
                <Spinner animation="border" role="status" />
                <span>Loading...</span>
            </div>
        );
    } else if (error) {
        innerContent = (
            <div className="alert alert-danger">
                Error loading data
            </div>
        );
    } else if (loads && loads.length > 0) {
        innerContent = (
            <>
                <h4>Sync History</h4>
                <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                        <tbody>
                            {loads.map((dl: DataLoad) => (
                                <DataLoadRow load={dl} key={dl.id} />
                            ))}
                        </tbody>
                    </table>
                </div>
            </>
        );
    } else {
        innerContent = (
            <InfoAlert>You haven't synced this source yet.</InfoAlert>
        );
    }

    return <>{innerContent}</>;
};

export default DataLoadList;