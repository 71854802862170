import { useMemo, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { AnalysisDimensionFilter } from '@models/analysis';
import { MultiDropdown, Option } from '@components/form/Dropdown.component';
import Dropdown from '@components/form/Dropdown.component';
import { useUniqueValuesInDimension } from '@models/reportBuilder';

interface SingleDimensionFilterProps {
    dimensionFilter: AnalysisDimensionFilter;
    onChange: (newFilter: AnalysisDimensionFilter) => void;
    dimensionOptions?: Option[];
    onDelete?: () => void;
    displayType: 'table-row' | 'form';
}

const SingleDimensionFilter = (props: SingleDimensionFilterProps) => {
    const [query, setQuery] = useState<string>('');
    const [debouncedQuery] = useDebounce(query, 2000);

    const { data: dimensionValues, isLoading } = useUniqueValuesInDimension(props.dimensionFilter.dimension_id, debouncedQuery);
    
    const valueOptions = useMemo(() => {
        if (!dimensionValues) return [];
        return dimensionValues.map(v => ({
            value: v,

            // If the value is longer than 50 characters, truncate it and add an ellipses
            label: v.length > 50 ? `${v.slice(0, 50)}...` : v,
        }));
    }, [dimensionValues]);

    const valueForDropdown = useMemo(() => {
        if (!props.dimensionFilter.value) return [];

        // if the dimension value is a string for whatever reason, just add it as a single-element array
        if (typeof props.dimensionFilter.value === 'string') {
            return [props.dimensionFilter.value];
        }
        return props.dimensionFilter.value;
    }, [props.dimensionFilter.value]);

    if (props.displayType == 'table-row') {
        if (props.dimensionFilter.comparator == 'EMPTY') {
            const dimOption = props.dimensionOptions?.find(o => o.value === props.dimensionFilter.dimension_id);
            return <tr>
                <td>
                    {dimOption?.label}
                </td>
                <td>
                    <span className="badge bg-warning">Empty</span>
                </td>
                <td className="text-end">
                    <button className="icon-button" onClick={props.onDelete}>
                        <i className="mdi mdi-delete"></i>
                    </button>
                </td>
            </tr>
        }
        return <tr>
            <td>
                <Dropdown
                    options={props.dimensionOptions || []}
                    selected={props.dimensionFilter.dimension_id}
                    onChange={(newval) => {
                        props.onChange({
                            ...props.dimensionFilter,
                            dimension_id: newval,
                            comparator: 'IN',
                        });
                    }}
                />
            </td>
            <td>
                <div className="d-flex center-vertically w-100">
                    <MultiDropdown
                        allowCreate
                        className="flex-1"
                        options={valueOptions}
                        selected={valueForDropdown}
                        onChange={(newval) => {
                            props.onChange({
                                ...props.dimensionFilter,
                                value: newval,
                                comparator: 'IN',
                            });
                        }}
                        onInputChange={(newQuery) => setQuery(newQuery)}
                    />
                    {isLoading && <i className="mdi mdi-loading mdi-spin ms-2" />}
                    {!isLoading && <i className="mdi mdi-check ms-2" />}
                </div>
            </td>
            <td className="text-end">
                <button className="icon-button" onClick={props.onDelete}>
                    <i className="mdi mdi-delete"></i>
                </button>
            </td>
        </tr>
    }
    return <div>
        <div className="d-flex center-vertically w-100">
            <MultiDropdown
                allowCreate
                className="flex-1"
                options={valueOptions}
                selected={valueForDropdown}
                onChange={(newval) => {
                    props.onChange({
                        ...props.dimensionFilter,
                        value: newval,
                        comparator: 'IN',
                    });
                }}
                onInputChange={(newQuery) => setQuery(newQuery)}
            />
            {isLoading && <i className="mdi mdi-loading mdi-spin ms-2" />}
            {!isLoading && <i className="mdi mdi-check ms-2" />}
        </div>
    </div>
    
}

export default SingleDimensionFilter;