import { PipelineNode } from "@models/pipelineNode";
import DataWhitelistForm from "../PipelineNodeDataWhitelist.component";
import { Updater } from "use-immer";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DefaultSortForm from "@pages/SourceRecordType/DefaultSortForm.component";
import PipelineNodeColumnSelector from "../PipelineNodeColumnSelector.component";
import { useIsInDraftMode } from "@stores/data.store";

interface Props {
    node: PipelineNode;
    onChange: Updater<PipelineNode>;
}

const PipelineNodeOutputConfiguration = (props: Props) => {
    const inDraftMode = useIsInDraftMode();
    return <>
        <div className="mb-3">
            <div className="d-flex center-vertically mb-1">

                <h2 className="mb-0 flex-1">
                    <i className="mdi mdi-star"></i> Advanced
                </h2>
                {/* <button className="btn btn-outline-primary btn-sm" onClick={() => {
                    addNewFieldAtTop();
                }}>
                    <i className="mdi mdi-plus-circle"></i> Add Filter
                </button> */}
            </div>
            <p>Big scary features for deeper control.</p>
        </div>
        <Form.Group className="mb-3">
            <Form.Check
                type="switch"
                label="Enable snapshots for this node"
                checked={!!props.node.include_in_snapshots}
                disabled={!inDraftMode}
                onChange={(e) => {
                    props.onChange(draft => {
                        draft.include_in_snapshots = e.target.checked;
                    });
                }}
            />
            <Form.Text className="text-muted">
                Snapshots are updated after each <Link to="/account/build-schedule">scheduled build <i className="mdi mdi-information-outline"></i></Link>
            </Form.Text>

        </Form.Group>
        <Form.Group className="mb-3">
            <Form.Label>Default Sort Order</Form.Label>
            <DefaultSortForm
                fields={props.node.fields}
                currentSort={props.node.default_sort || []}
                onChange={(sort) => {
                    props.onChange(draft => {
                        draft.default_sort = sort;
                    });
                }}
                disabled={!inDraftMode}
            />
        </Form.Group>
        {['SOURCE', 'SOURCE_FILE', 'STAGING', 'STACK', 'MERGE', 'DIMENSION'].includes(props.node.node_type) && <div>
            <Form.Group className="mb-2">
                <Form.Check
                    type="switch"
                    checked={props.node.incremental_build}
                    onChange={(e) => {
                        props.onChange(draft => {
                            draft.incremental_build = e.target.checked;
                        });
                    }}
                    label="Incremental Builds"
                    disabled={!inDraftMode}
                />
                <Form.Text>
                    When enabled, Pliable will only update records that have changed since the last build.
                </Form.Text>

            </Form.Group>
            {props.node.incremental_build && ['STACK', 'STAGING', 'MERGE', 'DIMENSION'].includes(props.node.node_type) && <Form.Group className="mb-2">
                <Form.Label>Incremental Timestamp Field</Form.Label>
                
                <PipelineNodeColumnSelector
                    pipelineNodeId={props.node.id as string}
                    selectedId={props.node.incremental_timestamp_field_id}
                    onSelect={(fieldId: string) => {
                        props.onChange(draft => {
                            draft.incremental_timestamp_field_id = fieldId;
                        });
                    }}
                    disabled={!inDraftMode}
                />
                <Form.Text>Select the column to use for the "last updated" value for incremental builds. Any record for which this column is later than the current maximum value will be processed with each build.</Form.Text>
            </Form.Group>}
        </div>}
    </>
}

export default PipelineNodeOutputConfiguration;