import React, { useMemo } from 'react';
import { DataWhitelist, DataWhitelistEntry } from '@models/pipelineNode';
import { humanReadableJoin } from '@services/list.service';
import styled from 'styled-components';
import { usePipelineNodes } from '@stores/data.store';

const WhitelistText = styled.span`
    color: var(--ct-gray-600);
    font-style: italic;
`;

interface Props {
    whitelist: DataWhitelist;
}

const getComparatorText = (comparator: string): string => {
    switch (comparator) {
        case 'EQUALS': return 'is';
        case 'NOT_EQUALS': return 'is not';
        case 'CONTAINS': return 'contains';
        case 'NOT_CONTAINS': return 'does not contain';
        case 'GT': return 'is greater than';
        case 'GTE': return 'is greater than or equal to';
        case 'LT': return 'is less than';
        case 'LTE': return 'is less than or equal to';
        case 'STARTS_WITH': return 'starts with';
        case 'NOT_STARTS_WITH': return 'does not start with';
        case 'ENDS_WITH': return 'ends with';
        case 'NOT_ENDS_WITH': return 'does not end with';
        case 'IS_NULL': return 'is empty';
        case 'IS_NOT_NULL': return 'is not empty';
        default: return comparator.toLowerCase().replace(/_/g, ' ');
    }
};

const HumanReadableWhitelist: React.FC<Props> = ({ whitelist }) => {
    const nodes = usePipelineNodes();

    const humanReadableEntries = useMemo(() => {
        if (!nodes.data || whitelist.entries.length === 0) {
            return [];
        }

        return whitelist.entries.map((entry: DataWhitelistEntry) => {
            const node = nodes.data.find(n => n.id === entry.pipeline_node_id);
            const field = node?.fields.find(f => f.id === entry.field_id);
            
            const fieldName = field?.label || entry.field_id;
            const comparator = getComparatorText(entry.comparator);
            
            let valueText = '';
            if (Array.isArray(entry.value)) {
                valueText = humanReadableJoin(entry.value, ', ', ' or ');
            } else if (entry.value !== undefined && entry.value !== null) {
                valueText = entry.value.toString();
            }

            if (['IS_NULL', 'IS_NOT_NULL'].includes(entry.comparator)) {
                return `${fieldName} ${comparator}`;
            }

            return `${fieldName} ${comparator} "${valueText}"`;
        });
    }, [nodes.data, whitelist.entries]);

    if (!whitelist.entries.length) {
        return <WhitelistText>No filters applied</WhitelistText>;
    }

    if (whitelist.logic_gate === 'CUSTOM') {
        return <WhitelistText>Custom SQL filter applied</WhitelistText>;
    }

    const connector = whitelist.logic_gate === 'AND' ? ' and ' : ' or ';
    
    return (
        <WhitelistText>
            Where {humanReadableJoin(humanReadableEntries, ', ', connector)}
        </WhitelistText>
    );
};

export default HumanReadableWhitelist;