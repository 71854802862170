import { subscribe, unsubscribe, emit } from '@nextcloud/event-bus'
import { ReactNode, useCallback, useEffect, useState } from 'react';
import ConfirmationModal, { ConfirmationModalProps } from './ConfirmationModal.component'
import PromptModal, { PromptModalProps } from './PromptModal.component';
import VideoModal, {VideoModalProps} from './VideoModal.component';
import EmojiPickerModal, { EmojiPickerModalProps } from './EmojiPickerModal.component';
import TemplatePickerModal, { TemplatePickerProps } from './TemplatePickerModal.component';
import WaitingModal, { WaitingModalProps } from './WaitingModal.component';
import BuildModal, { BuildModalProps } from './BuildModal.component';
import BuildOrchestrationORM from '@models/buildOrchestration';
import toast from '@services/toast.service';
import { useLocation } from 'react-router-dom';
import HotKetsHelpModal from './HotKeysHelpModal.component';
import { Option } from '@components/form/Dropdown.component';
import ShareTextModal, { ShareTextModalProps } from './ShareTextModal.component';


export const AlertsContainer = () => {
    const [confirmationModalProps, setConfirmationModalProps] = useState<ConfirmationModalProps|undefined>(undefined);
    const [promptModalProps, setPromptModalProps] = useState<PromptModalProps|undefined>(undefined);
    const [emojiPromptModalProps, setEmojiPromptModalProps] = useState<EmojiPickerModalProps|undefined>(undefined);
    const [videoModalProps, setVideoModalProps] = useState<VideoModalProps|undefined>(undefined);
    const [templatePickerModalProps, setTemplatePickerModalProps] = useState<TemplatePickerProps|undefined>(undefined);
    const [waitingModalProps, setWaitingModalProps] = useState<WaitingModalProps|undefined>(undefined);
    const [shareTextProps, setShareTextProps] = useState<ShareTextModalProps|undefined>(undefined);
    const [shortcutsModalProps, setShortcutsModalProps] = useState<any|undefined>(undefined);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showPrompt, setShowPrompt] = useState(false);
    const [showEmojiPrompt, setShowEmojiPrompt] = useState(false);
    const [showVideoModal, setShowVideoModal] = useState(false);
    const [showKeyboardShortcuts, setKeyboardShortcuts] = useState(false);
    const [showTemplatePickerModal, setShowTemplatePickerModal] = useState(false);
    const [showWaitingModal, setShowWaitingModal] = useState(false);
    const [showShareTextModal, setShowShareTextModal] = useState(false);
    const [buildModalProps, setBuildModalProps] = useState<BuildModalProps|undefined>(undefined);
    const [showBuildModal, setShowBuildModal] = useState(false);
    const confirmationModalTriggered = (props: ConfirmationModalProps) => {
        setConfirmationModalProps(props);
        setShowConfirmation(true);
    }

    const promptModalTriggered = (props: PromptModalProps) => {
        setPromptModalProps(props);
        setShowPrompt(true);
    }

    const emojiPromptModalTriggered = (props: PromptModalProps) => {
        setEmojiPromptModalProps(props);
        setShowEmojiPrompt(true);
    }

    const videoModalTriggered = (props: VideoModalProps) => {
        setVideoModalProps(props);
        setShowVideoModal(true);
    }

    const templatePickerModalTriggered = (props: TemplatePickerProps) => {
        setTemplatePickerModalProps(props);
        setShowTemplatePickerModal(true);
    }

    const waitingModalTriggered = (props: WaitingModalProps) => {
        setWaitingModalProps(props);
        setShowWaitingModal(true);
    }

    const waitingModalClosed = (input: any) => {
        setShowWaitingModal(false);
    }

    const keyboardShortcutsTriggered = () => {
        setShortcutsModalProps(true)
        setKeyboardShortcuts(true);
    }

    const onKeyboardShortcutsClosed = (input: any) => {
        setShortcutsModalProps(undefined)
        setKeyboardShortcuts(false);
    }

    const sharetextTriggered = (props: ShareTextModalProps) => {
        setShareTextProps(props);
        setShowShareTextModal(true);
    }

    const buildModalTriggered = (props: BuildModalProps) => {
        setBuildModalProps(props);
        setShowBuildModal(true);
    }

    const buildModalClosed = () => {
        setShowBuildModal(false);
    }

    const onConfirmationClose = () => {
        if (!!confirmationModalProps?.onClose) {
            confirmationModalProps.onClose();
        }
        setShowConfirmation(false);
    }

    const onConfirmationConfirm = () => {
        setShowConfirmation(false);
        if (!!confirmationModalProps?.onConfirm) {
            confirmationModalProps.onConfirm();
        }
    }

    const onPromptClose = useCallback(() => {
        if (!!promptModalProps?.onClose) {
            promptModalProps.onClose();
        }
        setShowPrompt(false);
    }, [promptModalProps]);

    const onPromptConfirm = useCallback((val: string) => {
        setShowPrompt(false);
        if (!!promptModalProps?.onConfirm) {
            promptModalProps.onConfirm(val);
        }
        setPromptModalProps(undefined);
    }, [promptModalProps]);

    const onEmojiPromptClose = useCallback(() => {
        if (!!emojiPromptModalProps?.onClose) {
            emojiPromptModalProps.onClose();
        }
        setShowEmojiPrompt(false);
    }, [emojiPromptModalProps]);

    const onEmojiPromptConfirm = useCallback((val: string) => {
        setShowEmojiPrompt(false);
        if (!!emojiPromptModalProps?.onConfirm) {
            emojiPromptModalProps.onConfirm(val);
        }
    }, [emojiPromptModalProps]);

    const onVideoClose = useCallback(() => {
        if (!!videoModalProps?.onClose) {
            videoModalProps.onClose();
        }
        setShowVideoModal(false);
    }, [videoModalProps]);

    const onTemplatePickerClose = useCallback(() => {
        if (!!templatePickerModalProps?.onClose) {
            templatePickerModalProps.onClose();
        }
        setShowTemplatePickerModal(false);
    }, [templatePickerModalProps]);

    const onBuildModalClose = useCallback(() => {
        if (!!buildModalProps?.onClose) {
            buildModalProps.onClose();
        }
        setShowBuildModal(false);
    }, [buildModalProps]);

    const onShareTextClose = useCallback(() => {
        setShowShareTextModal(false);
    }, [videoModalProps]);


    useEffect(() => {
        subscribe('pliable:alerts:confirmation', confirmationModalTriggered);
        subscribe('pliable:alerts:prompt', promptModalTriggered);
        subscribe('pliable:alerts:emojiPrompt', emojiPromptModalTriggered);
        subscribe('pliable:alerts:video', videoModalTriggered);
        subscribe('pliable:alerts:templatePicker', templatePickerModalTriggered);
        subscribe('pliable:alerts:waiting', waitingModalTriggered);
        subscribe('pliable:alerts:waitingClose', waitingModalClosed);
        subscribe('pliable:alerts:build', buildModalTriggered);
        subscribe('pliable:alerts:closebuild', buildModalClosed);
        subscribe('pliable:alerts:keyboardShortcuts', keyboardShortcutsTriggered);
        subscribe('pliable:alerts:shareText', sharetextTriggered);
        return () => {
            unsubscribe('pliable:alerts:confirmation', confirmationModalTriggered);
            unsubscribe('pliable:alerts:prompt', promptModalTriggered);
            unsubscribe('pliable:alerts:emojiPrompt', emojiPromptModalTriggered);
            unsubscribe('pliable:alerts:video', videoModalTriggered);
            unsubscribe('pliable:alerts:templatePicker', templatePickerModalTriggered);
            unsubscribe('pliable:alerts:waiting', waitingModalTriggered);
            unsubscribe('pliable:alerts:waitingClose', waitingModalClosed);
            unsubscribe('pliable:alerts:build', buildModalTriggered);
            unsubscribe('pliable:alerts:closebuild', buildModalClosed);
            unsubscribe('pliable:alerts:keyboardShortcuts', keyboardShortcutsTriggered);
            unsubscribe('pliable:alerts:shareText', sharetextTriggered);
        };
    }, []);

    const location = useLocation();
    // If the location changes, close the build modal
    useEffect(() => {
        setShowBuildModal(false);
    }, [location.pathname]);



    return <>

        {!!confirmationModalProps && (<ConfirmationModal {...confirmationModalProps} show={showConfirmation} onClose={onConfirmationClose} onConfirm={onConfirmationConfirm}/>)}

        {!!promptModalProps && (<PromptModal {...promptModalProps} show={showPrompt} onClose={onPromptClose} onConfirm={onPromptConfirm}/>)}

        {!!emojiPromptModalProps && (<EmojiPickerModal {...emojiPromptModalProps} show={showEmojiPrompt} onClose={onEmojiPromptClose} onConfirm={onEmojiPromptConfirm}/>)}

        {!!videoModalProps && (<VideoModal {...videoModalProps} show={showVideoModal} onClose={onVideoClose} />)}

        {!!templatePickerModalProps && (<TemplatePickerModal {...templatePickerModalProps} show={showTemplatePickerModal} onClose={onTemplatePickerClose} />)}

        {!!waitingModalProps && (<WaitingModal {...waitingModalProps} show={showWaitingModal} />)}

        {!!buildModalProps && (<BuildModal {...buildModalProps} show={showBuildModal} onClose={onBuildModalClose}/>)}

        {!!shortcutsModalProps && (<HotKetsHelpModal {...shortcutsModalProps} show={showKeyboardShortcuts} onClose={onKeyboardShortcutsClosed}/>)}
        
        {!!shareTextProps && (<ShareTextModal {...shareTextProps} show={showShareTextModal} onClose={onShareTextClose}/>)}
    </>
}


export const confirmation = (props: ConfirmationModalProps) => {
    emit('pliable:alerts:confirmation', props);
}

export const prompt = (props: PromptModalProps) => {
    emit('pliable:alerts:prompt', props);
}

export const showWaiting = (props: WaitingModalProps) => {
    emit('pliable:alerts:waiting', props);
}

export const closeWaiting = () => {
    emit('pliable:alerts:waitingClose', {});
}

export const shareText = (props: ShareTextModalProps) => {
    emit('pliable:alerts:shareText', props);
}

export const showKeyboardShortcuts = () => {
    emit('pliable:alerts:keyboardShortcuts', {});
}



export const getPromptAnswer = async (question: string, header: string = 'Wait', textarea: boolean = false, defaultValue: string = '', dropdownOptions?: Option[]) => {
    const p = new Promise<string>((resolve, reject) => {
        prompt({
            header: header,
            promptText: question,
            useTextArea: textarea,
            promptDefaultValue: defaultValue,
            dropdownOptions: dropdownOptions,
            onClose: () => {
                resolve('');
            },
            onConfirm: (value: string) => {
                resolve(value);
            }
        });
    });

    return p;
}

export const alert = async (header: string, message: string, headerBg: string = '') => {
    confirmation({
        header: header,
        message: message,
        onClose: () => {

        },
        onConfirm: () => {

        },
        confirmationButtonText: 'OK',
        hideCloseButton: true,
        confirmationButtonVariant: 'light',
    });
}

export const requireConfirmation = async (message: string | JSX.Element, header: string = 'Confirmation', confirmationButtonText: string = 'OK', closeButtonText: string = 'No', typedValueExpectation?: string) => {
    const p = new Promise<boolean>((resolve, reject) => {
        confirmation({
            header: header,
            message: message,
            onConfirm: () => {
                resolve(true);
            },
            onClose: () => {
                resolve(false);
            },
            typedValueExpectation: typedValueExpectation,
            confirmationButtonText: confirmationButtonText,
            closeButtonText: closeButtonText,
        });
    });

    return p;
}

export const promptEmoji = (props: EmojiPickerModalProps) => {
    emit('pliable:alerts:emojiPrompt', props);
}

export const showVideo = (props: VideoModalProps) => {
    emit('pliable:alerts:video', props);
}

export const pickTemplate = (props: TemplatePickerProps) => {
    emit('pliable:alerts:templatePicker', props);
}


type voidfunc = () => void;

export const runBuild = async (
    selector: string, 
    force: boolean = false,
    checkForRecords: boolean = false,
    completeButtons?: ReactNode,
    errorButtons?: ReactNode,
) => {
    const orchestration = await BuildOrchestrationORM.buildWithSelector(selector, {force, checkForRecords});

    if (orchestration.status == 'COMPLETE') {
        toast('info', 'No rebuild needed', 'Everything is up to date.');
    }

    emit('pliable:alerts:build', {
        orchestrationId: orchestration.id as string,
        show: true,
        onClose: () => {
            // Do nothing
        },
        completeButtons: completeButtons,
        errorButtons: errorButtons,
    });
}

export const showBuild = async (
    orchestrationId: string, 
) => {

    emit('pliable:alerts:build', {
        orchestrationId,
        show: true,
        onClose: () => {
            // Do nothing
        },
        compactMode: false,
        compactModeCompleteNextButtonText: undefined,
        compactModeOnClickViewData: undefined,
        compactModeOnClickNext: undefined,
        compactModeBuildingCopy: undefined,
    });
}