import { 
    useTable,
    useSortBy, 
    useResizeColumns, 
    usePagination 
} from 'react-table';
import styled from 'styled-components'; 
import { useMemo, useEffect, ReactNode, ReactElement, useCallback, useState, useRef, MouseEvent as ReactMouseEvent } from 'react';
import LoadingCard from '@components/card/LoadingCard.component';
import { GridOnScrollProps, VariableSizeGrid } from 'react-window';
import { formatValue, summarizeNumber, urlEncodeObject, varEx } from '@services/formatting.service';
import { Menu, MenuItem, MenuHeader } from '@szhsin/react-menu';
import { CellAction, PipelineNodeCellAction } from '@models/pipelineNode';
import { useNavigate } from 'react-router-dom';
import DataTablePagination from './Pagination.component';
import { PageContent, PaneContent, PaneFooter } from '@pages/PageStructure.component';
import Warning from '@components/statusIndicators/Warning.component';

export interface Column {
    header: string;
    key: string;
    formatter?: string;
    onClick?: (value: any, rowData?: any) => void;
    display?: (value: any, rowData?: any) => ReactElement;
}

export interface CellFormatOptions {
    cellColor?: string;
    textColor?: string;
}

export interface CellActionCommand {
    uri: string;
    method: string;
}

export const cellActionToCommand = (cell_action: PipelineNodeCellAction, column: string, record: any) : CellActionCommand =>{
    switch(cell_action.action){
        case CellAction.NAVIGATE_TO_NODE:
            const qry = (cell_action.config.search_tmpl) ? varEx(cell_action.config.search_tmpl, record) : '';
            const uri = `/node/${cell_action.config.node_id}?q=${encodeURIComponent(qry)}`;
            return { uri, method: 'navigate' }
            break;
        case CellAction.REFER_TO:
            const payload = Object.assign({}, cell_action.config.data);
            for (const [key, value] of Object.entries(payload)) {
                payload[key] = varEx(value as string, record);
            }
            let refer_to_uri = `${cell_action.config.url}${(cell_action.config.url.includes('?')) ? '&' : '?'}${urlEncodeObject(payload)}`;
            return { uri: refer_to_uri, method: 'referal' }
            break;
        default:
            throw new Error(`Unhandled cell action: ${cell_action.action}`);
    }
}


interface Props {
    loading?: boolean;
    columns: Column[];
    data: Record<string, any>[];
    columnFormatters: { [columnId: string]: (value: any) => CellFormatOptions; };
    columnActions: { [columnId: string]: PipelineNodeCellAction[] };
    lastRowAsFooter?: boolean;
    groupByColumn?: string;
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    totalRecords?: number;
    onShowColumnStats?: (column: string) => void;
    onShowColumnLineage?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
    onCleanColumn?: (column: string) => void;
    defaultSort?: any[];
    enableColumnContextMenu?: boolean;
    enableRowContextMenu?: boolean;
    onRowContextMenuSelect?: (row: any, contextMenuOption: string) => any;
    justTable?: boolean;
}



export interface FetchDataParams {
    pageIndex: number;
    pageSize: number;
    sortBy: any;
}


interface SimpleProps {
    loading?: boolean;
    data: Record<string, any>[];
    columns?: Column[]|string[];
    columnFormatters?: { [columnId: string]: (value: any) => CellFormatOptions; };
    columnActions?: { [columnId: string]: PipelineNodeCellAction[] };
    onColumnClick?: {
        [key: string]: (value: any) => void;
    }
    onCellDoubleClick?: any;
    highlightRowsByIndexes?: number[];
    totalPageCount?: number;
    totalRecords?: number;
    onShowColumnStats?: (column: string) => void;
    onShowColumnLineage?: (column: string) => void;
    onCleanColumn?: (column: string) => void;
    onFilterSortChange?: (params: FetchDataParams) => void;
    initialSort?: any[];
    enableColumnContextMenu?: boolean;
    enableRowContextMenu?: boolean;
    onRowContextMenuSelect?: (row: any, contextMenuOption: string) => any;
    justTable?: boolean;
}

const Styles = styled.div`
width: 100%;
height: 100%;
overflow: auto;
background: white;

.table {
    display: inline-block;
    border-spacing: 0;
    font-size: 12px;
    border-width: 0px 0px 1px 0px;
    height: auto;
    width: 100%;
    margin-bottom: 0px;
    border-bottom: none;

    .thead {
        position: sticky;
        z-index: 1;
        width: fit-content;
        top: 0;
    }

    .header-grid::-webkit-scrollbar {
        display: none;
    }

    .header-grid {
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;     /* Firefox */
    }

    .tr {
        :last-child {
            // .td {
            //     border-bottom: 0;
            // }
        }
    }

    .tr.highlighted .td {
        --ct-table-accent-bg: rgba(255, 159, 0, 0.25) !important;
    }

    .th {
        .th-content {
            a {
                color: black;
                font-weight: 700;
                flex: 1;
            }
              
            a:hover {
                text-decoration: underline;
                color: black;
            }
    
            display: flex;
        }
    }

    .td, .th {
        border-right: solid 1px var(--ct-border-color);
    }


    .td {
        border-bottom: solid 1px var(--ct-border-color);
        overflow: hidden;
        text-overflow: ellipsis;

        a {
            color: var(--ct-primary);
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }

        &.cell-with-actions {

            .value-wrapper {
                overflow: hidden;
            }
            overflow: visible;
        ;}
    }

    .th {
        border-top: none;
    }

    [role="columnheader"] {
        border-top: none;

        background-color: var(--bs-table-bg);
        z-index: 2;
        overflow: visible;
    }

    .th,
    .td {
        margin: 0;
        padding: 3px 5px;
        position: relative;

        :last-child {
            border-right: 0;
        }

        .resizer {
            display: inline-block;
            background: transparent;
            width: 10px;
            height: 100%;
            position: absolute;
            right: 0;
            top: 0;
            transform: translateX(50%);
            z-index: 1;
            ${'' /* prevents from scrolling while dragging on touch devices */}
            touch-action: none;

            &.isResizing {
                background: transparent;
            }
        }
    }

    .th {
  overflow: visible !important;
  transform: none !important; /* This can help with stacking context */
}

.th-content {
  overflow: visible !important;
}

.szh-menu-container {
  z-index: 1000 !important; /* Increase z-index if needed */
}

}
`


interface TableDimensions {
    width: number;
    height: number;
}


const COLUMN_MIN_WIDTH = 30;
const ROW_HEIGHT = 25;

export const DataTable = (props: Props) => {
    const navigate = useNavigate();
    const containerRef = useRef<HTMLDivElement>(null);
    const headerGridRef = useRef<any>();
    const bodyGridRef = useRef<any>();
    const isAutoScrollingRef = useRef(false);
    const lastScrollPositionRef = useRef(0);
    const isSortingRef = useRef(false);
    const sortScrollPositionRef = useRef(0);
    const [tableDimensions, setTableDimensions] = useState<TableDimensions>({ width: 800, height: 400 });
    const [columnWidths, setColumnWidths] = useState<Record<string, number>>({});

    const tableStyles = {
        tableWrapper: {
            position: 'relative' as const,
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        headerWrapper: {
            position: 'sticky' as const,
            top: 0,
            zIndex: 1,
            backgroundColor: '#fff',
            borderBottom: '1px solid #dee2e6',
        },
        bodyWrapper: {
            width: '100%',
            height: `calc(100% - ${ROW_HEIGHT*4}px)`
        }
    };

    const columns = useMemo(
        () => props.columns.map((col, idx) => {
            return {
                Header: col.header || `column_${idx + 1}`,
                accessor: col.key || `column_${idx + 1}`,
                Cell: (data: any) => {
                    const value = data.value;
                    const format = col.formatter;
                    const formattedValue = formatValue({ value, format });

                    const innerContent: ReactNode = col.display
                        ? col.display(value, data.row.original)
                        : <span>{formattedValue}</span>;

                    if (!!col.onClick) {
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        return <a role="button" onClick={() => {
                            // @ts-ignore
                            col.onClick(value, data.row.values);
                        }}>{innerContent}</a>
                    }
                    return innerContent;
                }
            }
        }),
        [props.columns]
    );

    const data = useMemo(
        () => props.data,
        [props.data]
    );

    const manualSortPag = useMemo(
        () => !props.totalPageCount || props.totalPageCount > 1 ||  (!!props.defaultSort && props.defaultSort!.length > 0),
        [props.totalPageCount, props.defaultSort]
    );

    // Table setup with react-table
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy }
    } = useTable(
        {
            columns: columns,
            data: data,
            initialState: {
                pageIndex: 0,
                pageSize: 100,
                sortBy: props.defaultSort || []
            },
            manualSortBy: manualSortPag,
            manualPagination: manualSortPag,
            pageCount: props.totalPageCount || 1,
        },
        useSortBy,
        useResizeColumns,
        usePagination
    );

    useEffect(() => {
        if(manualSortPag && props.onFilterSortChange){
            props.onFilterSortChange({pageIndex, pageSize, sortBy});
        }
    }, [
        manualSortPag, 
        sortBy,
        pageIndex, 
        // pageSize, 
        // sortBy.length, 
        // props.onFilterSortChange
    ])

    const headerRow = headerGroups[0];

    const getColumnWidth = useCallback((index: number): number => {
        if (!headerRow?.headers) return 150; // default width if headers aren't ready
        const column = headerRow.headers[index];
        if (!column) return 150; // default width
        return columnWidths[column.id] as number || column.width as number || 150;
    }, [headerRow?.headers, columnWidths]);

    const getRowHeight = useCallback((index: number): number => {
        // all rows are the same height
        return ROW_HEIGHT;
    }, []);

    // Handle column resize
    const handleColumnResize = useCallback((columnId: string, newWidth: number) => {
        setColumnWidths(prev => ({
            ...prev,
            [columnId]: Math.max(COLUMN_MIN_WIDTH, newWidth)
        }));
        // Reset cache when column sizes change
        headerGridRef.current?.resetAfterColumnIndex(0);
        bodyGridRef.current?.resetAfterColumnIndex(0);
    }, []);

    // Handle resize start
    const handleResizeStart = useCallback((columnId: string, event: ReactMouseEvent<HTMLDivElement>) => {
        if (!headerRow?.headers) return;
        
        // Store the initial mouse position from the React synthetic event
        const startX = event.clientX;
        
        // Get the initial width of the column
        const columnIndex = headerRow.headers.findIndex(col => col.id === columnId);
        const initialWidth = getColumnWidth(columnIndex);
        
        // Use native MouseEvent type for document listeners
        const handleMouseMove = (e: MouseEvent) => {
            const deltaX = e.clientX - startX;
            const newWidth = Math.max(COLUMN_MIN_WIDTH, initialWidth + deltaX);
            handleColumnResize(columnId, newWidth);
        };
        
        const handleMouseUp = () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
        
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }, [handleColumnResize, headerRow?.headers, getColumnWidth]);

    // Handle scroll sync between grids
    // Sync scroll between header and body
    const onScroll = useCallback((scrollProps: GridOnScrollProps) => {
        if (isAutoScrollingRef.current) return;
        
        const newScrollLeft = scrollProps.scrollLeft;
        const headerEl = headerGridRef.current?._outerRef;
        const bodyEl = bodyGridRef.current?._outerRef;
        
        // Update the grid that doesn't match the new scroll position
        if (headerEl && headerEl.scrollLeft !== newScrollLeft) {
            headerEl.scrollLeft = newScrollLeft;
        }
        if (bodyEl && bodyEl.scrollLeft !== newScrollLeft) {
            bodyEl.scrollLeft = newScrollLeft;
        }

        lastScrollPositionRef.current = newScrollLeft;
    }, []);


    // Handle dimension calculations
    useEffect(() => {
        const calculateDimensions = () => {
            // Set initial dimensions based on viewport
            const viewportHeight = window.innerHeight;
            const viewportWidth = window.innerWidth;
            
            if (containerRef.current) {
                // Once container is available, use its position
                const rect = containerRef.current.getBoundingClientRect();
                const topPosition = rect.top;
                const leftPosition = rect.left;
                
                const availableHeight = viewportHeight - topPosition - 20;
                const availableWidth = viewportWidth - leftPosition - 20;
                
                setTableDimensions({
                    height: Math.max(200, availableHeight),
                    width: Math.max(400, availableWidth)
                });
            } else {
                // Initial render - use viewport dimensions
                setTableDimensions({
                    height: Math.max(200, viewportHeight - 215), // height of nav, footer, etc
                    width: Math.max(400, viewportWidth - 330) //width of sidebar
                });
            }
        };

        calculateDimensions();
        window.addEventListener('resize', calculateDimensions);
        
        const resizeObserver = new ResizeObserver(calculateDimensions);
        if (containerRef.current) {
            resizeObserver.observe(containerRef.current);
        }
        
        return () => {
            window.removeEventListener('resize', calculateDimensions);
            resizeObserver.disconnect();
        };
    }, []);

    interface CellRendererProps {
        columnIndex: number;
        rowIndex: number;
        style: React.CSSProperties;
    }

    const HeaderCellRenderer = useCallback(({ columnIndex, rowIndex, style }: CellRendererProps) => {
        if (!headerRow?.headers) return null;
        
        const headerCell = headerRow.headers[columnIndex];
        if (!headerCell) return null;
        
        return (
            <div
                {...headerCell.getHeaderProps(headerCell.getSortByToggleProps())}
                style={style}
                className="th fs-mask"
            >
                <div className="th-content user-select-none">
                    <div className="d-flex center-vertically w-100">
                        <a role="button" className="flex-1" {...headerCell.getSortByToggleProps()}>
                            {headerCell.render('Header')}
                        </a>
                        <div>
                        {headerCell.isSorted && headerCell.isSortedDesc && <i className='mdi mdi-sort-ascending'></i>}
                        {headerCell.isSorted && !headerCell.isSortedDesc && <i className='mdi mdi-sort-descending'></i>}
                        </div>
                        {props.enableColumnContextMenu && headerCell.id !== '_PLB_UUID' && (
                            <Menu
                                portal={true}
                                menuButton={
                                    <button className="icon-button" onClick={(e) => e.stopPropagation()}>
                                        <i className="mdi mdi-menu" />
                                    </button>
                                }
                            >
                                <MenuHeader>{headerCell.render('Header')}</MenuHeader>
                                {props.onShowColumnStats && <MenuItem onClick={(e) => {
                                    e.syntheticEvent?.stopPropagation();
                                    props.onShowColumnStats!(headerCell.id as string)
                                }}>Column Details</MenuItem>}
                                {props.onShowColumnLineage && <MenuItem onClick={(e) => {
                                    e.syntheticEvent?.stopPropagation();
                                    props.onShowColumnLineage!(headerCell.id as string)
                                }}>Column Lineage</MenuItem>}
                            </Menu>
                        )}
                    </div>
                </div>
                <div
                    {...headerCell.getResizerProps()}
                    className={`resizer ${headerCell.isResizing ? 'isResizing' : ''}`}
                    onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleResizeStart(headerCell.id, e);
                    }}
                />
            </div>
        );
    }, [headerRow?.headers, props.enableColumnContextMenu, props.onShowColumnStats, props.onShowColumnLineage, handleResizeStart, sortBy]);

    const BodyCellRenderer = useCallback(({ columnIndex, rowIndex, style }: CellRendererProps) => {
        if (!rows) return null;

        const row = rows[rowIndex];
        if (!row) return null;

        prepareRow(row);
        const cell = row.cells[columnIndex];
        if (!cell) return null;

        const cellProps = cell.getCellProps();
        if (props.columnFormatters?.[cell.column.id]) {
            const format = props.columnFormatters[cell.column.id](cell.value);
            if (format.cellColor) {
                style.backgroundColor = format.cellColor;
            }
            if (format.textColor) {
                style.color = format.textColor;
            }
        }

        return (
            <div
                {...cellProps}
                style={{
                    ...style,
                    ...cellProps.style
                }}
                className={`td fs-mask text-truncate ${
                    props.columnActions?.[cell.column.id] ? 'cell-with-actions' : ''
                }`}
            >
                <div className="d-flex center-vertically w-100">
                    <div className="flex-1 value-wrapper">
                        <span className="cell-value">{cell.render('Cell')}</span>
                    </div>
                </div>
            </div>
        );
    }, [rows, props.columnActions, props.columnFormatters]);

    if (props.loading) {
        return <LoadingCard />;
    }

    const theDataTable = (
        <Styles>
            <div {...getTableProps()} className="table table-sm table-bordered table-striped" style={tableStyles.tableWrapper}>
                <div className="table-light thead" style={tableStyles.headerWrapper}>
                    {/* header group */}
                    <VariableSizeGrid
                        ref={headerGridRef}
                        columnCount={headerRow?.headers?.length || 0}
                        columnWidth={getColumnWidth}
                        height={ROW_HEIGHT}
                        rowCount={1}
                        rowHeight={getRowHeight}
                        width={tableDimensions.width}
                        className="header-grid"
                        onScroll={onScroll}
                    >
                        {HeaderCellRenderer}
                    </VariableSizeGrid>
                </div>
                
                <div {...getTableBodyProps()} role="rowgroup" style={tableStyles.bodyWrapper}>
                    {/* body group */}
                    <VariableSizeGrid
                        ref={bodyGridRef}
                        columnCount={headerRow?.headers?.length || 0}
                        columnWidth={getColumnWidth}
                        height={tableDimensions.height}
                        rowCount={rows?.length || 0}
                        rowHeight={getRowHeight}
                        width={tableDimensions.width}
                        className="body-grid"
                        onScroll={onScroll}
                    >
                        {BodyCellRenderer}
                    </VariableSizeGrid>
                </div>
            </div>
        </Styles>
        
    );

    if(props.justTable){
        return theDataTable;
    }

    return (<>
        
        {theDataTable}

        <PaneFooter>
            <div className="d-flex flex-row justify-content-between">
                <div className="">
                    {props.totalRecords && props.totalRecords >=0 && <div className="ps-1" style={{lineHeight: '40px'}}>
                        <span>{summarizeNumber(props.totalRecords)} total records</span>
                    </div>}
                </div>
                <div className="">
                    <DataTablePagination
                            canPreviousPage={canPreviousPage}
                            canNextPage={canNextPage}
                            gotoPage={gotoPage}
                            previousPage={previousPage}
                            nextPage={nextPage}
                            pageIndex={pageIndex}
                            pageCount={pageCount}
                            setPageSize={setPageSize}
                            pageSize={pageSize}
                        />
                </div>
            </div>
        </PaneFooter>
    </>);
};

export default DataTable;


export function SimpleDataTable({ loading, data, columns, onCellDoubleClick, onShowColumnStats, onShowColumnLineage, onFilterSortChange, totalPageCount, totalRecords, onColumnClick, highlightRowsByIndexes, columnFormatters, initialSort, enableColumnContextMenu, enableRowContextMenu, onRowContextMenuSelect, columnActions, onCleanColumn, justTable }: SimpleProps) {
    if (loading !== true && !data) {
        return <span></span>;
    }

    if (loading !== true && !data.length) {
        return <div className="col-12 text-center mt-4">
            <p style={{fontSize: '30px'}}><i className="mdi mdi-block-helper"></i></p>
            <p>No records match that query</p>
        </div>
            
    }

    let parsedColumns : Column[] = [];

    if (loading !== true && columns?.length === 0) {
        return <div className="p-4">
        <Warning><div>
            All columns are hidden. Mark at least one column visible to see the table.
            </div>
        </Warning>
    </div>
    }

    if (columns && columns.length > 0) {
        if (typeof(columns) == 'string') {
            // @ts-ignore
            parsedColumns = columns.map((c: string) => {
                return {
                    header: c,
                    key: c,
                }
            }).slice(0, 50)
        } else {
            // @ts-ignore
            parsedColumns = columns;
        }
       
    } else if (!loading) {
        let keys = Object.keys(data[0]);

        parsedColumns = keys.map(k => {
            const h: string = k;
            if (k.includes('.')){
                // lets strip the periods because they make accesing the data impossible in react-table v7
                k = k.replaceAll('.', '')
                if (h !== k) {
                    data.forEach((rowdata) => {
                        Object.defineProperty(rowdata, k,
                            // @ts-ignore
                            Object.getOwnPropertyDescriptor(rowdata, h));
                        delete rowdata[h];
                    })
                    
                }
            }

            return {
                header: h,
                key: k,
            };
        }).slice(0, 50);
    }

    if (!!onColumnClick) {
        const clickableKeys = Object.keys(onColumnClick);
        parsedColumns = parsedColumns.map(pk => {
            if (clickableKeys.includes(pk.key)) {
                pk.onClick = onColumnClick[pk.key];
            }
            return pk;
        })
    }

    return <DataTable 
        loading={loading} 
        columns={parsedColumns} 
        columnFormatters={columnFormatters || {}}
        columnActions={columnActions || {}}
        data={data} 
        onFilterSortChange={onFilterSortChange} 
        onCellDoubleClick={onCellDoubleClick} 
        onShowColumnStats={onShowColumnStats}
        onShowColumnLineage={onShowColumnLineage}
        onCleanColumn={onCleanColumn}
        totalPageCount={totalPageCount}
        totalRecords={totalRecords}
        highlightRowsByIndexes={highlightRowsByIndexes}
        defaultSort={initialSort}
        enableColumnContextMenu={enableColumnContextMenu}
        enableRowContextMenu={enableRowContextMenu}
        onRowContextMenuSelect={onRowContextMenuSelect}
        justTable={justTable}
    />;       
}

interface GroupedProps extends SimpleProps {
    groupBy: string;
}
export function GroupedDataTable({data, groupBy}: GroupedProps) {

}

export function tableDataFromRows(rows: string[][]) {
    const header = rows[0];
    const body = rows.slice(1);

    return body.map(b => {
        const entries = b.map((val: string, idx: number) => {
            return [header[idx], val];
        });
        return Object.fromEntries(entries);
    });
}
