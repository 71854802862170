import PageStructure, { PageContent, PageContentHeader, PageContentInner } from "@pages/PageStructure.component"
import { useLocation } from "react-router-dom";
import { useBillingAccount, useProjectConfig } from "@stores/data.store";
import { Form } from "react-bootstrap";
import { useTenantRegistration } from "@services/tenant/tenant.service";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Cron from "react-js-cron";
import { useRouteBlocker } from "@services/routing.service";
import TimezoneSelect, { ITimezoneOption, type ITimezone } from 'react-timezone-select'
import styled from "styled-components";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import { DashboardIcon } from "@components/pipelineNodes/PipelineNodeIcon.component";
import CurrentBillingPlan from "@components/billing/CurrentPlan.component";
import { ReadOnly } from "@components/project/DraftModeRequired.component";
import { useAuthState } from "@services/auth/auth.service";
import TemplateInstallations from "@components/templates/TemplateInstallations.component";
import { useEntitlements } from "@frontegg/react";
import SaveButton from "@components/button/SaveButton.component";
import TenantConfigService from "@services/tenantConfig.service";
import TrackingService, { Events } from "@services/tracking.service";
import toast from "@services/toast.service";
import { useImmer } from "use-immer";
import { getErrorMessage } from "@services/errors.service";
import { useFeatureFlag } from "@services/featureFlags/featureFlag.service";



const CronWrapper = styled.div`
    border:solid 1px var(--ct-border-color);
    padding: 1rem;
    border-radius: .25rem;

    .react-js-cron-field {
        margin-bottom: 0px !important;
    }
`
const SettingsWrapperPage = () => {
    const location = useLocation();
    const lastHash = useRef('');
    const tenantRegistration = useTenantRegistration();

    const {isLoading: loadingBillingAccount, data: billingAccount } = useBillingAccount(true);

    const subscriptionStatus = useMemo(() => {
        return tenantRegistration.data?.tenant_config?.subscription_status;
    }, [tenantRegistration.dataUpdatedAt])

    const [cronExpression, setCronExpression] = useState('');
    const [scheduleEnabled, setScheduleEnabled] = useState(false);
    const {dataUpdatedAt, data: tenantConfig} = useProjectConfig();

    const [projectVariables, setProjectVariables] = useImmer<{[key: string]: string}>({});


    const [selectedTimezone, setSelectedTimezone] = useState<ITimezone>(
        Intl.DateTimeFormat().resolvedOptions().timeZone
    )

    const authState = useAuthState();

    const templateUpgradeEnabled = useFeatureFlag('template-upgrade', false);
    
    useEffect(() => {
        if(loadingBillingAccount) {
            return;
        }

        if (location.hash) {
          lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
        }
    
        if (lastHash.current && document.getElementById(lastHash.current)) {
          setTimeout(() => {
            document
              .getElementById(lastHash.current)
              ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            lastHash.current = '';
          }, 400);
        }
      }, [location, loadingBillingAccount]);

    useEffect(() => {
        if (!tenantRegistration.data) {
            return;
        }

        setSelectedTimezone(tenantRegistration.data.tenant_config.build_schedule.timezone || '');
        setCronExpression(tenantRegistration.data.tenant_config.build_schedule.cron_expression);
        setScheduleEnabled(tenantRegistration.data.tenant_config.build_schedule.enabled);
        setProjectVariables(tenantRegistration.data.tenant_config.dbt_variables || {});
    }, [tenantRegistration.dataUpdatedAt]);

    const [newVariableName, setNewVariableName] = useState('');
    const addNewVariable = useCallback(() => {
        if (!newVariableName) {
            return;
        }
        setProjectVariables(draft => {
            draft[newVariableName] = '';
        });
        setNewVariableName('');
    }, [newVariableName]);

    const { pageDirty, setPageDirty } = useRouteBlocker(() => {

    });


    const onCronChange = useCallback((value: string) => {
        if (!tenantRegistration.data) {
            return;
        }

        setCronExpression(value);
        if (tenantRegistration.data.tenant_config.build_schedule.cron_expression != value) {
            setTimeout(() => {
                setPageDirty(true);
            }, 0);
        }

    }, [tenantRegistration]);

    const onTimezoneChange = useCallback((value: ITimezone) => {
        setSelectedTimezone(value);
        setPageDirty(true);
    }, []);

    const onScheduleEnabledChange = useCallback(() => {

        setScheduleEnabled(!scheduleEnabled);
        setPageDirty(true);

    }, [scheduleEnabled]);

    const onSave = useCallback(async () => {
        try {
            const updates = {
                cron_expression: cronExpression,
                timezone: (selectedTimezone.hasOwnProperty('label')) ? (selectedTimezone as ITimezoneOption).value : selectedTimezone,
                enabled: scheduleEnabled,
            };
            await TenantConfigService.getInstance().saveBuildSchedule(tenantConfig!.id as string, updates);
    
            // This is for when we add back the EU vs USD selector
            // if (formatLocale) {
            //     setForceSupersetLogout(false);
            //     await TenantConfigService.getInstance().saveFormatLocale(tenantConfig!.id as string, formatLocale);
            //     setForceSupersetLogout(true);
            // }
    
            TrackingService.track_event(Events.BUILD_SCHEDULE_UPDATED, updates);
            toast('success', 'Success!', 'Build Schedule Saved');
            setPageDirty(false);
        } catch (error) {
            toast('error', 'Error!', 'Failed to save build schedule');
        }
    }, [cronExpression, selectedTimezone, scheduleEnabled, tenantConfig, setPageDirty]);


    const saveVariables = useCallback(async () => {
        try {
            await TenantConfigService.getInstance().saveVariables(tenantConfig!.id as string, projectVariables);
            toast('success', 'Success!', 'Variables saved');
        } catch (err) {
            toast('danger', 'Error!', getErrorMessage(err));
        }
    }, [projectVariables]);



    return <PageStructure>
        <PageContent>
            <PageContentHeader>
                <div>
                    <DashboardIcon bgColor="dark" icon="mdi mdi-cog"/>
                    <h1 className="mb-0">Account Settings</h1>
                </div>
            </PageContentHeader>
            <PageContentInner hasHeader>
                <div className="p-4">
                    <ReadOnly>            
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2>Snowflake Settings</h2>
                                {tenantRegistration.data?.tenant_config.hosted_account && <InfoAlert>
                                    Your Snowflake account is fully managed by Pliable.
                                </InfoAlert>}
                                <div className="row mt-3">
                                    <div className="col-6">
                                        <Form.Group>
                                            <Form.Label>Account Identifier</Form.Label>
                                            <Form.Control disabled value={tenantRegistration.data?.connection_creds.account} />
                                            {/* <Form.Control value={tenantConfig?.} disabled={true} /> */}
                                        </Form.Group>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ReadOnly>

                        <div className="card mb-3">

                            <div className="card-body">
                                <div className="d-flex center-vertically mb-3">
                                    <h2 className="flex-1 mb-0">Orchestration</h2>
                                    <div>

                                        <Form.Group>
                                            <Form.Check
                                                id="schedule_enabled_check"
                                                type="switch"
                                                label={`Schedule ${(scheduleEnabled) ? 'Enabled' : 'Disabled'}`}
                                                checked={scheduleEnabled}
                                                onChange={onScheduleEnabledChange}
                                            />
                                            <Form.Text className="text-muted">
                                            </Form.Text>
                                        </Form.Group>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <Form.Group className="mb-3">
                                            <Form.Label>Build Schedule</Form.Label>
                                            <CronWrapper>
                                                <Cron
                                                    value={cronExpression}
                                                    setValue={onCronChange}
                                                    clockFormat="12-hour-clock"
                                                    clearButton={false}
                                                />
                                                <div className="mt-2">
                                                    <TimezoneSelect
                                                        value={selectedTimezone}
                                                        onChange={onTimezoneChange}
                                                        placeholder="Select Timezone"
                                                    />
                                                </div>
                                                <div className="mt-2">
                                                    <SaveButton
                                                        onClick={onSave}
                                                        disabled={!(pageDirty)}
                                                    />
                                                </div>
                                            </CronWrapper>
                                        </Form.Group>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h2 id="billing">Billing</h2>
                                {subscriptionStatus == 'enterprise' && <>
                                    <InfoAlert>Your billing is managed outside of the app. To make a change to your payment information or your plan, please contact your Pliable account manager.</InfoAlert>
                                </>}

                                {subscriptionStatus != 'enterprise' && <>
                                    <CurrentBillingPlan billingAccount={billingAccount} allowCancel={true} /> 
                                </>}
                                    
                            </div>
                        </div>
                        {templateUpgradeEnabled &&
                            <div className="card mb-3">
                                <div className="card-body">
                                    <TemplateInstallations />
                                </div>
                            </div>
                        }           
 
                        <div className="card mb-3">
                            <div className="card-body">
                                <div className="d-flex center-vertically">
                                    <h2 className="mb-0 flex-1">
                                        Variables
                                    </h2>
                                    <button className="btn btn-success" onClick={saveVariables}>Save</button>
                                </div>
                                <p>Set DBT variables that will be available anywhere you use custom SQL (including custom transforms). You can use them like <code>&#123;&#123;var('VAR_NAME')&#125;&#125;</code></p>
                                <div className="mb-2">
                                    <input type="text" className="form-control me-1" style={{width: '300px', display: 'inline-block'}} placeholder="Enter variable name" value={newVariableName} onChange={(e) => setNewVariableName(e.target.value)} />
                                    <button className="btn btn-secondary" onClick={addNewVariable}>Add</button>
                                </div>
                                
                                <table className="table table-centered">
                                    <thead>
                                        <tr>
                                            <th>Variable</th>
                                            <th>Value</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(projectVariables).map((key) => {
                                            return <tr>
                                                <td>{key}</td>
                                                <td><input type="text" value={projectVariables[key]} onChange={(e) => {
                                                    setProjectVariables(draft => {
                                                        draft[key] = e.target.value;
                                                    });
                                                }} /></td>
                                                <td>
                                                    <button className="icon-button font-24" onClick={() => {
                                                        setProjectVariables(draft => {
                                                            delete draft[key];
                                                        });
                                                    }}>
                                                        <i className="mdi mdi-delete"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>         
                </div>
            </PageContentInner>
        </PageContent>
    </PageStructure>
}

export default SettingsWrapperPage;