import { BuildError } from "@components/missionControl/dataflow/MissionControlDataFlow";
import Danger from "@components/statusIndicators/Danger.component";
import InfoAlert from "@components/statusIndicators/InfoAlert.component";
import SuccessAlert from "@components/statusIndicators/SuccessAlert.component";
import Warning from "@components/statusIndicators/Warning.component";
import { BuildExecution, BuildOrchestration, ModelStatus } from "@models/buildOrchestration";
import { decimalFormatter } from "@services/formatting.service";
import { getNodeTypeConfig } from "@services/modeling.service";
import { timeAgo } from "@services/time.service";
import { useDataMart, usePipelineNode } from "@stores/data.store";
import { useMemo } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from 'styled-components';

const NodeIcon = styled.div`
width: 25px;
height: 25px;
min-width: 25px;
line-height: 25px;
border-radius: 100%;
text-align: center;
margin-right: .5rem;
`
interface PipelineNodeExecutionProps {
    modelStatus: ModelStatus;
}

const PipelineNodeExecution = (props: PipelineNodeExecutionProps) => {
    const pn = usePipelineNode(props.modelStatus.node_id);

    const nodeConfig = useMemo(() => {
        if (pn.data) {
            console.log('Getting node type config for:', pn.data);
            return getNodeTypeConfig(pn.data);
        }
        return undefined;
    }, [pn.dataUpdatedAt]);
    return <div className="shadow-box p-2">
        {pn.isLoading && <div>
            <i className="mdi mdi-loading mdi-spin"></i>
        </div>}
        {pn.data && <div>
            <div className="d-flex mb-2 center-vertically">
                {!!nodeConfig && nodeConfig.group && <NodeIcon className={`bg-${nodeConfig.group.color}`}><i className={nodeConfig.nodeType.icon}/></NodeIcon>}
                <h4 className="mb-0 flex-1">
                    {pn.data.node_type == 'SEED' && (
                        <span>{pn.data.label}</span>
                    )}
                    {pn.data.node_type != 'SEED' && (
                        <Link to={`/node/${pn.data.id}/config`}>{pn.data.label}</Link>

                    )}
                </h4>
            </div>
            
            {props.modelStatus.status == 'started' && (
                <Badge bg="primary"><i className="mdi mdi-loading mdi-spin"></i> Running</Badge>
            )}
            {/* {props.buildExecution.status == 'QUEUED' && (
                <Badge bg="info">Queued</Badge>
            )} */}
            {props.modelStatus.status == 'success' && (
                <Badge bg="success">
                    <i className="mdi mdi-check-circle"></i> Complete
                </Badge>
            )}
            {props.modelStatus.status == 'queued' && (
                <Badge bg="light">
                    <i className="mdi mdi-clock"></i> Queued
                </Badge>
            )}
            {props.modelStatus.status == 'skipped' && (
                <div><Badge bg="warning">Skipped</Badge> (no changes since last build)</div>
            )}
            {props.modelStatus.status == 'error' && (
                <div>
                    <Badge bg="danger">
                        <i className="mdi mdi-alert-circle"></i>Failed
                    </Badge>
                    <div><code>{props.modelStatus.error}</code></div>
                </div>
            )}
        </div>}
    </div>  
}

const DataMartExecution = (props: PipelineNodeExecutionProps) => {
    const dm = useDataMart(props.modelStatus.node_id);
    return <div className="shadow-box p-2">
        {dm.isLoading && <div>
            <i className="mdi mdi-loading mdi-spin"></i>
        </div>}
        {dm.data && <div>
            <h4><Link to={`/reporting/${dm.data.id}`}>{dm.data.name}</Link></h4>
            {props.modelStatus.status == 'started' && (
                <InfoAlert>
                    <div><i className="mdi mdi-loading mdi-spin"></i> Running</div>
                </InfoAlert>
            )}
            {/* {props.buildExecution.status == 'QUEUED' && (
                <Badge bg="info">Queued</Badge>
            )} */}
            {props.modelStatus.status == 'success' && (
                <SuccessAlert>
                    Build completed successfully!
                </SuccessAlert>
            )}
            {props.modelStatus.status == 'queued' && (
                <InfoAlert>Your build is queued. It will begin shortly.</InfoAlert>
            )}
            {props.modelStatus.status == 'error' && (
                <div>
                    <Badge bg="danger">Failed</Badge>
                    <pre><code>{props.modelStatus.error}</code></pre>
                </div>
            )}
        </div>}
    </div>  
}

interface PipelineOrchestrationProps {
    orchestration: BuildOrchestration;
}

const PipelineOrchestration = (props: PipelineOrchestrationProps) => {
    const sortedLogs = useMemo(() => {
        if (!props.orchestration.build_logs) {
            return [];
        }
        return [...props.orchestration.build_logs].sort((a, b) => {
            if (a.seconds < b.seconds) {
                return 1;
            }
            return -1;
        })
    }, [props.orchestration.id, props.orchestration.build_logs ? props.orchestration.build_logs.length : 0]);

    const runningModelIds = useMemo(() => {
        return Object.keys(props.orchestration.model_statuses).filter(k => {
            return props.orchestration.model_statuses[k].status == 'started';
        });
    }, [props.orchestration.model_statuses]);

    const failedModelIds = useMemo(() => {
        return Object.keys(props.orchestration.model_statuses).filter(k => {
            return props.orchestration.model_statuses[k].status == 'error';
        });
    }, [props.orchestration.model_statuses]);

    const completeModelIds = useMemo(() => {
        return Object.keys(props.orchestration.model_statuses).filter(k => {
            return props.orchestration.model_statuses[k].status == 'success';
        });
    }, [props.orchestration.model_statuses])

    const otherModelIds = useMemo(() => {
        return Object.keys(props.orchestration.model_statuses).filter(k => {
            return !['started', 'error', 'success'].includes(props.orchestration.model_statuses[k].status);
        });
    }, [props.orchestration.model_statuses])

    return <div>
        {/* {props.orchestration.status == 'ERROR' && (
            <button className="btn-close" id="test-id"></button>
        )}                             */}
        <h2> 
        {props.orchestration.status == 'RUNNING' && (
            <InfoAlert>
                <div><i className="mdi mdi-loading mdi-spin"></i> Running</div>
            </InfoAlert>
        )}
        {props.orchestration.status == 'QUEUED' && (
            <InfoAlert>
                Your build is queued. It will begin shortly.
            </InfoAlert>
        )}
        {props.orchestration.status == 'CANCELLED' && (
            <Warning>
                This build was cancelled.
            </Warning>
        )}
        {props.orchestration.status == 'COMPLETE' && (
            <SuccessAlert>Build completed successfully!</SuccessAlert>
        )}
        {props.orchestration.status == 'ERROR' && (
            <Danger>Build failed!</Danger>
        )}</h2>
        <div className="row">
            <div className="col-8">
                <h3>Logs</h3>
                <table className="table table-condensed table-fixed">
                    <thead>
                        <tr>
                            <th style={{width: '60px'}}>Time</th>
                            <th>Message</th>
                        </tr>
                    </thead>
                    <tbody className="font-code">
                        {sortedLogs.map(l => {
                            return <tr>
                                <td>{decimalFormatter(l.seconds)}s</td>
                                <td>{l.message}</td>
                            </tr>
                        })}
                    </tbody>
                </table>
            </div>
            <div className="col-4">
                <h3>Model Details</h3>
                {runningModelIds.map(k => {
                    if (props.orchestration.model_statuses[k].is_legacy_datamart) {
                        return <div className="mb-2">
                            <DataMartExecution modelStatus={props.orchestration.model_statuses[k]}/>
                        </div>
                    }
                    return <div className="mb-2">
                        <PipelineNodeExecution modelStatus={props.orchestration.model_statuses[k]}/>
                    </div>
                })}

                {failedModelIds.map(k => {
                    if (props.orchestration.model_statuses[k].is_legacy_datamart) {
                        return <div className="mb-2">
                            <DataMartExecution modelStatus={props.orchestration.model_statuses[k]}/>
                        </div>
                    }
                    return <div className="mb-2">
                        <PipelineNodeExecution modelStatus={props.orchestration.model_statuses[k]}/>
                    </div>
                })}

                {completeModelIds.map(k => {
                    if (props.orchestration.model_statuses[k].is_legacy_datamart) {
                        return <div className="mb-2">
                            <DataMartExecution modelStatus={props.orchestration.model_statuses[k]}/>
                        </div>
                    }
                    return <div className="mb-2">
                        <PipelineNodeExecution modelStatus={props.orchestration.model_statuses[k]}/>
                    </div>
                })}

                {otherModelIds.map(k => {
                    if (props.orchestration.model_statuses[k].is_legacy_datamart) {
                        return <div className="mb-2">
                            <DataMartExecution modelStatus={props.orchestration.model_statuses[k]}/>
                        </div>
                    }
                    return <div className="mb-2">
                        <PipelineNodeExecution modelStatus={props.orchestration.model_statuses[k]}/>
                    </div>
                })}
            </div>
        </div>
        
        
    </div>
}

export default PipelineOrchestration;