import styled from 'styled-components';
import { Pane, PaneContent } from "./PageStructure.component";
import pliableLogo from '@assets/images/pliable/logo_with_text_black.png'
import { Link } from 'react-router-dom';


const Styles  = styled.div`
    h1 {
        font-size: 2rem;
        margin-bottom: 1.5rem;
    }
`


const Page404 = () => {


    return <div className="d-flex">
        <div style={{width: '50%', height: '100vh'}} className=" border-right bg-white">
            <Pane>
                <PaneContent>
                    <div className="p-5">
                        <img src={pliableLogo} style={{width: '200px'}} className="mb-5"/>

                        <h1>Page Note Found - 404</h1>
                        <p>We can not find the page you are looking for</p>
                        <hr />
                        <Link to="/" className="btn btn-primary">
                            <i className="mdi mdi-home"></i> Go to Home
                        </Link>
                    </div>
                </PaneContent>
            </Pane>
        </div>
    </div>
}

export default Page404;