import { useCallback, useEffect, useState } from "react";
import { Form, Modal } from "react-bootstrap";
import { CopyBlock, atomOneLight } from "react-code-blocks";


export interface ShareTextModalProps {
    onClose: () => void;
    show?: boolean;
    header: string;
    label: string;
    text: string;
    helpText?: string;
}

const ShareTextModal = (props: ShareTextModalProps) => {
    const close = useCallback(() => {
        props.onClose();
    }, [props.onClose]);

    return (
        <Modal show={props.show}  backdrop="static">
            <Modal.Header closeButton onClick={() => close()}>
                <Modal.Title>{props.header}</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{fontSize: '1.4em'}}>
                <Form.Group>
                <CopyBlock
                        text={props.text}
                        showLineNumbers={false}
                        theme={atomOneLight}
                        codeBlock={true}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => close()}>Close</button>
            </Modal.Footer>
        </Modal>

    )
}

export default ShareTextModal;